import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@rhythm/components';
import { useField } from 'formik';
import { ChangeEvent } from 'react';

export interface CheckboxProps {
  name: string;
  children: React.ReactNode;
  checkBoxProps?: ChakraCheckboxProps;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function Checkbox({ name, checkBoxProps, onChange, children }: CheckboxProps) {
  const [field] = useField(name);
  const isChecked = field.value;

  return (
    <ChakraCheckbox
      {...checkBoxProps}
      {...field}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        field.onChange(event);
        onChange && onChange(event);
      }}
      isChecked={isChecked}
    >
      {children}
    </ChakraCheckbox>
  );
}
