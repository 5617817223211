import React from 'react';
import {
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  Icon,
  InputProps,
} from '@rhythm/components';
import { useField } from 'formik';
import ReactDatePicker from 'react-datepicker';

export interface MonthPickerProps {
  name: string;
}

export function MonthPicker({ name }: MonthPickerProps) {
  const [field, meta, helpers] = useField(name);

  const selectedDate: Date = meta.value && new Date(field.value);

  return (
    <>
      <FormLabel fontSize="xs">Month</FormLabel>
      <ReactDatePicker
        {...field}
        dateFormat="MMM, yyyy"
        selected={selectedDate}
        showMonthYearPicker
        customInput={<DatePickerInput />}
        onChange={(date) => helpers.setValue(date)}
        value={meta.value}
      />
    </>
  );
}

const DatePickerInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <InputGroup>
      <Input ref={ref} {...props} />
      <InputRightElement>
        <Icon icon="calendar" color="neutral.800" boxSize="sm" />
      </InputRightElement>
    </InputGroup>
  );
});
