import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from '@rhythm/components';
import { useDeleteClinicallNote, useUpdateClinicalNote } from '~src/services/notes';
import { getTimeZoneShortCode } from '~src/utils';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useCallback, useEffect, useState } from 'react';
import { editDeleteNotes } from '~src/constants/roles';
import RoleBasedRoutes from '../RoleBasedRoutes';

dayjs.extend(timezone);

type NoteResponse = {
  id: string;
  notes: string | any[];
  firstname: string;
  lastname: string;
  editedByUserFirstName?: string;
  editedByUserLastName?: string;
  newConvertedtime: Date;
  createdAt: Date;
  updatedAt: Date;
  minutes: number;
};

type NoteCardProps = {
  note: NoteResponse;
  timezone: string | undefined;
  isLoading: boolean;
};

export function NoteCard({ note, timezone, isLoading }: NoteCardProps) {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [hasBeenEdited, setHasBeenEdited] = useState<boolean>(false);
  const [editedText, setEditedText] = useState<string>('');
  const [noteState, setNoteState] = useState<NoteResponse>(note);
  const updateClinicalNote = useUpdateClinicalNote();
  const deleteClinicalNote = useDeleteClinicallNote();
  const [textChange, setTextChange] = useState('');

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setTextChange(value);
  }, []);

  useEffect(() => {
    if (hasBeenEdited) {
      setEditedText(`Edited by you`);
    } else {
      setEditedText(
        `Edited by ${noteState?.editedByUserFirstName?.charAt(
          0
        )}. ${noteState?.editedByUserLastName}`
      );
    }
  }, [hasBeenEdited, noteState]);

  const saveClinicalNote = async (id: string) => {
    if (!textChange) return;

    const payload = {
      id,
      notes: textChange,
    };

    await updateClinicalNote.mutate(payload);

    const newNoteState = {
      ...noteState,
      notes: textChange,
    };

    setNoteState(newNoteState);
    setHasBeenEdited(true);
    setEditMode(false);
  };

  const isEditedNote = (note: NoteResponse) => {
    const { editedByUserFirstName, editedByUserLastName } = note;
    return editedByUserFirstName && editedByUserLastName;
  };

  const removeNote = async (id: string) => {
    await deleteClinicalNote.mutate({ id });
    setEditMode(false);
  };

  return (
    <>
      {typeof noteState.notes === 'object' &&
      noteState.notes &&
      typeof noteState.notes.map === 'function' ? (
        <Grid
          templateColumns="1fr 1fr 1fr 2fr 1fr"
          borderBottomWidth="1px"
          borderBottomColor="neutral.200"
          py="lg"
        >
          <Box>
            <Text fontWeight={'bold'}>{`${noteState?.lastname}, ${noteState?.firstname}`}</Text>
            <Text fontSize={'12px'}>
              {noteState.newConvertedtime} {getTimeZoneShortCode(timezone as string)}
            </Text>
          </Box>
          <Text fontWeight={'bold'} marginLeft={'15px'}>
            {noteState?.minutes}
          </Text>
          <Grid minW={'320%'} maxW={'325%'}>
            {noteState.notes?.map((subNote: any, index) => (
              <Box marginBottom={'5px'} key={`sub-${index}`}>
                <Text>
                  {' '}
                  <span style={{ fontWeight: 'bold' }}>{subNote.readingType}:</span>
                  {subNote.reading}{' '}
                </Text>{' '}
                <Text fontSize={'12px'}>
                  {dayjs(subNote?.time)
                    .tz(timezone)
                    .format('MM/DD/YY h:mm A')}{' '}
                  {getTimeZoneShortCode(timezone as string)}
                </Text>
              </Box>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid
          templateColumns="1fr 1fr 1fr 2fr 1fr"
          mb="lg"
          borderBottomWidth="1px"
          borderBottomColor="neutral.200"
          py="lg"
        >
          <Box>
            <Text fontWeight={'bold'}>{`${noteState?.lastname}, ${noteState?.firstname}`}</Text>
            <Text fontSize={'12px'}>
              {noteState.newConvertedtime} {getTimeZoneShortCode(timezone as string)}
            </Text>
          </Box>
          <Text fontWeight={'bold'} marginLeft={'15px'}>
            {noteState?.minutes}
          </Text>
          <Grid minW={'320%'} maxW={'325%'}>
            {editMode ? (
              <Grid alignContent="left">
                {' '}
                <Textarea
                  h={'150px'}
                  onChange={handleChange}
                  defaultValue={noteState.notes}
                  mb="2"
                  variant="default"
                />{' '}
                <Box>
                  <Flex justifyContent="flex-end">
                    <Button
                      width="70px"
                      height="30px"
                      onClick={() => setEditMode(false)}
                      variant="plain"
                    >
                      {' '}
                      Cancel{' '}
                    </Button>
                    <Button
                      width="50px"
                      disabled={!textChange}
                      isLoading={isLoading}
                      onClick={() => saveClinicalNote(noteState.id)}
                      color="#0E6DAD"
                      height="30px"
                      variant="plain"
                    >
                      {' '}
                      Save{' '}
                    </Button>
                  </Flex>
                </Box>
              </Grid>
            ) : (
              <>
                <div style={{ whiteSpace: 'pre-wrap' }}>{noteState?.notes}</div>{' '}
                {isEditedNote(noteState) || hasBeenEdited ? (
                  <Grid>
                    {' '}
                    <Text color="primary.500" fontStyle="italic" fontSize="11">
                      {editedText}
                    </Text>{' '}
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>
          <RoleBasedRoutes allowedRoles={editDeleteNotes}>
            <Grid gap="lg" position={'absolute'} right={'8'} templateColumns="24px 1fr" ml="20px">
              <Tooltip hasArrow label="edit" placement="top">
                <div style={{ cursor: 'pointer' }}>
                  <Icon boxSize="4" onClick={() => setEditMode(true)} icon="edit" />
                </div>
              </Tooltip>

              <Popover placement="top">
                <PopoverTrigger>
                  <div>
                    <Icon boxSize="4" icon="delete" />
                  </div>
                </PopoverTrigger>
                <PopoverContent w="180px" border={0}>
                  <VStack
                    alignItems="flex-start"
                    spacing="xl"
                    bg="neutral.black"
                    p="xl"
                    w="full"
                    borderRadius="md"
                    borderWidth="x1"
                    borderColor="neutral.400"
                    boxShadow="0px 2px 16px rgba(108, 119, 137, 0.32)"
                  >
                    <Box>
                      <Text fontSize="12" color="white">
                        {' '}
                        Are you sure you want to delete this note?
                      </Text>
                    </Box>
                    <Box flexDirection="row">
                      <Button mr="5px" size="sm" backgroundColor="transparent" variant="plain">
                        Cancel
                      </Button>

                      <Button onClick={() => removeNote(note.id)} size="sm">
                        Delete
                      </Button>
                    </Box>
                  </VStack>
                </PopoverContent>
              </Popover>
            </Grid>
          </RoleBasedRoutes>
        </Grid>
      )}
    </>
  );
}
