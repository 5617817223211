import { Button, Heading, Card, Box } from '@rhythm/components';
import { Formik, Form, FormikProps } from 'formik';
import dayjs from 'dayjs';
import { useBillingReport } from '~src/services/billing';
import { MonthPicker } from '../MonthPicker';

interface BillingReportFormValues {
  month: Date;
}

const currentMonth = dayjs().startOf('month').toDate();

const initialValues: BillingReportFormValues = {
  month: currentMonth,
};

export function BillingReportForm() {
  const createExport = useBillingReport();

  const handleSubmit = async (values: BillingReportFormValues) => {
    const originalDate = new Date(values.month);

    // Get the month and year from the original date
    const month = originalDate.getMonth();
    const year = originalDate.getFullYear();

    // Create a new date in UTC using the extracted month and year
    const utcDate = new Date(Date.UTC(year, month, 1, 0, 0, 0));

    const file = await createExport(utcDate);
    window.open(file, '_blank')?.focus();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }: FormikProps<BillingReportFormValues>) => (
        <Form>
          <Heading variant="h4" mb="xl">
            Create Billing Report
          </Heading>
          <Card py="xl" px="xl">
            <Box mb="4">
              <MonthPicker name="month" />
            </Box>
            <Button type="submit" size="md" isLoading={isSubmitting}>
              Export
            </Button>
          </Card>
        </Form>
      )}
    </Formik>
  );
}
