import { Fragment } from 'react';
import { Text, Heading, Flex, Box, Grid, GridItem } from '@rhythm/components';
import dayjs from 'dayjs';
import { SocialNote } from '~generated';
export interface AlertDataModalProps {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export interface NewSocialNote extends SocialNote {
  convertedTimestamp: string;
}
export interface AlertPinnedNotesDataModalProps {
  pinnedNotes: NewSocialNote[];
}

export function PatientWidgetNotesData({ pinnedNotes }: AlertPinnedNotesDataModalProps) {
  return (
    <Fragment>
      <Flex flexDirection="column">
        <Heading variant="h6">Pinned Notes :</Heading>
        <Box
          maxHeight="270px"
          minHeight="50px"
          overflowY="scroll"
          overflowX="hidden"
          my="xl"
          width="100%"
          position="relative"
          css={{
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '96px',
              left: 'calc(50% - 6px/2)',
              top: '8px',
              background: '#6C7789',
            },
            '&::-webkit-scrollbar-track': {
              background: '#FFFFFF',
              opacity: 0.5,
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '100px',
              background: '#6C7789',
            },
          }}
        >
          {pinnedNotes && pinnedNotes?.length > 0 ? (
            pinnedNotes?.map((note, index) => (
              <Grid
                p="xl"
                bg={'feedback.warningLight'}
                key={index}
                borderRadius="md"
                border="neutral.400"
                borderWidth="1px"
                alignItems="top"
                gap="xl"
                mb="md"
                position="relative"
                marginRight="2px"
                _hover={{
                  '.tileWidget': {
                    display: 'block',
                  },
                }}
                templateRows="repeat(2, 1fr)"
                templateColumns="repeat(9, 1fr)"
              >
                <GridItem colSpan={8} rowSpan={1}>
                  <Box>{note.notes}</Box>
                </GridItem>
                <GridItem colSpan={4} rowSpan={1}>
                  <Box>
                    <Text size="sm" color="neutral.800">
                      {dayjs(note.convertedTimestamp).format('MMMM D YYYY')}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem colSpan={4} rowSpan={1} colStart={9} colEnd={6} w={'auto'}>
                  <Box>
                    <Text size="sm" color="neutral.800">
                      Created by: {note.user.lastName + ',' + note.user.firstName}
                    </Text>
                  </Box>
                </GridItem>
              </Grid>
            ))
          ) : (
            <Text>No Notes Available</Text>
          )}
        </Box>
      </Flex>
    </Fragment>
  );
}
