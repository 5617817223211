import { makeOptionsArray } from '~src/utils';
import type { Option } from '~src/utils';
import { DeviceThreshold } from '~generated';

export const devices = {
  [DeviceThreshold.metricType.BLOOD_PRESSURE]: 'Blood Pressure Monitor',
  [DeviceThreshold.metricType.BLOOD_SUGAR]: 'Glucometer',
  [DeviceThreshold.metricType.BLOOD_OXYGEN]: 'Pulse Oximetry',
  [DeviceThreshold.metricType.WEIGHT]: 'Scale',
};

export enum DeviceTypes {
  blood_pressure = 'Blood Pressure Monitor',
  weight = 'Scale',
  blood_sugar = 'Glucometer',
  blood_oxygen = 'Pulse Oximetry',
}

export const vendors = [
  {
    name: 'iGlucose',
    devices: ['GLUCOMETER', 'BP_MONITOR', 'SCALE'],
    url: 'http://iGlucose.com',
  },
  {
    name: 'BodyTrace',
    devices: ['SCALE', 'BP_MONITOR'],
    url: 'http://bodytrace.com',
  },
  {
    name: 'tellihealth',
    devices: ['BP_MONITOR'],
    url: 'https://www.tellihealth.com/',
  },
];

export const devicesOptionsArray: Option[] = makeOptionsArray(devices);
