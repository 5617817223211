import { useEffect, useState } from 'react';
import {
  TextButton,
  Modal,
  Button,
  Text,
  Heading,
  HStack,
  Flex,
  SimpleGrid,
  Box,
} from '@rhythm/components';
import { useParams } from 'react-router';
import { Formik, Form, FormikProps } from 'formik';
import { FormField } from '../FormField';
import { useCreatePatientDataExport } from '~src/services/patients';
import { Checkbox } from '../Checkbox';
import { FormCalendar } from '../FormCalendar';
import dayjs from 'dayjs';

interface ExportDataFormValues {
  startDataRange: Date;
  endDataRange: Date;
  startNotesRange: Date;
  endNotesRange: Date;
  bloodPressure: boolean;
  pulse: boolean;
  weight: boolean;
  glucose: boolean;
  pulseOx: boolean;
  includeNotes: boolean;
  additionalNote: string;
}

const startOfMonth = dayjs().startOf('month').toDate();
const endOfMonth = dayjs().endOf('month').toDate();

export function ExportData({ devices }) {
  const { id: patientId } = useParams<{ id: string }>();
  const [open, setOpen] = useState<boolean>(false);
  const createExport = useCreatePatientDataExport(patientId);
  const [hasBloodPressure, setHasBloodPressure] = useState<boolean>(false);
  const [hasBloodSugar, setHasBloodSugar] = useState<boolean>(false);
  const [hasPulseOx, setHasPulseOx] = useState<boolean>(false);
  const [hasWeight, setHasWeight] = useState<boolean>(false);

  const initialValues: ExportDataFormValues = {
    startDataRange: startOfMonth,
    endDataRange: endOfMonth,
    startNotesRange: startOfMonth,
    endNotesRange: endOfMonth,
    additionalNote: '',
    glucose: hasBloodSugar,
    bloodPressure: hasBloodPressure,
    pulse: hasPulseOx || hasBloodPressure,
    weight: hasWeight,
    pulseOx: hasPulseOx,
    includeNotes: true,
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    devices.forEach((device) => {
      if (device.type === 'blood_pressure') {
        console.log('bp');
        setHasBloodPressure(true);
      }
      if (device.type === 'blood_sugar') {
        setHasBloodSugar(true);
      }
      if (device.type === 'blood_oxygen') {
        setHasPulseOx(true);
      }
      if (device.type === 'weight') {
        setHasWeight(true);
      }
    });
  }, [devices]);

  const handleSubmit = async (values: ExportDataFormValues) => {
    const file = await createExport({
      ...values,
      startDataRange: dayjs(values.startDataRange).format('YYYY-MM-DD'),
      endDataRange: dayjs(values.endDataRange).format('YYYY-MM-DD'),
      startNotesRange: dayjs(values.startDataRange).format('YYYY-MM-DD'),
      endNotesRange: dayjs(values.endDataRange).format('YYYY-MM-DD'),
    });
    window.open(file, '_blank')?.focus();
    setOpen(false);
  };

  return (
    <>
      <TextButton leftIcon="download" color="primary.500" onClick={() => setOpen(true)}>
        Export
      </TextButton>
      <Modal
        isOpen={open}
        onClose={handleClose}
        footer={
          <HStack spacing="4">
            <Button variant="secondaryDark" type="reset" form="export-data-form">
              Close
            </Button>
            <Button type="submit" form="export-data-form">
              Export
            </Button>
          </HStack>
        }
      >
        <Flex flexDirection="column" mb="8">
          <Heading variant="h4">Export Report</Heading>
          <Text>Select content below to include in export</Text>
        </Flex>
        <Flex flexDirection="column">
          <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={handleClose}>
            {({ values, errors }: FormikProps<ExportDataFormValues>) => (
              <Form id="export-data-form">
                <Text>
                  {Object.entries(errors)
                    .map(([key, val]) => `${key}: ${val}`)
                    .join(', ')}
                </Text>
                <Text fontSize="sm" mb="4">
                  Export Range
                </Text>
                <Box
                  __css={{
                    '.chakra-input__right-element': {
                      zIndex: '0 !important',
                    },
                  }}
                >
                  <HStack mb="4">
                    <FormCalendar
                      name="startDataRange"
                      mb="0"
                      dateFormat="MMM d, yyyy"
                      applyTimezoneOffset={false}
                    />
                    <Text>&mdash;</Text>
                    <FormCalendar
                      name="endDataRange"
                      mb="0"
                      dateFormat="MMM d, yyyy"
                      minDate={values.startDataRange}
                      applyTimezoneOffset={false}
                    />
                  </HStack>
                </Box>
                <Text fontSize="sm" mb="4">
                  Devices
                </Text>
                <SimpleGrid columns={2} spacing={4} mb="4">
                  <Checkbox name="bloodPressure">Blood Pressure</Checkbox>
                  <Checkbox name="weight">Weight</Checkbox>
                  <Checkbox name="pulse">Pulse</Checkbox>
                  <Checkbox name="glucose">Glucose</Checkbox>
                  <Checkbox name="pulseOx">PulseOX</Checkbox>
                </SimpleGrid>
                <Text fontSize="sm" mb="4">
                  Notes
                </Text>
                <Box
                  __css={{
                    '.chakra-input__right-element': {
                      zIndex: '0 !important',
                    },
                  }}
                ></Box>
                <FormField textarea name="additionalNote" label="Add Note" />
                <Checkbox name="includeNotes">Include Notes</Checkbox>
              </Form>
            )}
          </Formik>
        </Flex>
      </Modal>
    </>
  );
}
