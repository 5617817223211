interface ErrorMessages {
  [key: string]: string;
}

const errorMessages: ErrorMessages = {
  401: 'You are not authorized to perform this action',
  403: 'You do not have permission to perform this action',
  406: 'There was an error structuring your request, please ensure that your fields are filled out correctly',
  '4XX':
    'There was an error in your request. Please verify that all fields are filled out correctly',
  503: 'Service unavailable. Please try again later',
  '5XX': 'A server-side error occurred. Please try again later',
};

export const getErrorMessage = (status: number | undefined): string => {
  if (!status) return 'An error occurred. Please try again later';
  if (errorMessages[status]) {
    return errorMessages[status];
  }
  let errorClass = status.toString()[0];
  errorClass += 'XX';
  return errorMessages[errorClass];
};
