export type Option = {
  label: string;
  value: string;
};

export interface SourceObject {
  [key: string]: string;
}

export const makeOptionsArray = (sourceObject: SourceObject): Option[] => {
  const optionsArray: Option[] = [];
  Object.entries(sourceObject).map((key) => {
    const optionPair = { label: key[1], value: key[0] };
    optionsArray.push(optionPair);
  });
  return optionsArray;
};

export const getLanguageOptionsArray = (languages: string[]): Option[] => {
  return languages.map((language: string) => ({
    label: language,
    value: language,
  }));
};
