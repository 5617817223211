import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  FormLabel,
  Flex,
  FormControl,
} from '@rhythm/components';
import { useField, useFormikContext, FormikProps } from 'formik';
import { EnrollPatientFormValues } from '../EnrollPatientForm/EnrollPatientForm';

interface RangeSliderProps {
  label: string;
  ariaLabel?: string[];
  max?: number;
  min?: number;
  disabled?: boolean;
  name: string;
  defaultValue: number[];
  unit?: string;
}

export function FormRangeSlider(props: RangeSliderProps) {
  const {
    label,
    ariaLabel = ['min', 'max'],
    defaultValue = [0, 100],
    disabled = false,
    max = 100,
    min = 0,
    unit = '',
  } = props;

  const { values }: FormikProps<EnrollPatientFormValues> = useFormikContext();
  const [field, meta, helpers] = useField<number[]>(props.name);

  const handleChange = (value: number[]) => {
    helpers.setValue(value);
  };

  const handleDisabled = () => {
    if (values.isEditing === undefined) {
      return false;
    }
    return !values.isEditing;
  };

  return (
    <FormControl mb="2xl">
      <FormLabel fontSize="xs" mb="2xl">
        {label}
      </FormLabel>
      <RangeSlider
        {...field}
        value={meta.value}
        aria-label={ariaLabel}
        defaultValue={defaultValue}
        onChange={handleChange}
        max={max}
        min={min}
        isDisabled={disabled || handleDisabled()}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb
          index={0}
          borderColor="primary.500"
          borderWidth="3px"
          position="relative"
          zIndex={0}
        >
          <Flex
            position="absolute"
            top="-24px"
            color="neutral.800"
            w="36px"
            justifyContent="center"
          >
            {`${meta.value[0]}${unit}`}
          </Flex>
        </RangeSliderThumb>
        <RangeSliderThumb
          index={1}
          borderColor="primary.500"
          borderWidth="3px"
          position="relative"
          zIndex={0}
        >
          <Flex
            position="absolute"
            top="-24px"
            color="neutral.800"
            w="36px"
            justifyContent="center"
          >
            {`${meta.value[1]}${unit}`}
          </Flex>
        </RangeSliderThumb>
      </RangeSlider>
    </FormControl>
  );
}
