import {
  TableRow,
  TableHeader,
  DataTable as RhythmDataTable,
  DataTableProps as RhythmDataTableProps,
  TableCell,
} from '@rhythm/components';
import type { Column } from 'react-table';

interface DataTableProps<T extends Record<string, unknown>>
  extends Omit<RhythmDataTableProps, 'columns' | 'data'> {
  columns: Column<T>[];
  data: T[];
  search?: string;
  isSetting?: boolean;
  isPatientManagementPage?: boolean;
}

export function DataTable<T extends Record<string, unknown>>({
  columns,
  data,
  search,
  onRowClick,
  isSetting,
  isPatientManagementPage,
  ...rest
}: DataTableProps<T>) {
  if (isSetting) {
    return (
      <RhythmDataTable
        columns={columns as Column<Record<string, unknown>>[]}
        data={data}
        isSortable
        isSearchable
        hasPagination
        selectedRowBackgroundColor="primary.100"
        selectedRowBorderColor="primary.400"
        borderCollapse="separate"
        borderSpacing="0 2px"
        searchTerm={search}
        isClickable={onRowClick !== undefined}
        onRowClick={onRowClick}
        tableBodyCell={<TableCell px="2xl" py="xl" valign="top" />}
        tableHeadCell={
          <TableHeader
            color="neutral.800"
            bg="neutral.white"
            borderBottomColor="neutral.200"
            borderBottomStyle="solid"
            borderBottomWidth="2px"
            px="2xl"
            py="xl"
          />
        }
        tableBodyRow={
          <TableRow
            borderBottomColor="neutral.200"
            borderBottomStyle="solid"
            borderBottomWidth="2px"
            bg="neutral.white"
            borderRadius="4px"
            position="relative"
            sx={{
              'svg:first-of-type': {
                display: 'none',
              },
              'td:first-of-type': {
                borderRadius: '4px 0 0 4px',
              },
              'td:last-of-type': {
                borderRadius: '0 4px 4px 0',
              },
              _hover: {
                'svg:first-of-type': {
                  display: 'block',
                  verticalAlign: 'bottom',
                },
              },
            }}
          />
        }
        {...rest}
      />
    );
  } else {
    return (
      <RhythmDataTable
        columns={columns as Column<Record<string, unknown>>[]}
        data={data}
        isSortable
        isSearchable
        // hasPagination
        customPageSizeOption={isPatientManagementPage ? [10, 20, 50, 100] : []}
        selectedRowBackgroundColor="primary.100"
        selectedRowBorderColor="primary.400"
        borderCollapse="separate"
        borderSpacing="0 2px"
        searchTerm={search}
        tableHeadCell={
          <TableHeader
            color="neutral.800"
            bg="neutral.white"
            sx={{
              '&:first-of-type': {
                borderTopLeftRadius: 'md',
              },
              '&:last-of-type': {
                borderTopRightRadius: 'md',
              },
            }}
          />
        }
        tableBodyRow={
          <TableRow
            borderBottomColor="neutral.200"
            borderBottomStyle="solid"
            borderBottomWidth="2px"
            bg="neutral.white"
            sx={{
              _hover: {
                boxShadow: 'cardHover',
              },
              '&:first-of-type': {
                borderTopWidth: 'none',
                '> td': {
                  '&:first-of-type': {
                    borderBottomLeftRadius: 'md',
                  },
                  '&:last-of-type': {
                    borderBottomRightRadius: 'md',
                  },
                },
              },
              '&:not(:first-of-type) > td': {
                '&:first-of-type': {
                  borderTopLeftRadius: 'md',
                  borderBottomLeftRadius: 'md',
                },
                '&:last-of-type': {
                  borderTopRightRadius: 'md',
                  borderBottomRightRadius: 'md',
                },
              },
              '&:last-of-type': {
                borderBottomWidth: 'none',
              },
            }}
          />
        }
        {...rest}
      />
    );
  }
}
export function DataTableFastReview<T extends Record<string, unknown>>({
  columns,
  data,
  search,
  onRowClick,
  isSetting,
  ...rest
}: DataTableProps<T>) {
  return (
    <RhythmDataTable
      columns={columns as Column<Record<string, unknown>>[]}
      data={data}
      isSortable
      isSearchable
      // hasPagination
      selectedRowBackgroundColor="primary.100"
      selectedRowBorderColor="primary.400"
      borderCollapse="separate"
      borderSpacing="0 2px"
      searchTerm={search}
      tableHeadCell={
        <TableHeader
          color="neutral.800"
          bg="neutral.white"
          sx={{
            '&:first-of-type': {
              borderTopLeftRadius: 'md',
            },
            '&:last-of-type': {
              borderTopRightRadius: 'md',
            },
          }}
        />
      }
      tableBodyRow={
        <TableRow
          borderBottomColor="neutral.200"
          borderBottomStyle="solid"
          borderBottomWidth="2px"
          bg="neutral.white"
          sx={{
            _hover: {
              boxShadow: 'cardHover',
            },
            '&:first-of-type': {
              borderTopWidth: 'none',
              '> td': {
                '&:first-of-type': {
                  borderBottomLeftRadius: 'md',
                },
                '&:last-of-type': {
                  borderBottomRightRadius: 'md',
                },
              },
            },
            '&:not(:first-of-type) > td': {
              '&:first-of-type': {
                borderTopLeftRadius: 'md',
                borderBottomLeftRadius: 'md',
              },
              '&:last-of-type': {
                borderTopRightRadius: 'md',
                borderBottomRightRadius: 'md',
              },
            },
            '&:last-of-type': {
              borderBottomWidth: 'none',
            },
          }}
        />
      }
      {...rest}
    />
  );
}
