import { Button, Box } from '@rhythm/components';
import { Formik, Form } from 'formik';
import { usePatientOrganization } from '~src/services/patients';

export function UpdatePatientOrganization() {
  const callUpdatePatientOrganization = usePatientOrganization();

  const handleOrgSubmit = async () => {
    await callUpdatePatientOrganization();
  };

  const initialValues = {};

  return (
    <Box style={{ marginTop: '30px', marginBottom: '30px' }}>
      <Formik initialValues={initialValues} onSubmit={handleOrgSubmit}>
        <Form>
          <Button type="submit" size="md">
            Update Patients Organization
          </Button>
        </Form>
      </Formik>
    </Box>
  );
}
