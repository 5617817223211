import { Box, Button, FormLabel, Grid, IconButton } from '@rhythm/components';
import { FieldArray, useFormikContext } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormSelect } from '~src/components/FormSelect';
import {
  diseaseStatesOptionsArray,
  icd10CodesDiabetesOptionsArray,
  icd10CodesHeartFailureOptionsArray,
  icd10CodesHypertensionOptionsArray,
} from '~src/constants';
import { EnrollPatientFormValues } from '../EnrollPatientForm';

enum ICDCodesFields {
  DISEASE_STATE = 'Diagnosis',
  ICD_10_CODE = 'ICD-10 Code',
}

const getActiveIcd10Codes = (index: any, icdcodes: any) => {
  if (icdcodes && icdcodes[index] && icdcodes[index].diagnosis) {
    const diagnosis: string = icdcodes[index].diagnosis;
    switch (diagnosis) {
      case 'hypertension':
        return icd10CodesHypertensionOptionsArray;
      case 'diabetes':
        return icd10CodesDiabetesOptionsArray;
      case 'heart_failure':
        return icd10CodesHeartFailureOptionsArray;
    }
  }
  return [];
};

const getDiagnosisStr = (diagnosis: string) => {
  switch (diagnosis) {
    case 'hypertension':
      return 'Hypertension';
    case 'diabetes':
      return 'Diabetes';
    case 'heart_failure':
      return 'Heart Failure';
  }
};

const ICDCodesForm: React.FC = (): ReactElement => {
  const {
    setFieldValue,
    values: { icdcodes, isEditing },
  } = useFormikContext<EnrollPatientFormValues>();

  const [showAddMore, setShowAddMore] = useState<boolean>(true);

  useEffect(() => {
    if (icdcodes && icdcodes.length < 3) {
      setShowAddMore(true);
    } else {
      setShowAddMore(false);
    }
  }, [icdcodes, setShowAddMore]);

  return (
    <Box mt="4xl" mb="2xl">
      <FieldArray
        name="icdcodes"
        render={(arrayHelpers) => (
          <>
            {icdcodes?.map((icdcode, index) => (
              <Box key={index}>
                <Grid templateColumns="repeat(3, 1fr)" gap="5xl">
                  <Box>
                    <FormSelect
                      options={diseaseStatesOptionsArray}
                      label={ICDCodesFields.DISEASE_STATE}
                      name={`icdcodes.${index}.diagnosis`}
                      onInputChange={(value) => {
                        if (!value) {
                          setFieldValue(`icdcodes.${index}.diagnosis`, '');
                          setFieldValue(`icdcodes.${index}.icd10Code`, '');
                        }
                      }}
                      clearable={true}
                      placeholder={'Select a diagnosis'}
                    />
                  </Box>
                  <Box>
                    <FormSelect
                      key={icdcodes[`${index}`]?.diagnosis}
                      options={getActiveIcd10Codes(index, icdcodes)}
                      label={ICDCodesFields.ICD_10_CODE}
                      name={`icdcodes.${index}.icd10Code`}
                      disabled={getActiveIcd10Codes(index, icdcodes).length === 0}
                      placeholder={
                        getActiveIcd10Codes(index, icdcodes).length === 0
                          ? 'This field is unavailble. Select a diagnosis first'
                          : 'Select an ICD-10 code for ' +
                            getDiagnosisStr(icdcode.diagnosis as string)
                      }
                    />
                  </Box>
                  {index > 0 && (
                    <Box>
                      <FormLabel>&nbsp;</FormLabel>
                      {isEditing && (
                        <IconButton
                          aria-label="delete device"
                          color="primary.600"
                          variant="plain"
                          icon="delete"
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      )}
                    </Box>
                  )}
                </Grid>
              </Box>
            ))}
            {isEditing && showAddMore && (
              <Button
                onClick={() => {
                  if (icdcodes && icdcodes.length < 3) {
                    arrayHelpers.push({
                      diagnosis: '',
                      icd10Code: '',
                    });
                  }
                }}
                variant="primary"
              >
                + Add ICD-10
              </Button>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default ICDCodesForm;
