import { Card, Flex, Heading, Icon, Text } from '@rhythm/components';
import { Organization } from '~generated';

export type DataProp = {
  data: Organization;
};

const AccountSnapshot = ({ data }: DataProp) => {
  return (
    <Card mt="10px">
      <Flex>
        {' '}
        <Icon icon="patients" />{' '}
        <Heading ml="18px" variant="h4">
          {' '}
          Account snapshot{' '}
        </Heading>{' '}
      </Flex>
      <Flex pt="25px">
        {' '}
        <Flex direction="column" justifyContent="space-between">
          <Text variant="smallCaps" mb="8px" fontWeight="700" color="neutral.800">
            {' '}
            Enrolled Patients{' '}
          </Text>
          <Heading variant="h1">{data?.patients?.length || 0}</Heading>
        </Flex>
      </Flex>
    </Card>
  );
};

export default AccountSnapshot;
