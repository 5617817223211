import { useState } from 'react';
import dayjs from 'dayjs';
import { SocialNote, CreateSocialNoteDto } from '~generated';
import {
  Modal,
  Heading,
  Text,
  Button,
  Flex,
  Box,
  IconButton,
  Grid,
  GridItem,
  Icon,
} from '@rhythm/components';
import { Formik, Form, FormikHelpers } from 'formik';
import { useCreateSocialNote } from '../../services/notes';
import { FormField } from '../FormField';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { createPatientRoles } from '~src/constants/roles';

type SocialNoteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  notes: SocialNote[] | undefined;
  patientId: string;
  pinnedNotes: SocialNote[] | undefined;
  updateSocialNote: { mutate: (payload: Partial<CreateSocialNoteDto>) => void };
  handleDelete: (id: string) => void;
  togglePinned: (id: string, isPinned: boolean) => void;
};

type FooterProps = Pick<SocialNoteModalProps, 'onClose'>;

type NoteTileProps = {
  notes: string;
  isPinned: boolean;
  timestamp: string;
  id: string;
  updateSocialNote: { mutate: (payload: Partial<CreateSocialNoteDto>) => void };
  togglePinned: (id: string, isPinned: boolean) => void;
  handleDelete: (id: string) => void;
  createdBy: string;
};

type NotesInitialValues = {
  socialNoteContent: string;
};

type TileWidgetProps = {
  id: string;
  isPinned: boolean;
  togglePin: (payload: unknown) => void;
  handleEdit: () => void;
  handleDelete: (id: string) => void;
};

function ModalFooter({ onClose }: FooterProps) {
  return (
    <Button onClick={onClose} variant="secondaryDark">
      Close
    </Button>
  );
}

function TileWidget({ isPinned, togglePin, handleEdit, handleDelete, id }: TileWidgetProps) {
  return (
    <Flex
      position="absolute"
      right="sm"
      top="0"
      bg="white"
      borderColor="neutral.400"
      borderWidth="1px"
      borderRadius="8px"
      transform="translateY(-50%)"
      boxShadow="cardHover"
      className="tileWidget"
      display="none"
      color="neutral.800"
      zIndex="10"
    >
      <IconButton
        variant="ghost"
        icon={isPinned ? 'pin-filled' : 'pin'}
        aria-label="toggle pin"
        onClick={togglePin}
      />
      <IconButton
        variant="ghost"
        icon="edit"
        aria-label="toggle pin"
        onClick={() => handleEdit()}
      />
      <IconButton
        variant="ghost"
        icon="delete"
        aria-label="toggle pin"
        onClick={() => handleDelete(id)}
      />
    </Flex>
  );
}

type EditNotesValues = {
  isEditing: boolean;
  notes: string;
};

export function NoteTile({
  notes,
  isPinned,
  timestamp,
  updateSocialNote,
  id,
  togglePinned,
  handleDelete,
  createdBy = '',
}: NoteTileProps) {
  const initialValues: EditNotesValues = {
    isEditing: false,
    notes,
  };

  async function handleSubmit(
    values: EditNotesValues,
    { setFieldValue }: FormikHelpers<EditNotesValues>
  ) {
    const payload = {
      id,
      notes: values.notes,
    };
    setFieldValue('isEditing', false);
    await updateSocialNote.mutate(payload);
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ setFieldValue, resetForm, values }) => (
        <Form id={`edit-social-note-${id}`}>
          <Grid
            p="xl"
            bg={isPinned ? 'feedback.warningLight' : 'white'}
            borderRadius="md"
            border="neutral.400"
            borderWidth="1px"
            alignItems="top"
            gap="xl"
            mb="md"
            position="relative"
            _hover={{
              '.tileWidget': {
                display: 'block',
              },
            }}
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(9, 1fr)"
          >
            <TileWidget
              isPinned={isPinned}
              togglePin={() => togglePinned(id, isPinned)}
              handleDelete={handleDelete}
              handleEdit={() => setFieldValue('isEditing', true)}
              id={id}
            />
            <GridItem colSpan={8} rowSpan={1}>
              <Box>
                <FormField label="" name="notes" />
                {values.isEditing && (
                  <Box textAlign="right" margin="0" padding="0" mb="xl" mt="-24px">
                    <Button
                      size="sm"
                      variant="plain"
                      color="neutral.800"
                      bg="transparent"
                      onClick={() => {
                        resetForm();
                        setFieldValue('isEditing', false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      form={`edit-social-note-${id}`}
                      type="submit"
                      size="sm"
                      variant="plain"
                      color="primary.500"
                      bg="transparent"
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            </GridItem>
            {isPinned && (
              <GridItem colSpan={1} rowSpan={1}>
                <Icon
                  aria-label="toggle pinned note"
                  icon="pin-filled"
                  color="feedback.warningText"
                  bg="transparent"
                  sx={{ height: '16px', width: '16px', justifySelf: 'flex-end' }}
                />
              </GridItem>
            )}
            <GridItem colSpan={4} rowSpan={1}>
              <Box>
                <Text size="sm" color="neutral.800">
                  {dayjs(timestamp).format('MMMM D YYYY')}
                </Text>
              </Box>
            </GridItem>
            {createdBy && (
              <GridItem colSpan={4} rowSpan={1} colStart={9} colEnd={6} w={'auto'}>
                <Box>
                  <Text size="sm" color="neutral.800">
                    Created by: {createdBy}
                  </Text>
                </Box>
              </GridItem>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export function SocialNoteModal({
  isOpen,
  onClose,
  notes,
  patientId,
  pinnedNotes,
  updateSocialNote,
  handleDelete,
  togglePinned,
}: SocialNoteModalProps) {
  const createSocialNote = useCreateSocialNote();

  const [showNoteField, setShowNoteField] = useState(false);
  const initialValues: NotesInitialValues = {
    socialNoteContent: '',
  };

  const toggleShowNote = () => setShowNoteField((prevState) => !prevState);
  const handleSubmit = async (
    values: NotesInitialValues,
    { resetForm }: FormikHelpers<NotesInitialValues>
  ) => {
    const payload = {
      patientId,
      notes: values.socialNoteContent,
      isPinned: false,
      minutes: 0,
    };
    await createSocialNote.mutate(payload);
    resetForm();
  };
  const validationFunction = (values: NotesInitialValues) => {
    const errors: Partial<NotesInitialValues> = {};
    if (!values.socialNoteContent) {
      errors.socialNoteContent = 'This field is required';
    }
    if (values.socialNoteContent.length > 280) {
      errors.socialNoteContent = 'Too many characters!';
    }
    return errors;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} footer={<ModalFooter onClose={onClose} />}>
      <Heading variant="h4">Notes</Heading>
      <Text variant="body" color="neutral.800" mb="4xl">
        Add or edit patient notes
      </Text>
      <Flex alignItems="center" justifyContent="space-between" mb="md">
        <Heading
          variant="h6"
          fontWeight="bold"
        >{`Pinned Notes (${pinnedNotes?.length}/3)`}</Heading>
      </Flex>
      <Box mb="2xl">
        {pinnedNotes?.map((note) => (
          <NoteTile
            key={note.id}
            notes={note.notes}
            isPinned={note.isPinned}
            timestamp={note.updatedAt}
            id={note.id}
            updateSocialNote={updateSocialNote}
            handleDelete={handleDelete}
            togglePinned={togglePinned}
            createdBy={note.user.firstName + ' ' + note.user.lastName}
          />
        ))}
      </Box>
      <Flex alignItems="center" justifyContent="space-between" mb="xl">
        <Heading variant="h6" fontWeight="bold">{`All Notes (${notes?.length || 0})`}</Heading>
        <RoleBasedRoutes allowedRoles={createPatientRoles}>
          <Button
            leftIcon="add"
            variant="plain"
            color="primary.500"
            size="sm"
            onClick={toggleShowNote}
          >
            Add Note
          </Button>
        </RoleBasedRoutes>
      </Flex>
      {showNoteField && (
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validate={validationFunction}>
          <Form id="create-social-note">
            <FormField name="socialNoteContent" label="" textarea bottomGutter={false} />
            <Box textAlign="right" margin="0" padding="0" mb="xl">
              <Button size="sm" variant="plain" color="neutral.800" onClick={toggleShowNote}>
                Cancel
              </Button>
              <Button
                form="create-social-note"
                type="submit"
                size="sm"
                variant="plain"
                color="primary.500"
              >
                Save
              </Button>
            </Box>
          </Form>
        </Formik>
      )}
      <Box mb="2xl">
        {notes?.map((note) => (
          <NoteTile
            key={note.id}
            notes={note.notes}
            isPinned={note.isPinned}
            timestamp={note.updatedAt}
            id={note.id}
            updateSocialNote={updateSocialNote}
            handleDelete={handleDelete}
            togglePinned={togglePinned}
            createdBy={note?.user?.firstName + ' ' + note?.user?.lastName}
          />
        ))}
      </Box>
    </Modal>
  );
}
