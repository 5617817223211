/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Patient } from './Patient';
import type { User } from './User';

export type PatientStatusTypeHistory = {
    patient: Patient;
    patientId: string;
    user: User;
    userId: string;
    statusType: PatientStatusTypeHistory.statusType;
    followupDate?: string;
    pauseReason: string;
    reason: string;
    note: string;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace PatientStatusTypeHistory {

    export enum statusType {
        NEW = 'new',
        ACTIVE = 'active',
        NON_ADHERENT = 'non-adherent',
        PAUSED = 'paused',
        UNENROLLED = 'unenrolled',
    }


}

