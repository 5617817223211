import { useEffect, useState } from 'react';
import { Tabs, Flex, Box, TabList, Tab, TabPanels, TabPanel } from '@rhythm/components';
import { AccountManagement } from '~src/components/Settings/AccountManagement';
import { TeamManagement } from '~src/components/Settings/TeamManagement';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import { Account } from '..';
import { BillingReportForm } from '~src/components/BillingReportForm/BillingReportForm';
import { UpdatePatientOrganization } from '~src/components/UpdatePatientTimeZone/UpdatePatientTimeZone';
import { CreateAccount } from '.';
import { CreateClinic } from '../CreateClinic/CreateClinic';
import RoleBasedRoutes from '~src/components/RoleBasedRoutes';
import { editPatientUserRoles } from '~src/constants/roles';

export function Settings() {
  const [accountsCount, setAccountsCount] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/settings/account':
        setTabIndex(0);
        break;
      case '/settings/team':
        setTabIndex(1);
        break;
      default:
        setTabIndex(0);
        break;
    }
  });

  return (
    <Switch>
      <Route path="/settings/account/create" component={CreateAccount}>
        <Route path="/" component={CreateAccount} />
      </Route>

      <Route path="/settings/clinic/create" component={CreateClinic}>
        <Route path="/" component={CreateClinic} />
      </Route>

      <Route path="/settings/account/edit" component={CreateAccount}>
        <Route path="/settings/account/edit/:id" component={CreateAccount} />
      </Route>

      {/* <Route path="/settings/clinic/create/:id" component={CreateClinic}>
       
      </Route> */}
      <Route path="/settings/account/:id" component={Account} />
      <Route path="/settings/clinic/:id" component={Account} />

      <Route>
        <RoleBasedRoutes allowedRoles={editPatientUserRoles}>
          <Tabs>
            <Flex pb="20">
              <Box w="100%">
                <Tabs index={tabIndex}>
                  <Flex justifyContent="space-between" paddingY="xl">
                    <TabList>
                      <Tab>
                        <Link
                          to={`/settings/account`}
                        >{`Account Management (${accountsCount})`}</Link>
                      </Tab>
                    </TabList>
                  </Flex>
                  <Box maxW="1140px">
                    <TabPanels>
                      <TabPanel py="0">
                        <AccountManagement setAccountsCounts={(count) => setAccountsCount(count)} />
                      </TabPanel>
                      <TabPanel py="1">
                        <TeamManagement />
                      </TabPanel>
                    </TabPanels>
                  </Box>
                </Tabs>
              </Box>
            </Flex>
            <Flex>
              <BillingReportForm />
            </Flex>
            <Flex>
              <UpdatePatientOrganization />
            </Flex>
          </Tabs>
        </RoleBasedRoutes>
      </Route>
    </Switch>
  );
}
