/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DeviceThreshold } from './DeviceThreshold';
import type { Organization } from './Organization';
import type { Patient } from './Patient';
import type { PatientStatusTypeHistory } from './PatientStatusTypeHistory';
import type { Roles } from './Roles';
import type { UserContact } from './UserContact';
import type { UserCredential } from './UserCredential';

export type User = {
    role: Roles;
    contacts: Array<UserContact>;
    credentials: Array<UserCredential>;
    prefix: string;
    status: User.status;
    email: string;
    threshold: Array<DeviceThreshold>;
    organizations: Array<Organization>;
    statusTypes: Array<PatientStatusTypeHistory>;
    patients: Array<Patient>;
    deactivatedAt: string;
    deactivatedBy: string;
    patientCount: number;
    defaultEscalationNote: string;
    defaultEmailEscalationText: string;
    isActive: boolean;
    firstName: string;
    lastName: string;
    gender: User.gender;
    birthDate: string;
    photoUrl: string;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace User {

    export enum status {
        PENDING = 'pending',
        REGISTERED = 'registered',
        DECEASED = 'deceased',
        OPTEDIN = 'optedin',
        ENROLLED = 'enrolled',
        OPTEDOUT = 'optedout',
    }

    export enum gender {
        FEMALE = 'female',
        MALE = 'male',
    }


}

