export interface AccountInformation {
  accountName: string;
  phone: string;
  phone2: string;
  phone3: string;
  fax: string;
  account?: string;
}

export interface GoLiveInformation {
  date: string;
  devices: string | string[];
}

export interface AddressInformation {
  addressLine1: string;
  addressLine2: string;
  zipcode: string;
  city: string;
  state: string;
  country: string;
}

export interface EMRInformation {
  emrAccess: string;
  emrPlatform: string;
  preferredLanguage: string;
  notes: string;
}

export interface ThresholdInformation {
  bpSystolic: number[];
  bpDiastolic: number[];
  pulse: number[];
  glucose: number[];
  weightChange24h: number;
  weightChange7days: number;
  bloodOxygen: number[];
}

export enum AccountFields {
  accountName = 'Account Name',
  clinicName = 'Clinic Name',
  account = 'Account',
  phone = 'Phone Number',
  phone2 = 'Phone Number 2',
  phone3 = 'Phone Number 3',
  fax = 'Fax',
}

export enum GoLiveFields {
  date = 'Go Live Date',
  alertProtocol = 'Alert Protocol',
  devices = 'Devices Followed by Physicians',
}

export enum AddressFields {
  addressLine1 = 'Address Line 1',
  addressLine2 = 'Address Line 2',
  zipcode = 'Zipcode',
  city = 'City',
  state = 'State',
  country = 'Country',
}

export enum EMRFields {
  emrAccess = 'EMR Access',
  emrPlatform = 'EMR Platform',
  preferredLanguage = 'Preferred Language',
  notes = 'Notes',
}

export enum ThresholdFields {
  bpSystolic = 'Blood Pressure (Systolic)',
  bpDiastolic = 'Blood Pressure (Diastolic)',
  pulse = 'Pulse',
  glucose = 'Glucose',
  weightChange24h = 'Weight Change 24 Hours',
  weightChange7days = 'Weight Change 7 Days',
  weightChangeGeneric = 'Weight Change',
  bloodOxygen = 'Blood Oxygen ( % )',
}

export const accountInformation: AccountInformation = {
  accountName: '',
  phone: '',
  phone2: '',
  phone3: '',
  fax: '',
};

export const goLiveInformation: GoLiveInformation = {
  date: '',
  devices: '',
};

export const addressInformation: AddressInformation = {
  addressLine1: '',
  addressLine2: '',
  zipcode: '',
  city: '',
  state: '',
  country: 'United States',
};

export const emrInformation: EMRInformation = {
  emrAccess: 'false',
  emrPlatform: '',
  preferredLanguage: '',
  notes: '',
};

export const thresholdInformation: ThresholdInformation = {
  bpSystolic: [90, 180],
  bpDiastolic: [50, 110],
  pulse: [50, 120],
  glucose: [60, 300],
  weightChange24h: 3,
  weightChange7days: 5,
  bloodOxygen: [88, 92],
};
