import { Fragment, useState } from 'react';
import {
  Modal,
  Button,
  Text,
  HStack,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  VStack,
  PopoverCloseButton,
} from '@rhythm/components';
import {
  Patient,
  SocialNote,
  ClinicalNote,
  DefaultService as Service,
  User,
  Roles,
} from '~generated';
import { DataModal } from './ModalData';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { AlertPaginationResult } from '~src/services/patients';
import toast from 'react-hot-toast';
import { useUserRoleAndEmail } from '~src/hooks';

interface FormValues {
  note: string;
  userId: string;
  subject: string;
  header: string;
  body: string;
  emailTo: string;
  bcc: string;
}
export interface AlertDataModalProps {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  cliniciansData: User[];
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<AlertPaginationResult, unknown>>;
}
export interface AlertPatientDataModalProps {
  patient: Patient[];
}

export interface NewSocialNote extends SocialNote {
  convertedTimestamp: string;
}
export interface AlertPinnedNotesDataModalProps {
  pinnedNotes: NewSocialNote[];
}
export interface AlertClinicalNotesDataModalProps {
  clinicalNotes: ClinicalNote[];
}
export interface AlertReadingDataModalProps {
  data: any;
  readingType: string;
}
export function AlertDataModal({
  id,
  open,
  setOpen,
  cliniciansData,
  refetch,
}: AlertDataModalProps) {
  const { userRole } = useUserRoleAndEmail();
  const isInternal = [
    'roles:internal:super-admin',
    'roles:internal:lead-clinician',
    'roles:internal:admin',
  ].some((role) => userRole.includes(role));
  const isNonClinician = userRole === Roles.ROLES_INTERNAL_NON_CLINICAL;

  const [escalate, setEscalate] = useState<boolean>(false);
  const [submitType, setSubmitType] = useState<string>('');
  const [isLoading, setisLoading] = useState<boolean>(false);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (values: FormValues) => {
    setisLoading(true);
    const currentURL = window.location.href;
    const patientProfileURL = window.location.origin + '/patients/profile/' + id;
    if (submitType === 'escalate') {
      try {
        await Service.patientsControllerAddEscalateData(id, {
          minutes: 0,
          clinicianEmail: isInternal ? values?.emailTo?.trim() : values.userId,
          blindCC: isInternal ? [values.bcc] : [],
          notes: isInternal
            ? `${values.body} \n <a href="${patientProfileURL}">${patientProfileURL}</a>`
            : `<a href="${currentURL}">${currentURL}</a> \n ${values.note}`,
          replyTo: isInternal ? values.header : '',
          subject: isInternal ? values.subject : '',
          escalate: true,
        });
        setOpen(false);
        refetch();
        toast.success('Alert Escalated successfully');
        setisLoading(false);
      } catch (err) {
        console.log(err);
        toast.error('There was a problem while escalating Alert');
        setisLoading(false);
      }
    } else {
      try {
        await Service.patientsControllerAddEscalateData(id, {
          minutes: 0,
          clinicianEmail: '',
          blindCC: [],
          notes: values?.note || '',
          escalate: false,
        });
        setOpen(false);
        refetch();
        toast.success('Alerts cleared successfully');
        setisLoading(false);
      } catch (err) {
        console.log(err);
        toast.error('There was a problem while clearing Alert');
        setisLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        width={'1000px'}
        isOpen={open}
        onClose={handleClose}
        footer={
          <Fragment>
            {!isNonClinician && (
              <HStack spacing="4">
                <Button variant="secondaryDark" onClick={() => setOpen(false)}>
                  Close
                </Button>
                {escalate ? (
                  <Button
                    type="submit"
                    variant="danger"
                    form="escalate-form"
                    disabled={isLoading}
                    isLoading={isLoading}
                    loadingText="Escalating..."
                    onClick={() => setSubmitType('escalate')}
                  >
                    Escalate
                  </Button>
                ) : (
                  <Popover placement="top">
                    <PopoverTrigger>
                      <Button>Clear Alert</Button>
                    </PopoverTrigger>

                    <PopoverContent w="180px" border={0}>
                      <PopoverCloseButton background={'White'} _hover={{ background: 'grey' }} />
                      <VStack
                        alignItems="flex-start"
                        spacing="xl"
                        bg="neutral.black"
                        p="xl"
                        w="full"
                        borderRadius="md"
                        borderWidth="x1"
                        borderColor="neutral.400"
                        boxShadow="0px 2px 16px rgba(108, 119, 137, 0.32)"
                        paddingTop={'20px'}
                      >
                        <Box>
                          <Text fontSize="12" color="white">
                            {' '}
                            Are you sure you want to clear Alerts
                          </Text>
                        </Box>
                        <Box flexDirection="row">
                          <Button
                            type="submit"
                            form="escalate-form"
                            size="sm"
                            disabled={isLoading}
                            isLoading={isLoading}
                            loadingText="Clearing..."
                            onClick={() => setSubmitType('clear')}
                          >
                            Clear
                          </Button>
                        </Box>
                      </VStack>
                    </PopoverContent>
                  </Popover>
                )}
              </HStack>
            )}
          </Fragment>
        }
      >
        <DataModal
          id={id}
          setOpen={setOpen}
          escalate={escalate}
          setEscalate={setEscalate}
          handleSubmit={handleSubmit}
          cliniciansData={cliniciansData}
        />
      </Modal>
    </>
  );
}
