import { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Tabs, Flex, Box, HStack, TabList } from '@rhythm/components';
import { EnrollPatient, PatientProfile } from '.';
import { EditPatient } from './EditPatient';
import { PatientsFastReview } from './PatientsFastReview';
import { PatientsListPage } from './PatientsListPage';

export type FilterParam = {
  clinicianId: string;
  physicianId: string;
  contacted: string;
  daysTransmitted: string;
  minutesAccrued: string;
};

export type FilterKeys =
  | 'clinicianId'
  | 'physicianId'
  | 'contacted'
  | 'daysTransmitted'
  | 'minutesAccrued';

export function FilterIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 7H16M1 1H19M7 13H13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Patients() {
  const [currentTab, setCurrentTab] = useState<number>(0);
  return (
    <Switch>
      <Route path="/patients/edit/:id" component={EditPatient} />
      <Route path="/patients/profile/:id" component={PatientProfile} />
      <Route path="/patients/enroll" component={EnrollPatient} />
      <Route path="/patients/fast_review" component={PatientsFastReview} />
      <Route>
        <Flex pb="20" pr={'50px'}>
          <Box w="90%">
            <Tabs
              onChange={(index: number) => {
                setCurrentTab(index);
              }}
            >
              <HStack justifyContent="space-between" paddingY="10px">
                <Flex alignItems="center">
                  <TabList></TabList>
                </Flex>
              </HStack>
              <PatientsListPage currentTab={currentTab} />
            </Tabs>
          </Box>
        </Flex>
      </Route>
    </Switch>
  );
}
