import { Fragment } from 'react';
import { Text, Box, Grid } from '@rhythm/components';
import { ClinicalNote } from '~generated';
import { getTimeZoneShortCode } from '~src/utils';
export interface AlertDataModalProps {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}
export interface AlertClinicalNotesDataModal extends ClinicalNote {
  convertedTimestamp: string;
}
export interface AlertClinicalNotesDataModalProps {
  clinicalNotes: AlertClinicalNotesDataModal[];
  timeZone: string;
}

export function ClinicalNotes({ clinicalNotes, timeZone }: AlertClinicalNotesDataModalProps) {
  return (
    <Fragment>
      <Grid
        templateColumns="1fr 1fr 1fr 2fr 1fr"
        mb="sm"
        borderBottomWidth="1px"
        borderBottomColor="neutral.200"
        py="sm"
        mt={'8px'}
      >
        <Text variant="smallCaps" color="neutral.800">
          Review By / Date
        </Text>
        <Text variant="smallCaps" color="neutral.800" marginLeft={'8px'}>
          Minutes
        </Text>
        <Text variant="smallCaps" color="neutral.800" w={'150%'}>
          Notes/transmission date
        </Text>
      </Grid>
      <Box
        maxHeight="270px"
        minHeight="60px"
        overflowY="scroll"
        overflowX="hidden"
        my="xl"
        width="100%"
        position="relative"
        css={{
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '96px',
            left: 'calc(50% - 6px/2)',
            top: '8px',
            background: '#6C7789',
          },
          '&::-webkit-scrollbar-track': {
            background: '#FFFFFF',
            opacity: 0.5,
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '100px',
            background: '#6C7789',
          },
        }}
      >
        {clinicalNotes.length > 0 ? (
          clinicalNotes?.map((note, index) => (
            <Grid
              templateColumns="1fr 1fr 1fr 2fr 1fr"
              borderBottomWidth="1px"
              borderBottomColor="neutral.200"
              py="sm"
              key={index}
            >
              <Box>
                <Text
                  fontWeight={'bold'}
                >{`${note?.user?.lastName}, ${note?.user?.firstName}`}</Text>
                <Text fontSize={'12px'}>
                  {note.convertedTimestamp} {getTimeZoneShortCode(timeZone as string)}
                </Text>
              </Box>
              <Text fontWeight={'bold'} marginLeft={'10px'}>
                {note.minutes}
              </Text>
              <Grid width={'300%'}>
                <Box marginBottom={'5px'}>
                  <Text>{note.notes}</Text>
                </Box>
              </Grid>
            </Grid>
          ))
        ) : (
          <Text>No Records</Text>
        )}
      </Box>
    </Fragment>
  );
}
