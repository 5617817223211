import { Box, Button, Flex, Grid, Heading } from '@rhythm/components';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormRangeSlider, FormSlider } from '~src/components/FormSlider';
import { EnrollPatientFormValues } from '../EnrollPatientForm';
import { PhysiologicThresholdsFields } from '../formFields';

const PhysiologicThresholds: React.FC<any> = ({ patient }) => {
  const { initialValues, setFieldValue, values } = useFormikContext<EnrollPatientFormValues>();
  const { bpDiastolic, bpSystolic, pulse, bloodOxygen, glucose } = initialValues;
  const [isPhysicianDefaultsDisabled, setIsPhysicianDefaultsDisabled] = useState(true);

  useEffect(() => {
    setIsPhysicianDefaultsDisabled(
      patient?.deviceThresholds && patient?.deviceThresholds.length > 0 ? false : true
    );
    setFieldValue('isPhysicianDefaults', false);
  }, [patient]);

  const setToPhysicinasDefaults = async () => {
    const newThresholds = patient.thresholds;

    const getThresholdLimits = (metricType) => {
      const result = newThresholds.find((threshold) => threshold.metricType === metricType);

      if (result) {
        const lowerLimit = result.threshHoldLowerLimit;
        const upperLimit = result.threshHoldUpperLimit;
        return [lowerLimit, upperLimit];
      } else {
        return null; // Metric type not found in the array
      }
    };

    const thresholds = {
      bpSystolic: getThresholdLimits('blood_pressure_systolic'),
      bpDiastolic: getThresholdLimits('blood_pressure_diastolic'),
      pulse: getThresholdLimits('pulse'),
      glucose: getThresholdLimits('blood_sugar'),
      bloodOxygen: getThresholdLimits('blood_oxygen'),
      weightChangeGeneric: getThresholdLimits('weight'),
    };

    // console.log("thresholds", thresholds);
    setFieldValue('bpSystolic', thresholds.bpSystolic);
    setFieldValue('bpDiastolic', thresholds.bpDiastolic);
    setFieldValue('pulse', thresholds.pulse);
    setFieldValue('glucose', thresholds.glucose);
    setFieldValue('bloodOxygen', thresholds.bloodOxygen);
    setFieldValue('weightChange24h', thresholds?.weightChangeGeneric?.[0] ?? 3);
    setFieldValue('weightChange7Day', getThresholdLimits('seven_days_change')?.[0] ?? 5);

    setFieldValue('isPhysicianDefaults', true);

    setIsPhysicianDefaultsDisabled(true);
  };

  return (
    <Box>
      <Box mt="xl">
        <Flex justifyContent="space-between" align="center">
          <Heading variant="h5">Clinic Threshold Defaults</Heading>
          {values.isEditing && (
            <Button
              bg="transparent"
              leftIcon="refresh"
              variant="secondaryLight"
              onClick={setToPhysicinasDefaults}
              disabled={isPhysicianDefaultsDisabled}
            >
              Set to Physician Defaults
            </Button>
          )}
        </Flex>
      </Box>
      <Grid templateColumns="repeat(3, 1fr)" gap="2xl" mt="2xl" mb="2xl">
        <Box>
          <FormRangeSlider
            label={PhysiologicThresholdsFields.BP_SYSTOLIC}
            ariaLabel={['Blood Pressure Systolic Minimun', 'Blood Pressure Systolic Maximum']}
            name="bpSystolic"
            max={200}
            min={80}
            defaultValue={bpSystolic}
          />
          <FormRangeSlider
            label={PhysiologicThresholdsFields.BP_DIASTOLIC}
            ariaLabel={['Blood Pressure (Diastolic) Minimun', 'Blood Pressure (Diastolic) Maximum']}
            name="bpDiastolic"
            max={160}
            min={40}
            defaultValue={bpDiastolic}
          />
          <FormRangeSlider
            label={PhysiologicThresholdsFields.BLOOD_OXYGEN}
            ariaLabel={['Blood Oxygen Minimun', 'Blood Oxygen Maximum']}
            name="bloodOxygen"
            max={100}
            min={75}
            defaultValue={bloodOxygen}
          />
        </Box>
        <Box>
          <FormRangeSlider
            label={PhysiologicThresholdsFields.PULSE}
            ariaLabel={['Pulse minimum', 'Pulse maximum']}
            name="pulse"
            max={160}
            min={0}
            defaultValue={pulse}
          />
          <FormRangeSlider
            label={PhysiologicThresholdsFields.GLUCOSE}
            ariaLabel={['Glucose Minimun', 'Glucose Maximum']}
            name="glucose"
            max={600}
            min={0}
            defaultValue={glucose}
          />
        </Box>
        <Box>
          <FormSlider
            label={PhysiologicThresholdsFields.WEIGHT_24H}
            ariaLabel="Weight Change 24 Hours"
            name="weightChange24h"
            defaultValue={3}
            max={5}
            min={1}
            unit="lb"
          />
          <FormSlider
            label={PhysiologicThresholdsFields.WEIGHT_7DAY}
            ariaLabel="Weight Change 7 Days"
            name="weightChange7Day"
            defaultValue={5}
            max={10}
            min={3}
            unit="lb"
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default PhysiologicThresholds;
