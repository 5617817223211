import {
  Text,
  Flex,
  Box,
  DataTable,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from '@rhythm/components';
import { Fragment, useMemo } from 'react';
import { CC, DateCell } from '~src/pages/Patients/PatientOverview';
import { getTimeZoneShortCode } from '~src/utils';
const customStyle: any = {
  textTransform: 'none',
  fontFamily: 'var(--chakra-fonts-heading)',
  fontWeight: 'var(--chakra-fontWeights-bold)',
  fontSize: 'var(--chakra-fontSizes-xs)',
};
export interface AlertDataModalProps {
  id: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export interface AlertReadingDataModalProps {
  data: any;
  readingType: string;
}

export function AlertReadingDataTable({ data }: any) {
  function useReadingsData(data?: any[], type?: string, timezone?: string) {
    const timeZone = getTimeZoneShortCode(timezone ?? '');
    return useMemo(() => {
      if (!data) return [];
      if (type === 'bp') {
        return data.map((item) => ({
          dateTime: item.convertedTimestamp + ' ' + timeZone,
          reading: item.systolic + ' / ' + item.diastolic,
        }));
      }
      if (type === 'pulse') {
        return data.map((item) => ({
          dateTime: item.convertedTimestamp + ' ' + timeZone,
          reading: item.pulse,
        }));
      }
      if (type === 'bs') {
        return data.map((item) => ({
          dateTime: item.convertedTimestamp + ' ' + timeZone,
          reading: item.reading,
        }));
      }
      if (type === 'weight') {
        return data.map((item) => ({
          dateTime: item.convertedTimestamp + ' ' + timeZone,
          reading: item.weight,
        }));
      }
      if (type === 'bo') {
        return data.map((item) => ({
          dateTime: item.convertedTimestamp + ' ' + timeZone,
          reading: item.pulseox,
        }));
      }
    }, [data]);
  }

  return (
    <Fragment>
      <Tabs variant="soft-rounded" mt={'10px'}>
        <Flex mb={'5px'}>
          <TabList>
            <Tab
              _selected={{ color: 'white', bg: '#6C7789' }}
              _focus={{ color: 'white', bg: 'white' }}
              backgroundColor={'#EFF2F6'}
              color={'#6C7789'}
              borderRadius={'4px'}
              h={'20px'}
              p={'5px'}
            >
              BP
            </Tab>
            <Tab
              _selected={{ color: 'white', bg: '#6C7789' }}
              _focus={{ color: 'white', bg: 'white' }}
              backgroundColor={'#EFF2F6'}
              color={'#6C7789'}
              borderRadius={'4px'}
              h={'20px'}
              p={'5px'}
            >
              Pulse
            </Tab>
            <Tab
              _selected={{ color: 'white', bg: '#6C7789' }}
              _focus={{ color: 'white', bg: 'white' }}
              backgroundColor={'#EFF2F6'}
              color={'#6C7789'}
              borderRadius={'4px'}
              h={'20px'}
              p={'5px'}
            >
              Weight
            </Tab>
            <Tab
              _selected={{ color: 'white', bg: '#6C7789' }}
              _focus={{ color: 'white', bg: 'white' }}
              backgroundColor={'#EFF2F6'}
              color={'#6C7789'}
              borderRadius={'4px'}
              h={'20px'}
              p={'5px'}
            >
              Glucose
            </Tab>
            <Tab
              _selected={{ color: 'white', bg: '#6C7789' }}
              _focus={{ color: 'white', bg: 'white' }}
              backgroundColor={'#EFF2F6'}
              color={'#6C7789'}
              borderRadius={'4px'}
              h={'20px'}
              p={'5px'}
            >
              Pulse Ox
            </Tab>
          </TabList>
        </Flex>
        <TabPanels>
          <TabPanel pt={'0px'}>
            <Box mb="6" w={'125%'}>
              <ReadingDataTable
                readingType={'SYSTOLIC/DIASTOLIC (mmHg)'}
                data={useReadingsData(
                  data?.bloodPressureData,
                  'bp',
                  data && data?.patient ? data?.patient[0].address?.timeZone : ''
                )}
              />
            </Box>
          </TabPanel>
          <TabPanel pt={'0px'}>
            <Box mb="6" w={'125%'}>
              <ReadingDataTable
                readingType={'PULSE (bpm)'}
                data={useReadingsData(
                  data?.pulseData,
                  'pulse',
                  data && data?.patient ? data?.patient[0].address?.timeZone : ''
                )}
              />
            </Box>
          </TabPanel>
          <TabPanel pt={'0px'}>
            <Box mb="6" w={'125%'}>
              <ReadingDataTable
                readingType={'WEIGHT (lb)'}
                data={useReadingsData(
                  data?.weightData,
                  'weight',
                  data && data?.patient ? data?.patient[0].address?.timeZone : ''
                )}
              />
            </Box>
          </TabPanel>
          <TabPanel pt={'0px'}>
            <Box mb="6" w={'125%'}>
              <ReadingDataTable
                readingType={'BLOOD GLUCOSE (mg/dL)'}
                data={useReadingsData(
                  data?.bloodSugarData,
                  'bs',
                  data && data?.patient ? data?.patient[0].address?.timeZone : ''
                )}
              />
            </Box>
          </TabPanel>
          <TabPanel pt={'0px'}>
            <Box mb="6" w={'125%'}>
              <ReadingDataTable
                readingType={'BLOOD OXYGEN (SpO2)'}
                data={useReadingsData(
                  data?.pulseOxData,
                  'bo',
                  data && data?.patient ? data?.patient[0].address?.timeZone : ''
                )}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Fragment>
  );
}

export function ReadingDataTable({ readingType, data }: AlertReadingDataModalProps) {
  return (
    <Box
      maxHeight="416px"
      overflowY="scroll"
      overflowX="hidden"
      my="xl"
      width="100%"
      position="relative"
      css={{
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '96px',
          left: 'calc(50% - 6px/2)',
          top: '8px',
          background: '#6C7789',
        },
        '&::-webkit-scrollbar-track': {
          background: '#FFFFFF',
          opacity: 0.5,
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '100px',
          background: '#6C7789',
        },
      }}
    >
      <Flex display="flex" justifyContent="center" alignItems="center">
        <DataTable
          className="borderedDataTable"
          borderCollapse="separate"
          borderSpacing="0 2px"
          variant="striped"
          hasPagination={false}
          columns={[
            {
              id: 'reading',
              Header: () => <Text style={customStyle}>{readingType}</Text>,
              width: 70,
              accessor: 'reading',
            },
            {
              Header: 'Full Date & Time',
              accessor: 'dateTime',
              Cell: ({ value }: CC<string>) => <DateCell timestamp={value} timeZone={''} />,
            },
          ]}
          data={data}
        />
      </Flex>
    </Box>
  );
}
