/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PatientMetricThreshold = {
    type: PatientMetricThreshold.type;
};

export namespace PatientMetricThreshold {

    export enum type {
        WEIGHT = 'weight',
        BLOOD_PRESSURE = 'bloodPressure',
    }


}

