import { UseQueryResult } from 'react-query';
import { useFetch } from './query';

type OrgFilter = {
  id?: string;
};
export interface UseListTeam {
  page?: number;
  path?: string;
  filter?: OrgFilter;
  sort?: string;
  offset?: number;
  limit?: number;
  search?: string;
  bypassPagination?: boolean;
}

export type Pagination = {
  limit: number;
  offset: number;
  count: number;
};

export type PaginatedResponse = {
  data: any;
  pagination: Pagination;
};

export function useListTeam({
  page,
  filter = { id: '' },
  limit = 10,
  offset = 0,
  sort = 'ASC',
  search,
  bypassPagination = false,
}: UseListTeam): UseQueryResult<PaginatedResponse> {
  return useFetch<PaginatedResponse>({
    key: ['team', page, filter, sort, offset, limit, search],
    path: '/team',
    queryParams: {
      filter: JSON.stringify(filter),
      limit,
      offset,
      sort,
      search,
      ...(bypassPagination && { bypassPagination }),
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}
