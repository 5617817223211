import { Box, Search as RhythmSearch, SearchProps as RhythmSearchProps } from '@rhythm/components';

export interface SearchProps
  extends Pick<RhythmSearchProps, 'size' | 'value' | 'placeholder' | 'width'> {
  onSearchTerm: (query: string) => void;
  fastReview?: boolean;
  searchTermError?: any;
}

export function Search({
  onSearchTerm,
  placeholder,
  size,
  value,
  width,
  fastReview,
  searchTermError,
}: SearchProps) {
  return (
    <>
      <RhythmSearch
        isDisabled={fastReview}
        placeholder={placeholder ?? 'Search All Patients'}
        width={width ?? '100%'}
        leftIcon={{
          color: '#1083CB',
          icon: 'search',
          size: 'sm',
        }}
        rightIcon={{
          color: 'neutral.800',
          icon: 'close-circle',
          size: 'sm',
        }}
        color={'#6C7789'}
        placeholderColor="neutral.800"
        onSearchTerm={onSearchTerm}
        size={size ?? 'md'}
        value={value}
        background="#fff"
      />
      {searchTermError && (
        <Box>
          <p style={{ color: 'red' }}>{searchTermError}</p>
        </Box>
      )}
    </>
  );
}
