import { Button } from '@chakra-ui/button';
import {
  Icon,
  Text,
  MenuButton,
  Center,
  Menu,
  Button as ApplyButton,
  MenuList,
  Flex,
  MenuItem,
  Checkbox,
  Box,
  Input,
  AccordionIcon,
  Tooltip,
} from '@rhythm/components';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { useMemo, useRef, useState } from 'react';
import { FormFilterValues, Range } from '~src/pages/Patients/PatientFilters/Filters';
export interface FormOption {
  value: string;
  label: string;
}

type MenuStatus = { menuTitle: string; valid: boolean };

type MappedOptions = { [key: string]: string };

const signMapper = {
  '': '>=',
  '≥': '>=',
  '≤': '<=',
  '=': '=',
} as any;

export interface FormSelectMenuProps {
  options: FormOption[];
  subOptions?: FormOption[];
  name: 'accounts' | 'physicians' | 'clinicians' | 'contacted' | 'status' | 'onboarding' | 'group';
  menuTitle: string;
  title?: string;
  hintText?: string;
  style?: React.CSSProperties | undefined;
  rightIcon?: React.ReactElement | null;
  onNextEvent?: (value: string | boolean) => void;
  isContacted?: string;
}

interface FormMenuRange extends FormSelectMenuProps {
  name: any;
}

interface MoreOptions extends FormSelectMenuProps {
  index: number;
  arrayHelpers?: FieldArrayRenderProps;
}

export function FormMenuSelectFastReview(props: FormSelectMenuProps) {
  const { title = 'Physician', name, options = [{ label: 'Select All', value: '' }] } = props;
  const { values } = useFormikContext<FormFilterValues>();
  const newOptions = options.filter((item) => values[name]?.includes(item.value));
  const DEFAULT_WIDTH = 292;
  return (
    <Flex direction="column">
      <Menu closeOnBlur={true} closeOnSelect={false}>
        {({ isOpen }) => (
          <>
            <MenuButton as={Button}>
              <Text color={values[name]?.length ? '#1083CB' : '#768CA7'}>
                {' '}
                {title}{' '}
                {isOpen ? (
                  <AccordionIcon style={{ marginLeft: '2px', fontSize: '1.5rem' }} />
                ) : (
                  <AccordionIcon
                    style={{ marginLeft: '2px', fontSize: '1.5rem' }}
                    transform="auto"
                  />
                )}
              </Text>
            </MenuButton>
            <MenuList>
              <Box style={{ maxHeight: 450, overflowY: 'scroll' }}>
                {newOptions?.map((option, index) => (
                  <MenuItem key={index}>
                    <Tooltip
                      width={60}
                      label="Changing filters in the review mode is not allowed"
                      hasArrow
                      placement="top-start"
                      arrowSize={15}
                    >
                      <Box>
                        <Checkbox
                          isDisabled
                          isChecked={values[name] && values[name]?.includes(`${option.value}`)}
                          style={{ width: DEFAULT_WIDTH - 30 }}
                        >
                          {option.label}
                        </Checkbox>
                      </Box>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Box>
              <Center></Center>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
}
export function FormMenuSelect(props: FormSelectMenuProps) {
  const { title = 'Physician', name, options = [{ label: 'Select All', value: '' }] } = props;
  const { setFieldValue, values, submitForm } = useFormikContext<FormFilterValues>();
  const currentSelectedValues = useRef<string[]>([]);
  const [isMenuDirty, setIsDirtyMenuDirty] = useState(true);
  options?.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
  options.sort((a, b) => {
    if (values[name]?.includes(`${a.value}`)) {
      return -1;
    }
    if (values[name]?.includes(`${b.value}`)) {
      return 1;
    }
    return 0;
  });
  const DEFAULT_WIDTH = 292;
  // const DEFAULT_SELECTORS = 1;

  // const convertValuesToLabels = () => {
  //   const derivedOptions = options as FormOption[];
  //   const mergedOptions: MappedOptions = {};

  //   return derivedOptions?.reduce((acc, curr) => {
  //     acc[curr.value] = curr.label;
  //     return acc;
  //   }, mergedOptions);
  // };

  // const optionLabelsMapped = convertValuesToLabels();

  // const deriveSelectedFields = () => {
  //   const remappedData = values[name]?.map((value) => {
  //     return optionLabelsMapped[value];
  //   });

  //   const selectedValuesLength = values[name]?.length;
  //   if (selectedValuesLength) {
  //     const selectedFields = remappedData?.slice(0, DEFAULT_SELECTORS) || [];

  //     if (selectedFields?.length < selectedValuesLength) {
  //       return `${selectedFields.join(',')} + more...`;
  //     }
  //     return selectedFields?.join(',');
  //   }
  //   return menuTitle;
  // };

  const [toggle, setToggle] = useState(false);

  return (
    <Flex direction="column">
      <Menu closeOnBlur={true} closeOnSelect={false}>
        {({ onClose, isOpen }) => (
          <>
            <MenuButton as={Button}>
              <Text color={values[name]?.length ? '#1083CB' : '#768CA7'}>
                {' '}
                {title}{' '}
                {isOpen ? (
                  <AccordionIcon style={{ marginLeft: '2px', fontSize: '1.5rem' }} />
                ) : (
                  <AccordionIcon
                    style={{ marginLeft: '2px', fontSize: '1.5rem' }}
                    transform="auto"
                  />
                )}
              </Text>
            </MenuButton>
            <MenuList>
              <Box style={{ maxHeight: 450, overflowY: 'scroll' }}>
                {options?.map((option, index) => (
                  <MenuItem
                    key={index}
                    style={{
                      borderBottom: values[name]?.length === index + 1 ? '1px solid #DAE0E7' : '',
                    }}
                  >
                    <Checkbox
                      isChecked={values[name] && values[name]?.includes(`${option.value}`)}
                      onChange={(): void => {
                        const checkBoxValue = `${option.value}`;
                        const data = (values && values[name]) || [];
                        const index = data.indexOf(checkBoxValue);
                        if (index === -1) {
                          if (props.isContacted == 'true') {
                            data[0] = checkBoxValue;
                          } else {
                            data.push(checkBoxValue);
                          }
                        } else {
                          data.splice(index, 1);
                        }

                        /** If the User has
                         * performed any action,
                         * we need to ensure they select Apply
                         * filters
                         **/
                        if (isMenuDirty) {
                          setIsDirtyMenuDirty(false);
                        }

                        currentSelectedValues.current = data;
                        setToggle(!toggle);
                        setFieldValue(name, currentSelectedValues.current);
                        onClose();
                        submitForm();
                      }}
                      style={{ width: DEFAULT_WIDTH - 30 }}
                    >
                      {option.label}
                    </Checkbox>
                  </MenuItem>
                ))}
              </Box>
              <Center>
                {/* <ApplyButton
                  m="2"
                  isFullWidth
                  onClick={() => {
                    if (currentSelectedValues.current) {
                    }
                  }}
                >
                  Save Changes
                </ApplyButton> */}
              </Center>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
}

export function FormMenuSelectRange(props: FormMenuRange) {
  const { title = 'Physician', menuTitle = 'All', name, subOptions = [] } = props;

  const { setFieldValue, values, submitForm } = useFormikContext<any>();
  const [isMenuDirty, setIsDirtyMenuDirty] = useState(true);

  const deriveSelectedFields = (): MenuStatus => {
    const previousValues = values[name] as Range[];
    let valid = true;

    const remappedData = previousValues
      ?.filter((range: Range) => {
        if (range.type && range.value) {
          return true;
        } else {
          valid = false;
        }
      })
      .map((range: Range) => {
        return `${signMapper[range.type]} ${range.value}`;
      });

    if (remappedData?.length) {
      return { menuTitle: remappedData?.join(','), valid };
    }

    return { menuTitle, valid };
  };

  const DropDownMenuSelector = ({ subOptions, index }: MoreOptions) => {
    const existingSelectedMenus = values[name];
    const mergedOptions: MappedOptions = {};

    const hideSelectedMenus = useMemo(() => {
      return existingSelectedMenus.reduce((acc: MappedOptions, curr: Range) => {
        acc[curr.type] = 'true';
        return acc;
      }, mergedOptions);
    }, []);

    return (
      <Menu>
        <MenuButton
          as={Button}
          style={{
            textAlign: 'left',
            backgroundColor: 'white',
            height: 35,
            width: 61,
            borderRadius: '4px 0px 0px 4px',
            border: '0.5px solid #B8C0CE',
            boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.1)',
            borderRightStyle: 'none',
          }}
          rightIcon={
            <AccordionIcon
              style={{ marginLeft: '6px', fontSize: '1.5rem', color: '#8FA1B7' }}
              transform="auto"
            />
          }
        >
          <Text pl="5px" style={{ textAlign: 'center', paddingLeft: '15px' }}>
            {values[name][index]?.type}
          </Text>
        </MenuButton>
        <MenuList _focus={{ bg: 'none' }}>
          {subOptions?.map((subOption) => {
            if (hideSelectedMenus[subOption.value]) return null;

            return (
              <MenuItem
                onClick={() => {
                  const changeValue = subOption.value;
                  const presentValue = values[name];

                  presentValue[index].type = changeValue;

                  if (isMenuDirty) {
                    setIsDirtyMenuDirty(false);
                  }

                  setFieldValue(name, presentValue);
                }}
                key={subOption.label}
              >
                {subOption.label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  };

  const NumberInput = ({ index }: MoreOptions) => {
    return (
      <Input
        placeholder="Enter value"
        type="number"
        defaultValue={values[name][index]?.value}
        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
          const changeValue = event.target.value;
          const presentValue = values[name];

          presentValue[index].value = changeValue;

          if (isMenuDirty) {
            setIsDirtyMenuDirty(false);
          }

          setFieldValue(name, presentValue);
        }}
        style={{
          width: 170,
          height: 35,
          borderRadius: '0px 4px 4px 0px',
          borderLeftStyle: 'none',
          marginRight: 5,
          border: '0.5px solid #B8C0CE',
          borderLeft: 'none',
          backgroundColor: 'white',
        }}
        onClick={(e) => e.stopPropagation()}
      />
    );
  };

  const Delete = ({ arrayHelpers, index }: MoreOptions) => {
    return (
      <Icon
        style={{
          background: '#FFFFFF',
          border: '1px solid #C1CBD7',
          boxShadow: ' 0px 1px 2px rgba(16, 24, 40, 0.05)',
          borderRadius: '8px',
          height: '35px',
          width: '35px',
          padding: '8px',
        }}
        boxSize="4"
        onClick={() => arrayHelpers && arrayHelpers?.remove(index)}
        color="#6C7789"
        icon="delete"
      />
    );
  };

  return (
    <Flex direction="column">
      <Menu closeOnBlur={isMenuDirty} closeOnSelect={false}>
        {({ onClose, isOpen }) => (
          <>
            <MenuButton as={Button} isActive={isOpen}>
              <Text pl="0" color={values[name]?.length ? '#1083CB' : '#768CA7'}>
                {title}
                {isOpen ? (
                  <AccordionIcon style={{ marginLeft: '6px', fontSize: '1.5rem' }} />
                ) : (
                  <AccordionIcon
                    style={{ marginLeft: '6px', fontSize: '1.5rem' }}
                    transform="auto"
                  />
                )}
              </Text>
            </MenuButton>
            <MenuList>
              <FieldArray
                name={name}
                render={(arrayHelpers) => (
                  <>
                    <Box style={{ maxHeight: 250, overflowY: 'scroll' }}>
                      {values[name]?.map((_item: Range, index: number) => {
                        return (
                          <MenuItem _focus={{ bg: 'none' }} key={index}>
                            <Flex alignItems="center" flexDirection="row">
                              <DropDownMenuSelector
                                subOptions={subOptions}
                                {...props}
                                index={index}
                              />
                              <NumberInput {...props} arrayHelpers={arrayHelpers} index={index} />
                              <Delete {...props} arrayHelpers={arrayHelpers} index={index} />
                            </Flex>
                          </MenuItem>
                        );
                      })}
                      <Flex justify="center" alignItems="center">
                        <Button
                          style={{
                            border: '1px solid #C1CBD7',
                            boxShadow: ' 0px 1px 2px rgba(16, 24, 40, 0.05)',
                            borderRadius: '8px',
                            height: '35px',
                            width: '170px',
                            marginLeft: '15px',
                          }}
                          leftIcon={<Icon boxSize="4" icon="add" />}
                          color="#5F7895"
                          onClick={(): void => {
                            arrayHelpers.push({ type: '', value: '' });
                          }}
                          variant="ghost"
                        >
                          Add Field
                        </Button>
                        <ApplyButton
                          m="2"
                          style={{ height: '35px', marginLeft: '22px' }}
                          disabled={!deriveSelectedFields().valid}
                          onClick={() => {
                            onClose();
                            submitForm();
                          }}
                        >
                          Save
                        </ApplyButton>
                      </Flex>
                    </Box>
                  </>
                )}
              />
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
}
export function FormMenuSelectRangeFastReview(props: FormMenuRange) {
  const { title = 'Physician', name, subOptions = [] } = props;

  const { setFieldValue, values } = useFormikContext<any>();
  const [isMenuDirty, setIsDirtyMenuDirty] = useState(true);

  const DropDownMenuSelector = ({ subOptions, index }: MoreOptions) => {
    const existingSelectedMenus = values[name];
    const mergedOptions: MappedOptions = {};

    const hideSelectedMenus = useMemo(() => {
      return existingSelectedMenus.reduce((acc: MappedOptions, curr: Range) => {
        acc[curr.type] = 'true';
        return acc;
      }, mergedOptions);
    }, []);

    return (
      <Menu>
        <MenuButton
          disabled
          as={Button}
          style={{
            textAlign: 'left',
            backgroundColor: 'white',
            height: 35,
            width: 61,
            borderRadius: '4px 0px 0px 4px',
            border: '0.5px solid #B8C0CE',
            boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.1)',
            borderRightStyle: 'none',
          }}
          rightIcon={
            <AccordionIcon
              style={{ marginLeft: '6px', fontSize: '1.5rem', color: '#8FA1B7' }}
              transform="auto"
            />
          }
        >
          <Text pl="5px" style={{ textAlign: 'center', paddingLeft: '15px' }}>
            {values[name][index]?.type}
          </Text>
        </MenuButton>
        <MenuList _focus={{ bg: 'none' }}>
          {subOptions?.map((subOption) => {
            if (hideSelectedMenus[subOption.value]) return null;

            return (
              <MenuItem
                onClick={() => {
                  const changeValue = subOption.value;
                  const presentValue = values[name];

                  presentValue[index].type = changeValue;

                  if (isMenuDirty) {
                    setIsDirtyMenuDirty(false);
                  }

                  setFieldValue(name, presentValue);
                }}
                key={subOption.label}
              >
                {subOption.label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  };

  const NumberInput = ({ index }: MoreOptions) => {
    return (
      <Input
        isDisabled
        placeholder="Enter value"
        type="number"
        defaultValue={values[name][index]?.value}
        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
          const changeValue = event.target.value;
          const presentValue = values[name];

          presentValue[index].value = changeValue;

          if (isMenuDirty) {
            setIsDirtyMenuDirty(false);
          }

          setFieldValue(name, presentValue);
        }}
        style={{
          width: 170,
          height: 35,
          borderRadius: '0px 4px 4px 0px',
          borderLeftStyle: 'none',
          marginRight: 5,
          border: '0.5px solid #B8C0CE',
          borderLeft: 'none',
          backgroundColor: 'white',
        }}
        onClick={(e) => e.stopPropagation()}
      />
    );
  };

  return (
    <Flex direction="column">
      <Menu closeOnBlur={isMenuDirty} closeOnSelect={false}>
        {({ isOpen }) => (
          <>
            <MenuButton as={Button} isActive={isOpen}>
              <Text pl="0" color={values[name]?.length ? '#1083CB' : '#768CA7'}>
                {title}
                {isOpen ? (
                  <AccordionIcon style={{ marginLeft: '6px', fontSize: '1.5rem' }} />
                ) : (
                  <AccordionIcon
                    style={{ marginLeft: '6px', fontSize: '1.5rem' }}
                    transform="auto"
                  />
                )}
              </Text>
            </MenuButton>
            <MenuList>
              <FieldArray
                name={name}
                render={(arrayHelpers) => (
                  <>
                    <Tooltip
                      width={60}
                      label="Changing filters in the review mode is not allowed"
                      hasArrow
                      placement="top-start"
                      arrowSize={15}
                    >
                      <Box style={{ maxHeight: 250, overflowY: 'scroll' }}>
                        {values[name]?.map((_item: Range, index: number) => {
                          return (
                            <MenuItem _focus={{ bg: 'none' }} key={index}>
                              <Flex alignItems="center" flexDirection="row">
                                <DropDownMenuSelector
                                  subOptions={subOptions}
                                  {...props}
                                  index={index}
                                />
                                <NumberInput {...props} arrayHelpers={arrayHelpers} index={index} />
                              </Flex>
                            </MenuItem>
                          );
                        })}
                      </Box>
                    </Tooltip>
                  </>
                )}
              />
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
}
