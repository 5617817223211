import {
  Address,
  Contact,
  CreateDeviceThresholdDto,
  CreatePatientDto,
  Device,
  DeviceThreshold,
  Insurance,
  Organization,
  Patient,
  PatientContact,
  User,
} from '~generated';
import { generateVendorsParameters, stripCharacters } from '~src/utils';
import { FormikHelpers } from 'formik';
import { useCreatePatient } from '~src/services/patients';
import { EnrollPatientForm } from '~src/components/EnrollPatientForm';
import { EnrollPatientFormValues } from '~src/components/EnrollPatientForm/EnrollPatientForm';
import {
  alternateContactForm,
  insuranceDetailsFormEnrollPatientForm,
  deviceDetailsForm,
  physiologicThresholdsForm,
  fileUploadForm,
  DeviceDetailsForm,
  icdCodesDataForm,
  ICDCodesDataForm,
} from '~src/components/EnrollPatientForm/formFields';
import { useListDeviceVendors } from '~src/services/clinics';
import { Spinner } from '@chakra-ui/spinner';
import { PatientICDCode } from '~generated/models/PatientICDCode';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Button } from '@rhythm/components';

const initialValues: EnrollPatientFormValues = {
  rhythmId: '0000000',
  mrn2: '',
  firstName: '',
  lastName: '',
  dateOfBirth: undefined,
  age: '',
  gender: [],
  addressLine1: '',
  addressLine2: '',
  city: '',
  zipcode: '',
  state: [],
  country: 'United States',
  timeZone: '',
  landline: '',
  mobileNumber: '',
  preferredNumber: 'MOBILE',
  email: '',
  requestingPhysician: [],
  requestingClinician: undefined,
  isOnboardingAssignment: [],
  isConsentsToEnrollment: [],
  status: 'isActive',
  ...alternateContactForm,
  ...insuranceDetailsFormEnrollPatientForm,
  devices: [deviceDetailsForm],
  icdcodes: [icdCodesDataForm],
  ...physiologicThresholdsForm,
  ...fileUploadForm,
  isEditing: true,
  language: [],
  disclosePHI: false,
  team: [],
  clinicians: [],
};

interface Errors {
  [key: string]: any;
}

export const validation = (values: EnrollPatientFormValues, errors: Errors) => {
  if (values?.mrn2) {
    const checkString = values?.mrn2;
    if (/[^A-Za-z\d]/.test(checkString)) {
      errors.mrn2 = 'Please remove special character';
    }
  }

  if (!values?.firstName) {
    errors.firstName = 'This field is required';
  }

  if (!values?.lastName) {
    errors.lastName = 'This field is required';
  }

  if (!values?.addressLine1) {
    errors.addressLine1 = 'This field is required';
  }

  if (!values?.city) {
    errors.city = 'This field is required';
  }

  if (!values?.zipcode) {
    errors.zipcode = 'This field is required';
  }

  if (!values?.state?.length) {
    errors.state = 'This field is required';
  }

  if (!values?.dateOfBirth) {
    errors.dateOfBirth = 'This field is required';
  }

  if (!values?.gender?.length) {
    errors.gender = 'This field is required';
  }

  if (!values?.requestingPhysician?.length) {
    errors.requestingPhysician = 'This field is required';
  }

  if (!(values?.isOnboardingAssignment as [])?.length) {
    errors.isOnboardingAssignment = 'This field is required';
  }

  if (values?.copayAmount) {
    if (isNaN(parseInt(values?.copayAmount))) {
      errors.copayAmount = 'Copay amount must be a number';
    }
  }

  if (!values?.team?.length) {
    errors.team = 'This field is required';
  }

  if (!values?.timeZone) {
    errors.timeZone = 'This field is required';
  }

  if (values?.clinicians || values?.clinicians?.length === 1) {
    if (!values?.clinicians[0]?.id) {
      errors.clinicians = [{ id: 'You need to select at least one clinician' }];
    } else {
      if (_.has(values?.clinicians[0]?.id, 'value')) {
        if (!values?.clinicians[0]?.id) {
          errors.clinicians = [{ id: 'You need to select at least one clinician' }];
        }
      }
    }
  }

  /* As per https://rhythmmanagement.atlassian.net/browse/RM-1216:
   1. firstDischargeDate: If a group is selected, then this field should be made mandatory selection. 
   Else, its an optional field. 
   2. reasonForReadmission:If the ‘1st readmission date’ is selected, then this field should be made mandatory. 
   Else, this is an optional field.  
  */

  if (values?.groupIds?.length && !values?.firstDischargeDate) {
    errors.firstDischargeDate = 'This field is required!';
  }

  if (values?.firstReadmissionDate && !values?.reasonForReadmission) {
    errors.reasonForReadmission = 'This field is required!';
  }

  if (values?.devices) {
    type DeviceError = { deviceSerial?: string; vendorName?: string; deviceType?: string };
    const deviceErrors: DeviceError[] = [];
    for (let i = 0; i < values.devices.length; i++) {
      if (
        values.devices.length === 1 &&
        !values?.devices[i].vendorName.length &&
        !values?.devices[i].deviceSerial &&
        !values?.devices[i].deviceType.length
      ) {
        break;
      }
      let deviceSerial, vendorName, deviceType;
      if (!values?.devices[i].vendorName.length) {
        vendorName = 'This field is required!';
      }
      if (!values?.devices[i].deviceSerial) {
        deviceSerial = 'This field is required!';
      }
      if (!values?.devices[i].deviceType.length) {
        deviceType = 'This field is required!';
      }
      deviceErrors.push({ deviceSerial, vendorName, deviceType });
    }

    let devicesHaveError = false;
    for (const deviceError of deviceErrors) {
      if (deviceError.vendorName || deviceError.deviceSerial || deviceError.deviceType) {
        devicesHaveError = true;
        break;
      }
    }

    if (devicesHaveError) errors.devices = deviceErrors;
    else delete errors.devices;
  }
};

export const errorhandling = (errorMessage, values, setErrors) => {
  if (errorMessage?.message === 'DUPLICATE_DEVICE') {
    const devices: { deviceSerial: string }[] = [];
    errorMessage?.data.map((device: any) => {
      if (!device) {
        devices.push({ deviceSerial: '' });
      } else {
        values?.devices?.map((clientDevice: DeviceDetailsForm) => {
          if (clientDevice.deviceSerial === device.patient.serialNumber) {
            devices.push({
              deviceSerial: `Device is already attached to patient with id ${device.patient.id} and needs to be deactivated before adding to a new patient.`,
            });
          } else {
            devices.push({ deviceSerial: '' });
          }
        });
      }
    });
    setErrors({ devices });
  }
};

export function EnrollPatient() {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const createPatient = useCreatePatient(setButtonDisabled);
  const { data: deviceVendors, isLoading } = useListDeviceVendors({});
  const history = useHistory();

  const parseDevice = generateVendorsParameters(deviceVendors ?? []);

  if (isLoading) return <Spinner size="lg" />;

  if (!deviceVendors) {
    return null;
  }

  const formatPatientClinicians = (clinicians: User[]) => {
    return clinicians.map((clinician) => {
      if (clinician.id) {
        return {
          id: clinician.id,
        };
      }
    });
  };

  const handleSubmit = async (
    values: EnrollPatientFormValues,
    { resetForm, setErrors }: FormikHelpers<EnrollPatientFormValues>
  ): Promise<void> => {
    for (const key in values) {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim();
      }
    }
    // TODO: These will need to be chosen dynamically
    const organizationIds = ['2'];
    const clinicIds = ['2'] as unknown as Organization[];
    const insuranceEnroll: Insurance[] = [];
    const physician = {} as User;
    const clinician = {} as User;
    let devices = [] as Device[];
    const onboarding = {} as any;
    if (values?.devices?.length && values?.devices[0].vendorName?.length) {
      devices = values?.devices?.map((device: DeviceDetailsForm) => {
        return {
          serialNumber: device.deviceSerial,
          type: device.deviceType,
          isActive: device.isActive,
          vendor: {
            ...parseDevice[device.vendorSite],
          },
        } as unknown as Device;
      });
    }
    let icdcodes = [] as PatientICDCode[];
    if (values?.icdcodes && values?.icdcodes?.length > 0) {
      icdcodes = values?.icdcodes?.map((icdcode: ICDCodesDataForm) => {
        return {
          diagnosis: icdcode.diagnosis,
          icd10Code: icdcode.icd10Code,
        } as unknown as PatientICDCode;
      });
    }

    if (values?.requestingPhysician) {
      physician.id = `${values?.requestingPhysician}`;
    }

    if (values?.requestingClinician) {
      clinician.id = `${values?.requestingClinician}`;
    }
    if (values?.isOnboardingAssignment === 'Closed') {
      onboarding.id = null;
    } else {
      onboarding.id = `${values?.isOnboardingAssignment}`;
    }

    if (
      values?.primaryName ||
      values?.primaryMemberId ||
      values?.primaryGroupNumber ||
      values?.primaryContanctInformation ||
      values?.copayAmount
    ) {
      insuranceEnroll.push({
        name: values?.primaryName,
        copay: values?.copayAmount,
        isPrimary: true,
        groupName: values?.primaryGroupNumber,
        memberId: values?.primaryMemberId,
        contactInformation: values?.primaryContanctInformation,
      } as unknown as Insurance);
    }

    if (
      values?.secondaryName ||
      values?.secondaryMemberId ||
      values?.secondaryGroupNumber ||
      values?.secondaryContanctInformation
    ) {
      insuranceEnroll.push({
        name: values?.secondaryName,
        copay: values?.copayAmount,
        isPrimary: false,
        groupName: values?.secondaryGroupNumber,
        memberId: values?.secondaryMemberId,
        contactInformation: values?.secondaryContanctInformation,
      } as unknown as Insurance);
    }

    const contactsEnroll: any = [];
    /**Contacts */
    if (values.altFirstName && values?.altPhoneNumber) {
      contactsEnroll.push({
        isAlternate: true,
        name: `${values.altFirstName} ${values.altLastName}`,
        contact: stripCharacters(values.altPhoneNumber),
        isPreferred: values?.isPreferredContact,
        relation: values?.altRelation,
        disclosePHI: values?.disclosePHI,
        type: Contact.type.PHONE,
      } as PatientContact);
    }

    if (values.altFirstName && values?.altEmail) {
      contactsEnroll.push({
        isAlternate: true,
        name: `${values.altFirstName} ${values.altLastName}`,
        isPreferred: values?.isPreferredContact,
        contact: values.altEmail,
        relation: values?.altRelation,
        disclosePHI: values?.disclosePHI,
        type: Contact.type.EMAIL,
      } as PatientContact);
    }

    if (values?.landline) {
      contactsEnroll.push({
        isAlternate: false,
        name: `${values.firstName} ${values.lastName}`,
        contact: stripCharacters(values.landline),
        isPreferred: values?.preferredNumber == 'landline',
        type: Contact.type.PHONE,
      });
    }

    if (values?.email) {
      contactsEnroll.push({
        isAlternate: false,
        name: `${values.firstName} ${values.lastName}`,
        contact: values.email,
        type: Contact.type.EMAIL,
      });
    }

    /** Add address */
    const addressEnroll = {
      line1: values?.addressLine1,
      line2: values?.addressLine2,
      state: values?.state,
      postalCode: values?.zipcode,
      country: values?.country,
      timeZone: values?.timeZone,
      city: values?.city,
    } as Address;

    /**Contacts */

    const thresholdValuesEnroll: CreateDeviceThresholdDto[] = [];

    const bloodPressureDiastolicEnroll = {
      threshHoldUpperLimit: values.bpDiastolic[1],
      threshHoldLowerLimit: values.bpDiastolic[0],
      metricType: DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const bloodPressureSystolicEnroll = {
      threshHoldUpperLimit: values.bpSystolic[1],
      threshHoldLowerLimit: values.bpSystolic[0],
      metricType: DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const glucoseEnroll = {
      threshHoldUpperLimit: values.glucose[1],
      threshHoldLowerLimit: values.glucose[0],
      metricType: DeviceThreshold.metricType.BLOOD_SUGAR,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const bloodOxygenEnroll = {
      threshHoldUpperLimit: values.bloodOxygen[1],
      threshHoldLowerLimit: values.bloodOxygen[0],
      metricType: DeviceThreshold.metricType.BLOOD_OXYGEN,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const weightChangeEnroll = {
      threshHoldUpperLimit: values.weightChange24h,
      threshHoldLowerLimit: values.weightChange24h,
      unit: values.weightChange24h,
      metricType: DeviceThreshold.metricType.WEIGHT,
      sign: DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN,
    } as CreateDeviceThresholdDto;

    const weightChange7daysEnroll = {
      threshHoldUpperLimit: values.weightChange7Day,
      threshHoldLowerLimit: values.weightChange7Day,
      unit: values.weightChange7Day,
      metricType: DeviceThreshold.metricType.SEVEN_DAYS_CHANGE,
      sign: DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN,
    } as CreateDeviceThresholdDto;

    const pulseEnroll = {
      threshHoldUpperLimit: values.pulse[1],
      threshHoldLowerLimit: values.pulse[0],
      metricType: DeviceThreshold.metricType.PULSE,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    thresholdValuesEnroll.push(
      bloodPressureDiastolicEnroll,
      bloodPressureSystolicEnroll,
      glucoseEnroll,
      bloodOxygenEnroll,
      weightChangeEnroll,
      pulseEnroll,
      weightChange7daysEnroll
    );
    const patientData = {
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender === Patient.gender.MALE ? Patient.gender.MALE : Patient.gender.FEMALE,
      prefix: values.gender === 'male' ? 'Mr.' : 'Ms.',
      devices,
      icdcodes,
      mrn: values?.mrn2,
      mobileNumber: values?.mobileNumber,
      isConsentsToEnrollment: values?.isConsentsToEnrollment == 'true' ? true : false,
      deviceThresholds: thresholdValuesEnroll,
      organizationIds,
      birthDate: values.dateOfBirth ?? '',
      clinicIds,
      physician,
      clinician,
      onboarding,
      onBoardingClosed: values?.isOnboardingAssignment == 'Closed' ? true : false,
      contacts: contactsEnroll,
      address: addressEnroll,
      insurance: insuranceEnroll,
      language:
        Array.isArray(values.language) && values.language.length === 0 ? '' : values.language,
      team: values.team,
      clinicians: formatPatientClinicians(values.clinicians),
      firstDischargeDate: values.firstDischargeDate,
      firstReadmissionDate: values.firstReadmissionDate,
      reasonForReadmission: values.reasonForReadmission,
      groupIds: values.groupIds?.map((item: any) => item.value),
    } as unknown as CreatePatientDto;

    try {
      createPatient.mutate(
        { ...patientData },
        {
          onSuccess: async (data) => {
            const id = data?.id;
            resetForm && resetForm();
            history.push(`profile/${id}`);
          },
          onError: async (error: any) => {
            const errorMessage = JSON.parse(await error?.body?.message);
            errorhandling(errorMessage, values, setErrors);
          },
        }
      );
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const validationFunction = (values: EnrollPatientFormValues): Errors => {
    const errors: Errors = {};

    validation(values, errors);
    // This is going to be changed later once we have feedback from users what all validation required

    // const devices = [] as any;
    // if (values?.devices?.length && values?.devices[0].vendorName?.length) {
    //   values?.devices?.map((device: DeviceDetailsForm) => {
    //     const deviceSerialValue = device?.deviceSerial.replace(/ /g, '');
    //     if (device?.deviceType == 'blood_sugar' && deviceSerialValue.length !== 7) {
    //       devices.push({
    //         deviceSerial:
    //           deviceSerialValue.length === 0 ? 'This field is required' : 'Invalid Device ID',
    //       });
    //       errors.devices = devices;
    //     } else if (device?.deviceType != 'blood_sugar' && deviceSerialValue.length != 15) {
    //       devices.push({
    //         deviceSerial:
    //           deviceSerialValue.length === 0 ? 'This field is required' : 'Invalid Device ID',
    //       });
    //       errors.devices = devices;
    //     } else {
    //       devices.push({
    //         deviceSerial: '',
    //       });
    //     }
    //     return {} as unknown as Device;
    //   });
    // }
    return errors;
  };

  return (
    <>
      <Button
        style={{
          width: '32px',
          height: '6px',
          color: '#6C7789',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        }}
        onClick={() => history.goBack()}
        leftIcon={'arrow-left'}
        mb="md"
      >
        Back
      </Button>
      <EnrollPatientForm
        handleSubmit={handleSubmit}
        validationFunction={validationFunction}
        initialValues={initialValues}
        pageHeader="Create a Patient"
        disabled={buttonDisabled}
        isLoading={createPatient.isLoading}
      />
    </>
  );
}
