import { Box, Flex, Text } from '@rhythm/components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { BloodPressure, BloodSugar, PatientReading, Pulse, PulseOx, Weight } from '~generated';
import { PatientBloodPressureFastReviewReading } from './PatientBloodPressureReadingFastReview';

dayjs.extend(relativeTime);

export interface MetricCellProps {
  type: PatientReading.type;
  id: string;
}
export interface MetricCellFastReviewProps {
  reading?: BloodPressure[] | Weight[] | Pulse[] | BloodSugar[] | PulseOx[];
  type?: PatientReading.type;
}

export interface MetricElasticCellProps {
  id: string;
  type: any;
  value: any;
  timestamp: any;
  outlier: any;
}

export function MetricCellFastReview(data: MetricCellFastReviewProps) {
  return (
    <>
      {data.type === PatientReading.type.BLOOD_PRESSURE && (
        <PatientBloodPressureFastReviewReading measurements={data} />
      )}

      {data.type === PatientReading.type.PULSE && data.reading?.length ? (
        <Box className="fast-review">
          {data?.reading?.length &&
            data?.reading?.map((measurement: any, index) => (
              <Flex
                direction="column"
                key={index}
                marginBottom={index < (data?.reading?.length ?? 0) - 1 ? '10px' : '0px'}
              >
                <Text mb="xs" fontWeight="bold" color="#0E6DAD">
                  {measurement.pulse} bpm
                </Text>
                <Text mb="xs" color="#1083CB">
                  {dayjs(measurement.createdAt).fromNow()}
                </Text>
              </Flex>
            ))}
        </Box>
      ) : (
        <Flex direction="column"></Flex>
      )}
      {data.type === PatientReading.type.WEIGHT && data.reading?.length ? (
        <Box className="fast-review">
          {data?.reading &&
            data?.reading?.map((measurement: any, index) => (
              <Flex
                direction="column"
                key={index}
                marginBottom={index < (data?.reading?.length ?? 0) - 1 ? '10px' : '0px'}
              >
                <Text mb="xs" fontWeight="bold" color="#0E6DAD">
                  {measurement.reading} lb
                </Text>
                <Text mb="xs" color="#1083CB">
                  {dayjs(measurement.createdAt).fromNow()}
                </Text>
              </Flex>
            ))}
        </Box>
      ) : (
        <Flex direction="column"></Flex>
      )}
      {data.type === PatientReading.type.BLOOD_SUGAR && data.reading?.length ? (
        <Box className="fast-review">
          {data?.reading &&
            data?.reading?.map((measurement: any, index) => (
              <Flex
                direction="column"
                key={index}
                marginBottom={index < (data?.reading?.length ?? 0) - 1 ? '10px' : '0px'}
              >
                <Text mb="xs" fontWeight="bold" color="#0E6DAD">
                  {measurement.reading} mg/dL
                </Text>
                <Text mb="xs" color="#1083CB">
                  {dayjs(measurement.createdAt).fromNow()}
                </Text>
              </Flex>
            ))}
        </Box>
      ) : (
        <Flex direction="column"></Flex>
      )}
      {data.type === PatientReading.type.BLOOD_OXYGEN && data.reading?.length ? (
        <Box className="fast-review">
          {data?.reading &&
            data?.reading?.map((measurement: any, index) => (
              <Flex
                direction="column"
                key={index}
                marginBottom={index < (data?.reading?.length ?? 0) - 1 ? '10px' : '0px'}
              >
                <Text mb="xs" fontWeight="bold" color="#0E6DAD">
                  {measurement.pulseox} %
                </Text>
                <Text mb="xs" color="#1083CB">
                  {dayjs(measurement.createdAt).fromNow()}
                </Text>
              </Flex>
            ))}
        </Box>
      ) : (
        <Flex direction="column"></Flex>
      )}
    </>
  );
}
