import { ReactNode } from 'react';
import { Link as RhythmLink, LinkProps as RhythymLinkProps } from '@rhythm/components';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export interface LinkProps {
  children: ReactNode;
  to: string;
}

type OverrideProps = RhythymLinkProps & RouterLinkProps;

function OverrideLink(props: OverrideProps) {
  return <RhythmLink {...props} />;
}

export function Link({ children, to }: LinkProps) {
  return (
    <OverrideLink as={RouterLink} to={to}>
      {children}
    </OverrideLink>
  );
}
