import { Box, Flex, IconButton, Text } from '@rhythm/components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AllDeviceThresholds } from '~src/types/AllDeviceThresholds';
import { PatientReading } from '~generated';
import { useState } from 'react';
import { AlertDataModal } from '../Alert/PatientAlertDataModal';
import { AlertReadingMetricCellProp } from '~src/pages/Alerts';

dayjs.extend(relativeTime);

export interface alertPatientMetricCellProps {
  id?: string;
  type: PatientReading.type;
  deviceThresholds?: AllDeviceThresholds;
  timestamp?: string;
  systolic?: number;
  diastolic?: number;
  irregular?: boolean;
  deviceId?: string;
  meter_id?: string;
  reading?: number;
  before_meal?: boolean;
  battery?: number;
  volume?: number;
  event_flag?: string;
  pulse?: number;
  pulseox?: number;
  weight?: number;
  latestWeight?: number;
  previousWeight?: any;
  weightDifference?: number;
  isReviewed?: boolean;
}

export interface alertPatientMetricElasticCellProps {
  id: string;
  type: any;
  value: any;
  timestamp: any;
  outlier: any;
}

type alertPatientDataResponse = {
  reading?: string;
  timestamp?: string;
  outlier?: boolean;
  systolicReading?: string;
  diastolicReading?: string;
  systolicOutlier?: boolean;
  diastolicOutlier?: boolean;
  unit?: string;
  isReviewed?: boolean;
};

export function AlertMetricCell({
  type,
  timestamp,
  systolic,
  diastolic,
  reading,
  pulse,
  pulseox,
  weight,
}: alertPatientMetricCellProps) {
  const defaultColor = 'neutral.black';

  if (pulseox) type = PatientReading.type.BLOOD_OXYGEN;
  if (systolic && diastolic) type = PatientReading.type.BLOOD_PRESSURE;
  if (pulse && !systolic) type = PatientReading.type.PULSE;
  if (reading) type = PatientReading.type.BLOOD_SUGAR;
  if (weight) type = PatientReading.type.WEIGHT;

  const formatPatient = (type: PatientReading.type): alertPatientDataResponse => {
    switch (type) {
      case PatientReading.type.BLOOD_PRESSURE: {
        if (diastolic && systolic) {
          return {
            systolicReading: `${systolic}`,
            diastolicReading: `${diastolic}`,
            timestamp: timestamp,
            unit: 'mmHg',
          };
        }
        return {};
      }

      case PatientReading.type.BLOOD_SUGAR: {
        if (reading && timestamp) {
          return {
            reading: `${reading} mg/dL`,
            timestamp: timestamp,
          };
        }

        return {};
      }

      case PatientReading.type.PULSE: {
        if (pulse) {
          return {
            reading: `${pulse} bpm`,
            timestamp: timestamp,
            unit: 'mmHg',
          };
        }
        return {};
      }

      case PatientReading.type.WEIGHT: {
        if (weight) {
          return {
            reading: `${weight} lb`,
            timestamp: timestamp,
          };
        }
        return {};
      }

      case PatientReading.type.BLOOD_OXYGEN: {
        if (pulseox) {
          return {
            reading: `${pulseox}%`,
            timestamp: timestamp,
            unit: '%',
          };
        }
        return {};
      }
    }

    return {};
  };

  const measurement = formatPatient(type);

  if (type == PatientReading.type.BLOOD_PRESSURE) {
    return <AlertPatientBloodPressureReading measurement={measurement} />;
  }

  if (!measurement.reading) {
    return (
      <Flex direction="column">
        <Text mb="xs" fontWeight="bold" color={defaultColor}>
          —
        </Text>
      </Flex>
    );
  }
  return (
    <Flex direction="column">
      <Text mb="xs" fontWeight="bold" color={defaultColor}>
        {measurement.reading}
      </Text>
      <Text mb="xs" color={defaultColor}>
        {dayjs(measurement.timestamp).fromNow()}
      </Text>
    </Flex>
  );
}

export const AlertPatientBloodPressureReading = (props: { measurement: any }) => {
  const { measurement } = props;
  if (!measurement.systolicReading && !measurement.diastolicReading) {
    return (
      <Flex direction="column">
        <Text mb="xs" fontWeight="bold" color={'neutral.black'}>
          —
        </Text>
      </Flex>
    );
  } else {
    return (
      <Flex direction="column" className="fast-review-card">
        <Box mb="xs" alignItems="center" display={'inline-flex'}>
          <Text mb="xs" fontWeight="bold" color={'neutral.black'}>
            {measurement?.systolicReading || '-'}
          </Text>
          <Text mb="xs" color={'neutral.black'}>
            /
          </Text>
          <Text mb="xs" fontWeight="bold" color={'neutral.black'}>
            {measurement?.diastolicReading || '-'}
          </Text>
          <span>&nbsp;</span>
          <Text mb="xs" color={'neutral.black'} fontWeight="bold">
            {measurement?.unit || '-'}
          </Text>
        </Box>
        <Text mb="xs" color={'neutral.black'}>
          {measurement?.timestamp && dayjs(measurement?.timestamp).fromNow()}
        </Text>
      </Flex>
    );
  }
};

export function PatientModalLink({
  id,
  Patient,
  refetch,
  cliniciansData,
}: AlertReadingMetricCellProp) {
  const [open, setOpen] = useState<boolean>(false);

  const openModal = async () => {
    setOpen(true);
  };
  return (
    <Box>
      {Patient ? (
        <Box
          onClick={() => {
            openModal();
          }}
          style={{ cursor: 'pointer' }}
        >
          <Flex alignItems="center">
            <Flex direction="column">
              <Text mb="xs" fontWeight="bold" overflowWrap={'anywhere'} color={'#0E6DAD'}>
                {Patient}
              </Text>
              {open && (
                <AlertDataModal
                  id={id}
                  open={open}
                  setOpen={setOpen}
                  cliniciansData={cliniciansData?.data}
                  refetch={refetch}
                />
              )}
            </Flex>
          </Flex>
        </Box>
      ) : (
        <Flex alignItems="left">
          <Flex cursor={'pointer'} direction="column">
            <IconButton
              variant="ghost"
              icon="phone-call"
              fill={'#0E6DAD'}
              aria-label="toggle pin"
              onClick={() => openModal()}
            />
            {open && (
              <AlertDataModal
                id={id}
                open={open}
                setOpen={setOpen}
                cliniciansData={cliniciansData?.data}
                refetch={refetch}
              />
            )}
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
