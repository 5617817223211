import { Fragment, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import type { Column, Cell } from 'react-table';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  VStack,
} from '@rhythm/components';
import { Link as RhythmLink } from '~src/components/Link';
import { useMutation } from 'react-query';
import { DataTable } from '~src/components/DataTable';
import { Search } from '../Search';
import { stripCharacters, telMask, globalThresholdDefaults } from '~src/utils';
import { formatPhoneNumber } from '~src/utils/formatPhoneNumber';
import { UserManagementModal } from './UserManagementModal';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import toast from 'react-hot-toast';
import { Errors } from '~src/pages/CreateAccount/CreateAccount';
import { FormField, MaskedInput } from '../FormField';
import { FormSelect } from '../FormSelect';
import { FormRangeSlider, FormSlider } from '../FormSlider';
import {
  Contact,
  CreateUserDto,
  DeviceThreshold,
  Organization,
  PatientContact,
  DefaultService as Service,
  UserContact,
  User,
  CreateDeviceThresholdDto,
  Roles,
} from '~generated';
import { useClinicUsers, useFetchListOrganization, UseListUsers } from '~src/services/clinics';
import { FormOption } from '../FormSelect/FormSelect';
import { thresholdInformation } from '~src/pages/CreateAccount/formFields';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { createUserRoles, editUserRoles, editUserRolesRoles } from '~src/constants/roles';
import { credentialOptions } from '~src/constants/credential';
import { useGetUser, useUpdateUser } from '~src/services/users';
import { ROLE_TYPES } from '~src/types';
import { HypertensionReportForm } from '~src/components/HypertensionReportForm';
import { GlucoseReportForm } from '~src/components/GlucoseReportForm/GlucoseReportForm';
import { ResponseError } from '~src/services/patients';
import { GetAllUsersProps, UserPaginationParams } from '../InternalSettings/InternalUserManagement';
import { useDebounce } from 'react-use';

interface UserRow extends Record<string, unknown> {
  id: string;
  name: string;
  accountName: string;
  phoneNumber?: string;
  clinics: Organization[];
  role: string;
  email: string;
}

interface InitialValues {
  firstName: string;
  lastName: string;
  credentials: string[] | unknown;
  phone: string;
  bpSystolic: number[];
  bpDiastolic: number[];
  pulse: number[];
  glucose: number[];
  weightChange24h: number;
  weightChange7days: number;
  bloodOxygen: number[];
  email: string;
  organizations: string[] | unknown;
  role: string;
  defaultEscalationNote: string | undefined;
  clinics: Array<string> | unknown;
}

type UserManagementType = {
  id?: string;
  role?: string;
  type?: 'providers' | 'non-provider';
  childrenId?: string[];
  setUserCount?: (count: number) => void;
};

export const formatUserTable = (userData, props): UserRow[] => {
  if (!userData) return [];

  return userData
    ?.map((user) => {
      const clinicName: Organization[] = [];
      const accountName: string[] = [];

      const phoneNumber = user?.contacts?.find((item) => item.type == Contact.type.PHONE)?.contact;
      user.organizations.forEach((org: Organization) => {
        if (org.type === 'account') {
          accountName.push(org.name);
        } else {
          clinicName.push(org);
        }
      });

      const userNameFormatter = (lastName: string, firstName: string, credentials: any) => {
        const credential = credentials?.map(
          (credential: { credential: string }) => ` ${credential.credential}`
        );
        return `${lastName}, ${firstName},${credential}`;
      };

      return {
        name:
          user?.credentials?.length > 0
            ? userNameFormatter(user.lastName, user.firstName, user.credentials)
            : `${user.lastName}, ${user.firstName}`,
        id: user.id,
        role: user.role,
        phoneNumber,
        clinics: clinicName,
        accountName: accountName.join(','),
        email: user.email,
      };
    })
    .filter((user) => {
      if (props.type === 'providers') {
        return user.role == ROLE_TYPES.PROVIDER;
      }

      if (props.type === 'non-provider') {
        if (user.role == ROLE_TYPES.PROVIDER) return false;
      }

      return true;
    });
};

export function UserManagement(props: UserManagementType) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState<boolean>(false);
  const updateUser = useUpdateUser();
  let selectedData = {};
  const [currentReportType, setCurrentReportType] = useState('hypertension');
  const [selectedUsers, setSelectedUsers] = useState({
    physicianIds: [],
    organizationIds: null,
    clinicalIds: null,
  });

  const DEFAULT_PAGE_SIZE = 10;
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(DEFAULT_PAGE_SIZE);
  const [sortBy, setSortBy] = useState<string>('');
  const [sort, setSort] = useState<string>('');

  const getRoleFromType = (type: 'providers' | 'non-provider') => {
    if (type === 'providers') return [Roles.ROLES_EXTERNAL_PROVIDER];
    return [Roles.ROLES_EXTERNAL_NON_PROVIDER];
  };

  const [queryData, setQueryData] = useState<UseListUsers>({
    sort: 'ASC',
    offset: 0,
    search: '',
    limit: DEFAULT_PAGE_SIZE,
    filter: {
      isActive: true,
      ...(props.id && { id: props.id }),
      ...(props.id && props.childrenId?.length && { childrenId: [...props.childrenId, props.id] }),
      userRoles: props.type ? getRoleFromType(props.type) : [],
    },
  });

  let organizations = useFetchListOrganization({}).data as Organization[];
  let clinics = useFetchListOrganization({ filter: { clinic: true, id: props.id } })
    .data as Organization[];
  const clinicsToLink = clinics;

  const getLocalStorageData = () => {
    return {
      pageIndex: 0,
      initialSize: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      defaultSortBy: [],
      goToPage: 0,
      totalCount: 0,
      searchWord: '',
      filters: {},
    };
  };

  const [pagination, setPagination] = useState<UserPaginationParams>({
    ...getLocalStorageData(),
  });

  const [pageParams, setPageParams] = useState({ totalSize: 0, totalPageCount: 0 });

  const paginatedData = useClinicUsers({ ...queryData });

  const refetchUserData = paginatedData.refetch;
  const userData = paginatedData?.data?.data;

  const paginationInfo = paginatedData?.data?.pagination;

  const totalUsersCount = paginationInfo?.count ?? 0;

  useEffect(() => {
    if (userData) {
      if (totalUsersCount < pagination.pageSize) {
        setPageParams({
          totalPageCount: 1,
          totalSize: totalUsersCount,
        });
      } else {
        setPageParams({
          totalPageCount: Math.ceil(totalUsersCount / pagination?.pageSize),
          totalSize: totalUsersCount,
        });
      }
    }
  }, [userData]);

  const fetchData = useCallback(
    ({ offset, limit, sortBy, sort, searchTerm }: GetAllUsersProps) => {
      const updatedQueryData: any = {};
      updatedQueryData.offset = offset;
      updatedQueryData.limit = limit;
      updatedQueryData.search = searchTerm;
      updatedQueryData.filter = {
        isActive: true,
        userRoles: props.type ? getRoleFromType(props.type) : [],
      };
      if (props.id) {
        updatedQueryData.filter = {
          ...updatedQueryData.filter,
          ...(props.id && { id: props.id }),
          ...(props.id &&
            props.childrenId?.length && { childrenId: [...props.childrenId, props.id] }),
        };
      }
      if (sortBy !== undefined && sort !== undefined) {
        updatedQueryData.sortBy = sortBy;
        updatedQueryData.sort = sort ? 'DESC' : 'ASC';
      }
      setQueryData(updatedQueryData);
    },
    [setQueryData]
  );

  const getData = (data: any): void => {
    const pageIndex = data?.pageIndex;
    const pageSize = data?.pageSize;
    if (data?.pageIndex !== pagination.pageIndex || data?.pageSize !== pagination.pageSize) {
      setPagination({
        pageIndex,
        pageSize,
      });
      return;
    }
    if (offset !== data.pageIndex) {
      setOffset(data.pageIndex * data.pageSize);
    }
    if (limit !== data.pageSize) {
      setLimit(data.pageSize);
    }
    const sort = data.sortBy[0];
    if (sort) {
      const { desc, id } = sort;
      if (sortBy !== id || sort !== desc) {
        setSortBy(id);
        setSort(desc);
      }
    } else {
      if (sortBy !== undefined || sort !== undefined) {
        setSortBy('');
        setSort('');
      }
    }
  };

  useEffect(() => {
    fetchData({ offset, limit, sortBy, sort, searchTerm });
  }, [offset, limit, sortBy, sort, pagination.searchWord, fetchData]);

  const parseSearchColumn = (name?: string) => {
    if (!name) return '';
    const splitName = name?.split(' ').filter((e) => e);
    if (splitName.length > 1) return `${splitName.join('&')}`;
    return splitName[0];
  };

  useDebounce(
    async () => {
      if (parseSearchColumn(searchTerm) !== pagination?.searchWord) {
        setPagination({
          ...pagination,
          pageIndex: 0,
          goToPage: 0,
          initialSize: 0,
          searchWord: searchTerm,
        });
      }
    },
    250,
    [searchTerm]
  );

  const clinicId = props?.id;

  const isRole = props.role || '';
  if (clinicId) {
    organizations = organizations?.filter((users) => {
      if (users?.id === clinicId) return true;
      return false;
    });

    clinics = clinics?.filter((users) => {
      if (users?.id === clinicId) return true;
      return false;
    });
  }

  const handleRowSelection = (state: any) => {
    selectedData = state;
  };

  const rolesValues = Object.values(CreateUserDto.role);

  const rolesFormatted = Object.keys(CreateUserDto.role)
    .map((role, index) => ({
      label: role,
      value: rolesValues[index],
    }))
    .filter((role) => {
      if (isRole) {
        if (props.role === 'providers') {
          return role.value === CreateUserDto.role.ROLES_EXTERNAL_PROVIDER;
        }

        if (props.role === 'non-provider') {
          return (
            role.value === CreateUserDto.role.ROLES_INTERNAL_ADMIN ||
            role.value === CreateUserDto.role.ROLES_INTERNAL_SUPER_ADMIN ||
            role.value === CreateUserDto.role.ROLES_INTERNAL_CLINICAL ||
            role.value === CreateUserDto.role.ROLES_INTERNAL_NON_CLINICAL ||
            role.value === CreateUserDto.role.ROLES_EXTERNAL_NON_PROVIDER
          );
        }

        return false;
      }

      // do not show external practitioner and external non practitioner roles
      return (
        role.value !== CreateUserDto.role.ROLES_EXTERNAL_PRACTITIONER &&
        role.value !== CreateUserDto.role.ROLES_EXTERNAL_NON_PRACTITIONER
      );
    });

  const formatOrganization = (organization: Organization[]): FormOption[] => {
    return organization?.map((org: Organization) => ({ value: org.id, label: org.name }));
  };

  let orgFormatted: FormOption[] = [];
  orgFormatted = [...formatOrganization(organizations || []), ...orgFormatted];

  let clinicsFormatted: FormOption[] = [];
  clinicsFormatted = [...formatOrganization(clinicsToLink || []), ...clinicsFormatted];

  function UserName({ id, name, email, phoneNumber }: UserRow) {
    const user = useGetUser()?.data;

    const handleEditClick = () => {
      setSelectedUser(id);
      setShowEditModal(true);
    };

    const handleShowClick = () => {
      setSelectedUser(id);
      setShowViewModal(true);
    };
    const editOwnProfile = user?.email === email;
    return (
      <Box
        position="relative"
        sx={{
          _hover: {
            '.edit-user': {
              display: 'block',
            },
          },
        }}
      >
        <Link to={`#`}>
          <Text onClick={handleShowClick} fontWeight="bold">
            {name}
          </Text>
          {phoneNumber && <Text>{formatPhoneNumber(phoneNumber)}</Text>}
        </Link>
        <RoleBasedRoutes allowedRoles={editUserRoles} overrideCondition={editOwnProfile}>
          <IconButton
            aria-label="edit user"
            className="edit-user"
            onClick={handleEditClick}
            icon="edit"
            variant="unstyled"
            color="primary.500"
            sx={{
              transform: 'translateY(-16px)',
              display: 'none',
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          />
        </RoleBasedRoutes>
      </Box>
    );
  }

  function Clinic({ clinics }: UserRow) {
    if (clinics.length === 0) return <div> -- </div>;

    return (
      <Fragment>
        {clinics.map((clinic, i) => (
          <Fragment key={clinic.id}>
            {i > 0 && ', '}
            <RhythmLink to={`/settings/account/${clinic.id}`}>{clinic.name}</RhythmLink>
          </Fragment>
        ))}
      </Fragment>
    );
  }

  const columns: Column<UserRow>[] = [
    {
      Header: 'User',
      accessor: 'name',
      Cell: ({ row: { original } }: Cell<UserRow, string>) => {
        return <UserName {...original} />;
      },
    },
    {
      Header: 'Account',
      accessor: 'accountName',
    },
    {
      Header: 'Clinics',
      accessor: 'clinics',
      Cell: ({ row: { original } }: Cell<UserRow, string>) => <Clinic {...original} />,
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
  ];

  const initialValues: InitialValues = {
    firstName: '',
    lastName: '',
    credentials: [],
    phone: '',
    email: '',
    bpSystolic: globalThresholdDefaults.bpSystolic,
    bpDiastolic: globalThresholdDefaults.bpDiastolic,
    pulse: globalThresholdDefaults.pulse,
    weightChange24h: thresholdInformation.weightChange24h,
    weightChange7days: thresholdInformation.weightChange7days,
    bloodOxygen: thresholdInformation.bloodOxygen,
    glucose: thresholdInformation.glucose,
    organizations: [],
    role: '',
    clinics: [],
    defaultEscalationNote: '',
  };

  const userInitialValues = userData?.find((user: { id: string }) => user.id === selectedUser);
  const formattedCredentials = userInitialValues?.credentials?.map(
    (credential: { credential: any; id: any }) => ({
      label: credential.credential,
      value: credential.id,
    })
  );
  const filteredAccounts = userInitialValues?.organizations.filter((org: { type: string }) => {
    return org.type === 'account';
  });
  const filteredClinics = userInitialValues?.organizations.filter((org: { type: string }) => {
    return org.type === 'clinic';
  });
  const getThresholdLimits = (metricType: string) => {
    const result = userInitialValues?.threshold?.find(
      (threshold: { metricType: any }) => threshold.metricType === metricType
    );
    if (result) {
      const lowerLimit = result.threshHoldLowerLimit;
      const upperLimit = result.threshHoldUpperLimit;
      return [lowerLimit, upperLimit];
    } else {
      return null;
    }
  };

  const editInitialValues: InitialValues = {
    firstName: userInitialValues?.firstName ?? '',
    lastName: userInitialValues?.lastName ?? '',
    credentials: formattedCredentials ?? [],
    phone: userInitialValues?.contacts?.[0]?.contact ?? '',
    email: userInitialValues?.email ?? '',
    bpSystolic: getThresholdLimits('blood_pressure_systolic') || globalThresholdDefaults.bpSystolic,
    bpDiastolic:
      getThresholdLimits('blood_pressure_diastolic') || globalThresholdDefaults.bpDiastolic,
    pulse: getThresholdLimits('pulse') || globalThresholdDefaults.pulse,
    weightChange24h: getThresholdLimits('weight')?.[0] || thresholdInformation.weightChange24h,
    weightChange7days:
      getThresholdLimits('seven_days_change')?.[1] || thresholdInformation.weightChange7days,
    bloodOxygen: getThresholdLimits('blood_oxygen') || thresholdInformation.bloodOxygen,
    glucose: getThresholdLimits('blood_sugar') || thresholdInformation.glucose,
    organizations: [...formatOrganization(filteredAccounts ?? [])],
    role: userInitialValues?.role,
    defaultEscalationNote: userInitialValues?.defaultEscalationNote,
    clinics: [...formatOrganization(filteredClinics ?? [])],
  };

  formatUserTable(userData, props);

  const notifySuccess = (name: string) => toast.success(`${name} has been successfully created.`);

  const notifyError = (name: string) =>
    toast.error(`There was an error while creating ${name}'s' account.`);

  const saveUser = async (data: InitialValues) => {
    const userData = data as unknown as FormikValues;

    const contacts: any = [];

    if (data.phone) {
      contacts.push({
        isAlternate: false,
        name: `${data.firstName} ${data.lastName}`,
        contact: stripCharacters(data.phone),
        isPreferred: true,
        type: Contact.type.PHONE,
      } as PatientContact);
    }

    try {
      const formattedPayload = {
        ...userData,
        gender: User.gender.MALE,
        isActive: true,
        organizations: [{ id: userData.organizations }],
        contacts,
        threshold: [],
        status: CreateUserDto.status.REGISTERED,
        role: userData.role,
      } as unknown as CreateUserDto;
      await Service.userControllerCreate(formattedPayload);
      refetchUserData();
      setOpen(false);
      notifySuccess(`${userData.firstName}`);
    } catch (err) {
      notifyError(`${userData.firstName}`);
      return err as ResponseError;
    }
  };

  const { isLoading } = useMutation(saveUser, {
    onSuccess: () => {
      setOpen(false);
      refetchUserData();
    },
  });

  const handleSubmit = async (
    values: InitialValues,
    { setErrors }: FormikHelpers<CreateUserDto>
  ): Promise<void> => {
    if (values.role !== ROLE_TYPES.PROVIDER && values.role !== ROLE_TYPES.NON_PROVIDER) {
      values.clinics = [];
    }
    const res = await saveUser(values);
    const errorMessage = await res?.body.message;
    if (errorMessage.toLowerCase().includes('already exists')) {
      setErrors({ email: 'Email already exists.' });
    }
  };

  const handleUpdate = async (values: InitialValues): Promise<void> => {
    const org = organizations.find((org) => org.id === values.organizations);
    if (values.role !== ROLE_TYPES.PROVIDER && values.role !== ROLE_TYPES.NON_PROVIDER) {
      values.clinics = [];
    }

    const formattedOrg = {
      ...org,
    };

    delete formattedOrg.patients;

    const contacts: any[] = [];
    contacts.push({
      name: `${values.firstName} ${values.lastName}`,
      contact: stripCharacters(values.phone),
      type: Contact.type.PHONE,
    } as UserContact);

    const thresholdValues: CreateDeviceThresholdDto[] = [];

    const bloodPressureDiastolic = {
      threshHoldUpperLimit: values.bpDiastolic[1],
      threshHoldLowerLimit: values.bpDiastolic[0],
      metricType: DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const bloodPressureSystolic = {
      threshHoldUpperLimit: values.bpSystolic[1],
      threshHoldLowerLimit: values.bpSystolic[0],
      metricType: DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const glucose = {
      threshHoldUpperLimit: values.glucose[1],
      threshHoldLowerLimit: values.glucose[0],
      metricType: DeviceThreshold.metricType.BLOOD_SUGAR,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const weightChange = {
      threshHoldUpperLimit: values.weightChange24h,
      threshHoldLowerLimit: values.weightChange24h,
      unit: values.weightChange24h,
      metricType: DeviceThreshold.metricType.WEIGHT,
      sign: DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN,
    } as CreateDeviceThresholdDto;

    const pulse = {
      threshHoldUpperLimit: values.pulse[1],
      threshHoldLowerLimit: values.pulse[0],
      metricType: DeviceThreshold.metricType.PULSE,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const bloodOxygen = {
      threshHoldUpperLimit: values.bloodOxygen[1],
      threshHoldLowerLimit: values.bloodOxygen[0],
      metricType: DeviceThreshold.metricType.BLOOD_OXYGEN,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const weightChange7days = {
      threshHoldUpperLimit: values.weightChange7days,
      threshHoldLowerLimit: values.weightChange7days,
      unit: values.weightChange7days,
      metricType: DeviceThreshold.metricType.SEVEN_DAYS_CHANGE,
      sign: DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN,
    } as CreateDeviceThresholdDto;

    thresholdValues.push(
      bloodPressureDiastolic,
      bloodPressureSystolic,
      glucose,
      weightChange,
      pulse,
      bloodOxygen,
      weightChange7days
    );

    const payload = {
      id: selectedUser,
      ...values,
      status: userInitialValues?.status,
      organizations: [formattedOrg],
      contacts,
      threshold: thresholdValues,
    };

    updateUser.mutate(payload);
    refetchUserData();
    setShowEditModal(false);
  };

  const handleValidate = (values: FormikValues): Errors => {
    const errors: Errors = {};

    if (!values?.firstName.trim()) {
      errors.firstName = 'This field is required';
    } else {
      // trim the value to remove any leading or trailing spaces
      values.firstName = values.firstName.trim();
    }

    if (!values?.lastName.trim()) {
      errors.lastName = 'This field is required';
    } else {
      // trim the value to remove any leading or trailing spaces
      values.lastName = values.lastName.trim();
    }

    if (!values?.phone) {
      errors.phone = 'This field is required';
    }

    if (!values?.email) {
      errors.email = 'This field is required';
    }

    if (!values?.organizations) {
      errors.organizations = 'This field is required';
    }

    if (!values?.role) {
      errors.role = 'This field is required';
    }
    console.log(errors);
    return errors;
  };

  function UserAccountForm(props: {
    organization: Organization[];
    setFieldValue: (field: string, value: number[] | number) => void;
    isEditing?: boolean;
    disabled?: boolean;
    getFieldProps: (field: string) => { value: string };
  }) {
    const setToClinicDefaults = (value: string | boolean) => {
      const userOrg = organizations?.filter((org: Organization) => org.id === value);
      if (userOrg.length) {
        const thresholds = userOrg[0].threshold;
        thresholds.forEach((threshold: DeviceThreshold) => {
          if (threshold.metricType === DeviceThreshold.metricType.BLOOD_PRESSURE) {
            props.setFieldValue('bpDiastolic', [
              threshold.threshHoldLowerLimit,
              threshold.threshHoldUpperLimit,
            ]);

            props.setFieldValue('bpSystolic', [
              threshold.threshHoldLowerLimit,
              threshold.threshHoldUpperLimit,
            ]);
          }

          if (threshold.metricType === DeviceThreshold.metricType.BLOOD_SUGAR) {
            props.setFieldValue('glucose', [
              threshold.threshHoldLowerLimit,
              threshold.threshHoldUpperLimit,
            ]);
          }

          if (threshold.metricType === DeviceThreshold.metricType.PULSE) {
            props.setFieldValue('pulse', [
              threshold.threshHoldLowerLimit,
              threshold.threshHoldUpperLimit,
            ]);
          }

          if (threshold.metricType === DeviceThreshold.metricType.WEIGHT) {
            props.setFieldValue('weightChange24h', threshold.unit);
          }

          if (threshold.metricType === DeviceThreshold.metricType.SEVEN_DAYS_CHANGE) {
            props.setFieldValue('weightChange7days', threshold.unit);
          }

          if (threshold.metricType === DeviceThreshold.metricType.BLOOD_OXYGEN) {
            props.setFieldValue('bloodOxygen', [
              threshold.threshHoldLowerLimit,
              threshold.threshHoldUpperLimit,
            ]);
          }
        });
      }
    };

    return (
      <Grid>
        <Box mt="2" mr="lg">
          <FormField
            disabled={props.disabled}
            label={'First Name'}
            name="firstName"
            placeholder="Enter a first name"
          />
          <FormField
            disabled={props.disabled}
            label={'Last Name'}
            name="lastName"
            placeholder="Enter a last name"
          />
          <FormSelect
            disabled={props.disabled}
            label={'Credentials'}
            name="credentials"
            options={credentialOptions}
            multi
            clearable
            optionsDisabled={
              props.getFieldProps('credentials').value?.length > 1 ? () => true : () => false
            }
          />
          <MaskedInput
            disabled={props.disabled}
            label={'Phone'}
            name="phone"
            format={telMask}
            placeholder="(123) 456 7890"
          />
          <FormField
            disabled={props.disabled}
            label={'Email'}
            name="email"
            placeholder="Enter an email"
          />
          <FormSelect
            label={'Account'}
            onNextEvent={(value) => {
              setToClinicDefaults(value);
            }}
            disabled={props.disabled}
            name="organizations"
            options={orgFormatted}
          />
          <RoleBasedRoutes allowedRoles={editUserRolesRoles}>
            <FormSelect
              label={'Role'}
              name="role"
              options={rolesFormatted}
              disabled={props.disabled}
            />
          </RoleBasedRoutes>
          {props.getFieldProps('role').value === ROLE_TYPES.PROVIDER ||
          props.getFieldProps('role').value === ROLE_TYPES.NON_PROVIDER ? (
            <FormSelect
              label={'Clinics'}
              name="clinics"
              options={clinicsFormatted}
              disabled={props.disabled}
              multi={true}
              clearable
            />
          ) : null}
          <FormField
            label={'Default Escalation Note'}
            name="defaultEscalationNote"
            disabled={props.disabled}
            placeholder="Enter a default escalation note"
          />
          {props?.organization?.length ? (
            <Grid templateColumns="repeat(3, 1fr)" gap="2xl" mt="2xl" mb="2xl">
              <Box>
                <FormRangeSlider
                  label={'Blood Pressure (Systolic'}
                  ariaLabel={['Blood Pressure Systolic Minimun', 'Blood Pressure Systolic Maximum']}
                  name="bpSystolic"
                  disabled={props.disabled}
                  max={200}
                  min={80}
                  defaultValue={globalThresholdDefaults.bpSystolic}
                />
                <FormRangeSlider
                  label={'Blood Pressure (Diastolic)'}
                  ariaLabel={[
                    'Blood Pressure (Diastolic) Minimun',
                    'Blood Pressure (Diastolic) Maximum',
                  ]}
                  name="bpDiastolic"
                  disabled={props.disabled}
                  max={160}
                  min={40}
                  defaultValue={globalThresholdDefaults.bpDiastolic}
                />
                <FormRangeSlider
                  label={'Blood Oxygen ( % )'}
                  ariaLabel={['Blood Oxygen Minimun', 'Blood Oxygen Maximum']}
                  name="bloodOxygen"
                  disabled={props.disabled}
                  max={100}
                  min={75}
                  defaultValue={globalThresholdDefaults.bloodOxygen}
                />
              </Box>
              <Box>
                <FormRangeSlider
                  label={'Pulse'}
                  ariaLabel={['Pulse minimum', 'Pulse maximum']}
                  name="pulse"
                  disabled={props.disabled}
                  max={160}
                  min={0}
                  defaultValue={globalThresholdDefaults.pulse}
                />
                <FormRangeSlider
                  label={'Glucose'}
                  ariaLabel={['Glucose Minimun', 'Glucose Maximum']}
                  name="glucose"
                  disabled={props.disabled}
                  max={600}
                  min={0}
                  defaultValue={thresholdInformation.glucose}
                />
              </Box>
              <Box>
                <FormSlider
                  label={'Weight Change 24 Hours'}
                  ariaLabel="Weight Change 24 Hours"
                  name="weightChange24h"
                  disabled={props.disabled}
                  defaultValue={thresholdInformation.weightChange24h}
                  max={5}
                  min={1}
                  unit="lb"
                />
                <FormSlider
                  label={'Weight Change 7 Days'}
                  ariaLabel="Weight Change 7 Days"
                  name="weightChange7days"
                  disabled={props.disabled}
                  defaultValue={thresholdInformation.weightChange7days}
                  max={10}
                  min={3}
                  unit="lb"
                />
              </Box>
            </Grid>
          ) : null}
        </Box>
        {props.disabled ? null : (
          <Flex p="xl" borderRadius="8px 8px 0 0" justify="end">
            <Button
              form="create-user"
              type="submit"
              id="submit-form"
              disabled={props.disabled}
              // disabled={!(isValid)}
              isLoading={isLoading}
            >
              Save &amp; Continue
            </Button>
          </Flex>
        )}
      </Grid>
    );
  }

  interface UserFormProps {
    initialValues: InitialValues;
    handleSubmit: any;
    isEditing?: boolean;
    isView?: boolean;
  }

  const UserForm = ({ initialValues, handleSubmit, isEditing, isView }: UserFormProps) => {
    return (
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={(values: FormikValues) => handleValidate(values)}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, getFieldProps }) => (
          <Form id="create-user">
            <Box sx={{ position: 'relative' }}>
              <Box bg="white" p="3xl" borderRadius="8px">
                <Flex align="center" mb="2xl">
                  <Box bg="neutral.200" p={6} mr={5} borderRadius={4}>
                    <Icon color="primary.600" icon="file" />
                  </Box>
                  <Heading variant="h5">
                    {isView ? (isEditing ? 'Edit a User' : 'View a User') : 'Create a User'}
                  </Heading>
                </Flex>
                <UserAccountForm
                  organization={values.organizations as Organization[]}
                  setFieldValue={setFieldValue}
                  isEditing={isEditing}
                  disabled={!isEditing}
                  getFieldProps={getFieldProps}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  const handleReportFormClick = (reportType: any) => {
    setIsDateRangePickerOpen(true);
    setCurrentReportType(reportType);
    if (Object.keys(selectedData).length > 0) {
      const userIds: any = [];
      Object.entries(selectedData).forEach(([key]: any[]) => {
        userIds.push(users[key]?.id);
      });
      setSelectedUsers({ physicianIds: userIds, organizationIds: null, clinicalIds: null });
    }
  };

  if (!userData || !organizations) return null;

  const users = formatUserTable(userData, props);

  if (users) {
    if (props.setUserCount) {
      props.setUserCount(users?.length);
    }
  }

  return (
    <Fragment>
      <HStack justifyContent="right">
        <RoleBasedRoutes allowedRoles={createUserRoles}>
          <Button variant="secondaryDark" leftIcon="add" onClick={(): void => setOpen(true)}>
            Add New{props.type === 'providers' ? ' Provider' : ' User'}
          </Button>
        </RoleBasedRoutes>
      </HStack>

      <HStack
        justifyContent="space-between"
        alignItems="center"
        padding="xl"
        marginTop="xl"
        marginBottom="l"
        background={'#FFFFFF'}
      >
        <Flex alignItems="center">
          <HStack justifyContent="space-between" alignItems="center">
            <Box>
              <Menu
                onClose={() => {
                  setIsDateRangePickerOpen(false);
                }}
              >
                <MenuButton as={Button} rightIcon={'drop-down'}>
                  Clinical Report
                </MenuButton>
                {!isDateRangePickerOpen ? (
                  <MenuList>
                    <MenuItem
                      closeOnSelect={false}
                      onClick={() => handleReportFormClick('hypertension')}
                    >
                      Hypertensives
                    </MenuItem>
                    <MenuItem
                      closeOnSelect={false}
                      onClick={() => handleReportFormClick('glucose')}
                    >
                      Diabetes
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList>
                    <Flex
                      minWidth="max-content"
                      basis={'flex'}
                      direction={'row'}
                      align={'center'}
                      gap="2"
                    >
                      <VStack>
                        <Box>
                          {currentReportType === 'hypertension' ? (
                            <HypertensionReportForm
                              selectedData={selectedUsers}
                              handleCancelClick={() => setIsDateRangePickerOpen(false)}
                            />
                          ) : (
                            <GlucoseReportForm
                              selectedData={selectedUsers}
                              handleCancelClick={() => setIsDateRangePickerOpen(false)}
                            />
                          )}
                        </Box>
                      </VStack>
                    </Flex>
                  </MenuList>
                )}
              </Menu>
            </Box>
          </HStack>
        </Flex>
        <Flex>
          <Search placeholder="Search Users" onSearchTerm={setSearchTerm} />
        </Flex>
      </HStack>
      {users.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <span style={{ fontSize: '16px', fontWeight: '600' }}>No Accounts found</span>
        </div>
      ) : (
        <DataTable
          hasPagination
          isSelectable
          columns={columns}
          data={users}
          search={searchTerm}
          onRowsSelected={handleRowSelection}
          fetchData={(data: any) => getData(data)}
          // Pagination params
          totalPageCount={pageParams.totalPageCount}
          totalRowCount={pageParams.totalSize}
          initialPageSize={DEFAULT_PAGE_SIZE}
        />
      )}
      <UserManagementModal
        nextStep={() => ''}
        setClose={() => {
          setOpen(false);
        }}
        buttonText={'Save'}
        open={open}
      >
        <UserForm
          initialValues={initialValues}
          isView={false}
          isEditing={true}
          handleSubmit={handleSubmit}
        />
      </UserManagementModal>
      <UserManagementModal open={showEditModal} setClose={() => setShowEditModal(false)}>
        <UserForm
          initialValues={editInitialValues}
          isEditing={true}
          isView={true}
          handleSubmit={handleUpdate}
        />
      </UserManagementModal>

      <UserManagementModal open={showViewModal} setClose={() => setShowViewModal(false)}>
        <UserForm
          initialValues={editInitialValues}
          isEditing={false}
          isView={true}
          handleSubmit={handleUpdate}
        />
      </UserManagementModal>
    </Fragment>
  );
}
