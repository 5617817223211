import { DateRange } from '~src/pages/Patients/PatientOverview';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getUTCDateRange = (
  dateRange: DateRange,
  timeZone: string,
  postalCode: string
): { utcStartDate: string; utcEndDate: string } => {
  const isNewYorkZip = (zipFirst2) => {
    return zipFirst2 <= 34 || (zipFirst2 >= 39 && zipFirst2 <= 49);
  };

  const isChicagoZip = (zipFirst2) => {
    return (
      (zipFirst2 >= 35 && zipFirst2 <= 38) ||
      (zipFirst2 >= 50 && zipFirst2 <= 58) ||
      (zipFirst2 >= 60 && zipFirst2 <= 79)
    );
  };

  const isDenverZip = (zipFirst2, zipFirst3) => {
    return (
      zipFirst2 === 59 ||
      (zipFirst2 >= 80 && zipFirst2 <= 87) ||
      (zipFirst3 >= 880 && zipFirst3 <= 888)
    );
  };

  const isLAZip = (zipFirst2, zipFirst3) => {
    return (
      (zipFirst3 >= 889 && zipFirst3 <= 961) ||
      (zipFirst2 >= 97 && zipFirst2 <= 98) ||
      zipFirst3 === 994
    );
  };

  const getUndetectedTimezone = (postalCode: string) => {
    if (!postalCode) return 'America/New_York';
    let tz = '';
    const zipFirst2 = Number(postalCode.slice(0, 2));
    const zipFirst3 = Number(postalCode.slice(0, 3));

    if (isNewYorkZip(zipFirst2)) {
      tz = 'America/New_York';
    } else if (isChicagoZip(zipFirst2)) {
      tz = 'America/Chicago';
    } else if (isDenverZip(zipFirst2, zipFirst3)) {
      tz = 'America/Denver';
    } else if (isLAZip(zipFirst2, zipFirst3)) {
      tz = 'America/Los_Angeles';
    }
    return tz;
  };

  const patientTimeZone = timeZone || getUndetectedTimezone(postalCode);

  const isoStartDate = dateRange.startDate.toISOString();
  const isoEndDate = dateRange.endDate.toISOString();
  const sourceStartDate = isoStartDate.slice(0, isoStartDate.indexOf('T')) + 'T00:00:00';
  const sourceEndDate = isoEndDate.slice(0, isoEndDate.indexOf('T')) + 'T23:59:59';

  // Convert the source datetime to the target timezone
  const utcStartDate = dayjs.tz(sourceStartDate, patientTimeZone).utc().toISOString();
  const utcEndDate = dayjs.tz(sourceEndDate, patientTimeZone).utc().toISOString();

  return { utcStartDate, utcEndDate };
};
