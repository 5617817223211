import React, { ReactElement } from 'react';
import { Flex, IconBadge, Text, Grid } from '@rhythm/components';
import { IconName, IconNameFilled } from '@rhythm/components/dist/Icon/IconsMap';

type CardBodyProps = {
  icon: IconName | IconNameFilled;
  text: string;
  data: string;
};

const CardBody: React.FC<CardBodyProps> = ({ icon, text, data }): ReactElement => {
  return (
    <Flex>
      <IconBadge icon={icon} />
      <Grid alignItems="center" pl="10px">
        <Text variant="smallCaps" color="neutral.800">
          {text}
        </Text>
        <Text>{data}</Text>
      </Grid>
    </Flex>
  );
};

export default CardBody;
