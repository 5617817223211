export interface TeamFields {
  teamName: string;
  teamLead: string;
}

export const teamFields: TeamFields = {
  teamName: 'Team Name',
  teamLead: 'Team Lead',
};

export interface MembersDataForm {
  userId: string;
}
