import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spacer,
  VStack,
} from '@rhythm/components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { makeOptionsArray } from '~src/utils';
import type { Option } from '~src/utils';

import './style.css';
import dayjs from 'dayjs';
import { useState } from 'react';
import { CustomDateRangePicker } from './CustomDataRangePicker';

export interface DateFilters {
  name: string;
}

export const fixedFilters = {
  w1: '1 Week',
  w2: '2 Weeks',
  d30: 'Last 30 Days',
  d60: 'Last 60 Days',
  d90: 'Last 90 Days',
};

export const fixedFiltersArray: Option[] = makeOptionsArray(fixedFilters);

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

type DateRangeProps = {
  dateRange: DateRange;
  initialValues: DateRange;
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
    }>
  >;
};

export function DateFilters(props: DateRangeProps) {
  const [fixedDateFilter, setFixedDataFilter] = useState('d30');
  const [canClickNextButton, setCanClickNextButton] = useState(false);

  const today = dayjs().toDate();

  const handleChange = (event: any) => {
    // console.log('event.target.value', event.value);
    const selectTimeframe = event.value;
    setFixedDataFilter(selectTimeframe);
    const endDate = today;
    applySelectTimeframe(selectTimeframe, endDate);
  };

  const applySelectTimeframe = (selectTimeframe: string, endDate: Date) => {
    switch (selectTimeframe) {
      case 'w1': {
        const startDate = dayjs(endDate).subtract(7, 'day').toDate();
        props.setDateRange({
          startDate,
          endDate,
        });
        break;
      }
      case 'w2': {
        const startDate = dayjs(endDate).subtract(14, 'day').toDate();
        props.setDateRange({
          startDate,
          endDate,
        });
        break;
      }
      case 'd30': {
        const startDate = dayjs(endDate).subtract(30, 'day').toDate();
        props.setDateRange({
          startDate,
          endDate,
        });
        break;
      }
      case 'd60': {
        const startDate = dayjs(endDate).subtract(60, 'day').toDate();
        props.setDateRange({
          startDate,
          endDate,
        });
        break;
      }
      case 'd90': {
        const startDate = dayjs(endDate).subtract(90, 'day').toDate();
        props.setDateRange({
          startDate,
          endDate,
        });
        break;
      }
    }
    evaluateClickNextButton();
  };

  const prevXDuration = () => {
    let endDate = props.dateRange.endDate;
    switch (fixedDateFilter) {
      case 'w1': {
        endDate = dayjs(endDate).subtract(7, 'day').toDate();
        break;
      }
      case 'w2': {
        endDate = dayjs(endDate).subtract(14, 'day').toDate();
        break;
      }
      case 'd30': {
        endDate = dayjs(endDate).subtract(30, 'day').toDate();
        break;
      }
      case 'd60': {
        endDate = dayjs(endDate).subtract(60, 'day').toDate();
        break;
      }
      case 'd90': {
        endDate = dayjs(endDate).subtract(90, 'day').toDate();
        break;
      }
    }
    applySelectTimeframe(fixedDateFilter, endDate);
  };

  const nextXDuration = () => {
    if (!canClickNextButton) return;

    let endDate = props.dateRange.endDate;
    switch (fixedDateFilter) {
      case 'w1': {
        endDate = dayjs(endDate).add(7, 'day').toDate();
        break;
      }
      case 'w2': {
        endDate = dayjs(endDate).add(14, 'day').toDate();
        break;
      }
      case 'd30': {
        endDate = dayjs(endDate).add(30, 'day').toDate();
        break;
      }
      case 'd60': {
        endDate = dayjs(endDate).add(60, 'day').toDate();
        break;
      }
      case 'd90': {
        endDate = dayjs(endDate).add(90, 'day').toDate();
        break;
      }
    }
    applySelectTimeframe(fixedDateFilter, endDate);
  };

  const showLast30Days = () => {
    setFixedDataFilter('d30');
    const endDate = today;
    applySelectTimeframe('d30', endDate);
  };

  const evaluateClickNextButton = () => {
    console.log(
      dayjs(today).format('MM/DD/YYYY'),
      dayjs(props.dateRange.endDate).format('MM/DD/YYYY')
    );
    if (dayjs(today).format('MM/DD/YYYY') === dayjs(props.dateRange.endDate).format('MM/DD/YYYY'))
      return setCanClickNextButton(false);
    return setCanClickNextButton(true);
  };

  const applyCustomDateFilter = (dates: any) => {
    props.setDateRange({
      startDate: dates?.startDate.toDate(),
      endDate: dates?.endDate.toDate(),
    });
  };

  return (
    <>
      <Flex className="dateFiltersContainer">
        <Box>
          <Button className="defaultDateButton" variant="plain" onClick={showLast30Days}>
            Last 30 Days
          </Button>
        </Box>
        <Flex className="dateRangeContainer">
          <Box>
            <Icon
              icon="chevron-left"
              boxSize="18px"
              onClick={prevXDuration}
              // color={`neutral.${!canPreviousPage ? '600' : 'black'}`}
              // cursor={!canPreviousPage ? 'not-allowed' : 'pointer'}
            />
          </Box>
          <Box>
            <Icon
              className="chevronRightIcon"
              icon="chevron-right"
              boxSize="18px"
              onClick={nextXDuration}
              // color={`neutral.${!canClickNextButton ? '600' : 'black'}`}
              cursor={!canClickNextButton ? 'not-allowed' : 'pointer'}
            />
          </Box>
          <Box className="dateRangeSelection">
            <Menu>
              <MenuButton className="dateRangeMenuButton" as={Button} rightIcon={'drop-down'}>
                {dayjs(props?.dateRange?.startDate).format('MMM DD')} -{' '}
                {dayjs(props?.dateRange?.endDate).format('MMM DD, YYYY')}
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Flex
                    minWidth="max-content"
                    basis={'flex'}
                    direction={'row'}
                    align={'center'}
                    gap="2"
                  >
                    <VStack>
                      <Box>
                        <CustomDateRangePicker
                          applyCustomDateFilter={applyCustomDateFilter}
                        ></CustomDateRangePicker>
                      </Box>
                    </VStack>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
        <Spacer className="spaceForDateFilters" />
        <Box className="fixedFilterBox">
          <Select options={fixedFiltersArray} name="fixedDateFilter" onChange={handleChange} />
        </Box>
      </Flex>
    </>
  );
}
