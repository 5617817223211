export { states, stateOptionsArray } from './states';
export { genders, gendersOptionsArray } from './genders';
export { relations, relationsOptionsArray } from './relations';
export { devices, devicesOptionsArray } from './devices';
export { diseaseStates, diseaseStatesOptionsArray } from './diseaseStates';
export {
  icd10CodesHypertension,
  icd10CodesHypertensionOptionsArray,
} from './icd10CodesHypertension';
export {
  icd10CodesHeartFailure,
  icd10CodesHeartFailureOptionsArray,
} from './icd10CodesHeartFailure';
export { icd10CodesDiabetes, icd10CodesDiabetesOptionsArray } from './icd10CodesDiabetes';

export const UNAUTHENTICATED = 'Unauthenticated';
export const UNAUTHROIZED = 'Unauthorized';

/** Roles come as any, instead of enums so are forced to do this */
export const CLINICIAN_ROLE = 'roles:internal:clinical';
export const NON_CLINICIAN_ROLE = 'roles:internal:non-clinical';
export const LEAD_CLINICIAN_ROLE = 'roles:internal:lead-clinician';
export const NON_CLINICAL_ROLE = 'roles:internal:non-clinical';

export { timezones } from './timezones';
