import { Card, Flex } from '@rhythm/components';
import { isContacted, minutesAcrruedMonth } from '~src/utils';
import { PatientProp } from '../PatientProfile';
import CardBody from './CardBody';

const Cpt = ({ patient }: PatientProp) => {
  const patientData: any = patient;
  const minutes = patientData.monthlyTimeTrasmitted || minutesAcrruedMonth(patient);
  const days = patientData.monthlyDaysTrasmitted;
  return (
    <Flex mb="20px" justify="space-between">
      <Card width="300px">
        <CardBody icon="monitored-values" data={`${days || 0}`} text="Days of Data" />
      </Card>

      <Card width="300px">
        <CardBody icon="phone-call" data={isContacted(patient) ? 'Yes' : 'No'} text="Contacted" />
      </Card>

      <Card width="300px">
        <CardBody icon="information" data={`${minutes || 0}`} text="Minutes" />
      </Card>
    </Flex>
  );
};

export default Cpt;
