import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { HStack, Button } from '@rhythm/components';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { createAccountRoles } from '~src/constants/roles';

export function TeamManagement() {
  const history = useHistory();

  return (
    <Fragment>
      <HStack justifyContent="right">
        <RoleBasedRoutes allowedRoles={createAccountRoles}>
          <Button
            variant="secondaryDark"
            leftIcon="add"
            onClick={() => history.push('/settings/team/create')}
          >
            Add New Team
          </Button>
        </RoleBasedRoutes>
      </HStack>
    </Fragment>
  );
}
