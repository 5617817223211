import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  Button,
  Card,
  HStack,
  Heading,
  Icon,
  Flex,
  Box,
  Spinner,
} from '@rhythm/components';
import { useState } from 'react';
import GroupModal from '~src/components/Group/GroupModal';
import { useGroupList } from '~src/services/group';
import { Group } from '~generated';
import { useHistory } from 'react-router-dom';
import { useUserRoleAndEmail } from '~src/hooks';

export function GroupComponent() {
  const history = useHistory();
  const [showGroupModal, setShowGroupModal] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showGroupData, setShowGroupData] = useState<Group | undefined>(undefined);
  const { userRole } = useUserRoleAndEmail();
  const isInternal = [
    'roles:internal:super-admin',
    'roles:internal:lead-clinician',
    'roles:internal:admin',
  ].some((role) => userRole.includes(role));
  if (!isInternal) {
    history.push('/patients');
  }
  const { data, isLoading } = useGroupList(true);

  if (isLoading) {
    return <Spinner />;
  }
  const modalOpen = (list: Group) => {
    setShowGroupData(list);
    setIsEditMode(true);
    setShowGroupModal(true);
  };

  return (
    <>
      <Button
        style={{
          width: '100px',
          height: '8px',
          color: '#6C7789',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          marginLeft: '-20px',
        }}
        onClick={() => history.goBack()}
        leftIcon={'arrow-left'}
        mb="md"
      >
        Back
      </Button>
      <Heading variant="h3" mb="2xl">
        Permissions
      </Heading>
      <Accordion
        defaultIndex={[0]}
        allowToggle
        overflowY="scroll"
        bg={'#eee8e8'}
        w={'65%'}
        sx={{
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          heightStyle: 'content',
        }}
      >
        <Card>
          <AccordionItem borderTop="none" borderBottom="none">
            <Box as="div" flexDirection="row" alignItems="flex-end">
              <HStack spacing="xl" mb="lg" width={'100%'}>
                <div
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    display: 'flex',
                    margin: '1px auto 0px -17px',
                  }}
                >
                  <Icon icon="patients" color="primary.600" />
                  <Heading variant="h5" marginLeft="10px">
                    Patient Groups
                  </Heading>
                </div>
                <Button
                  variant="secondaryLight"
                  mr={'10px'}
                  onClick={() => {
                    setShowGroupModal(true);
                  }}
                >
                  Add Group
                </Button>
                <AccordionButton width={'5px'} marginRight={'10px'}>
                  <AccordionIcon ml={'-10px'} />
                </AccordionButton>
              </HStack>
            </Box>
            <AccordionPanel
              p="none"
              h={'100%'}
              overflow={'auto'}
              sx={{
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {!data?.length && (
                <Text mt={'25px'} textAlign={'center'} fontWeight="bold">
                  No Group Found
                </Text>
              )}
              {data?.map((list) => (
                <Card
                  key={list?.id}
                  marginBottom={'12px'}
                  mr={'10px'}
                  ml={'10px'}
                  marginTop={'5px'}
                  boxShadow="0 0px 8px rgb(0 0 0 / 0.04)"
                  _hover={{
                    boxShadow: '0 3px 8px rgb(0 0 0 / 0.12)',
                    color: 'black',
                    '& .css-yoy6c6': {
                      display: 'block',
                    },
                  }}
                  onClick={() => modalOpen(list)}
                >
                  <Flex justify="space-between" m={'-12px'} align="center">
                    <Box w={'70%'}>
                      <Text fontWeight="bold" mb={1}>
                        {list.name}
                      </Text>
                      <Text>{list.description}</Text>
                    </Box>
                    <Icon icon="edit" boxSize="18px" display="none" />
                  </Flex>
                </Card>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Card>
      </Accordion>
      {showGroupModal && (
        <GroupModal
          isModalOpen={showGroupModal}
          setShow={setShowGroupModal}
          data={showGroupData}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          setShowGroupData={setShowGroupData}
        />
      )}
    </>
  );
}
