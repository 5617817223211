import { Modal } from '@rhythm/components';

interface ModalProps {
  open: boolean;

  children: JSX.Element;

  nextStep?: () => void;

  step?: number;

  buttonText?: string;

  disabled?: boolean;

  setClose: () => void;
}

export function UserManagementModal({ open, setClose, children }: ModalProps) {
  return (
    <Modal isOpen={open} onClose={setClose}>
      {children}
    </Modal>
  );
}
