import { makeOptionsArray } from '~src/utils';
import type { Option } from '~src/utils';

export const relations = {
  spouse_partner: 'Spouse/Partner',
  child: 'Child',
  legal_guardian: 'Legal Guardian',
  sibling: 'Sibling',
  parent: 'Parent',
  friend: 'Friend',
  other: 'Other',
};

export const relationsOptionsArray: Option[] = makeOptionsArray(relations);
