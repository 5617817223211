import { useFetchPatientsCount } from '~src/services/clinics';

type UserPatientCountType = {
  organizationId?: string;
};

function FetchPatientsCount(id?: string) {
  // if(!id) return {} as any;
  const { data } = useFetchPatientsCount({
    id: id ? id : '',
  });
  return data;
}

export function UserPatientCount(props: UserPatientCountType) {
  const data = FetchPatientsCount(props.organizationId);
  return <div>{data?.[0]?.patientCount || 0}</div>;
}
