import { Flex } from '@chakra-ui/layout';
import { useOktaAuth } from '@okta/okta-react';
import { Formik, FormikHelpers, Form } from 'formik';
import { useEffect, useState } from 'react';
import { User } from '~generated';
import { FormMenuSelectFastReview } from '~src/components/FormSelect';
import { FormMenuSelectRangeFastReview } from '~src/components/FormSelect/FormMenuSelect';
import { CLINICIAN_ROLE, LEAD_CLINICIAN_ROLE } from '~src/constants';
import {
  useClinicUsers,
  useGetOnboardedPatients,
  useOrganizationBySortingOrder,
} from '~src/services/clinics';
import { PatientLocalStorage, retrievePersistedData, savePersistentData } from '~src/utils';
import RoleBasedRoutes from '~src/components/RoleBasedRoutes';
import { Box } from '@rhythm/components';
import {
  Props,
  FormFilterValues,
  Range,
  handleStorageUpdate,
  setPhysicianOptionsValue,
  accountFilterChecking,
  setOnboardingOptionsvalue,
  setPhysicianOptionsValueOnAccountFilter,
  setOnboardingOptionsvalueOnAccountFilter,
  subOptions,
  generateAccountOptions,
} from './index';
import { useGroupList } from '~src/services/group';

const PatientFiltersFastReview: React.FC<Props> = ({
  setUpdatedFilterSearch,
  currentTab = 0,
  fetchPhysiciansData,
}: Props) => {
  const { isLoading, data: paginatedData } = useClinicUsers({ bypassPagination: true });
  const usersData = paginatedData?.data;
  const [physicianOption, setPhysicianOptions] = useState([]);
  const [accountFilter, setaccountFilter] = useState<string[]>();
  const [onboardingOption, setOnboardingOptions] = useState([]);
  const organizations = useOrganizationBySortingOrder({}).data;
  const oktaAuth = useOktaAuth();
  // eslint-disable-next-line max-len
  const isProvider = ((oktaAuth.authState?.accessToken?.claims?.roles as string[]) ||
    [])[0].startsWith('roles:external');
  const { data: userOnboardedPatientsData } = useGetOnboardedPatients(!isProvider);
  const { data: groupsData } = useGroupList(!isProvider);
  let initialValues: FormFilterValues = {
    accounts: [],
    physicians: [],
    clinicians: [],
    days: [],
    minutes: [],
    contacted: [],
    onboarding: [],
    group: [],
  };

  const initialFilter = retrievePersistedData(`rpm-filter-${currentTab}`) as PatientLocalStorage;

  useEffect(() => {
    handleStorageUpdate(
      initialFilter,
      setaccountFilter,
      setUpdatedFilterSearch,
      initialValues,
      currentTab
    );
  }, []);

  useEffect(() => {
    if (usersData) {
      setPhysicianOptionsValue(usersData, isProvider, setPhysicianOptions);
      setOnboardingOptionsvalue(setOnboardingOptions, userOnboardedPatientsData);
    }
  }, [usersData, userOnboardedPatientsData, setPhysicianOptions, setOnboardingOptions]);

  useEffect(() => {
    if (accountFilter && accountFilter?.length > 0) {
      (async () => {
        const physicianData = await fetchPhysiciansData(accountFilter?.toString());
        accountFilterChecking(physicianData, setPhysicianOptions, isProvider);
      })();
      // }
    } else {
      setPhysicianOptionsValueOnAccountFilter(usersData, isProvider, setPhysicianOptions);
      setOnboardingOptionsvalueOnAccountFilter(setOnboardingOptions, usersData);
    }
  }, [accountFilter, accountFilter?.length]);

  if (isLoading) return null;

  if (!usersData) return null;

  const fastReviewClinicianOption = usersData
    ?.filter((user: User) => user.role === CLINICIAN_ROLE || user.role === LEAD_CLINICIAN_ROLE)
    ?.map((user: User) => {
      return {
        label: ` ${user.lastName}, ${user.firstName}`,
        value: user.id,
      };
    });

  const groups =
    groupsData?.map((group) => ({
      label: group.name,
      value: group.id,
    })) ?? [];

  const fastReviewSubOptions = subOptions;

  let accountOptions: any;
  if (organizations) {
    accountOptions = generateAccountOptions(organizations);
  }
  const fastReviewStorage = initialFilter?.filters;

  /**
   * This is to ensure old data structure does not fail
   * We used an array previously
   */
  if (
    fastReviewStorage &&
    Object.keys(fastReviewStorage).length > 0 &&
    fastReviewStorage.clinicians
  ) {
    initialValues = {
      ...fastReviewStorage,
    };
  }
  const handleSubmit = async (
    values: FormFilterValues,
    _formikHelpers?: FormikHelpers<{
      accounts: string[];
      physicians: string[];
      clinicians: string[];
      minutes: Range[];
      days: Range[];
      contacted: string[];
    }>
  ): Promise<void> => {
    const persistedFilter = retrievePersistedData(
      `rpm-filter-${currentTab}`
    ) as PatientLocalStorage;

    const persistedPaginationData = {
      ...persistedFilter,
      filters: values,
    };
    savePersistentData(`rpm-filter-${currentTab}`, JSON.stringify({ ...persistedPaginationData }));

    setUpdatedFilterSearch({ ...values });
    setaccountFilter(values?.accounts);
  };

  const defaultValidate = (): FormFilterValues => {
    const errors = {} as FormFilterValues;
    return errors;
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={defaultValidate}>
      {({}) => (
        <Form id="create-filter">
          <Flex justify="d-flex" alignItems="center" wrap="wrap">
            {initialValues['status']?.length ? (
              <FormMenuSelectFastReview
                menuTitle="All"
                title="Status"
                name="status"
                options={[
                  { label: 'New', value: 'new' },
                  { label: 'Active', value: 'active' },
                  { label: 'Non-Adherent', value: 'non-adherent' },
                  { label: 'Paused', value: 'paused' },
                  { label: 'Unenrolled', value: 'unenrolled' },
                ]}
              />
            ) : null}
            {initialValues['accounts']?.length ? (
              <RoleBasedRoutes internalUsersOnly>
                <FormMenuSelectFastReview
                  menuTitle="All"
                  title="Accounts"
                  name="accounts"
                  options={accountOptions!}
                />
              </RoleBasedRoutes>
            ) : null}
            {initialValues['physicians']?.length ? (
              <FormMenuSelectFastReview
                menuTitle="All"
                title="Physicians"
                name="physicians"
                options={physicianOption}
              />
            ) : null}
            {initialValues['contacted']?.length ? (
              <RoleBasedRoutes internalUsersOnly>
                <FormMenuSelectFastReview
                  menuTitle="All"
                  title="Contacted"
                  name="contacted"
                  options={[
                    { label: 'All', value: '' },
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '0' },
                  ]}
                />
              </RoleBasedRoutes>
            ) : null}
            {initialValues['days']?.length ? (
              <RoleBasedRoutes internalUsersOnly>
                <FormMenuSelectRangeFastReview
                  menuTitle="All"
                  title="Days"
                  subOptions={fastReviewSubOptions}
                  name="days"
                  options={physicianOption}
                />
              </RoleBasedRoutes>
            ) : null}
            {initialValues['minutes']?.length ? (
              <RoleBasedRoutes internalUsersOnly>
                <FormMenuSelectRangeFastReview
                  menuTitle="All"
                  title="Minutes"
                  subOptions={fastReviewSubOptions}
                  name="minutes"
                  options={physicianOption}
                />
              </RoleBasedRoutes>
            ) : null}
            {initialValues['clinicians']?.length ? (
              <RoleBasedRoutes internalUsersOnly>
                <FormMenuSelectFastReview
                  menuTitle="All"
                  title="Clinicians"
                  name="clinicians"
                  options={fastReviewClinicianOption}
                />
              </RoleBasedRoutes>
            ) : null}
            {initialValues['onboarding']?.length ? (
              <RoleBasedRoutes internalUsersOnly>
                <FormMenuSelectFastReview
                  menuTitle="All"
                  title="Onboarding"
                  name="onboarding"
                  options={onboardingOption}
                />
              </RoleBasedRoutes>
            ) : null}
            {initialValues['group']?.length ? (
              <RoleBasedRoutes internalUsersOnly>
                <FormMenuSelectFastReview
                  menuTitle="All"
                  title="Groups"
                  name="group"
                  options={groups}
                />
              </RoleBasedRoutes>
            ) : null}
            <Box
              style={{
                textAlign: 'left',
                height: 34,
                width: 250,
              }}
            ></Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default PatientFiltersFastReview;
