import { Options } from 'react-select';

export enum AltContactFields {
  ALT_FIRST_NAME = 'First Name',
  ALT_LAST_NAME = 'Last Name',
  ALT_PHONE_NUMBER = 'PhoneNumber',
  ALT_EMAIL = 'Email',
  ALT_RELATION = 'Relation',
  IS_PREFFERED = 'Is Preferred Contact',
  DISCLOSE_PHI = 'Patient has consented to discussing PHI with individual',
}

export interface AlternateContactForm {
  altFirstName: string;
  altLastName: string;
  altPhoneNumber: string;
  altEmail: string;
  altRelation: Options<any>[] | string;
  isPreferredContact: boolean;
}

export const alternateContactForm: AlternateContactForm = {
  altFirstName: '',
  altLastName: '',
  altPhoneNumber: '',
  altEmail: '',
  altRelation: '',
  isPreferredContact: false,
};

export enum InsuranceDetailsFieldsEnrollPatientForm {
  PRIMARY_NAME = 'Name of Insurer',
  PRIMARY_ID = 'Member ID',
  PRIMARY_GROUP_NUMBER = 'Group Number',
  PRIMARY_CONTACT_INFORMATION = 'Contact Information',
  SECONDARY_NAME = 'Name of Insurer',
  SECONDARY_ID = 'Member ID',
  SECONDARY_GROUP_NUMBER = 'Group Number',
  SECONDARY_CONTACT_INFORMATION = 'Contact Information',
  COPAY = 'Copay Amount',
}

export interface InsuranceDetailsFormEnrollPatientForm {
  primaryName: string;
  primaryMemberId: string;
  primaryGroupNumber: string;
  primaryContanctInformation: string;
  secondaryName: string;
  secondaryMemberId: string;
  secondaryGroupNumber: string;
  secondaryContanctInformation: string;
  copayAmount?: string;
}

export const insuranceDetailsFormEnrollPatientForm: InsuranceDetailsFormEnrollPatientForm = {
  primaryName: '',
  primaryMemberId: '',
  primaryGroupNumber: '',
  primaryContanctInformation: '',
  secondaryName: '',
  secondaryMemberId: '',
  secondaryGroupNumber: '',
  secondaryContanctInformation: '',
  copayAmount: '',
};

export enum DeviceDetailsFieldsEnrollPatientForm {
  VENDOR_NAME = 'Vendor Name',
  VENDOR_SITE = 'Vendor Site (URL)',
  DEVICE_TYPE = 'Device Type',
  DEVICE_SERIAL = 'Device Serial Number',
  IS_ACTIVE = 'Active',
}

export interface DeviceDetailsForm {
  isActive: boolean;
  id?: string;
  vendorName: Options<any>[] | string;
  vendorSite: string;
  deviceType: Options<any>[] | string;
  deviceSerial: string;
  vendorId: string;
}

export interface ICDCodesDataForm {
  id?: string;
  diagnosis: Options<any>[] | string;
  icd10Code: Options<any>[] | string;
}

export const deviceDetailsForm: DeviceDetailsForm = {
  vendorName: [],
  vendorSite: '',
  deviceType: [],
  deviceSerial: '',
  vendorId: '',
  isActive: true,
};

export const icdCodesDataForm: ICDCodesDataForm = {
  diagnosis: [],
  icd10Code: [],
};

export enum PhysiologicThresholdsFields {
  BP_SYSTOLIC = 'Blood Pressure (Systolic)',
  BP_DIASTOLIC = 'Blood Pressure (Diastolic)',
  PULSE = 'Pulse',
  GLUCOSE = 'Glucose',
  WEIGHT_24H = 'Weight Change 24 Hours',
  WEIGHT_7DAY = 'Weight Change 7 Days',
  BLOOD_OXYGEN = 'Blood Oxygen ( % )',
}

export interface PhysiologicThresholdsForm {
  bpSystolic: number[];
  bpDiastolic: number[];
  pulse: number[];
  glucose: number[];
  bloodOxygen: number[];
  weightChange24h: number;
  weightChange7Day: number;
}

export const physiologicThresholdsForm: PhysiologicThresholdsForm = {
  bpSystolic: [90, 180],
  bpDiastolic: [50, 110],
  pulse: [50, 120],
  glucose: [60, 300],
  bloodOxygen: [88, 92],
  weightChange24h: 3,
  weightChange7Day: 5,
};

export enum FileUploadFields {
  patientHistory = 'Patient History',
  faceSheet = 'Face Sheet',
}

export const fileUploadForm = {
  patientHistory: null,
  faceSheet: null,
};
