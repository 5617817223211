import { Button, Card, Box, HStack } from '@rhythm/components';
import { Formik, Form, FormikProps } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';

interface CustomDateRangeValues {
  dateRange: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
}

const initialValues: CustomDateRangeValues = {
  dateRange: {
    startDate: undefined,
    endDate: undefined,
  },
};

interface CustomDateRangeInput {
  startDate: any;
  endDate: any;
}

export function CustomDateRangePicker(props: { applyCustomDateFilter: (dates: any) => void }) {
  const [dates, setDates] = useState<CustomDateRangeInput>({ startDate: null, endDate: null });

  const defaultFocusedInput = 'startDate';
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(defaultFocusedInput);
  const handleDatesChange = (dates: any) => {
    // console.log(dates?.startDate);
    setDates(dates);
  };

  const onFocusChange = (focusedInput: any) => {
    // console.log(onFocusChange);
    setFocusedInput(focusedInput);
  };

  const handleSubmit = async () => {
    // console.log(dates?.startDate);
    props.applyCustomDateFilter(dates);
    // props.applyCustomDateFilter(dates?.startDate?.toDate(), dates?.endDate?.toDate());
  };

  //   const maxDate: any = props.maxDate && moment(props.maxDate);
  const isOutsideRange = () => {
    const dayMoment = moment();
    return dayMoment.isAfter();
  };

  const handleCancelClick = () => {
    console.log('handleCancelClick');
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }: FormikProps<CustomDateRangeValues>) => (
        <Form>
          <Card py="xl" px="xl">
            <Box mb="4">
              <DayPickerRangeController
                startDate={dates.startDate}
                endDate={dates.endDate}
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput || defaultFocusedInput}
                onFocusChange={onFocusChange}
                numberOfMonths={2}
                hideKeyboardShortcutsPanel={true}
                daySize={30}
                isOutsideRange={isOutsideRange}
                initialVisibleMonth={null}
                monthFormat="MMM YYYY"
                // maxDate={maxDate}
              />
            </Box>
            <HStack justifyContent={'space-between'}>
              <Box>
                <Button variant="secondaryLight" mr="xl" onClick={() => handleCancelClick()}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary" isLoading={isSubmitting}>
                  Apply
                </Button>
              </Box>
            </HStack>
          </Card>
        </Form>
      )}
    </Formik>
  );
}
