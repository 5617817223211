import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Flex, Icon, Progress, Text } from '@rhythm/components';
import type { Cell, SortingRule } from 'react-table';
import { useDebounce } from 'react-use';
import { DaysTransmittedCell, PatientStatusCell } from '~src/components/PatientsDataView';
import { Patient } from '~generated/models/Patient';
import { PatientReading, PatientTime } from '~generated';
import { DaysTransmittedCellPropsFastReview } from './DaysTransmittedCell';
import { PatientStatusCellProps } from './PatientStatusCell';
import { OnFetchProps } from '@rhythm/components/dist/DataTable/DataTable';
import { PatientLocalStorage, retrievePersistedData, savePersistentData } from '~src/utils';
import { useLogoutUser } from '~src/services/users';
import { UNAUTHROIZED } from '~src/constants';
import {
  FastReviewResponse,
  PaginationSort,
  useFastReviewPatientReadings,
  usePatientFastReviewPagination,
  usePatientReviewCount,
} from '~src/services/patients';
import { FormFilterValues, getInitialFilters } from '~src/pages/Patients/PatientFilters';
import { PatientCellFastReview, PatientNewLink } from './PatientCell';
import { MetricCellFastReview, MetricCellFastReviewProps } from './MetricCellFastReview';
import { DataTableFastReview } from '../DataTable/DataTable';
import { useHistory } from 'react-router-dom';
import { MyContext } from '~src/pages/createContext';
import dayjs from 'dayjs';

interface PatientRowFastReview extends Record<string, unknown> {
  id: string;
  fullname: string;
  contactNumber: string;
  physicianName: string;
  daysTransmitted: DaysTransmittedCellPropsFastReview;
  contacted: boolean;
  minutesLogged: number;
  bloodPressure?: MetricCellFastReviewProps;
  pulse?: MetricCellFastReviewProps;
  weight?: MetricCellFastReviewProps;
  glucose?: MetricCellFastReviewProps;
  bloodOxygen?: MetricCellFastReviewProps;
  isReviewed: boolean;
  minutes?: PatientTime[];
  statusType: PatientStatusCellProps;
}

interface PatientPaginationParams extends OnFetchProps {
  goToPage?: number | undefined;
  initialSize?: number | undefined;
  defaultSortBy?: SortingRule<unknown>[];
  searchWord?: string;
  totalItemsCount?: number;
}

interface PatientMonthlyTimeAPIResponse {
  monthly_time_accrued: number;
}

export type PC<T> = Cell<PatientRowFastReview, T>;
export type PatientCellProps = Pick<PatientRowFastReview, 'fullname' | 'contactNumber' | 'id'>;

const PAGE_SIZE = 50;

export interface PatientsPaginateDataView {
  search?: string;
  filterSearch?: string;
  updatedFilterSearch?: FormFilterValues;
  active?: boolean;
  currentTab?: number;
  setTotalPatients?: any;
  setFastReviewCount?: any;
  setPatientsIds?: any;
  setReviewedCounts?: any;
  setFastReviewDate?: any;
}

type BaseUser = {
  firstName: string;
  lastName: string;
};

function getName(user?: BaseUser): string {
  if (!user) {
    return '';
  }
  return `${user.lastName}, ${user.firstName}`;
}

function getPhysicianName(user?: BaseUser): string {
  if (!user) {
    return '';
  }
  return `${user.firstName} ${user.lastName}`;
}

function usePatientRows(patients?: Patient[]): PatientRowFastReview[] {
  return useMemo(() => {
    if (!patients) return [];

    return patients.map((patient) => ({
      id: patient.id,
      fullname: getName(patient),
      contactNumber: patient.contacts?.length > 0 ? patient.contacts[0]?.contact : '',
      physicianName: getPhysicianName(patient.physician),
      daysTransmitted: { days: patient.daysTransmittedMonthly },
      contacted: patient?.hasMonthlyContact != null,
      minutesLogged:
        (patient.monthlyTimeAccrued as unknown as PatientMonthlyTimeAPIResponse)
          ?.monthly_time_accrued ?? 0,
      bloodPressure: { reading: patient.bloodPressure, type: PatientReading.type.BLOOD_PRESSURE },
      pulse: { reading: patient.pulse, type: PatientReading.type.PULSE },
      weight: { reading: patient.weight, type: PatientReading.type.WEIGHT },
      glucose: { reading: patient.bloodSugar, type: PatientReading.type.BLOOD_SUGAR },
      bloodOxygen: { reading: patient.pulseOx, type: PatientReading.type.BLOOD_OXYGEN },
      isReviewed: patient.isReviewed,
      // minutes: patient.minutes,
      statusType: { statusType: patient.statusType },
    }));
  }, [patients]);
}

export function PatientsFastReviewDataView({
  search,
  updatedFilterSearch,
  active = true,
  currentTab,
  setTotalPatients,
  setFastReviewCount,
  setPatientsIds,
  setReviewedCounts,
  setFastReviewDate,
}: PatientsPaginateDataView) {
  const getDefaultSortBy = (): SortingRule<unknown>[] => {
    return [{ id: 'Patient', desc: false }];
  };

  const parseSearchColumn = (name?: string) => {
    if (name) return `${name?.split(' ').join('&')}`;

    return '';
  };

  const getLocalStorageData = () => {
    const persistedFilter = retrievePersistedData(
      `rpm-filter-${currentTab}`
    ) as PatientLocalStorage;

    if (persistedFilter) {
      if (!persistedFilter?.sortBy || typeof persistedFilter?.sortBy === 'string') {
        persistedFilter.sortBy = getDefaultSortBy();
      }
      const initial = persistedFilter?.initial || 0;
      const initialGreaterThanZero = initial > 0;
      const pageIndex = initialGreaterThanZero ? initial : 0;

      return {
        pageIndex,
        goToPage: parseInt(`${pageIndex / persistedFilter?.pageSize || PAGE_SIZE}`),
        initialSize: pageIndex,
        sortBy: persistedFilter?.sortBy || getDefaultSortBy(),
        defaultSortBy: persistedFilter?.sortBy || getDefaultSortBy(),
        pageSize: 50,
        totalCount: persistedFilter?.totalCount,
        search: persistedFilter?.search,
        searchWord: persistedFilter?.search,
        filters: persistedFilter?.filters || getInitialFilters(),
      };
    }

    return {
      pageIndex: 0,
      sortBy: getDefaultSortBy(),
      initialSize: 0,
      pageSize: 50,
      defaultSortBy: getDefaultSortBy(),
      goToPage: 0,
      totalCount: 0,
      search: '',
      searchWord: '',
      filters: getInitialFilters(),
    };
  };

  const [date] = useState(String(dayjs().utc().format()));
  // Wrap usePatientPagination in a function
  const usePatientPaginationWrapper = (queryData: PaginationSort) => {
    return usePatientFastReviewPagination({ ...queryData, currentTimestamp: date });
  };
  const persistedData = getLocalStorageData();
  const [pagination, setPagination] = useState<PatientPaginationParams>({
    ...getLocalStorageData(),
  });
  const [patientsData, setPatientsData] = useState<any[]>([]);
  const [currentPatientsIds, setCurrentPatientsIds] = useState<any[]>([]);
  const [completePatientsIds, setCompletePatientsIds] = useState<any[]>([]);
  const [currentReviewedCount, setCurrentReviewedCount] = useState(0);
  const [reviewedCount, setReviewedCount] = useState(0);
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [totalFastReviewReading, setTotalFastReviewReading] = useState(0);
  const [currentFastReviewReading, setCurrentFastReviewReading] = useState(0);
  const [pageParams, setPageParams] = useState({ totalSize: 0, totalPageCount: 0 });
  const { mutate: logout } = useLogoutUser();

  // Combine the pagination state values with the queryData initial values
  const initialQueryData: PaginationSort = {
    sort: persistedData.sortBy || ([] as unknown as any),
    search: persistedData.searchWord,
    offset: 0,
    limit: persistedData.pageSize,
    filters: getInitialFilters() as FormFilterValues,
  };

  const [queryData, setQueryData] = useState<PaginationSort>(initialQueryData);

  const createFastReviewPatientReadings = useFastReviewPatientReadings();
  const { push } = useHistory();
  const { data, isLoading } = usePatientPaginationWrapper({ ...queryData });
  const { data: totalCurrentReviewCount } = usePatientReviewCount({
    search: queryData.search,
    filters: queryData.filters,
  });
  if (persistedData.filters?.clinicians && persistedData.filters?.clinicians.length < 1) {
    push('/patients');
  }
  useDebounce(
    () => {
      if (search !== pagination?.searchWord) {
        setPagination({
          ...pagination,
          pageIndex: 0,
          goToPage: 0,
          searchWord: search,
        });
      }
    },
    300,
    [search]
  );

  useEffect(() => {
    if (!totalCurrentReviewCount) return;

    const total = totalCurrentReviewCount?.total ? totalCurrentReviewCount?.total : '0';

    setFastReviewCount(total);
    setCurrentFastReviewReading(parseInt(total));
  }, [totalCurrentReviewCount]);

  useEffect(() => {
    setPatientsData(data?.patients ?? []);

    if (data?.patients) {
      setCurrentPatientsIds(data?.patients.map((obj) => obj.id));
      setCurrentReviewedCount(data?.totalReviewCount ?? 0);

      setTotalPatients(data?.total);
      setTotalFastReviewReading(Number(data?.totalReviewCount) + totalFastReviewReading);
      setPageParams({
        totalSize: data?.total ?? 0,
        totalPageCount: Math.ceil(data?.total / 50) ?? 1,
      });

      const persistedPaginationData = retrievePersistedData(
        `rpm-filter-${currentTab}`
      ) as PatientLocalStorage;

      if (persistedPaginationData) {
        savePersistentData(
          `rpm-filter-${currentTab}`,
          JSON.stringify({
            ...persistedPaginationData,
            totalCount: data?.total,
          })
        );
      }
    }
  }, [data]);

  useEffect(() => {
    const fetchPaginatedData = async () => {
      try {
        setPageParams({
          totalSize: data?.total ?? 0,
          totalPageCount: Math.ceil(pageParams.totalSize / 50) ?? 1,
        });

        const offset = pagination.pageIndex > 0 ? 50 * pagination.pageIndex : pagination.pageIndex;

        const persistedFilter = retrievePersistedData(
          `rpm-filter-${currentTab}`
        ) as PatientLocalStorage;

        if (offset > persistedFilter?.totalCount) {
          return;
        }

        const persistedPaginationData = {
          ...persistedFilter,
          totalCount: pageParams.totalSize,
          initial: offset,
          sortBy: pagination.sortBy ?? getDefaultSortBy(),
          pageSize: pagination.pageSize,
          search: parseSearchColumn(pagination?.searchWord ?? persistedFilter?.search),
        };

        savePersistentData(
          `rpm-filter-${currentTab}`,
          JSON.stringify({ ...persistedPaginationData })
        );
        const newQueryData = {
          sort: pagination?.sortBy ?? getDefaultSortBy(),
          search: parseSearchColumn(pagination?.searchWord ?? persistedFilter?.search),
          offset: offset,
          limit: 50,
          filters: { ...updatedFilterSearch, active } as any,
        };
        return setQueryData(newQueryData);
      } catch (err: any) {
        if (err?.message == UNAUTHROIZED) {
          logout(null);
        }
      }
    };
    fetchPaginatedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, updatedFilterSearch]);

  const patients = usePatientRows(patientsData);

  const fetchData = ({ pageIndex, pageSize, sortBy }: OnFetchProps) => {
    if (pageIndex !== pagination.pageIndex || pageSize !== pagination.pageSize) {
      setPagination({
        pageIndex,
        pageSize,
        sortBy,
      });

      return;
    }

    if (sortBy && sortBy?.length > 0 && pagination.sortBy) {
      if (
        pagination?.sortBy[0]?.id !== sortBy[0].id ||
        pagination.sortBy[0]?.desc !== sortBy[0].desc
      ) {
        setPagination({
          pageIndex,
          pageSize,
          sortBy,
        });
      }
      return;
    }
  };

  const basicColumns = [
    {
      Header: 'Patient',
      width: 200,
      accessor: ({ id, fullname, contactNumber }: PatientRowFastReview) =>
        [fullname, id, contactNumber].join(' '),
      Cell: ({ row: { original } }: PC<PatientCellProps>) => (
        <PatientCellFastReview {...original} />
      ),
    },
    {
      Header: '  ',
      width: 25,
      accessor: ({ id }: PatientRowFastReview) => id,
      Cell: ({ row: { original } }: PC<PatientCellProps>) => <PatientNewLink {...original} />,
    },
    {
      Header: 'Status',
      accessor: 'statusType',
      width: 200,
      Cell: ({ value }: PC<PatientStatusCellProps>) => <PatientStatusCell {...value} />,
    },
    {
      Header: 'Physician',
      accessor: 'physicianName',
      width: 136,
    },
    {
      Header: 'Days',
      accessor: 'daysTransmitted',
      disableSortBy: true,
      width: 89,
      Cell: ({ value }: PC<DaysTransmittedCellPropsFastReview>) => (
        <DaysTransmittedCell {...value} />
      ),
    },
    {
      Header: 'Contact',
      accessor: 'contacted',
      width: 114,
      disableSortBy: true,
      Cell: ({ value }: PC<boolean>) => <Text>{value ? 'Yes' : 'No'}</Text>,
    },
    {
      Header: 'Minutes',
      width: 110,
      accessor: 'minutesLogged',
      disableSortBy: true,
    },
    {
      Header: 'BP',
      accessor: 'bloodPressure',
      maxWidth: 130,
      disableSortBy: true,
      Cell: ({ value }: PC<MetricCellFastReviewProps>) => <MetricCellFastReview {...value} />,
    },
    {
      Header: 'Pulse',
      accessor: 'pulse',
      maxWidth: 190,
      disableSortBy: true,
      Cell: ({ value }: PC<MetricCellFastReviewProps>) => <MetricCellFastReview {...value} />,
    },
    {
      Header: 'Weight',
      accessor: 'weight',
      maxWidth: 130,
      disableSortBy: true,
      Cell: ({ value }: PC<MetricCellFastReviewProps>) => <MetricCellFastReview {...value} />,
    },
    {
      Header: 'Glucose',
      accessor: 'glucose',
      maxWidth: 130,
      disableSortBy: true,
      Cell: ({ value }: PC<MetricCellFastReviewProps>) => <MetricCellFastReview {...value} />,
    },
    {
      Header: (
        <>
          S<small>p</small>O<sub>2</sub>
        </>
      ),
      accessor: 'bloodOxygen',
      maxWidth: 115,
      disableSortBy: true,
      Cell: ({ value }: PC<MetricCellFastReviewProps>) => <MetricCellFastReview {...value} />,
    },
  ];
  const { fastReviewState, setFastReviewState } = useContext(MyContext);
  const scrollFunc = async () => {
    await window.scrollTo({
      top: 0,
    });
  };
  const onNextClick = async () => {
    scrollFunc();
    setCompletePatientsIds((prevIds) => [...prevIds, ...currentPatientsIds]);
    setNextButtonDisable(true);
    setFastReviewDate(date ?? '');
    setReviewedCount(reviewedCount + currentReviewedCount);
    setPatientsIds(currentPatientsIds ?? []);
    setReviewedCounts(reviewedCount + currentReviewedCount);
    const nextPageData: PaginationSort = { ...queryData, offset: (queryData.offset ?? 0) + 50 };
    setQueryData(nextPageData);
  };
  const onCompleteClick = async () => {
    setNextButtonDisable(true);
    setCompletePatientsIds(completePatientsIds.concat(currentPatientsIds));
    const file: FastReviewResponse = await createFastReviewPatientReadings({
      patientIds: completePatientsIds.concat(currentPatientsIds),
      currentTimestamp: date,
    });
    if ((file.status = 201)) {
      const readingsNotReviewed = reviewedCount + currentReviewedCount - file.ReviewedRecords;
      setFastReviewState({
        ...fastReviewState,
        error: false,
        totalReviewed: file.ReviewedRecords,
        notReviewed: readingsNotReviewed > 0 ? readingsNotReviewed : 0,
        totalCount: reviewedCount + currentReviewedCount,
        bulkReview: true,
      });
      push('/patients');
    } else {
      push('/patients');
      setFastReviewState({
        ...fastReviewState,
        error: true,
        totalReviewed: 0,
        notReviewed: 0,
        totalCount: reviewedCount + currentReviewedCount,
        bulkReview: true,
      });
    }
  };
  // const onBackClick = () => {

  //   setNextButtonDisable(true);
  //   const nextPageData: PaginationSort = { ...queryData, offset: (queryData.offset ?? 0) - 50 }
  //   setQueryData(nextPageData)
  // }

  const completedColumns = basicColumns;
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current;

      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.bottom <= window.innerHeight) {
          // Element is within the viewport
          setNextButtonDisable(false);
        } else {
          // Element is outside the viewport
        }
      } else {
        setNextButtonDisable(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Box ref={elementRef} className="fast-review-table">
        <DataTableFastReview
          fetchData={fetchData}
          columns={completedColumns}
          isLoading={isLoading}
          data={patients}
          search={''}
          totalPageCount={pageParams.totalPageCount}
          totalRowCount={pageParams.totalSize}
          initialSortBy={pagination.defaultSortBy as any}
          goToPageIndex={pagination.goToPage}
          initialPageIndex={pagination.initialSize}
          initialPageSize={PAGE_SIZE}
        />
        <Box
          bg={'black'}
          position={'sticky'}
          bottom={'0'}
          w={'100%'}
          paddingLeft={'10px'}
          paddingRight={'10px'}
          h={'64px'}
          borderTopLeftRadius={'6px'}
          borderTopRightRadius={'6px'}
        >
          <Flex align="center" justify="space-between">
            <Flex align="center" flexDirection="column">
              <Box w={'100%'} style={{ display: 'inline-block' }}>
                <span style={{ color: 'white', float: 'left', fontSize: '14px' }}>Reviewed</span>
                <span
                  style={{
                    textAlign: 'end',
                    color: 'white',
                    float: 'right',
                    marginRight: '20px',
                    fontSize: '14px',
                  }}
                >
                  {reviewedCount}/{currentFastReviewReading}
                </span>
              </Box>
              <Progress
                bg={'#F59B00'}
                value={Math.ceil((reviewedCount / currentFastReviewReading) * 100)}
                w="200px"
                h={'8px'}
                mr={4}
              />
            </Flex>
            <Box marginTop={'10px'}>
              <span style={{ color: 'white' }}>
                Pages : {(queryData.offset ?? 0) / (queryData.limit ?? 0) + 1} /{' '}
                {pageParams.totalPageCount}
              </span>
              {/* <Button onClick={onBackClick} marginLeft={'10px'} w={32} h={'40px'} color={"white"} colorScheme="blue"> <Icon boxSize={'15px'} icon='arrow-left' marginRight={'5px'} /> Back</Button> */}
              {(queryData.offset ?? 0) / (queryData.limit ?? 0) + 1 ===
              pageParams.totalPageCount ? (
                <Button
                  disabled={nextButtonDisable}
                  onClick={onCompleteClick}
                  marginLeft={'10px'}
                  w={52}
                  h={'40px'}
                  color={'white'}
                  colorScheme="blue"
                  backgroundColor={'#1CA339'}
                >
                  {' '}
                  Complete & Exit
                </Button>
              ) : (
                <Button
                  disabled={nextButtonDisable}
                  onClick={onNextClick}
                  marginLeft={'10px'}
                  w={32}
                  h={'40px'}
                  color={'white'}
                  colorScheme="blue"
                >
                  {' '}
                  Next
                  <Icon boxSize={'15px'} icon="arrow-right" marginLeft={'5px'} />
                </Button>
              )}
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
