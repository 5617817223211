/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PatientStatusTypeHistoryDto = {
    patientId: string;
    statusType: PatientStatusTypeHistoryDto.statusType;
    followupDate?: string;
    pauseReason: string;
    reason: string;
    note: string;
};

export namespace PatientStatusTypeHistoryDto {

    export enum statusType {
        NEW = 'new',
        ACTIVE = 'active',
        NON_ADHERENT = 'non-adherent',
        PAUSED = 'paused',
        UNENROLLED = 'unenrolled',
    }


}

