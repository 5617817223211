import { FormLabel } from '@rhythm/components';
import { useField } from 'formik';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import { DayPickerRangeController } from 'react-dates';

export interface DateRangePickerProps {
  name: string;
  startDate: Moment;
  endDate: Moment;
  maxDate: Moment;
  initialVisibleMonth: Moment;
}

export function DateRangePicker({ ...props }: DateRangePickerProps) {
  const [field, meta, helpers] = useField(props);

  const fromDate: any = meta.value.startDate && moment(field.value.startDate);
  const toDate: any = meta.value.endDate && moment(field.value.endDate);
  const maxDate: any = props.maxDate && moment(props.maxDate);
  const initialVisibleMonth: any = props.initialVisibleMonth || moment();
  const handleDatesChange = (arg: { startDate: any; endDate: any }) => {
    helpers.setValue({
      startDate: moment(arg.endDate).subtract(1, 'M').add(1, 'd'),
      endDate: arg.endDate,
    });
  };

  const isOutsideRange = () => {
    const dayMoment = moment();
    return dayMoment.isAfter();
  };

  return (
    <>
      <FormLabel fontSize="xs">Month</FormLabel>
      <DayPickerRangeController
        startDate={fromDate}
        endDate={toDate}
        onDatesChange={handleDatesChange}
        onFocusChange={() => {
          console.log('onFocusChange');
        }}
        initialVisibleMonth={() => initialVisibleMonth}
        focusedInput="endDate"
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={2}
        daySize={50}
        isOutsideRange={isOutsideRange}
        maxDate={maxDate}
      />
    </>
  );
}
