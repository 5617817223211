import { Box, Divider, Grid, GridItem, Heading } from '@rhythm/components';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormCalendar } from '~src/components/FormCalendar';
import { FormField, MaskedInput } from '~src/components/FormField';
import { FormSelect } from '~src/components/FormSelect';
import { gendersOptionsArray, stateOptionsArray } from '~src/constants';
import { getAgeFromDate } from '~src/helpers';
import { useGroupList } from '~src/services/group';
import { useGetLanguages, useGetPatientTimeZone } from '~src/services/patients';
import { getLanguageOptionsArray, telMask, zipMask } from '~src/utils';
import { EnrollPatientFormValues } from '../EnrollPatientForm';
import ICDCodesForm from './ICDCodesForm';
import type { Option } from '~src/utils';
import { useUserRoleAndEmail } from '~src/hooks';
import { timezones } from '~src/constants';
import { useDebounce } from 'react-use';

enum PatientFields {
  RHYTHM_ID = 'Rhythm Id',
  MRN2 = 'MRN2',
  ENROLLMENT_DATE = 'Date of Enrollment',
  FIRST_NAME = 'First Name *',
  LAST_NAME = 'Last Name *',
  DATE_OF_BIRTH = 'Date of Birth *',
  AGE = 'Age',
  GENDER = 'Gender *',
  DISEASE_STATE = 'Disease State',
  ICD_10_CODE = 'ICD-10 Code',
  FIRST_DISCHARGE_DATE = 'First discharge date',
  FIRST_READMISSION_DATE = 'First readmission date',
  REASON_FOR_READMISSION = 'Reason for readmission',
  GROUPS = 'Groups',
}

enum AddressFields {
  ADDRESS_LINE_1 = 'Address Line 1 *',
  ADDRESS_LINE_2 = 'Address Line 2',
  CITY = 'City *',
  ZIPCODE = 'Zipcode *',
  STATE = 'State *',
  COUNTRY = 'Country',
  LANGUAGE = 'Preferred Language',
  TIMEZONE = 'Time Zone *',
}

enum ContactFields {
  LANDLINE = 'Phone Number',
  MOBILE = 'Mobile Number',
  PREFERRED_NUMBER = 'Preferred Contact Number',
  EMAIL = 'Email',
}

const PatientInformationForm: React.FC = () => {
  const {
    getFieldProps,
    setFieldValue,
    values: { dateOfBirth, firstReadmissionDate },
  } = useFormikContext<EnrollPatientFormValues>();
  const [postalCode, setPostalCode] = useState('');
  const [postalCodeFetched, setPostalCodeFetched] = useState<boolean>(true);
  const [doesTimezoneExist, setDoesTimezoneExist] = useState<boolean>(true);

  const groupsResponse = useGroupList(true);
  const groups: Option[] =
    groupsResponse?.data?.map((group) => ({
      label: group.name,
      value: group.id,
    })) ?? [];

  const languages = useGetLanguages();

  const callUpdatePatientTimeZone = useGetPatientTimeZone(postalCode);

  useEffect(() => {
    if (!dateOfBirth) return;
    const age = getAgeFromDate(new Date(dateOfBirth));
    setFieldValue('age', age);
  }, [dateOfBirth, setFieldValue]);

  useEffect(() => {
    if (!firstReadmissionDate) {
      setFieldValue('reasonForReadmission', '');
    }
  }, [firstReadmissionDate, setFieldValue]);

  useDebounce(
    () => {
      if (postalCode && postalCodeFetched === false) {
        callUpdatePatientTimeZone().then(function (response: any) {
          setPostalCodeFetched(true);
          if (response && response.timeZone) {
            setFieldValue('timeZone', response.timeZone);
            if (!doesTimezoneExist) {
              setDoesTimezoneExist(true);
            }
          } else {
            setFieldValue('timeZone', null);
            if (doesTimezoneExist) {
              setDoesTimezoneExist(false);
            }
          }
        });
      }
    },
    1000,
    [postalCode, postalCodeFetched, setFieldValue, callUpdatePatientTimeZone]
  );

  const handleBlur = (postalCodeVal: any) => {
    setPostalCode(postalCodeVal);
    setPostalCodeFetched(false);
  };

  const { userRole } = useUserRoleAndEmail();

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap="5xl">
        <FormField disabled name="rhythmId" label={PatientFields.RHYTHM_ID} />
        <FormField name="mrn2" label={PatientFields.MRN2} placeholder="MRN2" />
        <FormField
          name="enrollment"
          disabled
          label={PatientFields.ENROLLMENT_DATE}
          placeholder={dayjs().format('MM/DD/YYYY')}
        />
      </Grid>
      <Divider />
      <ICDCodesForm />
      <Divider />
      <Heading variant="h5" mt="4xl" mb="2xl">
        Patient Information
      </Heading>
      <Grid templateColumns="repeat(3, 1fr)" gap="2xl">
        <Box>
          <FormField name="firstName" label={PatientFields.FIRST_NAME} placeholder="First Name" />
          <FormField name="lastName" label={PatientFields.LAST_NAME} placeholder="Last Name" />
          <FormCalendar
            name="dateOfBirth"
            label={PatientFields.DATE_OF_BIRTH}
            placeholder="Enter patient's date of birth"
            applyTimezoneOffset={true}
          />
          <FormField disabled name="age" label={PatientFields.AGE} />
          <FormSelect options={gendersOptionsArray} name="gender" label={PatientFields.GENDER} />
          <FormField disabled name="country" label={AddressFields.COUNTRY} />
        </Box>
        <Box>
          <FormField
            name="addressLine1"
            label={AddressFields.ADDRESS_LINE_1}
            placeholder="Address Line 1"
          />
          <FormField
            name="addressLine2"
            label={AddressFields.ADDRESS_LINE_2}
            placeholder="Address Line 2"
          />
          <FormField name="city" label={AddressFields.CITY} placeholder="City" />
          <MaskedInput
            name="zipcode"
            label={AddressFields.ZIPCODE}
            format={zipMask}
            placeholder="Zipcode"
            onChange={(e) => handleBlur(e.target.value)}
          />
          <FormSelect options={stateOptionsArray} label={AddressFields.STATE} name="state" />
          <FormSelect
            multi={true}
            options={groups}
            name="groupIds"
            label={PatientFields.GROUPS}
            clearable
            placeholder="Patient groups"
          />
        </Box>
        <Box>
          <MaskedInput
            name="landline"
            label={ContactFields.LANDLINE}
            format={telMask}
            placeholder="(123) 456 7890"
          />
          <MaskedInput
            name="mobileNumber"
            label={ContactFields.MOBILE}
            format={telMask}
            placeholder="(123) 456 7890"
          />
          <FormField name="email" label={ContactFields.EMAIL} placeholder="Email" />
          <FormSelect
            options={getLanguageOptionsArray(languages?.data || [])}
            label={AddressFields.LANGUAGE}
            name="language"
            clearable
            creatable={[
              'roles:internal:super-admin',
              'roles:internal:lead-clinician',
              'roles:internal:admin',
            ].includes(userRole)}
          />
          <FormSelect
            name="timeZone"
            label={AddressFields.TIMEZONE}
            options={timezones}
            disabled={doesTimezoneExist}
            onInputChange={(value) => setFieldValue('timeZone', value)}
          />
          <FormCalendar
            name="firstDischargeDate"
            label={PatientFields.FIRST_DISCHARGE_DATE}
            placeholder="Enter patient's first discharge date"
            applyTimezoneOffset={true}
            showErrorOnTouch={false}
          />
        </Box>
      </Grid>
      <Grid templateColumns="repeat(6, 1fr)" gap="2xl">
        <GridItem colSpan={2}>
          <FormCalendar
            name="firstReadmissionDate"
            label={PatientFields.FIRST_READMISSION_DATE}
            placeholder="Enter patient's first readmission date"
            applyTimezoneOffset={true}
          />
        </GridItem>
        <GridItem colSpan={4}>
          <FormField
            textarea
            resize="vertical"
            name="reasonForReadmission"
            label={PatientFields.REASON_FOR_READMISSION}
            placeholder="Reason for readmission"
            disabled={!getFieldProps('firstReadmissionDate').value}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default PatientInformationForm;
