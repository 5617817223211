/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { DeviceThreshold } from './DeviceThreshold';
import type { OrganizationContact } from './OrganizationContact';
import type { OrganizationNotes } from './OrganizationNotes';
import type { Patient } from './Patient';
import type { User } from './User';

export type Organization = {
    isActive: boolean;
    name: string;
    isDefault: boolean;
    isRhythmOrg: boolean;
    type: Organization.type;
    emrAccess: boolean;
    platformName: string;
    language: string;
    goLive: string;
    address: Address;
    parent: Organization;
    children: Array<Organization>;
    users: Array<User>;
    patients: Array<Patient>;
    contacts: Array<OrganizationContact>;
    notes: Array<OrganizationNotes>;
    threshold: Array<DeviceThreshold>;
    value: Record<string, any>;
    patientCount: number;
    userCount: number;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace Organization {

    export enum type {
        CLINIC = 'clinic',
        ACCOUNT = 'account',
    }


}

