import { Box, Card, Flex, Heading, Icon, Text } from '@rhythm/components';
import { DeviceThreshold, Organization } from '~generated';

export type DataProp = {
  data: Organization;
};

type ThresholdLimit = { upperlimit: number; lowerlimit: number };

const AccountInformation = ({ data }: DataProp) => {
  const bloodPressureDiastolic: ThresholdLimit = { upperlimit: 0, lowerlimit: 0 };
  const bloodPressureSystolic: ThresholdLimit = { upperlimit: 0, lowerlimit: 0 };
  const weightChange24h: ThresholdLimit = { upperlimit: 0, lowerlimit: 0 };
  const weightChange7Day: ThresholdLimit = { upperlimit: 0, lowerlimit: 0 };
  const pulse: ThresholdLimit = { upperlimit: 0, lowerlimit: 0 };
  const bloodSugar: ThresholdLimit = { upperlimit: 0, lowerlimit: 0 };

  data?.threshold?.forEach((threshold) => {
    if (threshold.metricType == DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC) {
      bloodPressureSystolic.upperlimit = threshold.threshHoldUpperLimit;
      bloodPressureSystolic.lowerlimit = threshold.threshHoldLowerLimit;
    }

    if (threshold.metricType == DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC) {
      bloodPressureDiastolic.upperlimit = threshold.threshHoldUpperLimit;
      bloodPressureDiastolic.lowerlimit = threshold.threshHoldLowerLimit;
    }

    if (threshold.metricType == DeviceThreshold.metricType.PULSE) {
      pulse.upperlimit = threshold.threshHoldUpperLimit;
      pulse.lowerlimit = threshold.threshHoldLowerLimit;
    }

    if (threshold.metricType == DeviceThreshold.metricType.WEIGHT) {
      weightChange24h.upperlimit = threshold.threshHoldUpperLimit;
      weightChange24h.lowerlimit = threshold.threshHoldLowerLimit;
    }

    if (threshold.metricType == DeviceThreshold.metricType.SEVEN_DAYS_CHANGE) {
      weightChange7Day.upperlimit = threshold.threshHoldUpperLimit;
      weightChange7Day.lowerlimit = threshold.threshHoldLowerLimit;
    }

    if (threshold.metricType == DeviceThreshold.metricType.BLOOD_SUGAR) {
      bloodSugar.upperlimit = threshold.threshHoldUpperLimit;
      bloodSugar.lowerlimit = threshold.threshHoldLowerLimit;
    }
  });
  return (
    <Card>
      <Flex>
        {' '}
        <Icon icon="presenting-rhythm" />{' '}
        <Heading ml="18px" variant="h4">
          {' '}
          Devices{' '}
        </Heading>{' '}
      </Flex>
      <Flex mt="25px">
        <Box width="200px">
          <Flex direction="column" justifyContent="space-between">
            <Text variant="smallCaps" mb="8px" fontWeight="700" color="neutral.800">
              Devices
            </Text>
            <Text pb="3px" fontWeight="500" pt="3px">
              Weight
            </Text>
            <Text pb="3px" fontWeight="500" pt="3px">
              Blood Pressure
            </Text>
            <Text pb="3px" fontWeight="500" pt="3px">
              Pulse
            </Text>
            <Text pb="3px" fontWeight="500" pt="3px">
              Glucose{' '}
            </Text>
          </Flex>
        </Box>
        <Box w="100%">
          <Flex direction="column" justifyContent="space-between">
            <Box>
              <Flex justifyContent="space-between">
                <Text variant="smallCaps" mb="8px" fontWeight="700" color="neutral.800">
                  {' '}
                  Device Thresholds{' '}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex
                pb="3px"
                pt="3px"
                borderBottom="0.5px solid #B8C0CE;"
                align="center"
                justifyContent="space-between"
              >
                <Text> Blood Pressure (sys) </Text>
                <Text fontWeight="700">
                  {' '}
                  {bloodPressureSystolic?.lowerlimit} - {bloodPressureSystolic?.upperlimit}{' '}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex
                pb="3px"
                pt="3px"
                borderBottom="0.5px solid #B8C0CE;"
                align="center"
                justifyContent="space-between"
              >
                <Text> Blood Pressure (dia) </Text>
                <Text fontWeight="700">
                  {' '}
                  {bloodPressureDiastolic?.lowerlimit} - {bloodPressureDiastolic?.upperlimit}{' '}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex
                pb="3px"
                pt="3px"
                borderBottom="0.5px solid #B8C0CE;"
                align="center"
                justifyContent="space-between"
              >
                <Text> Pulse </Text>
                <Text fontWeight="700">
                  {' '}
                  {pulse.lowerlimit} - {pulse.upperlimit}{' '}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex
                pb="3px"
                pt="3px"
                borderBottom="0.5px solid #B8C0CE;"
                align="center"
                justifyContent="space-between"
              >
                <Text> Glucose </Text>
                <Text fontWeight="700">
                  {' '}
                  {bloodSugar?.lowerlimit} - {bloodSugar?.upperlimit}{' '}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex
                pb="3px"
                pt="3px"
                borderBottom="0.5px solid #B8C0CE;"
                align="center"
                justifyContent="space-between"
              >
                <Text> Weight (24 hr) </Text>
                <Text fontWeight="700">
                  {' '}
                  {weightChange24h.lowerlimit} - {weightChange24h.upperlimit}
                  {'lbs'}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex
                pb="3px"
                pt="3px"
                borderBottom="0.5px solid #B8C0CE;"
                align="center"
                justifyContent="space-between"
              >
                <Text> Weight (7 days) </Text>
                <Text fontWeight="700">
                  {' '}
                  {weightChange7Day.lowerlimit} - {weightChange7Day.upperlimit}
                  {'lbs'}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};

export default AccountInformation;
