import { makeOptionsArray } from '~src/utils';
import type { Option } from '~src/utils';

export const icd10CodesDiabetes = {
  'E11.21': 'E11.21 - Type 2 diabetes mellitus with diabetic nephropathy',
  'E11.22': 'E11.22 - Type 2 diabetes mellitus with diabetic chronic kidney disease',
  'E11.29': 'E11.29 - Type 2 diabetes mellitus with other diabetic kidney complication',
  'E11.31': 'E11.31 - Type 2 diabetes mellitus with unspecified diabetic retinopathy',
  'E11.32': 'E11.32 - Type 2 diabetes mellitus with mild nonproliferative diabetic retinopathy',
  'E11.33': 'E11.33 - Type 2 diabetes mellitus with moderate nonproliferative diabetic retinopathy',
  'E11.34': 'E11.34 - Type 2 diabetes mellitus with severe nonproliferative diabetic retinopathy',
  'E11.35': 'E11.35 - Type 2 diabetes mellitus with proliferative diabetic retinopathy',
  'E11.36': 'E11.36 - Type 2 diabetes mellitus with diabetic cataract',
  'E11.39': 'E11.39 - Type 2 diabetes mellitus with other diabetic ophthalmic complication',
  'E11.40': 'E11.40 - Type 2 diabetes mellitus with diabetic neuropathy, unspecified',
  'E11.41': 'E11.41 - Type 2 diabetes mellitus with diabetic mononeuropathy',
  'E11.42': 'E11.42 - Type 2 diabetes mellitus with diabetic polyneuropathy',
  'E11.43': 'E11.43 - Type 2 diabetes mellitus with diabetic autonomic (poly)neuropathy',
  'E11.44': 'E11.44 - Type 2 diabetes mellitus with diabetic amyotrophy',
  'E11.49': 'E11.49 - Type 2 diabetes mellitus with other diabetic neurological complication',
  'E11.51':
    'E11.51 - Type 2 diabetes mellitus with diabetic peripheral angiopathy without gangrene',
  'E11.52': 'E11.52 - Type 2 diabetes mellitus with diabetic peripheral angiopathy with gangrene',
  'E11.59': 'E11.59 - Type 2 diabetes mellitus with other circulatory complications',
  'E11.61': 'E11.61 - Type 2 diabetes mellitus with diabetic arthropathy',
  'E11.610': 'E11.610 - Type 2 diabetes mellitus with diabetic neuropathic arthropathy',
  'E11.618': 'E11.618 - Type 2 diabetes mellitus with other diabetic arthropathy',
  'E11.620': 'E11.620 - Type 2 diabetes mellitus with diabetic dermatitis',
  'E11.621': 'E11.621 - Type 2 diabetes mellitus with foot ulcer',
  'E11.622': 'E11.622 - Type 2 diabetes mellitus with other skin ulcer',
  'E11.628': 'E11.628 - Type 2 diabetes mellitus with other skin complications',
  'E11.630': 'E11.630 - Type 2 diabetes mellitus with periodontal disease',
  'E11.638': 'E11.638 - Type 2 diabetes mellitus with other oral complications',
  'E11.64': 'E11.64 - Type 2 diabetes mellitus with hypoglycemia',
  'E11.641': 'E11.641 - Type 2 diabetes mellitus with hypoglycemia with coma',
  'E11.649': 'E11.649 - Type 2 diabetes mellitus with hypoglycemia without coma',
  'E11.65': 'E11.65 - Type 2 diabetes mellitus with hyperglycemia',
  'E11.69': 'E11.69 - Type 2 diabetes mellitus with other specified complication',
  'E11.8': 'E11.8 - Type 2 diabetes mellitus with unspecified complications',
  'E11.9': 'E11.9 - Type 2 diabetes mellitus without complications',
  'Z79.4': 'Z79.4 - Long term insulin use',
};

export const icd10CodesDiabetesOptionsArray: Option[] = makeOptionsArray(icd10CodesDiabetes);
