import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import toast from 'react-hot-toast';
import { DefaultService as Service, UpdateUserDto } from '~generated';

export type User = UserClaims;

const notifySuccess = () => toast.success('User has been updated!');
const notifyError = () => toast.error('There was an error updating the user');

export function useGetUser(): UseQueryResult<User> {
  const okta = useOktaAuth();

  return useQuery('okta_user', () => okta?.oktaAuth.getUser(), {
    enabled: okta?.authState?.isAuthenticated ?? false,
  });
}

export function useLogoutUser(): UseMutationResult {
  const client = useQueryClient();
  const okta = useOktaAuth();

  return useMutation(
    async () => {
      await okta?.oktaAuth.signOut();
    },
    {
      onSuccess: () => {
        client.clear();
      },
    }
  );
}

interface UpdatePayload extends UpdateUserDto {
  id: string;
}

export function useUpdateUser(): UseMutationResult<unknown, unknown, UpdatePayload> {
  const client = useQueryClient();
  return useMutation(
    async (payload: UpdatePayload) => {
      return Service.userControllerUpdate(payload.id, payload);
    },
    {
      onSuccess: () => {
        client.invalidateQueries('user');
        notifySuccess();
      },
      onError: () => {
        notifyError();
      },
    }
  );
}

interface DeactivatePayload {
  id: string;
  email: string;
}

export function useDeactivateUser(userDetails: {
  name: any;
}): UseMutationResult<unknown, unknown, DeactivatePayload> {
  const client = useQueryClient();
  return useMutation(
    async (user: any) => {
      return Service.userControllerDeactivate(user.id, user.email);
    },
    {
      onSuccess: () => {
        client.invalidateQueries('user');
        toast.success(`User ${userDetails.name} has been removed`);
      },
      onError: () => {
        toast.error(`There was an error removing the user ${userDetails.name}`);
      },
    }
  );
}
