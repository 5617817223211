import toast from 'react-hot-toast';
import { useMutation, UseMutationResult, useQueryClient, UseQueryResult } from 'react-query';
import { Alert, DefaultService as Service } from '~generated';
import { getErrorMessage } from '~src/helpers/errorMessages';
import { ResponseError } from './patients';
import { useFetch } from './query';

type AlertQuery = {
  filters?: { all: boolean; clinicianId: string };
};

export function useAlerts({ filters }: AlertQuery): UseQueryResult<Alert[]> {
  return useFetch<Alert[]>({
    key: ['alert', filters],
    path: `/alert`,
    queryOptions: {
      refetchInterval: 500000,
      refetchOnWindowFocus: false,
    },
    queryParams: {
      filters: JSON.stringify(filters),
    },
  });
}

export function FindAllEscalatedCounts(): UseQueryResult<number> {
  return useFetch<number>({
    key: ['escalated/count'],
    path: `/alert/escalated/count`,
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });
}

const notifySuccess = () => toast.success(`The Alert has been removed.`);

const notifyError = (status: number | undefined) => {
  const errorMessage = getErrorMessage(status);
  toast.error(errorMessage);
};

export function useUpdateAlert(): UseMutationResult<Alert, unknown, Alert> {
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      return Service.alertControllerUpdate(payload.id, payload);
    },
    {
      onSuccess: (data) => {
        console.log(data);
        notifySuccess();
        queryClient.invalidateQueries('alert');
      },
      onError: (error: ResponseError) => {
        notifyError(error.status);
      },
    }
  );
}
