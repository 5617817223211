import { HTMLAttributes, ReactElement, useState } from 'react';
import { Box, Button, FormLabel, HStack, Heading, Modal, Text } from '@rhythm/components';
import { Group, DefaultService as Service } from '~generated';
import { Field, Form, Formik } from 'formik';
import { FormField } from '~src/components/FormField';
import { useUpdateGroup } from '~src/services/group';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

type FormData = {
  name: string;
  description: string;
};

interface GlobalFilterModalProps extends HTMLAttributes<HTMLDivElement> {
  isModalOpen: boolean;
  isEditMode: boolean;
  setShow: (value: boolean) => void;
  setIsEditMode: (value: boolean) => void;
  setShowGroupData: (value: Group | undefined) => void;
  data: Group | undefined;
}

const GroupModal = ({
  isModalOpen,
  setShow,
  data,
  isEditMode,
  setIsEditMode,
  setShowGroupData,
}: GlobalFilterModalProps): ReactElement => {
  const id = isEditMode && data ? data.id : '';
  const initialValues: FormData = {
    name: isEditMode && data ? data.name : '',
    description: isEditMode && data ? data.description : '',
  };
  const [defaultFilterData, setDefaultFilterData] = useState<FormData>(initialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const client = useQueryClient();
  const updateGroup = useUpdateGroup();
  const handleSubmit = async (formData: FormData) => {
    setIsLoading(true);
    formData.description = formData.description.trim();
    formData.name = formData.name.trim();
    if (isEditMode) {
      updateGroup.mutate({ id, ...formData });
      setIsEditMode(false);
      setShowGroupData(undefined);
      setDefaultFilterData({ name: '', description: '' });
      setIsLoading(false);
      setShow(false);
    } else {
      try {
        await Service.groupsControllerCreate(formData);
        setIsEditMode(false);
        setShowGroupData(undefined);
        setDefaultFilterData({ name: '', description: '' });
        setIsLoading(false);
        setShow(false);
        client.invalidateQueries('groups');
        toast.success(`${formData.name} Group Added Successfully`);
      } catch (err: any) {
        setIsLoading(false);
        console.log(err);
        const errorMessage = JSON.parse(await err?.body?.message);
        if (errorMessage?.message === 'DUPLICATE_GROUP_NAME') {
          toast.error(` ${formData.name} Group Already Exists`);
        } else {
          toast.error(`Error while Updating ${formData.name} Group`);
        }
      }
    }
  };

  const closeTheModal = (resetForm: any) => {
    resetForm();
    setIsEditMode(false);
    setShowGroupData(undefined);
    setDefaultFilterData({ name: '', description: '' });
    setShow(false);
  };
  const validationFunction = (values: FormData) => {
    values.description = values.description.trim();
    values.name = values.name.trim();
    const errors: Partial<FormData> = {};
    if (values.name.length < 3) {
      errors.name = 'This field require minimum 3 character';
    }
    if (values.description.length < 5) {
      errors.description = 'This field require minimum 5 character';
    }
    return errors;
  };
  const handleClose = () => {
    setDefaultFilterData(initialValues);
    setShow(false);
  };

  return (
    <Formik
      initialValues={defaultFilterData}
      onSubmit={handleSubmit}
      validate={validationFunction}
      onReset={handleClose}
    >
      {({ setFieldValue, errors, dirty, resetForm }) => (
        <Modal
          isOpen={isModalOpen}
          size={'2xl'}
          width={'34rem'}
          closeOnOverlayClick={false}
          onClose={() => closeTheModal(resetForm)}
          isCentered
          footer={
            <HStack justifyContent="flex-end">
              <Button variant="secondaryLight" size={'md'} onClick={() => closeTheModal(resetForm)}>
                Close
              </Button>
              <Button
                disabled={!dirty || isLoading}
                variant="primary"
                size={'md'}
                type="submit"
                form="group-form"
              >
                {isEditMode ? 'Update' : 'Add'}
              </Button>
            </HStack>
          }
        >
          <Form id="group-form">
            <Heading variant={'h5'} m={'30px 0px 30px 0px'}>
              {isEditMode ? 'Update' : 'Add'} Group
            </Heading>
            <Box>
              <FormField name="name" label="Group Name *" />
            </Box>
            <Box>
              <FormLabel fontSize="sm">Description *</FormLabel>
              <Field
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('description', event.target.value);
                }}
                as="textarea"
                name="description"
                placeholder="Description..."
                className="chakra-textarea"
                style={{
                  height: '100%',
                  width: '100%',
                  outline: '2px solid transparent',
                  boxShadow: '0px 0.5px 1px rgb(0 0 0 / 10%)',
                  borderColor: '#B8C0CE',
                  borderWidth: '0.5px',
                  paddingInlineStart: '1rem',
                  paddingInlineEnd: '1rem',
                  borderRadius: '4px',
                  paddingTop: '8px',
                  paddingBottom: '10px',
                }}
              />
              <Text style={{ fontSize: '11px', color: '#e53e3e' }}>{errors.description}</Text>
            </Box>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default GroupModal;
