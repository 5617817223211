import {
  Heading,
  Icon,
  Box,
  Flex,
  Divider,
  Button,
  Grid,
  FormLabel,
  Text,
  Spinner,
} from '@rhythm/components';
import { Formik, Form, FormikProps, FormikValues } from 'formik';
import { useMutation } from 'react-query';
import { FormField, MaskedInput } from '~src/components/FormField';
import { FormSelect } from '~src/components/FormSelect';
import { FormRangeSlider, FormSlider } from '~src/components/FormSlider';
import { FileUploadField } from '~src/components/FileUploadField';
import { globalThresholdDefaults, stripCharacters, telMask, zipMask } from '~src/utils';
import toast from 'react-hot-toast';
import {
  CreateOrganizationDto,
  DeviceThreshold,
  DefaultService as Service,
  CreateDeviceThresholdDto,
  Address,
  Contact,
  PatientContact,
} from '~generated';
import {
  emrInformation,
  AddressFields,
  AccountFields,
  EMRFields,
  AccountInformation,
  AddressInformation,
  EMRInformation,
  ThresholdInformation,
  ThresholdFields,
  GoLiveInformation,
  GoLiveFields,
  accountInformation,
  addressInformation,
  goLiveInformation,
} from '../CreateAccount/formFields';
import { stateOptionsArray, devicesOptionsArray } from '~src/constants';
import {
  deriveThresholds,
  useFetchListOrganization,
  useSingleOrganization,
} from '~src/services/clinics';
import { useHistory, useParams } from 'react-router-dom';
import { ParamsTypes } from '../Account/Account';

type InitialValues = AddressInformation &
  AccountInformation &
  EMRInformation &
  ThresholdInformation &
  GoLiveInformation;

export interface Errors {
  [key: string]: string;
}

function IconComponent() {
  return (
    <Box bg="neutral.200" p={6} mr={5} borderRadius={4}>
      <Icon color="primary.600" icon="file" />
    </Box>
  );
}

function AccountInfoForm(props: {
  setFieldValue: (field: string, value: number | number[]) => void;
}) {
  const { data, isLoading } = useFetchListOrganization({});
  const { setFieldValue } = props;

  const emrAccessOptions = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ];

  if (isLoading) return <Spinner />;

  const accountList = data?.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap="2xl" mt="4xl" mb="4xl">
      <Box mr="lg">
        <FormField
          label={AccountFields.clinicName}
          name="accountName"
          placeholder="Enter a clinic name"
        />
        <FormSelect
          label={AccountFields.account}
          name="account"
          options={accountList ?? []}
          onNextEvent={(value) => {
            const account = data?.find((account) => account.id === value);

            const threshold = account?.threshold;

            let bp_diastolic = globalThresholdDefaults.bpDiastolic;
            let bp_systolic = globalThresholdDefaults.bpSystolic;
            let pulse = globalThresholdDefaults.pulse;
            let blood_sugar = globalThresholdDefaults.glucose;
            let weight = globalThresholdDefaults.weightChangeGeneric[0];
            let sevenDays = globalThresholdDefaults.weightChangeGeneric[1];

            threshold?.forEach((thresholdData) => {
              if (
                thresholdData.metricType === DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC
              ) {
                bp_diastolic = [
                  thresholdData.threshHoldLowerLimit,
                  thresholdData.threshHoldUpperLimit,
                ];
              }

              if (thresholdData.metricType === DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC) {
                bp_systolic = [
                  thresholdData.threshHoldLowerLimit,
                  thresholdData.threshHoldUpperLimit,
                ];
              }

              if (thresholdData.metricType === DeviceThreshold.metricType.BLOOD_SUGAR) {
                blood_sugar = [
                  thresholdData.threshHoldLowerLimit,
                  thresholdData.threshHoldUpperLimit,
                ];
              }

              if (thresholdData.metricType === DeviceThreshold.metricType.PULSE) {
                pulse = [thresholdData.threshHoldLowerLimit, thresholdData.threshHoldUpperLimit];
              }

              if (thresholdData.metricType === DeviceThreshold.metricType.WEIGHT) {
                weight = thresholdData.threshHoldLowerLimit;
              }

              if (thresholdData.metricType === DeviceThreshold.metricType.SEVEN_DAYS_CHANGE) {
                sevenDays = thresholdData.threshHoldLowerLimit;
              }
            });

            setFieldValue('bpSystolic', bp_systolic);
            setFieldValue('bpDiastolic', bp_diastolic);
            setFieldValue('pulse', pulse);
            setFieldValue('glucose', blood_sugar);
            setFieldValue('weightChange24h', weight);
            setFieldValue('weightChange7Day', sevenDays);
          }}
        />
        <MaskedInput
          label={AccountFields.phone}
          name="phone"
          format={telMask}
          placeholder="(123) 456 7890"
        />

        <MaskedInput
          label={AccountFields.fax}
          name="fax"
          format={telMask}
          placeholder="(123) 456 7890"
        />
      </Box>
      <Box mr="lg">
        <FormField
          label={AddressFields.addressLine1}
          name="addressLine1"
          placeholder="Address line 1"
        />
        <FormField
          label={AddressFields.addressLine2}
          name="addressLine2"
          placeholder="Address line 2"
        />
        <MaskedInput
          label={AddressFields.zipcode}
          name="zipcode"
          format={zipMask}
          placeholder="12345"
        />
        <FormField label={AddressFields.city} name="city" placeholder="City" />
        <FormSelect label={AddressFields.state} name="state" options={stateOptionsArray} />
        <FormField label={AddressFields.country} name="country" disabled />
      </Box>
      <Box>
        <FormSelect label={EMRFields.emrAccess} name="emrAccess" options={emrAccessOptions} />
        <FormField label={EMRFields.notes} name="notes" placeholder="Notes" textarea />
      </Box>
    </Grid>
  );
}

function GoLiveForm() {
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap="2xl" mt="4xl" mb="4xl">
      <Box>
        <FormSelect
          label={GoLiveFields.devices}
          name="devices"
          options={devicesOptionsArray}
          multi
        />
      </Box>
    </Grid>
  );
}

function ClinicDefaultsForm(props: {
  initialValues: InitialValues;
  setFieldValue: (field: string, value: number | number[]) => void;
}) {
  const { initialValues, setFieldValue } = props;
  const { bpDiastolic, bpSystolic, pulse, glucose, bloodOxygen } = initialValues;

  const setToGlobalDefaults = () => {
    setFieldValue('bpSystolic', globalThresholdDefaults.bpSystolic);
    setFieldValue('bpDiastolic', globalThresholdDefaults.bpDiastolic);
    setFieldValue('pulse', globalThresholdDefaults.pulse);
    setFieldValue('glucose', globalThresholdDefaults.glucose);
    setFieldValue('weightChange24h', globalThresholdDefaults.weightChangeGeneric[0]);
    setFieldValue('weightChange7days', globalThresholdDefaults.weightChangeGeneric[1]);
  };

  return (
    <Box>
      <Box mt="xl">
        <Flex justifyContent="space-between" align="center">
          <Heading variant="h5">Account threshold settings</Heading>
          <Button
            bg="transparent"
            leftIcon="refresh"
            variant="secondaryLight"
            onClick={setToGlobalDefaults}
          >
            Set to system defaults
          </Button>
        </Flex>
      </Box>
      <Grid templateColumns="repeat(3, 1fr)" gap="2xl" mt="2xl" mb="2xl">
        <Box>
          <FormRangeSlider
            label={ThresholdFields.bpSystolic}
            ariaLabel={['Blood Pressure Systolic Minimun', 'Blood Pressure Systolic Maximum']}
            name="bpSystolic"
            max={200}
            min={80}
            defaultValue={bpSystolic}
          />
          <FormRangeSlider
            label={ThresholdFields.bpDiastolic}
            ariaLabel={['Blood Pressure (Diastolic) Minimun', 'Blood Pressure (Diastolic) Maximum']}
            name="bpDiastolic"
            max={160}
            min={40}
            defaultValue={bpDiastolic}
          />
          <FormRangeSlider
            label={ThresholdFields.bloodOxygen}
            ariaLabel={['Blood Oxygen Minimun', 'Blood Oxygen Maximum']}
            name="bloodOxygen"
            max={100}
            min={75}
            defaultValue={bloodOxygen}
          />
        </Box>
        <Box>
          <FormRangeSlider
            label={ThresholdFields.pulse}
            ariaLabel={['Pulse minimum', 'Pulse maximum']}
            name="pulse"
            max={160}
            min={0}
            defaultValue={pulse}
          />
          <FormRangeSlider
            label={ThresholdFields.glucose}
            ariaLabel={['Glucose Minimun', 'Glucose Maximum']}
            name="glucose"
            max={600}
            min={0}
            defaultValue={glucose}
          />
        </Box>
        <Box>
          <FormSlider
            label={ThresholdFields.weightChange24h}
            ariaLabel="Weight Change 24 Hours"
            name="weightChange24h"
            defaultValue={3}
            max={5}
            min={1}
            unit="lb"
          />
          <FormSlider
            label={ThresholdFields.weightChange7days}
            ariaLabel="Weight Change 7 Days"
            name="weightChange7days"
            defaultValue={3}
            max={10}
            min={3}
            unit="lb"
          />
        </Box>
      </Grid>
      <Box mt="2xl" mb="4xl">
        <Heading variant="h6">Global Threshold Defaults</Heading>
        <Grid templateColumns="repeat(3, 1fr)" gap="2xl" mt="2xl">
          {Object.entries(globalThresholdDefaults).map((entry) => {
            const [field, value] = entry;
            return (
              <Box key={field}>
                <FormLabel>{ThresholdFields[field as keyof typeof ThresholdFields]}</FormLabel>
                <Box
                  bg="neutral.200"
                  px="12px"
                  py="8px"
                  borderWidth="1px"
                  border="neutral.600"
                  borderRadius="4px"
                >
                  <Text color="neutral.600">{`${value[0]} - ${value[1]}`}</Text>
                </Box>
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

function DocumentUploadForm(props: { setFieldValue: (field: string, value: File) => void }) {
  return (
    <Box mt="4xl" mb="3xl">
      <FileUploadField
        setFieldValue={props.setFieldValue}
        name="documentUpload"
        fileName="documentFile"
        label="Document Upload"
      />
    </Box>
  );
}

export function CreateClinic() {
  const { id: clinicId } = useParams<ParamsTypes>();
  const history = useHistory();
  const { data } = useSingleOrganization({
    id: clinicId,
  });

  const notifySuccess = (name: string) =>
    toast.success(`${name} has been successfully ${clinicId ? 'update' : 'created'}.`);

  const notifyError = (name: string) =>
    toast.error(`There was an error while ${clinicId ? 'updating' : 'creating'} ${name} account.`);

  const saveClinic = async (data: InitialValues) => {
    const thresholdValues: CreateDeviceThresholdDto[] = [];

    const bloodPressureDiastolic = {
      threshHoldUpperLimit: data.bpDiastolic[1],
      threshHoldLowerLimit: data.bpDiastolic[0],
      metricType: DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const bloodPressureSystolic = {
      threshHoldUpperLimit: data.bpSystolic[1],
      threshHoldLowerLimit: data.bpSystolic[0],
      metricType: DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const glucose = {
      threshHoldUpperLimit: data.glucose[1],
      threshHoldLowerLimit: data.glucose[0],
      metricType: DeviceThreshold.metricType.BLOOD_SUGAR,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const weightChange = {
      threshHoldUpperLimit: data.weightChange24h,
      threshHoldLowerLimit: data.weightChange24h,
      unit: data.weightChange24h,
      metricType: DeviceThreshold.metricType.WEIGHT,
      sign: DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN,
    } as CreateDeviceThresholdDto;

    const weightChange7days = {
      threshHoldUpperLimit: data.weightChange7days,
      threshHoldLowerLimit: data.weightChange7days,
      unit: data.weightChange7days,
      metricType: DeviceThreshold.metricType.SEVEN_DAYS_CHANGE,
      sign: DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN,
    } as CreateDeviceThresholdDto;

    const pulse = {
      threshHoldUpperLimit: data.pulse[1],
      threshHoldLowerLimit: data.pulse[0],
      metricType: DeviceThreshold.metricType.PULSE,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const bloodOxygen = {
      threshHoldUpperLimit: data.bloodOxygen[1],
      threshHoldLowerLimit: data.bloodOxygen[0],
      metricType: DeviceThreshold.metricType.BLOOD_OXYGEN,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    thresholdValues.push(
      bloodPressureDiastolic,
      bloodPressureSystolic,
      glucose,
      weightChange,
      pulse,
      weightChange7days,
      bloodOxygen
    );

    const contacts: any[] = [];
    /**Contacts */
    if (data.phone2) {
      contacts.push({
        isAlternate: true,
        name: ``,
        contact: stripCharacters(data.phone2),
        isPreferred: false,
        type: Contact.type.PHONE,
      } as PatientContact);
    }

    if (data.phone) {
      contacts.push({
        isAlternate: true,
        name: ``,
        contact: stripCharacters(data.phone),
        isPreferred: true,
        type: Contact.type.PHONE,
      } as PatientContact);
    }

    if (data.fax) {
      contacts.push({
        name: ``,
        isPreferred: false,
        contact: stripCharacters(data.fax),
        type: Contact.type.FAX,
      } as PatientContact);
    }

    const address = {
      line1: data?.addressLine1,
      line2: data?.addressLine2,
      city: data?.city,
      country: data?.country,
      postalCode: data?.zipcode,
      state: data?.state,
    } as Address;

    try {
      if (clinicId) {
        const account = await Service.organizationControllerUpdate(clinicId, {
          name: data?.accountName,
          type: CreateOrganizationDto.type.CLINIC,
          isActive: true,
          threshold: thresholdValues,
          notes: [],
          parent: data?.account,
          contacts,
          address,
        });
        notifySuccess(data?.accountName);
        history.push(`/settings/clinic/${account.id}`);
        return;
      }

      const account = await Service.organizationControllerCreate({
        name: data?.accountName,
        type: CreateOrganizationDto.type.CLINIC,
        isActive: true,
        threshold: thresholdValues,
        notes: [],
        parentId: data?.account,
        contacts,
        address,
      } as any);

      notifySuccess(data?.accountName);
      history.push(`/settings/clinic/${account.id}`);

      /** Reset form and should show toast */
    } catch (err) {
      console.log(err);
      notifyError(data?.accountName);
      throw new Error(err as string);
    }
  };

  const dataThreshold = deriveThresholds();

  const { mutate, isLoading } = useMutation(saveClinic, {
    onSuccess: (data) => {
      console.log(data, 'Success Uploaded');
    },
    onError: () => {
      /**
       * Need to find how to pass data here to display toast and name
       */
      //alert('there was an error');
    },
    onSettled: () => {
      console.log('Test');
    },
  });

  const handleSubmit = async (values: InitialValues): Promise<void> => {
    mutate(values);

    /**
     * Figure out when to clear the form
     */
    // if (!error) resetForm && resetForm();
  };

  const handleValidate = (values: FormikValues): Errors => {
    const errors: Errors = {};
    const optionalFields = [
      'phone2',
      'phone3',
      'fax',
      'addressLine2',
      'date',
      'notes',
      'emrPlatform',
      'devices',
      'preferredLanguage',
    ];
    Object.entries(values).map((entry) => {
      const [field, value] = entry;
      if (!value) {
        if (optionalFields.includes(field)) return;
        return (errors[field] = 'This field is required');
      }
      // if (field.includes('phone') || field.includes('fax')) {
      //   const isValid = phoneRegex.test(value);

      //   if (isValid) return;
      //   return (errors[field] = 'Please enter a valid phone number');
      // }
    });

    if (values.accountName) {
      if (values?.accountName?.length < 3 || values?.accountName?.length > 100) {
        errors['accountName'] = 'Clinic Name lenght must be greater than 3 and less than 100.';
      }
    }

    return errors;
  };

  if (!data && clinicId) return null;

  let initialValues: InitialValues = {
    ...accountInformation,
    ...addressInformation,
    ...goLiveInformation,
    ...emrInformation,
    ...dataThreshold,
  };

  if (clinicId && data) {
    const [phone1, phone2, phone3] = data?.contacts?.filter(
      (item: Contact) => item.type == Contact.type.PHONE
    ) as Contact[];

    const [fax] = data?.contacts.filter(
      (item: Contact) => item.type == Contact.type.FAX
    ) as Contact[];

    const accountInformation: AccountInformation = {
      accountName: data?.name || '',
      phone: phone1?.contact || '',
      phone2: phone2?.contact || '',
      phone3: phone3?.contact || '',
      fax: fax?.contact || '',
    };

    const goLiveInformation: GoLiveInformation = {
      date: data?.goLive || '',
      devices: '',
    };

    const addressInformation: AddressInformation = {
      addressLine1: data?.address?.line1 || '',
      addressLine2: data?.address?.line2 || '',
      zipcode: data?.address?.postalCode || '',
      city: data?.address?.city || '',
      state: data?.address?.state || '',
      country: 'United States',
    };

    data?.threshold?.forEach((threshold) => {
      if (
        threshold.metricType == DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC &&
        dataThreshold?.bpDiastolic?.length > 0
      ) {
        dataThreshold.bpDiastolic[0] = threshold.threshHoldLowerLimit;
        dataThreshold.bpDiastolic[1] = threshold.threshHoldUpperLimit;
      }

      if (
        threshold.metricType == DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC &&
        dataThreshold?.bpSystolic?.length > 0
      ) {
        dataThreshold.bpSystolic[0] = threshold.threshHoldLowerLimit;
        dataThreshold.bpSystolic[1] = threshold.threshHoldUpperLimit;
      }

      if (
        threshold.metricType == DeviceThreshold.metricType.PULSE &&
        dataThreshold?.pulse?.length > 0
      ) {
        dataThreshold.pulse[0] = threshold.threshHoldLowerLimit;
        dataThreshold.pulse[1] = threshold.threshHoldUpperLimit;
      }

      if (threshold.metricType == DeviceThreshold.metricType.WEIGHT && dataThreshold) {
        dataThreshold.weightChange24h = threshold.threshHoldLowerLimit;
      }

      if (threshold.metricType == DeviceThreshold.metricType.SEVEN_DAYS_CHANGE && dataThreshold) {
        dataThreshold.weightChange7days = threshold.threshHoldLowerLimit;
      }

      if (
        threshold.metricType == DeviceThreshold.metricType.BLOOD_SUGAR &&
        dataThreshold?.glucose?.length > 0
      ) {
        dataThreshold.glucose[0] = threshold.threshHoldLowerLimit;
        dataThreshold.glucose[1] = threshold.threshHoldUpperLimit;
      }

      if (
        threshold.metricType == DeviceThreshold.metricType.BLOOD_OXYGEN &&
        dataThreshold?.bloodOxygen?.length > 0
      ) {
        dataThreshold.bloodOxygen[0] = threshold.threshHoldLowerLimit;
        dataThreshold.bloodOxygen[1] = threshold.threshHoldUpperLimit;
      }
    });

    initialValues = {
      ...accountInformation,
      ...addressInformation,
      ...goLiveInformation,
      ...emrInformation,
      ...dataThreshold,
    };
  }

  if (clinicId && !data) return null;

  if (!dataThreshold?.pulse) return null;
  return (
    <Box sx={{ position: 'relative' }}>
      <Box bg="white" p="3xl" borderRadius="8px">
        <Flex align="center">
          <IconComponent />
          <Heading variant="h5">Create a Clinic</Heading>
        </Flex>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={(values: FormikValues) => handleValidate(values)}
          validateOnBlur={false}
        >
          {({ setFieldValue, isValid, dirty }: FormikProps<InitialValues>) => (
            <Form id="create-clinic">
              <AccountInfoForm setFieldValue={setFieldValue} />
              <Divider />
              <GoLiveForm />
              <Divider />
              <ClinicDefaultsForm initialValues={initialValues} setFieldValue={setFieldValue} />
              <Divider />
              <DocumentUploadForm setFieldValue={setFieldValue} />
              <Flex p="xl" bg="neutral.black" borderRadius="8px 8px 0 0" justify="end" mt="6xl">
                <Button
                  form="create-clinic"
                  type="submit"
                  id="submit-form"
                  isLoading={isLoading}
                  disabled={clinicId ? (isValid ? false : true) : !(isValid && dirty)}
                >
                  Save &amp; Continue
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
