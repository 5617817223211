/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Device } from './Device';
import type { Patient } from './Patient';

export type Pulse = {
    pulse: number;
    sourceDevice: Pulse.sourceDevice;
    device: Device;
    patient: Patient;
    patientId: string;
    deviceId: string;
    timestamp: string;
    isReviewed: boolean;
    reviewedBy: string;
    reviewedOn: string;
    isOutlier: boolean;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace Pulse {

    export enum sourceDevice {
        PULSE_OX = 'pulseOx',
        BLOOD_PRESSURE = 'blood_pressure',
    }


}

