import { useContext, useEffect, useState } from 'react';
import {
  Tabs,
  Flex,
  Box,
  HStack,
  TabList,
  TabPanels,
  TabPanel,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
} from '@rhythm/components';
import { PatientsFastReviewDataView } from '~src/components/PatientsDataView';
import { PatientLocalStorage, retrievePersistedData } from '~src/utils';

import PatientFiltersFastReview from './PatientFilters/Filters/indexFastReview';
import { FormFilterValues } from './PatientFilters/Filters/index';
import RoleBasedRoutes from '~src/components/RoleBasedRoutes';
import { useFetchPhysiciansByAccount } from '~src/services/clinics';
import '../../components/PatientsDataView/style.css';

import { FastReviewResponse, useFastReviewPatientReadings } from '~src/services/patients';
import { useHistory } from 'react-router-dom';
import { MyContext } from '../createContext';
import { useApplicationSettings } from '~src/services/applicatonSettings';
import { useUserRoleAndEmail } from '~src/hooks';
import { useOktaAuth } from '@okta/okta-react/';

export type FilterParam = {
  clinicianId: string;
  physicianId: string;
  contacted: string;
  daysTransmitted: string;
  minutesAccrued: string;
};

export type FilterKeys =
  | 'clinicianId'
  | 'physicianId'
  | 'contacted'
  | 'daysTransmitted'
  | 'minutesAccrued';

export function FilterIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 7H16M1 1H19M7 13H13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PatientsFastReview() {
  const { push } = useHistory();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const history = useHistory();
  const [fastReviewEnabled, setFastReviewEnabled] = useState<boolean>();
  const { data: applicationSettings } = useApplicationSettings();
  const { userRole } = useUserRoleAndEmail();
  const isInternal = userRole.includes('roles:internal');

  useEffect(() => {
    if (applicationSettings) {
      setFastReviewEnabled(applicationSettings[0].isFastReviewEnabled);
    }
  }, [applicationSettings]);

  if (fastReviewEnabled === false || !isInternal) {
    history.push('/patients');
  }

  const getLocalStorageData = () => {
    const persistedFilter = retrievePersistedData(
      `rpm-filter-${currentTab}`
    ) as PatientLocalStorage;

    if (persistedFilter) return persistedFilter?.search;

    return '';
  };
  const [searchTerm] = useState(getLocalStorageData());
  const [totalPatient, setTotalPatient] = useState(0);
  const [fastReviewCount, setFastReviewCount] = useState(0);
  const [patientsIds, setPatientsIds] = useState([]);
  const [allPatientsIds, setAllPatientsIds] = useState([]);
  const [reviewedCounts, setReviewedCounts] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updatedFilterSearch, setUpdatedFilterSearch] = useState<FormFilterValues>({
    accounts: [],
    clinicians: [],
    physicians: [],
    contacted: [],
    days: [],
    minutes: [],
    onboarding: [],
    status: [],
    group: [],
  });
  const filterLength = Object.values(updatedFilterSearch).reduce(
    (a, b) => a + (Array.isArray(b) ? b.length : 0),
    0
  );
  const { authState } = useOktaAuth();
  const useFetchPhysiciansData = async (formValues: string) => {
    const physicianData = await useFetchPhysiciansByAccount({ ids: formValues }, authState);
    return physicianData;
  };
  const createFastReviewPatientReadings = useFastReviewPatientReadings();
  const { myState, setMyState } = useContext(MyContext);
  const { fastReviewState, setFastReviewState } = useContext(MyContext);
  const [fastReviewDate, setFastReviewDate] = useState('');

  useEffect(() => {
    if (myState) {
      onOpen();
    }
  }, [myState]);

  useEffect(() => {
    if (patientsIds) {
      setAllPatientsIds((prevIds) => [...prevIds, ...patientsIds]);
    }
  }, [patientsIds]);

  const fastReview = async () => {
    setMyState(false);
    const file: FastReviewResponse = await createFastReviewPatientReadings({
      patientIds: allPatientsIds,
      currentTimestamp: fastReviewDate,
    });
    if (file.status === 201) {
      setFastReviewState({
        ...fastReviewState,
        error: false,
        totalReviewed: file.ReviewedRecords,
        notReviewed:
          reviewedCounts - file.ReviewedRecords > 0 ? reviewedCounts - file.ReviewedRecords : 0,
        totalCount: reviewedCounts,
        bulkReview: true,
      });
      push('/patients');
    } else {
      push('/patients');
      setFastReviewState({
        ...fastReviewState,
        error: true,
        totalReviewed: 0,
        notReviewed: 0,
        totalCount: reviewedCounts,
        bulkReview: true,
      });
    }
  };

  const ExitPopUp = (): React.ReactElement => {
    return (
      <Modal
        closeOnOverlayClick={false}
        id="exit-modal"
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalBody>
          <Box>
            <Box h={'58px'} marginBottom={'15px'} textAlign={'center'}>
              <span style={{ fontSize: '25px', textAlign: 'center' }}>
                Are you sure you want to exit the{' '}
                <span style={{ fontWeight: 'bold' }}>review mode?</span>
              </span>
            </Box>
            <Box w={'400px'}>
              <Button
                disabled={reviewedCounts < 1 ? true : false}
                onClick={fastReview}
                sx={{
                  _hover: { background: 'transparent', color: '#1083CB' },
                  _focus: { background: 'transparent', borderColor: '#1083CB', color: '#1083CB' },
                }}
                w={'100%'}
                bg={'transparent'}
                border={'2px solid #1083CB'}
                textColor={'#1083CB'}
                fontSize={'14px'}
              >
                Save {reviewedCounts} Reviewed Transmissions & Exit
              </Button>
              <Button
                onClick={() => {
                  setMyState(false);
                  push('/patients');
                }}
                sx={{
                  _hover: { background: 'transparent', color: '#1083CB' },
                  _focus: { background: 'transparent', borderColor: '#1083CB', color: '#1083CB' },
                }}
                w={'100%'}
                bg={'transparent'}
                border={'2px solid #1083CB'}
                textColor={'#1083CB'}
                fontSize={'14px'}
                marginTop={'15px'}
                marginBottom={'15px'}
              >
                Disregard changes & Exit
              </Button>
              <Button
                onClick={() => {
                  setMyState(false);
                  onClose();
                }}
                w={'100%'}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </Modal>
    );
  };

  const customaccordionConfig = {
    background: '#FFFFFF',
    borderRadius: '8px 8px 0px 0px',
    width: '1475px',
    minW: '100%',
    allowMultiple: true,
  };

  const customDispalyStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  };

  return (
    <Flex pb="20">
      <Box w="90%">
        <Tabs
          onChange={(index: number) => {
            setCurrentTab(index);
          }}
        >
          <HStack justifyContent="space-between" paddingY="10px">
            <Flex alignItems="center">
              <TabList></TabList>
            </Flex>
          </HStack>
          <TabPanels style={{ minWidth: 'max-content' }}>
            <TabPanel py="0">
              {}
              <RoleBasedRoutes>
                <Box>
                  <Accordion {...customaccordionConfig}>
                    <AccordionItem border={'none'} style={{ padding: '8px' }}>
                      <h2 style={customDispalyStyles}>
                        <AccordionButton _hover={{ bg: 'white' }} w={'50'}>
                          <FilterIcon color={`${filterLength > 0 ? ' #1083CB' : '#516880'}`} />{' '}
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            color={filterLength > 0 ? '#1083CB' : '#516880'}
                            marginLeft={'10px'}
                          >
                            Filter <AccordionIcon style={{ fontSize: '1.5rem' }} />
                          </Box>
                        </AccordionButton>
                        <Box>
                          <Box
                            onClick={onOpen}
                            h={'40px'}
                            sx={{
                              _hover: { background: '#EFF2F6', color: '#5F7895' },
                              _focus: {
                                background: '#fff',
                                borderColor: '#E0E5EB',
                                color: '#5F7895',
                              },
                            }}
                            as={Button}
                            background={'#fff'}
                            color={'#5F7895'}
                            borderRadius={'8px'}
                            borderColor={'#E0E5EB'}
                            marginEnd={'5px'}
                            leftIcon={'close'}
                          >
                            &nbsp; Exit Mode
                          </Box>
                          <Box
                            h={'40px'}
                            as={Button}
                            disabled
                            cursor={'pointer'}
                            sx={{ _disabled: { background: '#1083CB', color: '#fff' } }}
                            background={'#1083CB'}
                            color={'#fff'}
                            borderRadius={'8px'}
                            borderColor={'#E0E5EB'}
                          >
                            <svg
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              focusable="false"
                              className="chakra-icon css-vxeva3"
                            >
                              <path
                                d="M1 8s2.545-5.333 7-5.333C12.454 2.667 15 8 15 8s-2.546 5.333-7 5.333C3.545 13.333 1 8 1 8z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M8 10a2 2 0 100-4 2 2 0 000 4z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            &nbsp; {`Review(${fastReviewCount})`}
                          </Box>
                        </Box>
                        <ExitPopUp />
                      </h2>
                      <AccordionPanel
                        borderTop="2px"
                        paddingTop={'10px'}
                        paddingBottom={'5px'}
                        borderColor={'#eff2f6'}
                      >
                        <PatientFiltersFastReview
                          currentTab={currentTab}
                          setUpdatedFilterSearch={setUpdatedFilterSearch}
                          fetchPhysiciansData={useFetchPhysiciansData}
                          totalPatients={totalPatient}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </RoleBasedRoutes>
              {currentTab === 0 ? (
                <PatientsFastReviewDataView
                  updatedFilterSearch={updatedFilterSearch}
                  search={searchTerm}
                  active={true}
                  currentTab={currentTab}
                  setTotalPatients={setTotalPatient}
                  setFastReviewCount={setFastReviewCount}
                  setPatientsIds={setPatientsIds}
                  setReviewedCounts={setReviewedCounts}
                  setFastReviewDate={setFastReviewDate}
                />
              ) : null}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
}
