import toast from 'react-hot-toast';
import { useMutation, UseMutationResult, useQueryClient, UseQueryResult } from 'react-query';
import { ApplicationSettings, DefaultService as Service } from '~generated';
import { getErrorMessage } from '~src/helpers/errorMessages';
import { ResponseError } from './patients';
import { useFetch } from './query';

interface ApplicationSettingsResponse {
  0: ApplicationSettings;
}

export function useApplicationSettings(): UseQueryResult<ApplicationSettingsResponse> {
  return useFetch<ApplicationSettingsResponse>({
    key: ['applicationSettings'],
    path: `/application-settings`,
    queryOptions: {
      refetchInterval: 500000,
      refetchOnWindowFocus: false,
    },
  });
}

const notifySuccess = () => toast.success('The Application Settings have been updated.');

const notifyError = (status: number | undefined) => {
  const errorMessage = getErrorMessage(status);
  toast.error(errorMessage);
};

export function useUpdateApplicationSettings(): UseMutationResult<
  ApplicationSettings,
  unknown,
  ApplicationSettings
> {
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      return Service.applicationSettingsControllerUpdate(payload.id, payload);
    },
    {
      onSuccess: (data) => {
        console.log(data);
        notifySuccess();
        queryClient.invalidateQueries('applicationSettings');
      },
      onError: (error: ResponseError) => {
        notifyError(error.status);
      },
    }
  );
}
