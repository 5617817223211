import { useOktaAuth } from '@okta/okta-react';
import { globalConfig } from '../configuration/config';
import qs from 'query-string';

export function useBillingReport(): (from?: Date, to?: Date) => Promise<string> {
  const { authState } = useOktaAuth();
  const config = globalConfig.get();
  return async (from?: Date, to?: Date) => {
    const queryParams = qs.stringify({
      from: from?.toISOString?.(),
      to: to?.toISOString?.(),
    });
    const response = await fetch(`${config.apiUrl}/billing/report.csv?${queryParams}`, {
      headers: {
        Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to download billing report');
    }
    const blob = await response.blob();
    return window.URL.createObjectURL(blob);
  };
}

export function useBillingReportByOrganization(): (
  orgId: string,
  from?: Date,
  to?: Date
) => Promise<string> {
  const { authState } = useOktaAuth();

  return async (orgId: string, from?: Date, to?: Date) => {
    const config = globalConfig.get();
    const queryParams = qs.stringify({
      from: from?.toISOString?.(),
      to: to?.toISOString?.(),
    });
    const response = await fetch(`${config.apiUrl}/billing/${orgId}/report.csv?${queryParams}`, {
      headers: {
        Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to download billing report');
    }
    const blob = await response.blob();
    return window.URL.createObjectURL(blob);
  };
}
