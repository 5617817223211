import { makeOptionsArray } from '~src/utils';
import type { Option } from '~src/utils';

export const icd10CodesHeartFailure = {
  'I50.1': 'I50.1 - Left ventricular failure, unspecified',
  'I50.2': 'I50.2 - Systolic (congestive) heart failure',
  'I50.20': 'I50.20 - Unspecified systolic (congestive) heart failure',
  'I50.21': 'I50.21 - Acute systolic (congestive) heart failure',
  'I50.22': 'I50.22 - Chronic systolic (congestive) heart failure',
  'I50.23': 'I50.23 - Acute on chronic systolic (congestive) heart failure',
  'I50.3': 'I50.3 - Diastolic (congestive) heart failure',
  'I50.30': 'I50.30 - Unspecified diastolic (congestive) heart failure',
  'I50.31': 'I50.31 - Acute diastolic (congestive) heart failure',
  'I50.32': 'I50.32 - Chronic diastolic (congestive) heart failure',
  'I50.33': 'I50.33 - Acute on chronic diastolic (congestive) heart failure',
  'I50.4': 'I50.4 - Combined systolic (congestive) and diastolic (congestive) heart failure',
  'I50.40':
    'I50.40 - Unspecified combined systolic (congestive) and diastolic (congestive) heart failure',
  'I50.41':
    'I50.41 - Acute combined systolic (congestive) and diastolic (congestive) heart failure',
  'I50.42':
    'I50.42 - Chronic combined systolic (congestive) and diastolic (congestive) heart failure',
  'I50.43':
    'I50.43 - Acute on chronic combined systolic (congestive) and diastolic (congestive) heart failure',
  'I50.8': 'I50.8 - Other heart failure',
  'I50.81': 'I50.81 - Right heart failure',
  'I50.810': 'I50.810 - Right heart failure unspecified',
  'I50.811': 'I50.811 - Acute right heart failure',
  'I50.812': 'I50.812 - Chronic right heart failure',
  'I50.813': 'I50.813 - Acute on chronic right heart failure',
  'I50.814': 'I50.814 - Right heart failure due to Left heart failure',
  'I50.82': 'I50.82 - Biventricular heart failure',
  'I50.83': 'I50.83 - High output heart failure',
  'I50.84': 'I50.84 - End stage heart failure',
  'I50.89': 'I50.89 - Other heart failure',
  'I50.9': 'I50.9 - Heart failure, unspecified',
};

export const icd10CodesHeartFailureOptionsArray: Option[] =
  makeOptionsArray(icd10CodesHeartFailure);
