import dayjs from 'dayjs';

import { useMemo, Fragment } from 'react';

import { useParams } from 'react-router';
import { NoteCard } from './NoteCard';
import {
  Box,
  Flex,
  Grid,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@rhythm/components';
import { Surface } from '../Surface';
import { AddNote } from './AddNote';
import { useListNotesByPatient } from '~src/services/notes';
import { ExportData } from './ExportData';
import { Device, Patient } from '~generated';

type NotesProp = {
  data?: Patient;
  devices?: Device[];
};

export function Notes({ data, devices }: NotesProp) {
  const { id: patientId } = useParams<{ id: string }>();
  const { data: notes, isLoading } = useListNotesByPatient({
    id: patientId,
  });

  const processedNotes = useMemo(() => {
    return notes?.map((item) => {
      const newDate = item.convertedtime.slice(0, -1);
      const date = dayjs(newDate).format('MM/DD/YY h:mm A');
      return { ...item, newConvertedtime: date };
    });
  }, [notes]);

  const fastReviewNotes = useMemo(() => {
    return processedNotes?.filter((note) => Array.isArray(note.notes));
  }, [processedNotes]);

  const standardNotes = useMemo(() => {
    return processedNotes?.filter((note) => typeof note.notes === 'string');
  }, [processedNotes]);

  return (
    <Box id="notes-section">
      <AddNote data={data} />
      <Surface>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading variant="h5">Notes</Heading>
          <ExportData devices={devices} />
        </Flex>
        <Tabs variant="soft-rounded" mt={'10px'}>
          <Flex>
            <TabList>
              <span style={{ marginTop: '10px', marginRight: '10px', color: '#213147' }}>
                Show:
              </span>
              <Tab
                _selected={{ color: 'white', bg: '#6C7789' }}
                _focus={{ color: 'white', bg: 'white' }}
                backgroundColor={'#EFF2F6'}
                color={'#6C7789'}
                borderRadius={'4px'}
                h={'16px'}
                p={'20px'}
              >
                All ({notes?.length})
              </Tab>
              <Tab
                _selected={{ color: 'white', bg: '#6C7789' }}
                _focus={{ color: 'white', bg: 'white' }}
                backgroundColor={'#EFF2F6'}
                color={'#6C7789'}
                borderRadius={'4px'}
                h={'16px'}
                p={'20px'}
              >
                Standard ({standardNotes?.length})
              </Tab>
              <Tab
                _selected={{ color: 'white', bg: '#6C7789' }}
                _focus={{ color: 'white', bg: 'white' }}
                backgroundColor={'#EFF2F6'}
                color={'#6C7789'}
                borderRadius={'4px'}
                h={'16px'}
                p={'20px'}
              >
                Fast Review ({fastReviewNotes?.length})
              </Tab>
            </TabList>
          </Flex>
          <Grid
            templateColumns="1fr 1fr 1fr 2fr 1fr"
            mb="lg"
            borderBottomWidth="1px"
            borderBottomColor="neutral.200"
            py="lg"
            mt={'18px'}
          >
            <Text variant="smallCaps" color="neutral.800">
              Review By / Date
            </Text>
            <Text variant="smallCaps" color="neutral.800" marginLeft={'8px'}>
              Minutes
            </Text>
            <Text variant="smallCaps" color="neutral.800" w={'150%'}>
              Notes/transmission date
            </Text>
          </Grid>
          <TabPanels>
            <TabPanel pt={'0px'}>
              <Box
                maxHeight="416px"
                overflowY="scroll"
                overflowX="hidden"
                my="xl"
                width="100%"
                position="relative"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '6px',
                    height: '96px',
                    left: 'calc(50% - 6px/2)',
                    top: '8px',
                    background: '#6C7789',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#FFFFFF',
                    opacity: 0.5,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    background: '#6C7789',
                  },
                }}
              >
                {processedNotes?.map((note) => (
                  <Fragment key={note.id}>
                    <NoteCard
                      note={note}
                      timezone={data?.address?.timeZone}
                      isLoading={isLoading}
                    ></NoteCard>
                  </Fragment>
                ))}
              </Box>
            </TabPanel>
            <TabPanel pt={'0px'}>
              <Box
                maxHeight="416px"
                overflowY="scroll"
                overflowX="hidden"
                my="xl"
                width="100%"
                position="relative"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '6px',
                    height: '96px',
                    left: 'calc(50% - 6px/2)',
                    top: '8px',
                    background: '#6C7789',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#FFFFFF',
                    opacity: 0.5,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    background: '#6C7789',
                  },
                }}
              >
                {standardNotes?.map((note) => (
                  <NoteCard
                    note={note}
                    timezone={data?.address?.timeZone}
                    isLoading={isLoading}
                    key={`standard-${note.id}`}
                  ></NoteCard>
                ))}
              </Box>
            </TabPanel>
            <TabPanel pt={'0px'}>
              <Box
                maxHeight="416px"
                overflowY="scroll"
                overflowX="hidden"
                my="xl"
                width="100%"
                position="relative"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '6px',
                    height: '96px',
                    left: 'calc(50% - 6px/2)',
                    top: '8px',
                    background: '#6C7789',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#FFFFFF',
                    opacity: 0.5,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: '100px',
                    background: '#6C7789',
                  },
                }}
              >
                {fastReviewNotes?.map((note) => (
                  <NoteCard
                    note={note}
                    timezone={data?.address?.timeZone}
                    isLoading={isLoading}
                    key={`fastReview-${note.id}`}
                  ></NoteCard>
                ))}
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Surface>
    </Box>
  );
}
