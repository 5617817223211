import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@rhythm/components';
import dayjs from 'dayjs';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { Cell } from 'react-table';
import { BloodPressure, BloodSugar, DeviceThreshold, PulseOx, Pulse, Weight } from '~generated';
import { FormCalendar } from '~src/components/FormCalendar';
import { Errors } from '~src/pages/CreateAccount/CreateAccount';
import { PatientProp } from '../PatientProfile';
import {
  BloodPressureDataTable,
  BloodPressurePulse,
  BloodPressureSugar,
  BloodPressureSysDia,
  BloodPressureWeight,
  BloodPulseDataTable,
  BloodSugarDataTable,
  WeightDataTable,
  BloodOxygen,
  BloodOxygenDataTable,
} from './DataTabs';
import { useThresholdReading } from '~src/services/patients';
import RoleBasedRoutes from '~src/components/RoleBasedRoutes';
import { editPatientRoles } from '~src/constants/roles';
import { DateFilters } from '~src/components/DateFilters/DateFilter';
import { getTimeZoneShortCode } from '~src/utils';
import { HorizontalScroll } from '~src/components/Carousel';
import '../PatientOverview/DataTabs/style.css';
import { useApplicationSettings } from '~src/services/applicatonSettings';

export interface Row {
  label: string;
  value: string;
}

export interface PULSE {
  pulse: number;
  outlier?: boolean;
  timestamp?: string;
  newTimestamp?: string;
  newDate?: string;
}

export interface WEIGHT {
  weight: number;
  weightChange?: number;
  outlier?: boolean;
  timestamp?: string;
  newTimestamp?: string;
  newDate?: string;
}

export interface PatientContainerType {
  title: string;
  icon: React.ReactElement | null;
  children: JSX.Element;
  handleEdit?: () => void;
  isActive?: boolean;
}

export interface BloodPressureData extends BloodPressure {
  outlier?: boolean;
  pulseOutlier?: boolean;
  pulse: any;
  newTimestamp?: string;
  newDate?: string;
}
const bpKey = 'BLOOD_PRESSURE';
const bsKey = 'BLOOD_SUGAR';
const pulseKey = 'PULSE';
const weightKey = 'WEIGHT';
const boKey = 'BLOOD_OXYGEN';

const tabs = [bpKey, pulseKey, weightKey, bsKey, boKey];

type InitialValues = {
  startDate: Date;
  endDate: Date;
};

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

type DateRangeProps = {
  dateRange: DateRange;
  initialValues: DateRange;
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
    }>
  >;
};

type WeightChange = {
  unit: string;
  outlier: boolean;
};

export type DataReading = {
  bloodPressure: BloodPressure[];
  bloodSugar: BloodSugar[];
  weight: Weight[];
  pulse: Pulse[];
  weightChange: WeightChange[];
  pulseOx: PulseOx[];
  bloodOxygen: PulseOx[];
};

export interface DateCellProps {
  timestamp: string;
  timeZone: string;
}

export interface ShortDateCellProps {
  timestamp: string;
}

export interface SystolicDiastolicCellProps {
  systolic?: number | undefined;
  diastolic?: number | undefined;
  outlier?: boolean;
  diastolicoutlier?: boolean;
}

export interface SystolicCellProps {
  systolic?: number | undefined;
  outlier?: boolean;
}

export interface DiastolicCellProps {
  diastolic?: number | undefined;
  outlier?: boolean;
}

export interface PulseCellProps {
  pulse?: number | undefined;
  outlier?: boolean;
}
export interface BpPulseCellProps {
  pulse?: number | undefined;
  pulseOutlier?: boolean;
}

export interface PulseCellPulseOxProps {
  pulse?: number | undefined;
  pulseOutlier?: boolean;
}

export interface PulseOxProps {
  pulseox?: number | undefined;
  outlier?: boolean;
}

export interface SugarCellProps {
  reading?: number | undefined;
  outlier?: boolean;
}

export type CC<T> = Cell<BloodPressureData, T>;

export type WW<T> = Cell<WEIGHT, T>;

export const DateCell = ({ timestamp, timeZone }: DateCellProps) => (
  <Text>
    {timestamp} {getTimeZoneShortCode(timeZone)}
  </Text>
);
export const DateCellBp = ({ timestamp, timeZone }: DateCellProps) => (
  <Text fontSize="10px">
    {dayjs(timestamp).format('ddd, MMM DD YYYY hh:mm A')} {getTimeZoneShortCode(timeZone)}
  </Text>
);

export const ShortDateCell = ({ timestamp }: ShortDateCellProps) => <Text>{timestamp}</Text>;

export const SystolicDiastolicCell = ({
  systolic,
  diastolic,
  outlier,
  diastolicoutlier,
}: SystolicDiastolicCellProps) => {
  return (
    <>
      <Text>
        <span style={{ color: outlier ? 'red' : '' }}>{systolic}</span>
        <span>&nbsp;/&nbsp;</span>
        <span style={{ color: diastolicoutlier ? 'red' : '' }}>{diastolic}</span>
      </Text>
    </>
  );
};

export const SystolicCell = ({ systolic, outlier }: SystolicCellProps) => {
  return <Text style={{ color: outlier ? 'red' : '' }}> {systolic} </Text>;
};

export const DiastolicCell = ({ diastolic, outlier }: DiastolicCellProps) => (
  <Text style={{ color: outlier ? 'red' : '' }}> {diastolic} </Text>
);

export const PulseCell = ({ pulse, outlier }: PulseCellProps) => (
  <Text style={{ color: outlier ? 'red' : '' }}> {pulse} </Text>
);
export const BpPulseCell = ({ pulse, pulseOutlier }: BpPulseCellProps) => (
  <Text style={{ color: pulseOutlier ? 'red' : '' }}> {pulse} </Text>
);

export const PulseCellPulseOx = ({ pulse, pulseOutlier }: PulseCellPulseOxProps) => (
  <Text style={{ color: pulseOutlier ? 'red' : '' }}> {pulse} </Text>
);

export const BloodOxygenCell = ({ pulseox, outlier }: PulseOxProps) => (
  <Box display={'flex'} flexDirection={'row'}>
    <Text style={{ color: outlier ? 'red' : '' }}> {pulseox}</Text>
    <Text>%</Text>
  </Box>
);

export const WeightCell = ({ weight, outlier }: WEIGHT) => (
  <Text style={{ color: outlier ? 'red' : '' }}> {weight} </Text>
);

export const BloodSugarCell = ({ reading, outlier }: SugarCellProps) => (
  <Text style={{ color: outlier ? 'red' : '' }}> {reading} </Text>
);

export const WeightChange = ({ weightChange, outlier }: WEIGHT) => (
  <Text style={{ color: outlier ? 'red' : '' }}> {weightChange} </Text>
);

export function PatientContainer({
  title,
  icon,
  children,
  handleEdit,
  isActive,
}: PatientContainerType) {
  const [view, setView] = useState<'none' | 'block'>('block');

  const toggleAccordion = (): void => {
    if (title) {
      setView(view === 'block' ? 'none' : 'block');
    }
  };

  return (
    <Grid mb="4" onClick={toggleAccordion} cursor="pointer" bg="white" pb="3xl" borderRadius="8px">
      <Flex direction="row">
        <Box alignItems="center" display="flex">
          {icon}
          <Text fontSize="18" ml="6" fontWeight="bold">
            {title}
          </Text>
        </Box>
        <Spacer />
        {title && (
          <Box>
            {isActive && (
              <RoleBasedRoutes allowedRoles={editPatientRoles}>
                <Icon
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleEdit && handleEdit();
                  }}
                  cursor="pointer"
                  boxSize="4"
                  mr="7"
                  color="#6C7789"
                  icon="edit"
                />
              </RoleBasedRoutes>
            )}
            {view === 'block' ? (
              <Icon
                cursor="pointer"
                boxSize="4"
                onClick={() => setView('none')}
                color="#6C7789"
                icon="chevron-up"
              />
            ) : (
              <Icon
                boxSize="4"
                cursor="pointer"
                onClick={() => setView('block')}
                color="#6C7789"
                icon="chevron-down"
              />
            )}
          </Box>
        )}
      </Flex>
      <Grid display={view}>{children}</Grid>
    </Grid>
  );
}

export function DateRange(props: DateRangeProps) {
  const handleSubmit = async (values: InitialValues): Promise<void> => {
    props.setDateRange({
      startDate: values?.startDate,
      endDate: values?.endDate,
    });
  };

  const handleValidate = (values: FormikValues): Errors => {
    const errors: Errors = {};

    props.setDateRange({
      startDate: values?.startDate,
      endDate: values?.endDate,
    });

    return errors;
  };

  const setWeekBefore = (propsFormik: FormikProps<InitialValues>) => {
    const startDate = dayjs(props?.dateRange?.startDate)
      .subtract(1, 'week')
      .toDate();
    const endDate = dayjs(props?.dateRange?.endDate)
      .subtract(1, 'week')
      .toDate();
    props.setDateRange({
      startDate,
      endDate,
    });

    propsFormik.setFieldValue('startDate', startDate);
    propsFormik.setFieldValue('endDate', endDate);
  };

  const setWeekAfter = (propsFormik: FormikProps<InitialValues>) => {
    const startDate = dayjs(props?.dateRange?.startDate)
      .add(1, 'week')
      .toDate();
    const endDate = dayjs(props?.dateRange?.endDate)
      .add(1, 'week')
      .toDate();

    props.setDateRange({
      startDate,
      endDate,
    });

    propsFormik.setFieldValue('startDate', startDate);
    propsFormik.setFieldValue('endDate', endDate);
  };

  const maxDate = dayjs().add(1, 'day');

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={props.initialValues}
      validate={(values: FormikValues) => handleValidate(values)}
      validateOnBlur={false}
    >
      {(props: FormikProps<InitialValues>) => (
        <Form id="create-range">
          <Flex display="flex" justifyContent="center" alignItems="center">
            <Flex>
              <Button
                size="sm"
                mr="2"
                variant="secondaryLight"
                onClick={() => {
                  setWeekBefore(props);
                }}
                leftIcon="arrow-left"
              >
                Prev Week
              </Button>
              <Button
                size="sm"
                mr="2"
                disabled={dayjs(props.values?.endDate).diff(maxDate) >= 0 ? true : false}
                variant="secondaryLight"
                onClick={() => {
                  setWeekAfter(props);
                }}
                leftIcon="arrow-right"
              >
                Next Week
              </Button>
            </Flex>

            <FormCalendar mb="" label="" name="startDate" placeholder="Select a Date" />
            <Icon icon="arrow-right" color="#6C7789" ml="2" mr="2" />
            <FormCalendar
              mb=""
              minDate={props.values.startDate}
              label=""
              name="endDate"
              placeholder="Select an end Date"
            />
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export function DataReading({ patient }: PatientProp) {
  const [hasTabs, setHasTabs] = useState(false);
  const [visibleTab, setVisibleTab] = useState(0);
  const [systolicValue, setSystolicValue] = useState(0);
  const [diastolicValue, setDiastolicValue] = useState(0);
  const [pulseValue, setPulseValue] = useState(0);
  const [pulseOxPulseValue, setPulseOxPulseValue] = useState(0);
  const [systolicOutlier, setSystolicOutlier] = useState(false);
  const [diastolicOutlier, setDiastolicOutlier] = useState(false);
  const [bpSystolicValue, setBpSystolicValue] = useState({ bpSysValue: 0, timeStamp: '' });
  const [bpDiastoicValue, setBpDiastolicValue] = useState({ bpDiastoicValue: 0, timeStamp: '' });
  const [bpPulseValue, setBpPulseValue] = useState({ bpPulseData: 0, timeStamp: '' });
  const [weightTotalValue, setWeightTotalValue] = useState({ weightValue: 0, timeStamp: '' });
  const [bloodSugarTotalValue, setBloodSugarTotalValue] = useState({
    bloodSugarData: 0,
    timeStamp: '',
  });
  const [bloodOxygenTotalValue, setBloodOxygenTotalValue] = useState({
    bloodOxygen: 0,
    timeStamp: '',
  });
  const [pulseOutlier, setPulseOutlier] = useState(false);
  const [weightOutlier, setWeightOutlier] = useState(false);
  const [bloodSugarOutlier, setBloodSugarOutlier] = useState(false);
  const [bloodOxygenOutlier, setBloodOxygenOutlier] = useState(false);
  const [pulseOxPulseOutlier, setPulseOxPulseOutlier] = useState(false);
  const [weightValue, setWeightValue] = useState(0);
  const [bloodSugarValue, setBloodSugarValue] = useState(0);
  const [bloodOxygenValue, setBloodOxygenValue] = useState(0);
  const [titleColor, setTitleColor] = useState(true);
  const [titleColorPulse, setTitleColorPulse] = useState(true);
  const [titleColorWeight, setTitleColorWeight] = useState(true);
  const [titleColorGlucose, setTitleColorGlucose] = useState(true);
  const [titleColorOxygen, setTitleColorOxygen] = useState(true);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: dayjs().subtract(4, 'week').toDate(),
    endDate: dayjs().add(1, 'day').toDate(),
  });
  const [totalDeviceTabCount, setTotalDeviceTabCount] = useState(0);

  const [isTableView] = useState(true);
  // const [historyLoading, setHistoryLoading] = useState(false);
  const initialValues: DateRange = {
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  };
  const [tableIndexesMap, setTableIndexesMap] = useState(new Map<number, number>());
  const [existingTabsMap, setExistingTabsMap] = useState(new Map<string, number>());

  useEffect(() => {
    const { bloodPressure, bloodSugar, weight, pulseOx } = patient;

    //Filtering tabs based on patient data, creating map where key is tab key and value is tab index
    const existingTabs = new Map(
      tabs
        .filter((tab) => {
          if ((tab === 'BLOOD_PRESSURE' || tab == 'PULSE') && bloodPressure?.length) {
            return true;
          } else if (tab === 'BLOOD_SUGAR' && bloodSugar?.length) {
            return true;
          } else if (tab === 'WEIGHT' && weight?.length) {
            return true;
          } else if (tab === 'BLOOD_OXYGEN' && pulseOx?.length) {
            return true;
          }
          // return false;
          return true;
        })
        .map((tab, index) => {
          return [tab, index];
        })
    );

    setExistingTabsMap(existingTabs);

    //Getting indexes of the tab (0 for BP, 1 for Pulse, 2 for Weight, 3 for Blood Sugar)
    const indexValues = Array.from(existingTabs.values());
    //Getting table indexes map to have mapping for table toggle as
    // we can have situation where we have Weight and Blood Sugar
    setTableIndexesMap(new Map(indexValues.map((value, index) => [index, value])));
    setVisibleTab(indexValues[0]);
  }, []);

  const timeZone = patient?.address?.timeZone ? patient?.address?.timeZone : '';
  const postalCode = patient?.address?.postalCode ? patient?.address?.postalCode : '';

  const patientThreshold = patient?.deviceThresholds;
  const physicianThreshold = patient?.physician?.threshold;
  const patientData: any = patient;
  const organizationThreshold = patientData?.organization[0].threshold;

  const { data: applicationSettingsData } = useApplicationSettings();
  const isPulseOxEnabled = applicationSettingsData
    ? applicationSettingsData[0].isPulseOxEnabled
    : false;

  let rangeThreshold = {} as DeviceThreshold | undefined;

  let bp_diastolic = {} as DeviceThreshold | undefined;
  let bp_systolic = {} as DeviceThreshold | undefined;
  let sugar_threshold = {} as DeviceThreshold | undefined;
  let oxygen_threshold = {} as DeviceThreshold | undefined;

  let bpDeviceVendor = false; // Boolean to check if TelliHealth Device
  let weightDeviceVendor = false;

  patient?.devices?.forEach((dv) => {
    if (dv.vendor.name === 'tellihealth' && dv.type === 'blood_pressure') {
      bpDeviceVendor = true;
    }
    if (dv.vendor.name === 'tellihealth' && dv.type === 'weight') {
      weightDeviceVendor = true;
    }
  });

  if (patientThreshold?.length) {
    rangeThreshold = patientThreshold?.find(
      (threshold: DeviceThreshold) => threshold.metricType === DeviceThreshold.metricType.PULSE
    );

    patientThreshold.forEach((threshold: DeviceThreshold) => {
      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC) {
        bp_diastolic = threshold;
      }

      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC) {
        bp_systolic = threshold;
      }
      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_SUGAR) {
        sugar_threshold = threshold;
      }
      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_OXYGEN) {
        oxygen_threshold = threshold;
      }
    });
  } else if (physicianThreshold && physicianThreshold.length > 0) {
    rangeThreshold = physicianThreshold?.find(
      (threshold: DeviceThreshold) => threshold.metricType === DeviceThreshold.metricType.PULSE
    );

    physicianThreshold.forEach((threshold: DeviceThreshold) => {
      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC) {
        bp_diastolic = threshold;
      }

      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC) {
        bp_systolic = threshold;
      }

      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_SUGAR) {
        sugar_threshold = threshold;
      }

      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_OXYGEN) {
        oxygen_threshold = threshold;
      }
    });
  } else {
    rangeThreshold = organizationThreshold?.find(
      (threshold: DeviceThreshold) => threshold.metricType === DeviceThreshold.metricType.PULSE
    );

    organizationThreshold.forEach((threshold: DeviceThreshold) => {
      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC) {
        bp_diastolic = threshold;
      }
      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC) {
        bp_systolic = threshold;
      }

      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_SUGAR) {
        sugar_threshold = threshold;
      }

      if (threshold.metricType === DeviceThreshold.metricType.BLOOD_OXYGEN) {
        oxygen_threshold = threshold;
      }
    });
  }

  const onTabChange = async (index: any) => {
    if (tableIndexesMap?.has(index)) {
      const tab = tableIndexesMap?.get(index);
      setVisibleTab(tab!);

      if (index === 0) {
        setTitleColor(false);
      } else {
        setTitleColor(true);
      }

      if (index === 1) {
        setTitleColorPulse(false);
      } else {
        setTitleColorPulse(true);
      }

      if (index === 2) {
        setTitleColorWeight(false);
      } else {
        setTitleColorWeight(true);
      }

      if (index === 3) {
        setTitleColorGlucose(false);
      } else {
        setTitleColorGlucose(true);
      }

      if (index === 4) {
        setTitleColorOxygen(false);
      } else {
        setTitleColorOxygen(true);
      }
    } else {
      setVisibleTab(visibleTab);
    }
  };
  useEffect(() => {
    if (hasTabs == false && existingTabsMap?.size > 0) {
      setHasTabs(true);
      // onTabChange(0);
      if (existingTabsMap?.size === 1) {
        onTabChange(0);
      }
    }
  }, [existingTabsMap]);

  const hasBloodPressureDevice = () => {
    let flag = false;
    patient.devices.forEach((device) => {
      if (device.type === 'blood_pressure') {
        flag = true;
      }
    });
    return flag;
  };
  const hasWeightDevice = () => {
    let flag = false;
    patient.devices.forEach((device) => {
      if (device.type === 'weight') {
        flag = true;
      }
    });
    return flag;
  };
  const hasBloodSugarDevice = () => {
    let flag = false;
    patient.devices.forEach((device) => {
      if (device.type === 'blood_sugar') {
        flag = true;
      }
    });
    return flag;
  };
  const hasBloodOxygenDevice = () => {
    let flag = false;
    patient.devices.forEach((device) => {
      if (device.type === 'blood_oxygen') {
        flag = true;
      }
    });
    return flag;
  };
  useEffect(() => {
    if (patient.devices.length > 0) {
      let bpCount = 0;
      let pulse = 0;
      let weight = 0;
      let bloodSugar = 0;
      let bloodOxygen = 0;
      patient?.devices?.forEach((dv) => {
        if (dv.type === 'blood_pressure') bpCount = bpCount + 1;
        if (dv.type === 'pulse') pulse = pulse + 1;
        if (dv.type === 'weight') weight = weight + 1;
        if (dv.type === 'blood_sugar') bloodSugar = bloodSugar + 1;
        if (dv.type === 'blood_oxygen') bloodOxygen = bloodOxygen + 1;
      });
      if (bpCount === 0 && pulse === 0 && weight === 0 && bloodSugar === 0 && bloodOxygen > 0) {
        setVisibleTab(4);
        setTitleColorOxygen(false);
      }
      if (bpCount === 0 && pulse === 0 && weight === 0 && bloodSugar > 0) {
        setVisibleTab(3);
        setTitleColorGlucose(false);
      }
      if (bpCount === 0 && pulse === 0 && weight > 0) {
        setVisibleTab(2);
        setTitleColorWeight(false);
      }
      if (bpCount === 0 && bloodOxygen > 0) {
        setVisibleTab(1);
        setTitleColorPulse(false);
      }
      if (bpCount === 0 && pulse > 0) {
        setVisibleTab(1);
        setTitleColorPulse(false);
      }
      if (bpCount > 0 && weight > 0 && bloodSugar > 0 && bloodOxygen > 0) {
        setTotalDeviceTabCount(5);
      }
    }
  }, [patient.devices]);

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { data, isLoading } = useThresholdReading(
    { id: patient.id, dateRange, timeZone, postalCode },
    true
  );

  return (
    <PatientContainer title="" icon={null}>
      <Fragment>
        <Tabs
          className="reading-tabs"
          onChange={(index: number) => {
            if (tableIndexesMap?.has(index)) {
              const tab = tableIndexesMap?.get(index);
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              setVisibleTab(tab!);
            } else {
              setVisibleTab(visibleTab);
            }
          }}
        >
          <Flex justifyContent="space-between">
            <HorizontalScroll>
              {hasBloodPressureDevice() ? (
                <Box
                  width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                >
                  <Box background={visibleTab == existingTabsMap?.get(bpKey) ? 'white' : '#F7F9FC'}>
                    <div
                      style={{
                        backgroundColor:
                          visibleTab == existingTabsMap?.get(bpKey) ? '#1083CB' : '#F7F9FC',
                        height: '5px',
                        width: visibleTab == existingTabsMap?.get(bpKey) ? '85%' : '100%',
                        alignSelf: 'center',
                        marginLeft: visibleTab == existingTabsMap?.get(bpKey) ? '8%' : '',
                        borderBottomLeftRadius:
                          visibleTab == existingTabsMap?.get(bpKey) ? '25px' : '',
                        borderBottomRightRadius:
                          visibleTab == existingTabsMap?.get(bpKey) ? '25px' : '',
                      }}
                    ></div>
                  </Box>
                  <Box
                    width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                    paddingTop="20px"
                    paddingLeft="20px"
                    paddingRight="10px"
                    paddingBottom="30px"
                    background={visibleTab == existingTabsMap?.get(bpKey) ? 'white' : '#F7F9FC'}
                    onClick={() => onTabChange(existingTabsMap?.get(bpKey))}
                  >
                    <BloodPressureSysDia
                      patient={patient}
                      systolicValue={systolicValue}
                      diastolicValue={diastolicValue}
                      systolicOutlier={systolicOutlier}
                      diastolicOutlier={diastolicOutlier}
                      bpSystolicValue={bpSystolicValue}
                      bpDiastoicValue={bpDiastoicValue}
                      titleColor={titleColor}
                      deviceVendor={bpDeviceVendor}
                    />
                  </Box>
                </Box>
              ) : null}
              {hasBloodPressureDevice() || hasBloodOxygenDevice() ? (
                <Box
                  width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                >
                  <Box
                    background={visibleTab == existingTabsMap?.get(pulseKey) ? 'white' : '#F7F9FC'}
                  >
                    <div
                      style={{
                        backgroundColor:
                          visibleTab == existingTabsMap?.get(pulseKey) ? '#1083CB' : '#F7F9FC',
                        height: '5px',
                        width: visibleTab == existingTabsMap?.get(pulseKey) ? '85%' : '100%',
                        alignSelf: 'center',
                        marginLeft: visibleTab == existingTabsMap?.get(pulseKey) ? '8%' : '',
                        borderBottomLeftRadius:
                          visibleTab == existingTabsMap?.get(pulseKey) ? '25px' : '',
                        borderBottomRightRadius:
                          visibleTab == existingTabsMap?.get(pulseKey) ? '25px' : '',
                      }}
                    ></div>
                  </Box>

                  <Box
                    width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                    paddingTop="20px"
                    paddingLeft="20px"
                    paddingRight="10px"
                    paddingBottom="30px"
                    background={visibleTab == existingTabsMap?.get(pulseKey) ? 'white' : '#F7F9FC'}
                    onClick={() => onTabChange(existingTabsMap?.get(pulseKey))}
                  >
                    <BloodPressurePulse
                      patient={patient}
                      pulseValue={pulseValue}
                      pulseOutlier={pulseOutlier}
                      bpPulseValue={bpPulseValue}
                      titleColorPulse={titleColorPulse}
                      deviceVendor={bpDeviceVendor}
                    />
                  </Box>
                </Box>
              ) : null}
              {hasWeightDevice() ? (
                <Box
                  width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                >
                  <Box
                    background={visibleTab == existingTabsMap?.get(weightKey) ? 'white' : '#F7F9FC'}
                  >
                    <div
                      style={{
                        backgroundColor:
                          visibleTab == existingTabsMap?.get(weightKey) ? '#1083CB' : '#F7F9FC',
                        height: '5px',
                        width: visibleTab == existingTabsMap?.get(weightKey) ? '85%' : '100%',
                        alignSelf: 'center',
                        marginLeft: visibleTab == existingTabsMap?.get(weightKey) ? '8%' : '',
                        borderBottomLeftRadius:
                          visibleTab == existingTabsMap?.get(weightKey) ? '25px' : '',
                        borderBottomRightRadius:
                          visibleTab == existingTabsMap?.get(weightKey) ? '25px' : '',
                      }}
                    ></div>
                  </Box>

                  <Box
                    width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                    paddingTop="20px"
                    paddingLeft="20px"
                    paddingRight="10px"
                    paddingBottom="30px"
                    background={visibleTab == existingTabsMap?.get(weightKey) ? 'white' : '#F7F9FC'}
                    onClick={() => onTabChange(existingTabsMap?.get(weightKey))}
                  >
                    <BloodPressureWeight
                      patient={patient}
                      weightValue={weightValue}
                      weightOutlier={weightOutlier}
                      weightTotalValue={weightTotalValue}
                      titleColorWeight={titleColorWeight}
                      deviceVendor={weightDeviceVendor}
                    />
                  </Box>
                </Box>
              ) : null}
              {hasBloodSugarDevice() ? (
                <Box
                  width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                >
                  <Box background={visibleTab == existingTabsMap?.get(bsKey) ? 'white' : '#F7F9FC'}>
                    <div
                      style={{
                        backgroundColor:
                          visibleTab == existingTabsMap?.get(bsKey) ? '#1083CB' : '#F7F9FC',
                        height: '5px',
                        width: visibleTab == existingTabsMap?.get(bsKey) ? '85%' : '100%',
                        alignSelf: 'center',
                        marginLeft: visibleTab == existingTabsMap?.get(bsKey) ? '8%' : '',
                        borderBottomLeftRadius:
                          visibleTab == existingTabsMap?.get(bsKey) ? '25px' : '',
                        borderBottomRightRadius:
                          visibleTab == existingTabsMap?.get(bsKey) ? '25px' : '',
                      }}
                    ></div>
                  </Box>

                  <Box
                    width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                    paddingTop="20px"
                    paddingLeft="20px"
                    paddingRight="10px"
                    paddingBottom="30px"
                    background={visibleTab == existingTabsMap?.get(bsKey) ? 'white' : '#F7F9FC'}
                    onClick={() => onTabChange(existingTabsMap?.get(bsKey))}
                  >
                    <BloodPressureSugar
                      patient={patient}
                      bloodSugarValue={bloodSugarValue}
                      bloodSugarOutlier={bloodSugarOutlier}
                      bloodSugarTotalValue={bloodSugarTotalValue}
                      titleColorGlucose={titleColorGlucose}
                    />
                  </Box>
                </Box>
              ) : null}
              {hasBloodOxygenDevice() && isPulseOxEnabled ? (
                <Box
                  width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                >
                  <Box background={visibleTab == existingTabsMap?.get(boKey) ? 'white' : '#F7F9FC'}>
                    <div
                      style={{
                        backgroundColor:
                          visibleTab == existingTabsMap?.get(boKey) ? '#1083CB' : '#F7F9FC',
                        height: '5px',
                        width: visibleTab == existingTabsMap?.get(boKey) ? '85%' : '100%',
                        alignSelf: 'center',
                        marginLeft: visibleTab == existingTabsMap?.get(boKey) ? '8%' : '',
                        borderBottomLeftRadius:
                          visibleTab == existingTabsMap?.get(boKey) ? '25px' : '',
                        borderBottomRightRadius:
                          visibleTab == existingTabsMap?.get(boKey) ? '25px' : '',
                      }}
                    ></div>
                  </Box>

                  <Box
                    width={totalDeviceTabCount < 5 ? '100%' : screenWidth > 2000 ? '100%' : '300px'}
                    overflowX={'hidden'}
                    paddingTop="20px"
                    paddingLeft="20px"
                    paddingRight="10px"
                    paddingBottom="30px"
                    background={visibleTab == existingTabsMap?.get(boKey) ? 'white' : '#F7F9FC'}
                    onClick={() => onTabChange(existingTabsMap?.get(boKey))}
                  >
                    <BloodOxygen
                      patient={patient}
                      bloodOxygenValue={bloodOxygenValue}
                      bloodOxygenOutlier={bloodOxygenOutlier}
                      titleColorOxygen={titleColorOxygen}
                      bloodOxygenTotalValue={bloodOxygenTotalValue}
                      pulseOutlier={pulseOxPulseOutlier}
                      pulseValue={pulseOxPulseValue}
                    />
                  </Box>
                </Box>
              ) : null}
            </HorizontalScroll>
          </Flex>

          <Flex justifyContent="space-between">
            {/* <TabList>
              {patient?.bloodPressure?.length > 0 ? (
                <Tab fontSize="16">
                  <Box>Blood Pressure</Box>
                </Tab>
              ) : null}
              {patient?.bloodPressure?.length > 0 ? <Tab fontSize="16">Pulse</Tab> : null}
              {patient?.weight?.length > 0 ? <Tab fontSize="16">Weight</Tab> : null}
              {patient?.bloodSugar?.length > 0 ? <Tab fontSize="16">Glucose</Tab> : null}
            </TabList> */}
            <Flex minWidth="450px">
              <Flex display="flex" justifyContent="center" alignItems="center">
                {/* <RoleBasedRoutes allowedRoles={lineGraphRoles}>
                  <Button
                    size="sm"
                    mr="1"
                    variant="plain"
                    leftIcon={isTableView ? 'graph' : 'table-icon'}
                    onClick={() => toggleView(!isTableView)}
                  >
                    {isTableView ? 'Line' : 'Table'}
                  </Button>
                </RoleBasedRoutes>
                {/* <DateRange
                  dateRange={dateRange}
                  initialValues={initialValues}
                  setDateRange={setDateRange}
                /> */}
              </Flex>
            </Flex>
          </Flex>
          <Box pl="3xl" pr="3xl">
            <DateFilters
              dateRange={dateRange}
              initialValues={initialValues}
              setDateRange={setDateRange}
            ></DateFilters>
            <TabPanels>
              {existingTabsMap?.has(bpKey) && visibleTab === existingTabsMap?.get(bpKey) && (
                <TabPanel py="0">
                  <BloodPressureDataTable
                    visible={true}
                    isTableView={isTableView}
                    dateRange={dateRange}
                    id={patient.id}
                    rangeThreshold={rangeThreshold}
                    bp_diastolic={bp_diastolic}
                    bp_systolic={bp_systolic}
                    timeZone={timeZone}
                    deviceVendor={bpDeviceVendor}
                    setSystolicValue={setSystolicValue}
                    setDiastolicValue={setDiastolicValue}
                    setSystolicOutlier={setSystolicOutlier}
                    setDiastolicOutlier={setDiastolicOutlier}
                    setTitleColor={setTitleColor}
                    setBpSystolicValue={setBpSystolicValue}
                    setBpDiastolicValue={setBpDiastolicValue}
                    postalCode={postalCode}
                    chartsReadings={patient.bloodPressure}
                    reading={data}
                    isLoading={isLoading}
                  />
                </TabPanel>
              )}
              {existingTabsMap?.has(pulseKey) && visibleTab === existingTabsMap?.get(pulseKey) ? (
                <TabPanel py="0">
                  <BloodPulseDataTable
                    visible={true}
                    dateRange={dateRange}
                    isTableView={isTableView}
                    id={patient.id}
                    rangeThreshold={rangeThreshold}
                    timeZone={timeZone}
                    deviceVendor={bpDeviceVendor}
                    setPulseValue={setPulseValue}
                    setPulseOutlier={setPulseOutlier}
                    setBpPulseValue={setBpPulseValue}
                    setTitleColorPulse={setTitleColorPulse}
                    postalCode={postalCode}
                    chartPulseReadings={patient.pulse}
                    reading={data}
                    isLoading={isLoading}
                  />
                </TabPanel>
              ) : null}

              {existingTabsMap?.has(weightKey) && visibleTab === existingTabsMap?.get(weightKey) ? (
                <TabPanel py="0">
                  <WeightDataTable
                    isTableView={isTableView}
                    dateRange={dateRange}
                    visible={true}
                    id={patient.id}
                    timeZone={timeZone}
                    deviceVendor={weightDeviceVendor}
                    setWeightValue={setWeightValue}
                    setWeightOutlier={setWeightOutlier}
                    setWeightTotalValue={setWeightTotalValue}
                    setTitleColorWeight={setTitleColorWeight}
                    postalCode={postalCode}
                    weightChartsReading={patient.weight}
                    rangeSelectedThresholdResponse={data}
                    isLoading={isLoading}
                  />
                </TabPanel>
              ) : null}
              {existingTabsMap?.has(bsKey) && visibleTab === existingTabsMap?.get(bsKey) ? (
                <TabPanel py="0">
                  <BloodSugarDataTable
                    isTableView={isTableView}
                    visible={true}
                    dateRange={dateRange}
                    id={patient.id}
                    sugar_threshold={sugar_threshold}
                    timeZone={timeZone}
                    setBloodSugarValue={setBloodSugarValue}
                    setBloodSugarOutlier={setBloodSugarOutlier}
                    setBloodSugarTotalValue={setBloodSugarTotalValue}
                    setTitleColorGlucose={setTitleColorGlucose}
                    postalCode={postalCode}
                    bloodSugarReadings={patient.bloodSugar}
                    thresholdData={data}
                    isLoading={isLoading}
                  />
                </TabPanel>
              ) : null}
              {existingTabsMap?.has(boKey) && visibleTab === existingTabsMap?.get(boKey) ? (
                <TabPanel py="0">
                  <BloodOxygenDataTable
                    isTableView={isTableView}
                    visible={true}
                    dateRange={dateRange}
                    oxygen_threshold={oxygen_threshold}
                    rangeThreshold={rangeThreshold}
                    id={patient.id}
                    timeZone={timeZone}
                    setBloodOxygenValue={setBloodOxygenValue}
                    setBloodOxygenOutlier={setBloodOxygenOutlier}
                    setBloodOxygenTotalValue={setBloodOxygenTotalValue}
                    setTitleColorOxygen={setTitleColorOxygen}
                    setPulseOxPulseValue={setPulseOxPulseValue}
                    setPulseOxPulseOutlier={setPulseOxPulseOutlier}
                    postalCode={postalCode}
                    bloodOxygenReadings={patient.pulseOx}
                    thresholdData={data}
                    isLoading={isLoading}
                  />
                </TabPanel>
              ) : null}
              <TabPanel py="0" style={{ display: 'none' }}>
                <BloodPulseDataTable
                  visible={true}
                  dateRange={dateRange}
                  isTableView={isTableView}
                  id={patient.id}
                  rangeThreshold={rangeThreshold}
                  timeZone={timeZone}
                  deviceVendor={bpDeviceVendor}
                  setPulseValue={setPulseValue}
                  setPulseOutlier={setPulseOutlier}
                  setBpPulseValue={setBpPulseValue}
                  setTitleColorPulse={setTitleColorPulse}
                  postalCode={postalCode}
                  chartPulseReadings={patient.pulse}
                  reading={data}
                  isLoading={isLoading}
                />
              </TabPanel>
              <TabPanel py="0" style={{ display: 'none' }}>
                <WeightDataTable
                  isTableView={isTableView}
                  dateRange={dateRange}
                  visible={true}
                  id={patient.id}
                  timeZone={timeZone}
                  deviceVendor={weightDeviceVendor}
                  setWeightValue={setWeightValue}
                  setWeightOutlier={setWeightOutlier}
                  setWeightTotalValue={setWeightTotalValue}
                  setTitleColorWeight={setTitleColorWeight}
                  postalCode={postalCode}
                  weightChartsReading={patient.weight}
                  rangeSelectedThresholdResponse={data}
                  isLoading={isLoading}
                />
              </TabPanel>
              <TabPanel py="0" style={{ display: 'none' }}>
                <BloodSugarDataTable
                  isTableView={isTableView}
                  visible={true}
                  dateRange={dateRange}
                  id={patient.id}
                  sugar_threshold={sugar_threshold}
                  timeZone={timeZone}
                  setBloodSugarValue={setBloodSugarValue}
                  setBloodSugarOutlier={setBloodSugarOutlier}
                  setBloodSugarTotalValue={setBloodSugarTotalValue}
                  setTitleColorGlucose={setTitleColorGlucose}
                  postalCode={postalCode}
                  bloodSugarReadings={patient.bloodSugar}
                  thresholdData={data}
                  isLoading={isLoading}
                />
              </TabPanel>
              <TabPanel py="0" style={{ display: 'none' }}>
                <BloodOxygenDataTable
                  isTableView={isTableView}
                  visible={true}
                  dateRange={dateRange}
                  oxygen_threshold={oxygen_threshold}
                  rangeThreshold={rangeThreshold}
                  id={patient.id}
                  timeZone={timeZone}
                  setBloodOxygenValue={setBloodOxygenValue}
                  setBloodOxygenOutlier={setBloodOxygenOutlier}
                  setBloodOxygenTotalValue={setBloodOxygenTotalValue}
                  setPulseOxPulseValue={setPulseOxPulseValue}
                  setPulseOxPulseOutlier={setPulseOxPulseOutlier}
                  setTitleColorOxygen={setTitleColorOxygen}
                  postalCode={postalCode}
                  bloodOxygenReadings={patient.pulseOx}
                  thresholdData={data}
                  isLoading={isLoading}
                />
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      </Fragment>
    </PatientContainer>
  );
}
