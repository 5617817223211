import { UseMutationResult, UseQueryResult, useMutation, useQueryClient } from 'react-query';
import { useFetch } from './query';
import { CreateGroupDto, Group, DefaultService as Service } from '~generated';
import toast from 'react-hot-toast';
const notifySuccess = () => toast.success('Group has been updated!');
const notifyError = () => toast.error('There was an error updating the Group');

interface UpdateGroupPayload extends CreateGroupDto {
  id: string;
}

export function useGroupList(shouldFetch: boolean): UseQueryResult<Group[]> {
  return useFetch<Group[]>({
    key: ['groups'],
    path: `/groups`,
    enabled: shouldFetch,
    queryOptions: {
      refetchInterval: 500000,
      refetchOnWindowFocus: false,
    },
  });
}

export function useUpdateGroup(): UseMutationResult<unknown, unknown, UpdateGroupPayload> {
  const client = useQueryClient();
  return useMutation(
    async (payload: UpdateGroupPayload) => {
      return Service.groupsControllerUpdate(payload.id, payload);
    },
    {
      onSuccess: () => {
        client.invalidateQueries('groups');
        notifySuccess();
      },
      onError: (err: any) => {
        const errorMessage = JSON.parse(err?.body?.message);
        if (errorMessage?.message === 'DUPLICATE_GROUP_NAME') {
          toast.error(`Group Name Already Exists`);
        } else {
          notifyError();
        }
      },
    }
  );
}
