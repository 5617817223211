import {
  Avatar,
  SideNav as RhythmSideNav,
  SideNavItem,
  SideNavItemProps,
  Spacer,
  Logo,
  SideNavLogoProps,
  Heading,
  HStack,
  Box,
} from '@rhythm/components';
import { Link as RouterLink } from 'react-router-dom';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { useContext } from 'react';
import { MyContext } from '~src/pages/createContext';

interface SideNavLinkProps {
  to: string;
  icon: SideNavItemProps['icon'];
  iconActive?: SideNavItemProps['icon'];
  active?: boolean;
  label: string;
}

interface SideNavProps {
  fullname: string;
  logout: (arg: null) => void;
}

function SideNavLink({ to, icon, iconActive, active, label }: SideNavLinkProps) {
  const { pathname } = window.location;
  const { setMyState } = useContext(MyContext);
  const handleClick = () => {
    if (pathname === '/patients/fast_review') setMyState(true);
  };
  return (
    <SideNavItem onClick={handleClick} icon={(active && iconActive) || icon} active={active}>
      {label}
      {pathname === '/patients/fast_review' || to === 'sameURL' ? null : <RouterLink to={to} />}
    </SideNavItem>
  );
}

function LogoComponent({ onClick }: SideNavLogoProps) {
  return (
    <HStack spacing="xl" onClick={onClick}>
      <Logo />
      <Heading variant="h4" color="#6A8DC8">
        RPM
      </Heading>
    </HStack>
  );
}

const getActiveLink = (targetRoute: string): boolean => {
  const { pathname } = window.location;
  return pathname.includes(targetRoute);
};

export function SideNav({ fullname, logout }: SideNavProps) {
  const { pathname } = window.location;
  const handleClick = () => {
    if (pathname !== '/patients' && pathname !== '/patients/fast_review') {
      localStorage.removeItem('rpm-filter-0');
    }
  };
  return (
    <RhythmSideNav LogoComponent={LogoComponent}>
      <SideNavLink label={fullname} to="#" icon={<Avatar name={fullname} size="sm" />} />
      <Box onClick={handleClick}>
        <SideNavLink
          label="Patients"
          to={pathname === '/patients' ? 'sameURL' : '/patients'}
          icon="patients"
          iconActive="patients-filled"
          active={getActiveLink('/patients')}
        />
      </Box>
      <RoleBasedRoutes internalUsersOnly={true}>
        <Box>
          <SideNavLink
            label="Accounts"
            to={pathname === '/settings' ? 'sameURL' : '/settings'}
            icon="medical"
            iconActive="medical-filled"
            active={getActiveLink('/settings')}
          />
        </Box>
        <Box>
          <SideNavLink
            label="Settings"
            to="/internalsettings"
            icon="settings"
            iconActive="settings-filled"
            active={getActiveLink('/internalsettings')}
          />
        </Box>
        <Box>
          <SideNavLink
            label="Alerts"
            to="/alerts"
            icon="status-warning"
            iconActive="status-warning"
            active={getActiveLink('/alerts')}
          />
        </Box>
        <Spacer />
      </RoleBasedRoutes>
      <SideNavItem
        icon="sign-out"
        onClick={() => {
          localStorage.removeItem('rpm-filter-0');
          logout(null);
        }}
      >
        Log Out
      </SideNavItem>
    </RhythmSideNav>
  );
}
