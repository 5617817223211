import { Heading } from '@rhythm/components';

import { useGetUser } from '~src/services/users';

export function Dashboard() {
  const { data: user, isLoading } = useGetUser();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Heading>
      Hello {user?.given_name} {user?.family_name}
    </Heading>
  );
}
