import { Input, Button, FormLabel, FormControl, Text, Box } from '@rhythm/components';
import { useField } from 'formik';
import { useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';

interface FileUploadFieldProps {
  label: string;
  name: string;
  accept?: string;
  fileName: string;
  hintText?: string;
  fullWidth?: boolean;
  setFieldValue: (field: string, value: File) => void;
}

export function FileUploadField(props: FileUploadFieldProps) {
  const {
    label,
    accept = '.png, .jpg, .jpeg, .pdf',
    setFieldValue,
    fileName,
    hintText,
    fullWidth = false,
  } = props;
  const [field, meta] = useField(props);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fieldHasError: boolean = !!meta.touched && !!meta.error;

  const onSuccess = () => toast.success('File successfully added!');
  const onError = () => toast.error('There was a problem adding your file');

  return (
    <FormControl mb="2xl" sx={{ position: 'relative' }}>
      <Toaster />
      <FormLabel fontSize="xs">{label}</FormLabel>
      <Input
        {...field}
        value=""
        ref={fileInputRef}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event?.currentTarget?.files?.length) {
            setFieldValue(fileName, event.currentTarget.files[0]);
            onSuccess();
          }
          if (!event?.currentTarget?.files?.length) {
            onError();
          }
        }}
        type="file"
        hidden
        accept={accept}
      />
      <Button
        rightIcon="upload"
        onClick={(): void => fileInputRef?.current?.click()}
        px="2xl"
        py="md"
        color="neutral.white"
        fontWeight="bold"
        isFullWidth={fullWidth}
        _hover={{
          bg: 'primary.600',
        }}
      >
        {label}
      </Button>
      <Box sx={{ position: 'absolute', top: '100%' }}>
        <Text
          fontSize="xs"
          color={fieldHasError ? 'red.500' : 'neutral.800'}
          role={(fieldHasError && 'alert') || undefined}
        >
          {fieldHasError ? meta.error : hintText}
        </Text>
      </Box>
      {field.value?.name && (
        <Text fontSize="xs" mt="sm" color="neutral.600" sx={{ position: 'absolute', top: '104%' }}>
          {field.value.name}
        </Text>
      )}
    </FormControl>
  );
}
