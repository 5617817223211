/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { BloodPressure } from './BloodPressure';
import type { BloodSugar } from './BloodSugar';
import type { ClinicalNote } from './ClinicalNote';
import type { Device } from './Device';
import type { DeviceThreshold } from './DeviceThreshold';
import type { Group } from './Group';
import type { HasMonthlyContactView } from './HasMonthlyContactView';
import type { Insurance } from './Insurance';
import type { MonthlyContact } from './MonthlyContact';
import type { Organization } from './Organization';
import type { PatientAdmission } from './PatientAdmission';
import type { PatientContact } from './PatientContact';
import type { PatientDaysTransmittedMonthly } from './PatientDaysTransmittedMonthly';
import type { PatientDeviceThresholds } from './PatientDeviceThresholds';
import type { PatientICDCode } from './PatientICDCode';
import type { PatientMonthlyTimeAccrued } from './PatientMonthlyTimeAccrued';
import type { PatientStatusTypeHistory } from './PatientStatusTypeHistory';
import type { PatientTime } from './PatientTime';
import type { Pulse } from './Pulse';
import type { PulseOx } from './PulseOx';
import type { User } from './User';
import type { Weight } from './Weight';

export type Patient = {
    contacts: Array<PatientContact>;
    icdcodes: Array<PatientICDCode>;
    status: Patient.status;
    statusType: Patient.statusType;
    notes: Array<ClinicalNote>;
    minutes: Array<PatientTime>;
    monthlyContact: Array<MonthlyContact>;
    hasMonthlyContact: HasMonthlyContactView;
    bloodPressure: Array<BloodPressure>;
    pulse: Array<Pulse>;
    bloodSugar: Array<BloodSugar>;
    weight: Array<Weight>;
    pulseOx: Array<PulseOx>;
    devices: Array<Device>;
    firstDischargeDate: string;
    readmissions: Array<PatientAdmission>;
    groups: Array<Group>;
    statusTypeHistory: Array<PatientStatusTypeHistory>;
    deviceThresholds: Array<DeviceThreshold>;
    clinic: Array<Organization>;
    clinicians: Array<User>;
    onboarding: User;
    onBoardingClosed: boolean;
    physician: User;
    clinician: User;
    isConsentsToEnrollment: boolean;
    createdBy: User;
    address: Address;
    insurance: Array<Insurance>;
    mrn: string;
    mobileNumber: string;
    daysTransmitted: number;
    daysTransmittedMonthly: number;
    totalTimeAccrued: number;
    isContacted: boolean;
    lastMonthUpdated: string;
    lastUpdatedReading: string;
    language: string;
    addressId: string;
    isReviewedDate: string;
    isReviewed: boolean;
    cptChargedDate: string;
    team: string;
    latestTimeAccruedByUser: string;
    latestTimeAccruedByUserTimestamp: string;
    allDeviceThresholds: PatientDeviceThresholds;
    monthlyTimeAccrued: PatientMonthlyTimeAccrued;
    monthlyDaysTransmitted: PatientDaysTransmittedMonthly;
    latestBloodSugar: BloodSugar;
    latestBloodPressure: BloodPressure;
    latestPulse: Pulse;
    latestPulseOx: PulseOx;
    latestWeight: Weight;
    latestBloodSugarAlert: BloodSugar;
    latestBloodPressureAlert: BloodPressure;
    latestPulseAlert: Pulse;
    latestPulseOxAlert: PulseOx;
    latestWeightAlert: Weight;
    secondLatestWeight: Weight;
    lastChangedDate: string;
    lastChangedBy: User;
    thresholds: Array<DeviceThreshold>;
    escalatedAt: string;
    escalatedToUser?: Record<string, any>;
    isActive: boolean;
    firstName: string;
    lastName: string;
    gender: Patient.gender;
    birthDate: string;
    photoUrl: string;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace Patient {

    export enum status {
        PENDING = 'pending',
        REGISTERED = 'registered',
        DECEASED = 'deceased',
        OPTEDIN = 'optedin',
        ENROLLED = 'enrolled',
        OPTEDOUT = 'optedout',
    }

    export enum statusType {
        NEW = 'new',
        ACTIVE = 'active',
        NON_ADHERENT = 'non-adherent',
        PAUSED = 'paused',
        UNENROLLED = 'unenrolled',
    }

    export enum gender {
        FEMALE = 'female',
        MALE = 'male',
    }


}

