import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton } from '@rhythm/components';

interface HorizontalScrollProps {
  children: React.ReactNode;
}

export const HorizontalScroll = ({ children }: HorizontalScrollProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  function removeNullsFromChildren(arr: any) {
    return arr.filter((value: null) => value !== null);
  }

  const filteredChildren = removeNullsFromChildren(children);

  const childrenCount = filteredChildren?.length;

  const handleScrollLeft = () => {
    if (containerRef?.current) {
      containerRef.current.scrollLeft -= 500;
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 500;
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const deriveWidthHorizontalScrollBox = () => {
    if (childrenCount < 5) {
      return '100%';
    } else if (screenWidth > 2000) {
      return '100%';
    } else {
      return '1199px';
    }
  };

  const deriveWidthHorizontalTabs = () => {
    if (childrenCount < 5) {
      return '100%';
    } else if (screenWidth > 2000) {
      return '100%';
    } else {
      return 'fit-content';
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth > 2000) {
      setScrollLeft(0);
    }
  }, [screenWidth]);

  return (
    <>
      <Box position="relative" width="100%">
        {scrollLeft > 0 && screenWidth < 2000 && (
          <IconButton
            size="lg"
            position="absolute"
            top="45px"
            left="-5"
            zIndex={1}
            icon="arrow-left"
            backgroundColor={'white'}
            color={'#6C7789'}
            onClick={handleScrollLeft}
            aria-label="Scroll Left"
            borderRadius={'50%'}
          />
        )}

        {containerRef.current &&
          (scrollLeft < containerRef.current.scrollWidth - containerRef.current.clientWidth ||
            screenWidth < 2000) &&
          childrenCount > 4 &&
          scrollLeft <= 0 && (
            <IconButton
              size="lg"
              position="absolute"
              top="45px"
              right="-5"
              zIndex={1}
              icon="arrow-right"
              onClick={handleScrollRight}
              backgroundColor={'white'}
              color={'#6C7789'}
              borderRadius={'50%'}
              aria-label="Scroll Right"
            />
          )}

        <Box
          width={deriveWidthHorizontalScrollBox()}
          className="horizontalScrollBox"
          position="relative"
          overflowX="hidden"
          // scrollBehavior={'smooth'}
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
          ref={containerRef}
          borderRadius={'10px'}
        >
          <Box
            width={deriveWidthHorizontalTabs()}
            className="horizontalScrollBox"
            display={'flex'}
            flexDirection="row"
            // width="fit-content"
            alignItems="center"
            height="100%"
            overflow={'scroll'}
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >
            {filteredChildren}
          </Box>
        </Box>
      </Box>
    </>
  );
};
