import { Box, Grid, Heading } from '@rhythm/components';
import React from 'react';
import { User } from '~generated';
import { FormSelect } from '~src/components/FormSelect';
import { CLINICIAN_ROLE, NON_CLINICAL_ROLE } from '~src/constants';
import { Roles } from '~generated/models/Roles';

type PhysicianFormType = {
  userData: User[];
};

const PhysicianForm: React.FC<PhysicianFormType> = ({ userData }) => {
  const physicanOptions = userData
    ?.filter((user: User) => user.role !== CLINICIAN_ROLE)
    ?.filter((user: User) => {
      return user.role === Roles.ROLES_EXTERNAL_PROVIDER;
    })
    ?.map((user: User) => {
      return {
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      };
    });

  const onBoardingOptions = userData
    ?.filter((user: User) => user.role === NON_CLINICAL_ROLE && user.isActive == true)
    ?.map((user: User) => {
      return {
        label: `${user.lastName} ${user.firstName}`,
        value: user.id,
      };
    });
  onBoardingOptions.sort((a, b) => a.label.localeCompare(b.label));

  onBoardingOptions.unshift({
    label: 'Closed',
    value: 'Closed',
  });

  const patientConsentOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  return (
    <Box mt="2xl">
      <Heading variant="h5" mb="2xl">
        Provider and Onboarding Information
      </Heading>
      <Grid templateColumns="repeat(3, 1fr)" gap="2xl" mt="2xl" mb="2xl">
        <FormSelect
          name="requestingPhysician"
          label="Physician Requesting RPM *"
          options={physicanOptions}
        />
        <FormSelect
          name="isOnboardingAssignment"
          label="Onboarding Assignment *"
          options={onBoardingOptions}
        />
        <FormSelect
          name="isConsentsToEnrollment"
          label="Patient Consents to RPM Program Enrollment"
          options={patientConsentOptions}
        />
      </Grid>
    </Box>
  );
};

export default PhysicianForm;
