export interface DynamicConfig {
  apiUrl: string;
  environment: 'DEV' | 'QA' | 'UAT' | 'PROD';
  clientId?: string;
  issuer?: string;
  redirectUri?: string;
}

export const defaultConfig: DynamicConfig = {
  apiUrl: 'http://localhost:4000',
  redirectUri: 'http://localhost:3000/login/callback',
  clientId: '0oa1aovk1a1h58yH11d7',
  issuer: 'https://login-uat.rhythmsynergy.com/oauth2/default',
  environment: 'DEV',
};

class GlobalConfig {
  config: DynamicConfig = defaultConfig;
  notDefinedYet = true;

  public get(): DynamicConfig {
    if (this.notDefinedYet) {
      throw new Error(
        // eslint-disable-next-line max-len
        'Global config has not been defined yet. Be sure to call the getter only after the config has been downloaded and set. Probable cause is accessing globalConfig in static context.'
      );
    } else {
      return this.config;
    }
  }

  public set(value: DynamicConfig): void {
    if (this.notDefinedYet) {
      this.config = value;
      this.notDefinedYet = false;
    } else {
      throw new Error(
        // eslint-disable-next-line max-len
        'Global config has already been defined and now has been called second time. This is probably not intended.'
      );
    }
  }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = '/settings.json';
