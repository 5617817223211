import { useState } from 'react';
import { Flex, Heading, IconButton, Box, Button } from '@rhythm/components';
import { useParams } from 'react-router-dom';
import { NoteTile, SocialNoteModal } from './SocialNoteModal';
import {
  useListSocialNotesByPatient,
  useUpdateSocialNote,
  useDeleteSocialNote,
} from '~src/services/notes';
import { SocialNote } from '~generated';
import { maxPinnedNotes } from '../../constants/socialNotes';
import toast from 'react-hot-toast';

type Params = {
  id: string;
};

export function SocialNotes() {
  const { id: patientId } = useParams<Params>();
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showAllNotes, setShowAllNotes] = useState(false);
  const updateSocialNote = useUpdateSocialNote();
  const deleteSocialNote = useDeleteSocialNote();

  const { data: socialNotes } = useListSocialNotesByPatient({
    id: patientId,
  });

  const sortNotes = () => {
    const pinnedNotes: SocialNote[] = [];
    const notes: SocialNote[] = [];
    socialNotes?.map((note) => {
      if (note.isPinned) {
        pinnedNotes.push(note);
      } else {
        notes.push(note);
      }
    });
    notes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    return [pinnedNotes, notes];
  };

  const [pinnedNotes, notes] = sortNotes();

  async function togglePinned(id: string, isPinned: boolean): Promise<void> {
    if (pinnedNotes.length >= maxPinnedNotes && !isPinned) {
      toast.error('You must un-pin a note first!');
      return;
    }
    const payload = {
      id,
      isPinned: !isPinned,
    };
    await updateSocialNote.mutate(payload);
  }

  async function handleDelete(id: string) {
    await deleteSocialNote({ id });
  }

  const toggleNoteModal = () => setShowNoteModal((prevState) => !prevState);
  const toggleShowNotes = () => setShowAllNotes((prevState) => !prevState);

  return (
    <Box>
      <SocialNoteModal
        isOpen={showNoteModal}
        onClose={toggleNoteModal}
        notes={notes}
        patientId={patientId}
        pinnedNotes={pinnedNotes}
        updateSocialNote={updateSocialNote}
        togglePinned={togglePinned}
        handleDelete={handleDelete}
      />
      <Flex alignItems="center" justifyContent="space-between">
        <Heading variant="h6" mb="lg">
          {`Pinned Notes (${pinnedNotes?.length || 0}/${maxPinnedNotes})`}
        </Heading>
        <IconButton
          icon="edit"
          onClick={() => {
            setShowAllNotes(false);
            toggleNoteModal();
          }}
          aria-label="Create social note"
          variant="ghost"
          color="primary.500"
        />
      </Flex>
      <Box>
        {!showNoteModal &&
          pinnedNotes?.map((note) => (
            <NoteTile
              key={note.id}
              notes={note.notes}
              timestamp={note.updatedAt}
              isPinned={note.isPinned}
              id={note.id}
              updateSocialNote={updateSocialNote}
              togglePinned={togglePinned}
              handleDelete={handleDelete}
              createdBy={note.user.firstName + ' ' + note.user.lastName}
            />
          ))}
      </Box>
      {!!notes?.length && (
        <Button variant="plain" color="primary.500" bg="transparent" onClick={toggleShowNotes}>
          {showAllNotes ? 'Hide' : 'Show'} all notes ({notes.length})
        </Button>
      )}
      {showAllNotes && (
        <Box>
          {notes?.map((note) => (
            <NoteTile
              key={note.id}
              notes={note.notes}
              timestamp={note.updatedAt}
              isPinned={note.isPinned}
              id={note.id}
              updateSocialNote={updateSocialNote}
              togglePinned={togglePinned}
              handleDelete={handleDelete}
              createdBy={note.user.firstName + ' ' + note.user.lastName}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
