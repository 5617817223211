import React, { useEffect, useState } from 'react';
import {
  Heading,
  Box,
  Text,
  Flex,
  Divider,
  Button,
  Grid,
  GridItem,
  Tooltip,
} from '@rhythm/components';
import { Formik, Form, useFormikContext, FieldArray, FormikHelpers } from 'formik';
import { useMutation } from 'react-query';
import { FormField } from '~src/components/FormField';
import { FormSelect } from '~src/components/FormSelect';
import toast from 'react-hot-toast';
import { teamFields } from './formFields';
import { useClinicUsers } from '~src/services/clinics';
import {
  CreateTeamDto,
  DefaultService as Service,
  Team,
  UpdateTeamDto,
  Roles,
  User,
} from '~generated';
import { CLINICIAN_ROLE } from '~src/constants';
import { FormOption } from '~src/components/FormSelect/FormSelect';
import { useHistory } from 'react-router-dom';
import RoleBasedRoutes from '~src/components/RoleBasedRoutes';
import { editTeamRoles } from '~src/constants/roles';

export interface Errors {
  [key: string]: string;
}

export interface CreateTeamFormValues {
  teamName: string;
  teamLead: any;
  members: any;
}

function CliniciansForm(props: { values: FormOption[] }) {
  const {
    setFieldValue,
    values: { members },
  } = useFormikContext<CreateTeamFormValues>();
  const [open, setOpen] = useState<boolean>(false);
  const [newClinician, setNewClinician] = useState<string | boolean>('');
  const [newClinicianName, setNewClinicianName] = useState<string>('');
  const [showConfirmText, setShowConfirmText] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<any>(null);

  const isOptionDisabled = (option: FormOption) => {
    return option.disabled;
  };
  const newClinicianValue = (userIdKey: string) => {
    setFieldValue(userIdKey, newClinician);
    setOpen(false);
    // setCurrentIndex(null)
    setNewClinician('');
    setShowConfirmText(true);
  };

  return (
    <Box>
      <FieldArray
        name="members"
        render={(arrayHelpers) => (
          <Box>
            {members?.map(
              (
                member: { userId: React.Key | null | undefined; indexValue: React.Key; user: User },
                index: number
              ) => (
                <>
                  <Box
                    key={member?.userId}
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                    height={'xl'}
                    mt="md"
                  >
                    <input
                      type="hidden"
                      name={`members.${index}.indexValue`}
                      value={member?.indexValue}
                      readOnly
                    />
                    <Box width={'100%'}>
                      <FormSelect
                        isClinician={
                          member.indexValue && member?.user?.patientCount > 0 ? true : false
                        }
                        style={{
                          marginTop: '1.7rem',
                        }}
                        label={''}
                        options={props.values}
                        name={`members.${index}.userId`}
                        placeholder="Clinician Name"
                        onInputChange={(value) => {
                          if (member.indexValue && member?.user?.patientCount > 0) {
                            setOpen(true);
                            setCurrentIndex(member?.indexValue);
                            setNewClinician(value);
                            setShowConfirmText(false);
                          } else {
                            setOpen(false);
                            setCurrentIndex(null);
                            setNewClinician('');
                            setFieldValue(`members.${index}.userId`, value);
                            setShowConfirmText(false);
                          }
                        }}
                        clinicianName={(value) => {
                          setNewClinicianName(value);
                        }}
                        optionsDisabled={isOptionDisabled}
                      />
                    </Box>
                    {member?.user?.patientCount > 0 ? (
                      <Tooltip
                        width={60}
                        label="You can’t remove the clinician with assigned patients."
                        hasArrow
                        placement="top"
                        arrowSize={15}
                      >
                        <Button
                          height={'2.7rem'}
                          size={'md'}
                          variant="plain"
                          borderColor="#B8C0CD"
                          color="#B8C0CD"
                          borderWidth={1}
                          ml={2}
                          backgroundColor="#EFF2F6"
                          style={{ cursor: 'default' }}
                          _hover={{ background: '#EFF2F6' }}
                        >
                          Remove
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        height={'2.7rem'}
                        size={'md'}
                        variant="plain"
                        borderColor="#B8C0CD"
                        color="#B8C0CD"
                        borderWidth={1}
                        ml={2}
                        backgroundColor="#FFFFFF"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        Remove
                      </Button>
                    )}
                  </Box>
                  <Box hidden={currentIndex !== member.indexValue || !open}>
                    <Text color={'#E18B00'} style={{ paddingBottom: '3px', fontSize: '13px' }}>
                      {' '}
                      Are you sure you want to to reassign {member?.user?.patientCount} patients to
                      - {newClinicianName} ?
                    </Text>
                    <Button
                      size="md"
                      onClick={() => {
                        setOpen(false);
                        setCurrentIndex(null);
                      }}
                      background={'#EFF2F6'}
                      style={{
                        borderRadius: 'border-radius: 4px',
                        color: '#0E6DAD',
                        fontWeight: '500',
                        fontSize: '13px',
                        marginRight: '6px',
                      }}
                    >
                      NO
                    </Button>
                    <Button
                      size="md"
                      onClick={() => newClinicianValue(`members.${index}.userId`)}
                      background={'#EFF2F6'}
                      style={{
                        borderRadius: 'border-radius: 4px',
                        color: '#0E6DAD',
                        fontWeight: '500',
                        fontSize: '13px',
                      }}
                    >
                      YES
                    </Button>
                  </Box>
                  <Box hidden={currentIndex !== member.indexValue || !showConfirmText}>
                    <Text color={'#068923'} style={{ paddingBottom: '3px', fontSize: '13px' }}>
                      {member?.user?.patientCount} patients will be reassigned to -{' '}
                      {newClinicianName} upon saving changes.{' '}
                    </Text>
                  </Box>
                </>
              )
            )}
            <Button
              height={'2.7rem'}
              variant="secondaryLight"
              backgroundColor={'#FFFFFF'}
              borderColor="#1083CB"
              mt="lg"
              leftIcon="add"
              onClick={() => {
                // add a new member to the array only if the last member is not empty
                if (members?.length > 0) {
                  if (members[members?.length - 1].userId !== '') {
                    arrayHelpers.push({ userId: '' });
                  }
                } else {
                  arrayHelpers.push({ userId: '' });
                }
              }}
            >
              Add Member
            </Button>
          </Box>
        )}
      />
    </Box>
  );
}

function TeamInfoForm() {
  const paginatedData = useClinicUsers({ bypassPagination: true });
  const usersData = paginatedData?.data?.data;
  const [teamLeasOptions, setTeamLeadsOptions] = React.useState([]);
  const [cliniciansOptions, setCliniciansOptions] = React.useState([]);
  const {
    setFieldValue,
    values: { teamName, members },
  } = useFormikContext<CreateTeamFormValues>();

  const checkIfOptionAlreadySelected = (userId: any) => {
    for (let i = 0; i < members?.length; i++) {
      if (members[i].userId === userId) {
        return true;
      }
    }
  };

  useEffect(() => {
    if (usersData) {
      setTeamLeadsOptions(
        usersData
          ?.filter((user: User) => user.role !== CLINICIAN_ROLE)
          ?.filter((user: User) => {
            return user.role === Roles.ROLES_INTERNAL_LEAD_CLINICIAN;
          })
          ?.map((user: User) => {
            return {
              label: `${user.lastName}, ${user.firstName}`,
              value: user.id,
            };
          })
      );
      setCliniciansOptions(
        usersData
          ?.filter(
            (user: User) =>
              user.role === CLINICIAN_ROLE || user.role === Roles.ROLES_INTERNAL_LEAD_CLINICIAN
          )
          ?.map((user: User) => {
            const creds = user.credentials?.map((cred) => cred.credential).join(', ');
            const count = user?.patientCount;
            return {
              label: `${count > 0 ? `${count} Patients -` : '0 Patients -'}  ${user.lastName}, ${
                user.firstName
              }${creds ? `, ${creds}` : ''}`,
              value: user.id,
              disabled: checkIfOptionAlreadySelected(user.id),
            };
          })
      );
    }
  }, [usersData, members]);

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap="2xl" mt="2xl" mb="4xl">
      <Box>
        <FormField
          label={teamFields.teamName}
          name="teamName"
          placeholder="Team Name"
          value={teamName}
          maxLength={25}
        />
      </Box>
      <Box>
        <FormSelect
          name="teamLead"
          label={teamFields.teamLead}
          options={teamLeasOptions}
          placeholder="Team Lead"
          onInputChange={(value) => {
            setFieldValue('teamLead', value);
          }}
        />
      </Box>

      <GridItem colSpan={2}>
        <Divider orientation="horizontal" color={'#E0E5EB'} />
      </GridItem>

      <GridItem colSpan={1}>
        <Heading variant="h5" fontWeight="bold" mt="xl" mb="lg">
          Clinicians
        </Heading>
        <CliniciansForm values={cliniciansOptions} />
      </GridItem>
    </Grid>
  );
}

export interface ResponseError extends Error {
  status?: number;
  body?: any;
}

export function CreateTeam(props: { isEditing?: boolean; editTeamData: Team }) {
  const history = useHistory();
  const editTeamMemberData: any = props?.editTeamData?.members;
  if (props.isEditing && props.editTeamData === undefined) history.push('/internalsettings/teams');
  let initialValues: CreateTeamFormValues = {
    teamName: '',
    teamLead: [],
    members: [
      {
        userId: '',
      },
    ],
  };
  if (props.isEditing) {
    if (editTeamMemberData && editTeamMemberData.length > 0) {
      for (let i = 0; i < editTeamMemberData.length; i++) {
        editTeamMemberData[i].indexValue = i + 1;
      }
    }
    initialValues = {
      teamName: props.editTeamData?.teamName,
      teamLead: props.editTeamData?.teamLead.id,
      members: editTeamMemberData,
    };
  }
  const oldMembrs: any = props?.editTeamData?.members;
  const formatCreatePayload = (values: CreateTeamDto) => {
    const payload = {
      teamName: values.teamName,
      teamLead: values.teamLead,
      members: values.members.filter((member: { userId: string }) => member.userId !== ''),
    };
    return payload;
  };

  const formatEditPayload = (values: UpdateTeamDto) => {
    // add teamId to the memebers array
    const members = values.members.map((member: { unassignedClinician: any; userId: string }) => {
      return {
        userId: member.userId,
        teamId: props.editTeamData.id,
        unassignedClinician: member.unassignedClinician,
      };
    });
    const payload = {
      teamName: values.teamName,
      teamLead: values.teamLead,
      members: members.filter((member: { userId: string }) => member.userId !== ''),
    };
    return payload;
  };

  const saveTeam = async (values: CreateTeamDto | UpdateTeamDto) => {
    if (props.isEditing) {
      const payload = formatEditPayload(values);
      return await Service.teamControllerUpdate(props.editTeamData.id, payload);
    } else {
      const payload = formatCreatePayload(values);
      return await Service.teamControllerCreate({
        ...payload,
      });
    }
  };

  const { mutate, isLoading } = useMutation(saveTeam, {
    onSuccess: () => {
      toast.success(`Successfully ${props.isEditing ? 'edited' : 'created'} team`);
      history.goBack();
    },
    onError: async (error: ResponseError) => {
      const errorMessage = await error?.body?.message;
      if (errorMessage === 'DUPLICATE_TEAM_NAME') {
        toast.error('Team name already exists.');
      }
    },
  });

  const formatValues = (values: CreateTeamFormValues) => {
    const oldMembers = oldMembrs?.filter(
      (member: { indexValue: number }) =>
        member.indexValue !== undefined &&
        member.indexValue !== null &&
        typeof member.indexValue === 'number'
    );
    const members = values.members.filter(
      (member: { userId: string }) => member.userId !== '' && typeof member.userId === 'string'
    );
    if (oldMembers && oldMembers?.length > 0) {
      for (let i = 0; i < oldMembers?.length; i++) {
        members.filter((member: any) => {
          if (member.indexValue === oldMembers[i].indexValue) {
            if (member.userId !== oldMembers[i].userId) {
              member.unassignedClinician = oldMembers[i].userId;
              delete member.indexValue;
            }
          }
        });
      }
    }

    const teamName = values.teamName.trim();
    return {
      ...values,
      teamName,
      members,
    };
  };

  const handleSubmit = (
    values: CreateTeamFormValues,
    { resetForm, setErrors }: FormikHelpers<CreateTeamDto>
  ) => {
    const formattedValues = formatValues(values);
    mutate(formattedValues, {
      onSuccess: () => {
        resetForm();
      },
      onError: async (error: ResponseError) => {
        const errorMessage = await error?.body?.message;
        if (errorMessage === 'DUPLICATE_TEAM_NAME') {
          setErrors({ teamName: 'Team name already exists.' });
        }
      },
    });
  };

  const handleResetForm = (resetForm: () => void) => {
    const confirmReset = confirm("Are you sure? You'll lose the changes that you've made so far!");
    if (confirmReset) {
      resetForm();
      history.push('/internalsettings/teams');
    }
    return;
  };

  const validationFunction = (values: CreateTeamFormValues) => {
    const errors: Errors = {};
    if (!values.teamName.trim()) {
      errors.teamName = 'Team Name is required';
    }
    if (values.teamName.trim()?.length > 25) {
      errors.teamName = 'Team Name must be less than 25 characters';
    }
    if (!values.teamLead?.[0]) {
      errors.teamLead = 'Team Lead is required';
    }
    return errors;
  };

  return (
    <Box width="80%" mx="auto" mb="4xl">
      <Button
        style={{
          width: '32px',
          height: '6px',
          color: '#6C7789',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        }}
        onClick={() => history.goBack()}
        leftIcon={'arrow-left'}
        mb="md"
      >
        Back
      </Button>
      <Heading variant="h3" fontWeight="bold" mb="md">
        {props.isEditing ? 'Edit a Team' : 'Create a Team'}
      </Heading>
      <Flex align={'center'} mb="2xl"></Flex>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validationFunction}>
        {({ resetForm }) => (
          <Form>
            <Box bg="white" p="3xl" borderRadius="8px">
              <Heading variant="h3" fontWeight="bold">
                Team
              </Heading>
              <TeamInfoForm />
            </Box>
            <RoleBasedRoutes allowedRoles={editTeamRoles}>
              <Flex justify="flex-end" mt="2xl">
                <Button
                  mr="2xl"
                  variant="secondaryLight"
                  isDisabled={isLoading}
                  onClick={() => handleResetForm(resetForm)}
                >
                  Discard Changes
                </Button>
                <Button type="submit" variant="primary" isLoading={isLoading}>
                  {props.isEditing ? 'Save Changes' : 'Create Team'}
                </Button>
              </Flex>
            </RoleBasedRoutes>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
