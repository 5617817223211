import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Alert, AlertIcon } from '@chakra-ui/react';
import {
  Tooltip,
  Flex,
  Box,
  TabPanels,
  TabPanel,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Button,
  Icon,
  Menu,
  Text,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@rhythm/components';
import { PatientsPaginateDataView } from '~src/components/PatientsDataView';
import { Search } from '~src/components/Search';
import PatientFilters, { FormFilterValues, getInitialFilters } from './PatientFilters/Filters';
import RoleBasedRoutes from '~src/components/RoleBasedRoutes';
import { useFetchPhysiciansByAccount } from '~src/services/clinics';
import { MyContext } from '../createContext';
import { useApplicationSettings } from '~src/services/applicatonSettings';
import { useUserRoleAndEmail } from '~src/hooks';

export type FilterParam = {
  clinicianId: string;
  physicianId: string;
  contacted: string;
  daysTransmitted: string;
  minutesAccrued: string;
};

export type FilterKeys =
  | 'clinicianId'
  | 'physicianId'
  | 'contacted'
  | 'daysTransmitted'
  | 'minutesAccrued';

export function FilterIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 7H16M1 1H19M7 13H13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PatientsListPage({ currentTab }) {
  const { userRole } = useUserRoleAndEmail();
  const isInternal = userRole.includes('roles:internal');
  const isAuthForPermission = [
    'roles:internal:super-admin',
    'roles:internal:lead-clinician',
    'roles:internal:admin',
  ].some((role) => userRole.includes(role));
  const location = useLocation(); // Get location object
  const urlParams = new URLSearchParams(location.search);
  const pageSearch = urlParams.get('search') ?? '';
  const [searchTerm, setSearchTerm] = useState(pageSearch);
  const [searchTermError, setSearchTermError] = useState('');
  const [totalPatient, setTotalPatient] = useState(0);
  const [updatedFilterSearch, setUpdatedFilterSearch] =
    useState<FormFilterValues>(getInitialFilters());

  const [fastReviewCount, setFastReviewCount] = useState('0');
  const [fastReviewLoading, setFastReviewLoading] = useState(true);
  const filterLength = Object.values(updatedFilterSearch).reduce(
    (a, b) => a + (Array.isArray(b) ? b.length : 0),
    0
  );
  const { authState } = useOktaAuth();
  const useFetchPhysiciansData = async (formValues: string) => {
    const physicianData = await useFetchPhysiciansByAccount({ ids: formValues }, authState);
    return physicianData;
  };

  const history = useHistory();
  const fastReview = () => history.push('/patients/fast_review');

  const [fastReviewEnabled, setFastReviewEnabled] = useState<boolean>();
  const { data: applicationSettings } = useApplicationSettings();

  useEffect(() => {
    if (applicationSettings) {
      setFastReviewEnabled(applicationSettings[0].isFastReviewEnabled);
    }
  }, [applicationSettings]);

  const closeAlert = () => {
    setFastReviewState({ ...fastReviewState, bulkReview: false });
  };
  const { fastReviewState, setFastReviewState } = useContext(MyContext);
  useEffect(() => {
    if (fastReviewState) {
      console.log(fastReviewState, 'fastReviewState');
    }
  }, [fastReviewState]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <TabPanels style={{ minWidth: 'max-content' }}>
      <TabPanel py="0">
        <Flex
          alignItems={'center'}
          display={'flex'}
          justifyContent={'space-between'}
          paddingBottom={'20px'}
        >
          <Box w="50%">
            <Search
              value={searchTerm}
              onSearchTerm={setSearchTerm}
              fastReview={false}
              searchTermError={searchTermError}
            />
          </Box>
          {!isAuthForPermission ? (
            <Box style={{ color: '#6C7789' }}>{totalPatient} Patients</Box>
          ) : (
            <Box>
              <Flex>
                <Text mr={'15px'} mt={'7px'}>
                  {totalPatient} Patients{' '}
                </Text>
                <Menu autoSelect={true} isOpen={isOpen} placement="bottom-end">
                  <MenuButton
                    as={Button}
                    bg={'transparent'}
                    padding={'5px !important'}
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                    _hover={{ bg: '#dce6f9' }}
                  >
                    <Icon mt={'3px'} icon="more-vertical" boxSize="sm" color="#0E6DAD" />
                  </MenuButton>
                  <MenuList
                    style={{ minWidth: '30%' }}
                    onMouseEnter={onOpen}
                    onMouseLeave={onClose}
                  >
                    <MenuItem onClick={() => history.push('/group')}>Permissions</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Box>
          )}
        </Flex>
        {}
        {fastReviewState.bulkReview ? (
          <Box>
            {fastReviewState.error === false ? (
              <Box
                marginBottom={'8px'}
                border={'1px solid #22A437'}
                h={'50px'}
                borderRadius={'8px'}
                p={'15px'}
                backgroundColor={'#E5F9ED'}
              >
                <Alert status="success">
                  <AlertIcon width={'16px'} style={{ color: '#22A437' }} />
                  <span style={{ paddingLeft: '10px' }}>
                    You have successfully updated {fastReviewState.totalReviewed} transmissions.{' '}
                    {fastReviewState.notReviewed} transmissions had previously been reviewed and
                    updated by other users
                  </span>
                  <span
                    style={{ width: '16px', position: 'absolute', right: '0' }}
                    onClick={closeAlert}
                  >
                    <Icon icon="close-circle" boxSize={'16px'}></Icon>
                  </span>
                </Alert>
              </Box>
            ) : (
              <Box
                marginBottom={'8px'}
                border={'1px solid #ED3B3B'}
                h={'50px'}
                borderRadius={'8px'}
                p={'15px'}
                backgroundColor={'#FDE2E2'}
              >
                <Alert status="error">
                  <AlertIcon width={'16px'} style={{ color: '#ED3B3B' }} />
                  <span style={{ paddingLeft: '10px' }}>
                    Something went wrong. Could not update {fastReviewState.totalCount}{' '}
                    transmissions. Please contact the system administrator.
                  </span>
                  <span
                    style={{ width: '16px', position: 'absolute', right: '0' }}
                    onClick={closeAlert}
                  >
                    <Icon icon="close-circle" boxSize={'16px'}></Icon>
                  </span>
                </Alert>
              </Box>
            )}
          </Box>
        ) : null}
        <RoleBasedRoutes>
          <Box>
            <Accordion
              background={'#FFFFFF'}
              borderRadius={'8px 8px 0px 0px'}
              width={'1475px'}
              minW={'100%'}
              allowMultiple
            >
              <AccordionItem border={'none'} style={{ padding: '8px' }}>
                <h2
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '8px',
                  }}
                >
                  <AccordionButton _hover={{ bg: 'white' }} w={'50'}>
                    <FilterIcon color={`${filterLength > 0 ? ' #1083CB' : '#516880'}`} />{' '}
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      color={filterLength > 0 ? '#1083CB' : '#516880'}
                      marginLeft={'10px'}
                    >
                      Filter <AccordionIcon style={{ fontSize: '1.5rem' }} />
                    </Box>
                  </AccordionButton>
                  {fastReviewEnabled && isInternal ? (
                    <>
                      <Flex justifyContent="flex-end" alignItems="center">
                        <Box mr={2}>
                          <Button
                            onClick={fastReview}
                            h={'40px'}
                            background={
                              parseInt(fastReviewCount) > 0 &&
                              updatedFilterSearch.clinicians.length > 0
                                ? '#DCE6F9'
                                : '#EFF2F6'
                            }
                            color={
                              parseInt(fastReviewCount) > 0 &&
                              updatedFilterSearch.clinicians.length > 0
                                ? '#0E6DAD'
                                : '#B8C0CD'
                            }
                            borderRadius={'8px'}
                            borderColor={'#E0E5EB'}
                            isDisabled={
                              parseInt(fastReviewCount) === 0 ||
                              updatedFilterSearch.clinicians.length === 0
                            }
                          >
                            <svg
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              focusable="false"
                              className="chakra-icon css-vxeva3"
                            >
                              <path
                                // eslint-disable-next-line max-len
                                d="M1 8s2.545-5.333 7-5.333C12.454 2.667 15 8 15 8s-2.546 5.333-7 5.333C3.545 13.333 1 8 1 8z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M8 10a2 2 0 100-4 2 2 0 000 4z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            &nbsp; {`Review ( ${fastReviewLoading ? '-' : fastReviewCount} )`}
                          </Button>
                        </Box>
                        <Tooltip
                          label="Please filter on a clinician to begin a fast review."
                          // eslint-disable-next-line max-len
                          aria-label="Please filter on a clinician to begin a fast review."
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
                            <circle cx="30" cy="30" r="12" fill="grey" />
                            <text
                              x="30"
                              y="37"
                              fontWeight="800"
                              fontSize="18"
                              textAnchor="middle"
                              fill="white"
                            >
                              ?
                            </text>
                          </svg>
                        </Tooltip>
                      </Flex>
                    </>
                  ) : null}
                </h2>
                <AccordionPanel
                  borderTop="2px"
                  paddingTop={'10px'}
                  paddingBottom={'5px'}
                  borderColor={'#eff2f6'}
                >
                  <PatientFilters
                    currentTab={currentTab}
                    setUpdatedFilterSearch={setUpdatedFilterSearch}
                    fetchPhysiciansData={useFetchPhysiciansData}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </RoleBasedRoutes>
        {currentTab === 0 ? (
          <PatientsPaginateDataView
            updatedFilterSearch={updatedFilterSearch}
            search={searchTerm}
            active={true}
            currentTab={currentTab}
            setTotalPatients={setTotalPatient}
            setFastReviewCount={setFastReviewCount}
            setSearchTermError={setSearchTermError}
            setFastReviewLoading={setFastReviewLoading}
          />
        ) : null}
      </TabPanel>
    </TabPanels>
  );
}
