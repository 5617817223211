import { Redirect } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import {
  Alerts,
  Dashboard,
  Patients,
  Reports,
  Settings,
  InternalSettings,
  GroupComponent,
} from '~src/pages';
import { useMixpanelUserTracking } from '~src/utils/useMixpanelUserTracking';

const AuthRoutes = () => {
  // Track user login
  useMixpanelUserTracking();

  return (
    <>
      <SecureRoute path="/patients" component={Patients} />
      <SecureRoute path="/dashboard" component={Dashboard} />
      <SecureRoute path="/group" component={GroupComponent} />
      <SecureRoute path="/alerts" component={Alerts} />
      <SecureRoute path="/reports" component={Reports} />
      <SecureRoute path="/settings" component={Settings} />
      <SecureRoute path="/internalsettings" component={InternalSettings} />
      <SecureRoute exact path="/">
        <Redirect to="/patients" />
      </SecureRoute>
    </>
  );
};

export default AuthRoutes;
