import { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import type { Cell, SortingRule } from 'react-table';
import { Text, Button, Menu, MenuButton, MenuList, MenuItem, Spinner } from '@rhythm/components';
import { DataTable } from '~src/components/DataTable';
import { useListOrganization } from '~src/services/clinics';
import { Organization } from '~generated';
import { OnFetchProps } from '@rhythm/components/dist/DataTable/DataTable';
import { useDebounce } from 'react-use';
interface AccountRow extends Record<string, unknown> {
  id: string;
  name: string;
  clinics: Organization[];
  patientCount: number;
  providerCount: number;
  createdAt: number;
  rightIcon?: React.ReactElement | null;
  isActive: boolean;
  disableSortBy?: boolean;
}

export interface OrganizationPaginationParams extends OnFetchProps {
  offset: number;
  totalCount: number;
  goToPage: number | undefined;
  initialSize: number | undefined;
  defaultSortBy?: SortingRule<unknown>[];
  searchWord?: string;
  sort?: string;
  limit?: number;
}

function OrgName({ id, name }: Readonly<AccountRow>) {
  return (
    <RouterLink to={`/settings/account/${id}`}>
      <Text fontWeight="bold">{name}</Text>
    </RouterLink>
  );
}

function ClinicName({ clinics }: Readonly<AccountRow>) {
  return (
    <Fragment>
      {clinics?.map((clinic) => (
        <Fragment key={clinic.id}>
          <Text>{clinic.name.substring(0, 24)}</Text>
        </Fragment>
      ))}
    </Fragment>
  );
}

function DateTime({ createdAt }: Readonly<AccountRow>) {
  return <Text>{dayjs(createdAt).format('MMMM D, YYYY')}</Text>;
}

const columns = [
  {
    Header: 'Account',
    accessor: 'name',
    Cell: ({ row: { original } }: Cell<AccountRow, string>) => (
      <>
        <OrgName {...original} />
        {!original?.isActive && (
          <span className="inactive-label" style={{ color: 'red' }}>
            inactive
          </span>
        )}
      </>
    ),
  },
  {
    Header: 'Clinics',
    accessor: 'clinics',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell<AccountRow, string>) => <ClinicName {...original} />,
  },
  {
    Header: 'Patients',
    accessor: 'patientCount',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell<AccountRow, string>) => (
      <div>{original.patientCount || 0}</div>
    ),
  },
  {
    Header: 'Providers',
    accessor: 'providerCount',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell<AccountRow, string>) => (
      <div>{original.providerCount || 0}</div>
    ),
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    disableSortBy: true,
    Cell: ({ row: { original } }: Cell<AccountRow, string>) => <DateTime {...original} />,
  },
];

const ITEMS_PER_PAGE = 10;

type PaginationType = {
  setAccountsCounts?: (count: number) => void;
  search: string;
  setOrganizations: React.Dispatch<React.SetStateAction<Organization[] | undefined>>;
  setSelectedData: (data: any) => void;
};

export function AccountPaginatePage({
  setAccountsCounts,
  search,
  setOrganizations,
  setSelectedData: setSelectedAccounts,
}: Readonly<PaginationType>) {
  const history = useHistory(); // Get history object
  const location = useLocation(); // Get location object
  const urlParams = new URLSearchParams(location.search);
  const pageIndex = parseInt(urlParams.get('page') ?? '1', 10) - 1; // Zero-based index
  const pageSize = parseInt(urlParams.get('pageSize') ?? ITEMS_PER_PAGE.toString(), 10);
  const pageSearch = urlParams.get('search') ?? '';

  const [pagination, setPagination] = useState({
    pageIndex: pageIndex,
    initialSize: pageSize,
    pageSize: pageSize,
    defaultSortBy: [],
    goToPage: 0,
    offset: pageIndex * pageSize,
    totalCount: 0,
    sortBy: { id: 'name', desc: false },
    sort: 'ASC',
    searchWord: pageSearch,
    search: '',
    limit: 0,
  });
  const initialQueryData = {
    sort: pagination.sort ?? 'ASC',
    search: pagination.searchWord,
    offset: pagination.offset,
    limit: pagination.pageSize,
  };
  const [queryData, setQueryData] = useState({
    ...initialQueryData,
  });
  const [pageParams, setPageParams] = useState({ totalSize: 0, totalPageCount: 0 });
  const [currentPage, setCurrentPage] = useState(pageIndex ? pageIndex : 1);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize ?? ITEMS_PER_PAGE);
  const totalPages = Math.ceil(pageParams.totalSize / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;

  useEffect(() => {
    // Update pagination state based on URL parameters
    setPagination({
      ...pagination,
      pageIndex: isNaN(pageIndex) ? 0 : pageIndex,
      pageSize: isNaN(pageSize) ? ITEMS_PER_PAGE : pageSize,
    });
    setCurrentPage(pageIndex + 1);
    setRowsPerPage(pageSize);
  }, [location.search]);

  // Wrap usePatientPagination in a function
  const usePatientPaginationWrapper = (queryData) => {
    return useListOrganization({ ...queryData });
  };
  const {
    data,
    isLoading: orgLoading,
    refetch,
    status,
  } = usePatientPaginationWrapper({ ...queryData });

  const organizations = data?.organizations;
  const totalOrganizationsCount = data?.total ?? 0;

  useEffect(() => {
    if (organizations) {
      setOrganizations(organizations);
      if (totalOrganizationsCount < pagination.pageSize) {
        setPageParams({
          totalPageCount: 1,
          totalSize: totalOrganizationsCount || 0,
        });
      } else {
        setPageParams({
          totalPageCount: Math.ceil(totalOrganizationsCount / pagination?.pageSize),
          totalSize: totalOrganizationsCount,
        });
      }
    }
  }, [organizations]);

  useDebounce(
    () => {
      const fetchPaginatedData = async () => {
        if (pagination.pageSize < pageParams.totalSize) {
          setPageParams({
            totalSize: data?.total ?? 0,
            totalPageCount: Math.ceil(pageParams.totalSize / pagination.pageSize),
          });
        } else {
          setPageParams({
            totalSize: data?.total ?? 0,
            totalPageCount: 1,
          });
        }

        const offset = pageIndex * pageSize;

        if (offset && offset > pageParams.totalSize) {
          return;
        }

        const newQueryData = {
          search: search,
          offset: offset,
          limit: pagination.pageSize,
          sort: 'ASC',
        };
        return setQueryData({ ...newQueryData });
      };
      fetchPaginatedData();
    },
    200,
    [pagination]
  );

  const fetchData = ({ pageIndex, sortBy }: OnFetchProps) => {
    if (sortBy && sortBy?.length > 0 && pagination.sortBy) {
      if (
        pagination?.sortBy[0]?.id !== sortBy[0].id ||
        pagination.sortBy[0]?.desc !== sortBy[0].desc
      ) {
        setPagination({
          ...pagination,
          pageIndex,
          pageSize: pagination.pageSize,
        });
      }
    }
  };

  const formatData = (): AccountRow[] => {
    if (!organizations?.length) return [];
    else {
      if (setAccountsCounts) {
        setAccountsCounts(pageParams.totalSize);
      }
      return organizations?.map((org: Organization) => {
        return {
          id: org.id,
          name: org.name,
          clinics: org?.children,
          patientCount: org.patientCount || 0,
          providerCount: org.userCount || 0,
          createdAt: Date.parse(org.createdAt),
          isActive: org?.isActive,
        };
      });
    }
  };

  const parseSearchColumnAccount = (name?: string) => {
    if (!name) return '';
    const splitName = name?.split(' ').filter((e) => e);
    if (splitName.length > 1) return `${splitName.join('&')}`;
    return splitName[0];
  };

  const fetchDataForPage = (page: number) => {
    const offset = (page - 1) * rowsPerPage;

    const newQueryData = {
      sort: pagination?.sort ?? 'ASC',
      search: search,
      offset,
      limit: pagination.pageSize,
    };
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('page', page.toString());
    urlParams.set('pageSize', pagination.pageSize.toString());
    history.push({ search: urlParams.toString() });
    setQueryData(newQueryData);
    setCurrentPage(page);
  };

  useDebounce(
    async () => {
      if (!search) search = '';
      if (parseSearchColumnAccount(search) !== pagination?.searchWord) {
        setCurrentPage(1);
        fetchDataForPage(1);
        setPagination({
          ...pagination,
          pageIndex: 0,
          goToPage: 0,
          initialSize: 0,
          searchWord: search,
          offset: 0,
        });
        urlParams.set('page', '1');
        urlParams.set('pageSize', pagination.pageSize.toString());
        urlParams.set('search', search.toString());
        history.push({ search: urlParams.toString() });
      }
    },
    500,
    [search]
  );

  if (orgLoading) {
    return <Spinner />;
  }

  const changeDropDown = (newValue) => {
    setRowsPerPage(newValue);
    setPagination({
      ...pagination,
      pageSize: newValue,
    });
    fetchDataForPage(1);
    urlParams.set('page', '1');
    urlParams.set('pageSize', newValue.toString());
    history.push({ search: urlParams.toString() });
  };
  const handleReload = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error('Error while refetching:', error);
    }
  };
  const getPageNumberRange = () => {
    const totalPageButtons = 5;
    const pageRange: number[] = [];

    const leftBoundary = Math.max(currentPage - Math.floor(totalPageButtons / 2), 1);
    const rightBoundary = Math.min(leftBoundary + totalPageButtons - 1, totalPages);

    for (let i = leftBoundary; i <= rightBoundary; i++) {
      pageRange.push(i);
    }

    return pageRange;
  };
  if (status === 'error') {
    return (
      <div>
        <span style={{ padding: '10px' }}>Could not load accounts.</span>
        <Button onClick={handleReload}>Reload</Button>
      </div>
    );
  }
  if (orgLoading) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {!orgLoading && organizations?.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <span style={{ fontSize: '16px', fontWeight: '600' }}>No Accounts found</span>
        </div>
      ) : (
        <>
          <DataTable
            isSelectable
            columns={columns}
            // hasPagination
            isSortable
            data={formatData()}
            fetchData={fetchData}
            search={''}
            isLoading={orgLoading}
            onRowsSelected={(rows) => {
              setSelectedAccounts(rows);
            }}
            totalPageCount={pageParams.totalPageCount}
            totalRowCount={pageParams.totalSize}
            initialPageSize={ITEMS_PER_PAGE}
          />
          <div>
            <div
              className=""
              style={{
                backgroundColor: '#fff',
                borderRadius: '7px',
                padding: '12px 24px',
                fontSize: '14px',
                textAlign: 'right',
                marginTop: '2px',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <div>
                <label>
                  <span
                    style={{
                      color: 'rgb(108, 119, 137)',
                      display: 'inline-block',
                      marginRight: '15px',
                    }}
                  >
                    {' '}
                    Rows per page:
                  </span>
                </label>
                <span className="pagination-ddl">
                  <Menu>
                    <MenuButton as={Button} rightIcon={'drop-down'}>
                      {rowsPerPage}
                    </MenuButton>
                    <MenuList>
                      <MenuItem value={10} onClick={() => changeDropDown(10)}>
                        10
                      </MenuItem>
                      <MenuItem value={20} onClick={() => changeDropDown(20)}>
                        {' '}
                        20
                      </MenuItem>
                      <MenuItem value={50} onClick={() => changeDropDown(50)}>
                        {' '}
                        50
                      </MenuItem>
                      <MenuItem value={100} onClick={() => changeDropDown(100)}>
                        {' '}
                        100
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </span>
              </div>
              <div className="pagination">
                {' '}
                {/* Display "1-20 of total" */}{' '}
                <span className="page-info" style={{ padding: '0 36px' }}>
                  {' '}
                  {startIndex + 1}-{Math.min(startIndex + rowsPerPage, pageParams.totalSize)} of{' '}
                  {pageParams.totalSize}{' '}
                </span>
                <button
                  style={{ marginRight: '30px', position: 'relative', top: '-4px' }}
                  disabled={currentPage === 1}
                  onClick={() => fetchDataForPage(1)}
                >
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <div className="arrow left" style={{ position: 'relative', right: '-4px' }} />
                    <div className="arrow left" />
                  </div>
                </button>{' '}
                {/* Previous arrow */}{' '}
                <button
                  style={{ marginRight: '30px', position: 'relative', top: '-4px' }}
                  disabled={currentPage === 1}
                  onClick={() => fetchDataForPage(currentPage - 1)}
                >
                  <div className="arrow left" />
                </button>{' '}
                {/* Page numbers */}{' '}
                {getPageNumberRange().map((pageNumber) => (
                  <button
                    style={{ margin: '0 10px' }}
                    key={pageNumber}
                    onClick={() => fetchDataForPage(pageNumber)}
                    className={`pagination-button ${currentPage === pageNumber ? 'active' : ''}`}
                  >
                    {' '}
                    {pageNumber}{' '}
                  </button>
                ))}{' '}
                {/* Next arrow */}{' '}
                <button
                  style={{ marginLeft: '30px' }}
                  disabled={currentPage === totalPages}
                  onClick={() => fetchDataForPage(currentPage + 1)}
                >
                  <div className="arrow right" />
                </button>
                <button
                  style={{ marginLeft: '30px' }}
                  disabled={currentPage === totalPages}
                  onClick={() => fetchDataForPage(totalPages)}
                >
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <div className="arrow right" />
                    <div className="arrow right" style={{ position: 'relative', left: '-4px' }} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
}
