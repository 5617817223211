import {
  ClinicalNote,
  CreateClinicalNoteDto,
  CreateSocialNoteDto,
  DefaultService as Service,
  SocialNote,
} from '~generated';
import toast from 'react-hot-toast';
import { useFetch, UseQueryResult } from '~src/services/query';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { QuerySortOperator, QueryFilter } from '@nestjsx/crud-request';
import { useOktaAuth } from '@okta/okta-react';
import { globalConfig } from '~src/configuration/config';

type CustomSort = {
  field: keyof ClinicalNote;
  order: QuerySortOperator;
};

interface UseListNotes {
  page?: number;
  sort?: CustomSort;
  filter?: QueryFilter;
  value?: string;
  id: string;
}

const notifySuccess = () => toast.success('Note created successfully!');
const notifyError = () => toast.error('There was an error creating your note');

export function useListNotesByPatient({ id }: UseListNotes): UseQueryResult<any[]> {
  return useFetch<any[]>({
    key: ['notes', id],
    path: `/notes/${id}`,
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });
}

export function useCreateNote(): UseMutationResult<ClinicalNote, unknown, CreateClinicalNoteDto> {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => {
      return Service.createOneBaseNotesControllerClinicalNote(payload);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('notes'),
    }
  );
}

export function useCreateSocialNote(): UseMutationResult<SocialNote, unknown, CreateSocialNoteDto> {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => {
      return Service.createOneBaseSocialNotesControllerSocialNote(payload);
    },
    {
      onSuccess: () => {
        notifySuccess();
        queryClient.invalidateQueries('social-notes');
      },
      onError: () => {
        notifyError();
      },
    }
  );
}

interface EditNotePayload extends SocialNote {
  id: string;
}

export function useUpdateClinicalNote(): UseMutationResult<
  unknown,
  unknown,
  Partial<EditNotePayload>
> {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => {
      return Service.updateOneBaseNotesControllerClinicalNote(
        payload.id!,
        payload as EditNotePayload
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries('notes'),
    }
  );
}

export function useUpdateSocialNote(): UseMutationResult<
  unknown,
  unknown,
  Partial<EditNotePayload>
> {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => {
      return Service.updateOneBaseSocialNotesControllerSocialNote(
        payload.id!,
        payload as EditNotePayload
      );
    },
    {
      onSuccess: () => queryClient.invalidateQueries('social-notes'),
    }
  );
}

type DeletePayload = {
  id: string;
};

export function useDeleteSocialNote(): ({ id }: DeletePayload) => Promise<any> {
  const queryClient = useQueryClient();
  const { authState } = useOktaAuth();
  const config = globalConfig.get();
  return async (payload) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
      },
    };
    const response = await fetch(
      `${config.apiUrl}/social-notes/${payload.id}`,
      requestOptions
    ).then(() => queryClient.invalidateQueries('social-notes'));
    return response;
  };
}

export function useDeleteClinicallNote(): UseMutationResult<
  DeletePayload,
  unknown,
  DeletePayload,
  unknown
> {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: DeletePayload) => {
      return Service.deleteOneBaseNotesControllerClinicalNote(payload.id);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('notes'),
    }
  );
}

export function useListSocialNotesByPatient({ id }: UseListNotes): UseQueryResult<SocialNote[]> {
  return useFetch<any[]>({
    key: ['social-notes'],
    path: `/social-notes/${id}`,
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });
}
