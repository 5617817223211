import { Text } from '@rhythm/components';
export interface DaysTransmittedCellProps {
  id: string;
}
export interface DaysTransmittedCellPropsFastReview {
  days: number;
}

export function DaysTransmittedCell({ days }: DaysTransmittedCellPropsFastReview) {
  return (
    <Text color="neutral.100" casing="uppercase">
      {days}
    </Text>
  );
}
