import { HStack, useNumberInput } from '@chakra-ui/react';
import { IconButton, Input } from '@rhythm/components';
import { useField } from 'formik';

interface NumberInputProps {
  name: string;
  step?: number;
  defaultValue?: number;
  min?: number;
  max?: number;
  precision?: number;
  isReadOnly?: boolean;
  maxW?: string;
}

export function NumberInput({
  name,
  step = 1,
  defaultValue = 0,
  min = 0,
  max,
  precision = 1,
  maxW = '150px',
}: NumberInputProps) {
  const [field, , helpers] = useField<number>(name);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step,
    defaultValue,
    min,
    max,
    precision,
    ...field,
    onChange: (_, valueAsNumber) => helpers.setValue(valueAsNumber),
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack maxW={maxW}>
      <IconButton
        {...dec}
        backgroundColor="#EFF2F6"
        padding="xl"
        variant="solid"
        icon="minimize"
        aria-label={`decrease time by ${1} minute`}
      />
      <Input
        {...input}
        borderColor="neutral.200"
        borderWidth="1px"
        borderRadius="md"
        height="100%"
        name="minutes"
      />
      <IconButton
        {...inc}
        backgroundColor="#EFF2F6"
        padding="xl"
        variant="solid"
        icon="add"
        aria-label={`increase time by ${1} minute`}
      />
    </HStack>
  );
}
