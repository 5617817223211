import { Button } from '@rhythm/components';

export interface EscalatedCellProps {
  escalatedAt: any;
  id?: string;
  refetch?: () => void;
}
export function EscalatedInteractionCell({ escalatedAt }: EscalatedCellProps): JSX.Element {
  const isEscalated = !!escalatedAt;
  return (
    <div>
      {isEscalated ? (
        <Button
          bg={'transparent'}
          _hover={{
            background: '#dadde3',
            cursor: 'pointer',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M5.33341 9.33325C3.33341 9.33325 2.66675 9.99992 2.66675 9.99992V2.66659C2.66675 2.66659 3.33341 1.99992 5.33341 1.99992C7.33342 1.99992 8.66675 3.33325 10.6667 3.33325C12.6667 3.33325 13.3334 2.66659 13.3334 2.66659V9.99992C13.3334 9.99992 12.6667 10.6666 10.6667 10.6666C8.66675 10.6666 7.33342 9.33325 5.33341 9.33325Z"
              fill="#FF5D5A"
            />
            <path
              d="M2.66675 9.99992C2.66675 9.99992 3.33341 9.33325 5.33341 9.33325C7.33341 9.33325 8.66675 10.6666 10.6667 10.6666C12.6667 10.6666 13.3334 9.99992 13.3334 9.99992V2.66659C13.3334 2.66659 12.6667 3.33325 10.6667 3.33325C8.66675 3.33325 7.33341 1.99992 5.33341 1.99992C3.33341 1.99992 2.66675 2.66659 2.66675 2.66659M2.66675 14.6666L2.66675 1.33325"
              stroke="#FF5D5A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      ) : (
        <Button
          bg={'transparent'}
          _hover={{
            background: 'red',
            cursor: 'pointer',
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M2.66675 9.99992C2.66675 9.99992 3.33341 9.33325 5.33341 9.33325C7.33341 9.33325 8.66675 10.6666 10.6667 10.6666C12.6667 10.6666 13.3334 9.99992 13.3334 9.99992V2.66659C13.3334 2.66659 12.6667 3.33325 10.6667 3.33325C8.66675 3.33325 7.33341 1.99992 5.33341 1.99992C3.33341 1.99992 2.66675 2.66659 2.66675 2.66659M2.66675 14.6666L2.66675 1.33325"
              stroke="#6C7789"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      )}
    </div>
  );
}
