import {
  FormLabel,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Flex,
  FormControl,
} from '@rhythm/components';
import { useField, useFormikContext, FormikProps } from 'formik';
import { EnrollPatientFormValues } from '../EnrollPatientForm/EnrollPatientForm';

interface SliderProps {
  label: string;
  ariaLabel: string;
  defaultValue?: number;
  name: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  unit?: string;
}

export function FormSlider(props: SliderProps) {
  const {
    label,
    ariaLabel,
    disabled = false,
    defaultValue = 0,
    min = 0,
    max = 100,
    unit = '',
  } = props;

  const { values }: FormikProps<EnrollPatientFormValues> = useFormikContext();
  const [field, meta, helpers] = useField<number>(props);

  const handleChange = (value: number) => {
    helpers.setValue(value);
  };

  const handleDisabled = () => {
    if (values.isEditing === undefined) {
      return false;
    }
    return !values.isEditing;
  };

  return (
    <FormControl mb="2xl">
      <FormLabel fontSize="xs" mb="2xl">
        {label}
      </FormLabel>
      <Slider
        {...field}
        value={meta.value}
        onChange={handleChange}
        aria-label={ariaLabel}
        defaultValue={defaultValue}
        isDisabled={disabled || handleDisabled()}
        min={min}
        max={max}
      >
        <SliderTrack>
          <Box position="relative" right={10} />
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb borderColor="primary.500" borderWidth="3px" position="relative" zIndex={0}>
          <Flex
            position="absolute"
            top="-24px"
            color="neutral.800"
            w="36px"
            justifyContent="center"
          >
            {`${meta.value}${unit}`}
          </Flex>
        </SliderThumb>
      </Slider>
    </FormControl>
  );
}
