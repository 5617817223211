import { Badge } from '@rhythm/components';

export interface PatientStatusCellProps {
  statusType: string;
}

export function PatientStatusCell({ statusType }: PatientStatusCellProps) {
  const bgColor = (status: string) => {
    switch (status) {
      case 'new':
        return 'gray.100';
      case 'active':
        return 'green.100';
      case 'non-adherent':
        return 'yellow.100';
    }
  };
  const borColor = (status: string) => {
    switch (status) {
      case 'new':
        return 'gray.500';
      case 'active':
        return 'green.500';
      case 'non-adherent':
        return 'yellow.500';
    }
  };
  return (
    <Badge
      backgroundColor={bgColor(statusType)}
      borderColor={borColor(statusType)}
      borderWidth="1px"
      textColor={'black'}
    >
      {statusType?.toUpperCase()}
    </Badge>
  );
}
