import { makeOptionsArray } from '~src/utils';
import type { Option } from '~src/utils';

export const icd10CodesHypertension = {
  I10: 'I10 - Essential/Primary Hypertension',
  'I11.0': 'I11.0 - Hypertensive Heart Disease with Heart Failure',
  'I11.9': 'I11.9 - Hypertensive Heart Disease without Heart Failure',
  'I12.0':
    'I12.0 - Hypertensive Chronic Kidney Disease with chronic kidney disease stage 5, or end stage renal disease',
  'I12.9':
    'I12.9 - Hypertensive Chronic Kidney Disease with with chronic kidney disease stage 1 - 4, or unspecified chronic kidney disease',
  'I13.0':
    'I13.0 - Hypertensive Heart and Chronic Kidney Disease with heart failure and chronic kidney disease stage 1 - 4',
  'I13.1':
    'I13.1 - Hypertensive Heart and Chronic Kidney Disease without heart failure and chronic kidney disease stage 1 - 4',
  'I13.11':
    'I13.11 - Hypertensive Heart and Chronic Kidney Disease without heart failure and chronic kidney disease stage 5 without heart failure and chronic kidney disease stage 5',
  'I13.2':
    'I13.2 - Hypertensive Heart and Chronic Kidney Disease with heart failure and chronic kidney disease stage 5',
  I15: 'I15 - Secondary Hypertension',
  I16: 'I16 - Hypertensive Crisis',
};

export const icd10CodesHypertensionOptionsArray: Option[] =
  makeOptionsArray(icd10CodesHypertension);
