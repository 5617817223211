import { ScriptableContext } from 'chart.js';
import { DeviceThreshold } from '~generated';
import { WeightData } from '~src/pages/Patients/PatientOverview/DataTabs';

export const pointResolverHelper = (
  context: ScriptableContext<'line'>,
  defaultValue: string | number,
  conditionValue: string | number,
  threshold: DeviceThreshold | undefined,
  weightData: (WeightData | null)[] = []
): any => {
  const index = context.dataIndex;
  const value = context.dataset.data[index];
  if (!value) {
    return defaultValue;
  }
  if (weightData.length > 0) {
    return weightData[index]?.outlier === true ? conditionValue : defaultValue;
  }
  if (!threshold) {
    return defaultValue;
  }
  return value > threshold.threshHoldUpperLimit || value < threshold.threshHoldLowerLimit
    ? conditionValue
    : defaultValue;
};
