import { IconButton, Text, Tooltip } from '@rhythm/components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { PatientTime } from '~generated';
import { PaginationResult, useCreateReview } from '~src/services/patients';
import { orderBy } from 'lodash';

dayjs.extend(relativeTime);

export interface LastInteractionCellProps {
  timestamp?: dayjs.ConfigType;
  name?: string;
}

export interface ReviewedCellProps {
  isReviewed?: boolean;
  id: string;
  minutes?: PatientTime[];
  latestTimeAccruedByUser?: string;
  latestTimeAccruedByUserTimestamp?: string;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<PaginationResult, unknown>>;
}

function Label({ timestamp, name }: { timestamp: dayjs.ConfigType; name: string }) {
  if (timestamp) {
    const datetime = dayjs(timestamp).format('MMM D, HH:mm:ss');
    return (
      <>
        <Text fontWeight="bold" color="neutral.100" casing="uppercase">
          Viewed {datetime}
        </Text>
        <Text color="neutral.400">{name}</Text>
      </>
    );
  } else {
    return (
      <Text fontWeight="bold" color="neutral.100" casing="uppercase">
        Not Viewed
      </Text>
    );
  }
}

export function LastInteractionCell({
  isReviewed,
  id,
  latestTimeAccruedByUser = '',
  latestTimeAccruedByUserTimestamp = '',
  minutes,
  refetch,
}: ReviewedCellProps): JSX.Element {
  const reviewPatient = useCreateReview({ refetch });

  if (
    (latestTimeAccruedByUser == null || latestTimeAccruedByUserTimestamp == null) &&
    minutes != null
  ) {
    const mostRecentTimeAccrued = orderBy(minutes, ['createdAt'], ['desc'])[0];
    // eslint-disable-next-line max-len
    latestTimeAccruedByUser =
      mostRecentTimeAccrued.user.firstName + ' ' + mostRecentTimeAccrued.user.lastName;
    latestTimeAccruedByUserTimestamp = mostRecentTimeAccrued.createdAt;
  }

  return (
    <Tooltip
      hasArrow
      label={<Label timestamp={latestTimeAccruedByUserTimestamp} name={latestTimeAccruedByUser} />}
      placement="bottom-end"
    >
      <div>
        <IconButton
          aria-label="check"
          disabled={isReviewed}
          isLoading={reviewPatient.isLoading}
          size="sm"
          icon="check"
          onClick={async () => {
            await reviewPatient.mutate({ id });
          }}
        />
      </div>
    </Tooltip>
  );
}
