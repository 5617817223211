import { Flex, Box, Checkbox, FormLabel, Text } from '@rhythm/components';
import { Field, Form, Formik } from 'formik';
import { FormField } from '../FormField';
import { FormSelect } from '../FormSelect';
import { Roles } from '~generated/models/Roles';
import { User } from '~generated';
import { useUserRoleAndEmail } from '~src/hooks';
interface FormValues {
  note: string;
  userId: string;
  subject: string;
  header: string;
  body: string;
  emailTo: string;
  bcc: string;
  escalate: boolean;
}
export interface AlertModalForm {
  setOpen: (open: boolean) => void;
  isEescalate: boolean;
  setEscalate: (escalate: boolean) => void;
  handleSubmit: (values: FormValues) => void;
  data: any;
  cliniciansData: User[];
}
export function AlertModalForm({
  setOpen,
  isEescalate,
  setEscalate,
  handleSubmit,
  data,
  cliniciansData,
}: AlertModalForm) {
  const { userRole, email: mailId } = useUserRoleAndEmail();
  const isInternal = [
    'roles:internal:super-admin',
    'roles:internal:lead-clinician',
    'roles:internal:admin',
  ].some((role) => userRole.includes(role));

  const handleClose = () => setOpen(false);
  const initialValues: FormValues = {
    note: data?.patient[0].escalatedToUser === null ? '' : 'Escalate to physician',
    userId: '',
    subject: 'Escalated Alert in RPM',
    header: mailId,
    body: data?.defaultEscalationNote ? data?.defaultEscalationNote : '',
    bcc: mailId,
    emailTo: '',
    escalate: data?.patient[0].escalatedToUser === null ? false : true,
  };
  const handleVerify = (
    setFieldValue: (field: string, value: string) => void,
    checked: boolean
  ) => {
    if (checked) setFieldValue('note', 'Escalate to physician');
    if (!checked) setFieldValue('note', '');
    setEscalate(!isEescalate);
  };
  const validationFunction = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (isInternal && isEescalate) {
      if (values.body.length === 0) {
        errors.body = 'This field is required';
      }
      if (!values.bcc) {
        errors.bcc = 'This field is required';
      }
      if (!values.emailTo) {
        errors.emailTo = 'This field is required';
      }
      if (!values.header) {
        errors.header = 'This field is required';
      }
      if (!values.subject) {
        errors.subject = 'This field is required';
      }
    }
    if (!isInternal) {
      if (isEescalate) {
        if (values.userId.length === 0) {
          errors.userId = 'This field is required';
        }
        if (values.note.length === 0) {
          errors.note = 'This field is required';
        }
      } else {
        if (values.note.length === 0) {
          errors.note = 'This field is required';
        }
      }
    }
    return errors;
  };

  const teamLeadOptions = cliniciansData
    ?.filter(
      (user: User) =>
        user.role === Roles.ROLES_INTERNAL_LEAD_CLINICIAN ||
        user.role === Roles.ROLES_INTERNAL_CLINICAL
    )
    ?.map((user: User) => {
      return {
        label: `${user.lastName}, ${user.firstName}`,
        value: user.email,
      };
    });

  return (
    <>
      <Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validationFunction}
          onReset={handleClose}
        >
          {({ setFieldValue, errors }) => (
            <Form id="escalate-form">
              {!isInternal && (
                <Box>
                  <FormLabel fontSize="sm">Notes</FormLabel>
                  <Field
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('note', event.target.value);
                    }}
                    as="textarea"
                    name="note"
                    placeholder="Notes..."
                    className="chakra-textarea"
                    style={{
                      height: '100%',
                      resize: 'none',
                      width: '50%',
                      outline: '2px solid transparent',
                      boxShadow: '0px 0.5px 1px rgb(0 0 0 / 10%)',
                      borderColor: '#B8C0CE',
                      borderWidth: '0.5px',
                      paddingInlineStart: '1rem',
                      paddingInlineEnd: '1rem',
                      borderRadius: '4px',
                      paddingTop: '8px',
                      paddingBottom: '10px',
                    }}
                  />
                  <Text style={{ fontSize: '11px', color: '#e53e3e' }}>{errors.note}</Text>
                </Box>
              )}
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleVerify(setFieldValue, event.target.checked)
                }
                defaultChecked={data?.patient[0].escalatedToUser === null ? false : true}
                name="escalate"
                style={{ paddingBottom: '10px' }}
              >
                Escalate
              </Checkbox>
              {isEescalate && !isInternal && (
                <FormSelect
                  name="userId"
                  style={{ width: '50%' }}
                  label="User"
                  options={teamLeadOptions}
                />
              )}
              {isEescalate && isInternal && (
                <Box>
                  <Flex>
                    <Box paddingRight={'30px'} w={'50%'}>
                      <FormField name="emailTo" label="Email To" />
                    </Box>
                    <Box w={'50%'}>
                      <FormField name="header" label="Reply to header" />
                    </Box>
                  </Flex>
                  <Flex>
                    <Box paddingRight={'30px'} w={'50%'}>
                      <FormField name="subject" label="Subject" />
                    </Box>
                    <Box w={'50%'}>
                      <FormField name="bcc" label="BCC" />
                    </Box>
                  </Flex>
                  <Box>
                    <FormLabel fontSize="sm">Body</FormLabel>
                    <Field
                      as="textarea"
                      name="body"
                      placeholder="Body..."
                      className="chakra-textarea"
                      style={{
                        height: '200%',
                        resize: 'none',
                        width: '100%',
                        outline: '2px solid transparent',
                        boxShadow: '0px 0.5px 1px rgb(0 0 0 / 10%)',
                        borderColor: '#B8C0CE',
                        borderWidth: '0.5px',
                        paddingInlineStart: '1rem',
                        paddingInlineEnd: '1rem',
                        borderRadius: '4px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        minHeight: '150px',
                      }}
                    />
                    <Text style={{ fontSize: '11px', color: '#e53e3e' }}>{errors.body}</Text>
                  </Box>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
