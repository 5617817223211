import {
  Patient,
  Contact,
  CreatePatientDto,
  DefaultService as Service,
  MonthlyContact,
  ExportPatientDataDto,
  PatientThreshold,
  BloodPressure,
  BloodSugar,
  Pulse,
  PulseOx,
  Weight,
  TeamMember,
} from '~generated';
import { useHistory } from 'react-router-dom';
import { QuerySortOperator, QueryFilter } from '@nestjsx/crud-request';
import { useCrud, useFetch } from '~src/services/query';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { SortingRule } from 'react-table';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { Filter } from './clinics';
import { useOktaAuth } from '@okta/okta-react';
import { globalConfig } from '../configuration/config';
import { MetricCellProps } from '~src/components/PatientsDataView';
import { getErrorMessage } from '~src/helpers/errorMessages';
import { DataReading, DateRange } from '~src/pages/Patients/PatientOverview';
import { FormFilterValues } from '~src/pages/Patients/PatientFilters';
import { SetStateAction } from 'react';
import { PatientsSearchData } from '~src/components/PatientsDataView/PatientsDataView';
import { NewSocialNote } from '~src/components/Alert/PatientPinnedNotes';
import { AlertClinicalNotesDataModal } from '~src/components/Alert/PatientClinicalNotes';
import { getUTCDateRange } from '~src/utils/getUtcDateRange';

dayjs.extend(utc);

type CustomSort = {
  field: keyof Patient;
  order: QuerySortOperator;
};

type S3File = {
  url?: string;
  Key?: string;
};

export interface UseListPatients {
  page?: number;
  sort?: CustomSort;
  path?: string;
  filter?: QueryFilter;
}

export interface fastReviewComplete {
  patientIds: any;
  currentTimestamp: string;
}

export interface ReviewCount {
  total: string;
  currentOffset: number;
}

export interface fastReviewComplete {
  patientIds: any;
  currentTimestamp: string;
}

export interface PaginationSort {
  sort?: SortingRule<unknown>[];
  search?: string;
  offset?: number;
  limit?: number;
  filters?: FormFilterValues;
  currentTimestamp?: string;
}

export interface AlertPaginationSort {
  sort?: string;
  search?: string;
  offset?: number;
  limit?: number;
  sortBy?: string;
  clinicianIds?: string[];
  isDefaultSearch?: boolean;
}

export interface FastReviewResponse extends Response {
  ReviewedRecords: number;
  status: number;
}

export interface AlertPaginationResult {
  data: PatientsSearchData[];
  totalCount: number;
  offset: number;
  teamClinicians?: TeamMember[];
}

export interface PaginationResult {
  patients: PatientsSearchData[];
  total: number;
  currentOffset: number;
}
export interface PaginationResultFastReview {
  patients: PatientsSearchData[];
  total: number;
  currentOffset: number;
  totalReviewCount?: number;
}

interface ReadingThreshold {
  id: string;
  type?: PatientThreshold.readingType;
  dateRange: DateRange;
  timeZone: string;
  postalCode: string;
}

export interface AlertModalData {
  patient: Patient[];
  clinicalNotes: AlertClinicalNotesDataModal[];
  pinnedNotes: NewSocialNote[];
  bloodPressureData: BloodPressure[];
  bloodSugarData: BloodSugar[];
  pulseData: Pulse[];
  pulseOxData: PulseOx[];
  weightData: Weight[];
}

export type UseCreatePatient = Omit<CreatePatientDto, 'isActive' | 'status'>;

const notifySuccess = (message: string) => toast.success(message);

const notifySuccessNote = () => toast.success(`Review Logged.`);

const notifyErrorNote = () =>
  toast.error(`Please clear all pending alerts belonging to patient from the alert tab`);

const notifyError = (status: number | undefined, message: string | null = null) => {
  let errorMessage;
  if (!message) {
    errorMessage = getErrorMessage(status);
  } else {
    errorMessage = message;
  }
  toast.error(errorMessage);
};

export interface ResponseError extends Error {
  status?: number;
  body?: any;
}

export function useCreatePatient(setButtonDisabled: {
  (value: SetStateAction<boolean>): void;
  (arg0: boolean): void;
}): UseMutationResult<Patient, unknown, UseCreatePatient> {
  return useMutation(
    (payload) => {
      payload.birthDate = formattedDOB(payload.birthDate);
      setButtonDisabled(true);
      const formattedPayload = {
        ...payload,
        isActive: true,
        status: CreatePatientDto.status.ENROLLED,
      };
      return Service.createOneBasePatientsControllerPatient(formattedPayload);
    },
    {
      onSuccess: (data) => {
        notifySuccess(`${data?.firstName} has been successfully Enrolled.`);
        setButtonDisabled(false);
      },
      onError: async (error: ResponseError) => {
        const errorMessage = JSON.parse(await error?.body?.message);
        if (errorMessage?.message === 'DUPLICATE_DEVICE') {
          notifyError(error.status, 'Device already exists');
        } else if (errorMessage?.message === 'DUPLICATE_PATIENT') {
          notifyError(error.status, 'Error: Duplicate Patient');
        } else {
          notifyError(error.status);
        }
        setButtonDisabled(false);
      },
    }
  );
}

interface UseCreateReview {
  id: string;
}

export function useCreateReview({
  refetch,
}: {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<PaginationResult, unknown>>;
}): UseMutationResult<unknown, unknown, UseCreateReview> {
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      return Service.patientsControllerCreateReview(payload.id);
    },
    {
      onSuccess: () => {
        notifySuccessNote();
        queryClient.invalidateQueries('patients');
        refetch();
      },
      onError: () => {
        notifyErrorNote();
      },
    }
  );
}

export function useUpdatePatient(): UseMutationResult<Patient, unknown, Patient> {
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      payload.birthDate = formattedDOB(payload.birthDate);
      return Service.patientsControllerUpdate(payload.id, payload);
    },
    {
      onSuccess: (data) => {
        notifySuccess(`${data?.firstName} has been successfully Updated.`);
        queryClient.invalidateQueries('patients-detail');
        setTimeout(() => {
          location.reload();
        }, 1000);
      },
      onError: async (error: ResponseError) => {
        const errorMessage = JSON.parse(await error?.body?.message);
        if (errorMessage?.message === 'DUPLICATE_DEVICE') {
          notifyError(error.status, 'Device already exists');
        } else {
          notifyError(error.status);
        }
      },
    }
  );
}

export function useListPatients({
  page,
  sort,
  filter,
}: UseListPatients): UseQueryResult<Patient[]> {
  return useCrud<Patient[]>({
    key: ['patients', filter, page, JSON.stringify(sort)],
    path: '/patients',
    queryParams: {
      page,
      sort,
      limit: 10,
      filter,
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useFastReviewPatientReadings(): ({
  patientIds,
  currentTimestamp,
}: fastReviewComplete) => Promise<FastReviewResponse> {
  const { authState } = useOktaAuth();
  const config = globalConfig.get();
  return async (body) => {
    const response = await fetch(`${config.apiUrl}/patients/bulkreview`, {
      headers: {
        Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (response.status === 201) {
      const data: FastReviewResponse = await response.json();
      data['status'] = response.status;
      return data;
    } else {
      return {} as FastReviewResponse;
    }
  };
}

export function usePatientFastReviewReading(
  { id, type }: MetricCellProps,
  skip: boolean
): UseQueryResult<Patient> {
  return useFetch({
    key: ['patient', 'reading', id, type],
    path: '/patients/fast-review/reading',
    method: 'POST',
    enabled: skip,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ type, id }),
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

/**
 *
 * @returns /patients/patients/paginate
 */

export function usePatientOpenSearch({
  sort,
  search,
  offset,
  limit,
  filters,
}: PaginationSort): UseQueryResult<PaginationResult> {
  return useFetch({
    key: ['patient', 'pagination', JSON.stringify(sort), search, offset, limit, filters],
    path: `/patients/patients/search`,
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
    queryParams: {
      sort: JSON.stringify(sort),
      search,
      offset,
      limit,
      filters: JSON.stringify(filters),
    },
  });
}

export function usePatientPagination({
  sort,
  search,
  offset,
  limit,
  filters,
}: PaginationSort): UseQueryResult<PaginationResult> {
  return useFetch({
    key: `patient-pagination-${JSON.stringify(sort)}-${search}-${offset}-${limit}-${JSON.stringify(
      filters
    )}`,
    path: `/patients/patients/paginate`,
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
    queryParams: {
      sort: JSON.stringify(sort),
      search,
      offset,
      limit,
      filters: JSON.stringify(filters),
    },
  });
}
export function usePatientsAlertList({
  sort,
  offset,
  limit,
  sortBy,
  search,
  clinicianIds,
  isDefaultSearch,
}: AlertPaginationSort): UseQueryResult<AlertPaginationResult> {
  return useFetch({
    key: `patient-alerts-${JSON.stringify(
      sort
    )}-${offset}-${limit}-${search}-${clinicianIds}-${isDefaultSearch}}`,
    path: `/patients/patients/alerts`,
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
    queryParams: {
      sort: sort ?? 'DESC',
      page: (offset ?? 0) / (limit ?? 0) + 1 ?? 1,
      perPage: limit,
      search: search,
      secondarySortBy: sortBy ?? '',
      clinicianIds: clinicianIds,
      isDefaultSearch: isDefaultSearch,
    },
  });
}
export function usePatientFastReviewPagination({
  sort,
  search,
  offset,
  limit,
  filters,
  currentTimestamp,
}: PaginationSort): UseQueryResult<PaginationResultFastReview> {
  return useFetch({
    key: [
      'patient',
      'pagination',
      JSON.stringify(sort),
      search,
      offset,
      limit,
      filters,
      currentTimestamp,
    ],
    path: `/patients/fast-review/paginate`,
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
    queryParams: {
      sort: JSON.stringify(sort),
      search,
      offset,
      limit,
      filters: JSON.stringify(filters),
      currentTimestamp: currentTimestamp,
    },
  });
}

export function usePatientReviewCount({
  sort,
  search,
  offset,
  limit,
  filters,
}: PaginationSort): UseQueryResult<ReviewCount> {
  return useFetch({
    key: [sort, search, offset, limit, filters],
    path: `/patients/review/count`,
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
    queryParams: {
      sort: JSON.stringify(sort),
      search,
      offset,
      limit,
      filters: JSON.stringify(filters),
    },
  });
}

export function useThresholdReading(
  { id, type, dateRange, timeZone, postalCode }: ReadingThreshold,
  skip: boolean
): UseQueryResult<DataReading> {
  const finalDateRange = getUTCDateRange(dateRange, timeZone, postalCode);
  return useFetch({
    key: ['patients', 'reading', id, type, finalDateRange.utcStartDate, finalDateRange.utcEndDate],
    enabled: skip,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      readingType: type,
      id,
      startDate: finalDateRange.utcStartDate,
      endDate: finalDateRange.utcEndDate,
      timezone: timeZone,
      postalCode,
    }),
    path: `/patients/${id}/threshold`,
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useListUploadedFiles({
  limit,
  offset,
  sort,
  id,
}: Filter): UseQueryResult<S3File[]> {
  return useFetch({
    key: ['upload', limit, offset, sort],
    path: '/upload',
    queryParams: {
      sort,
      path: `Documents/${id}`,
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useSinglePatient(id: string): UseQueryResult<Patient> {
  return useFetch<Patient>({
    key: ['patients', id],
    path: `/patients/${id}/profile`,
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });
}

export function useAlertModalData(id: string): UseQueryResult<AlertModalData> {
  return useFetch<AlertModalData>({
    key: ['alertData', id],
    path: `/patients/patients/${id}/alertData`,
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });
}

export function useSinglePatientDetail(id: string): UseQueryResult<Patient> {
  return useCrud<Patient>({
    key: ['patients-detail', id],
    path: `/patients/${id}`,
    queryParams: {
      join: {
        field: 'threshold',
      },
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useNonAherentPatient(id: string, skip: boolean): UseQueryResult<boolean> {
  return useFetch<boolean>({
    key: ['nonadherent', id],
    path: `/patients/${id}/nonadherent`,
    enabled: skip,
    queryOptions: {
      initialData: false,
      refetchOnWindowFocus: false,
    },
  });
}

// +15555555555 -> +1 (555) 555 5555
export function format(contactNumber: string): string | null {
  if (!contactNumber || typeof contactNumber !== 'string') {
    return null;
  }
  const match = contactNumber.match(/^\+(\d)(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const [, country, area, exchange, line] = match;
    return `+${country} (${area}) ${exchange} ${line}`;
  }
  return null;
}

export const getContact = (contacts: Contact[]): string => {
  return contacts.reduce((acc: string, curr: Contact) => {
    if (curr.type == Contact.type.PHONE) {
      acc = format(curr.contact) || '+2336363636';
    }

    return acc;
  }, '');
};

export function calculateBirthAge(patient: Patient): number {
  return dayjs().year() - dayjs(patient.birthDate).year();
}

export function formattedDOB(date: Date | string): string {
  return dayjs(date).format('YYYY-MM-DD');
}

export function formattedDate(
  timeZone: string,
  date: Date | string,
  format = 'MM/DD/YYYY'
): string {
  if (timeZone === undefined || timeZone === '') return dayjs(date).utc().format(format);
  dayjs.extend(timezone);
  const newdate = dayjs(date).tz(timeZone).format(format);
  return newdate;
}

export function userEmail(patient: Patient): string {
  return patient.contacts.reduce((accumulator, current) => {
    if (current.type === Contact.type.EMAIL && !current.isAlternate) {
      accumulator = current.contact;
    }

    return accumulator;
  }, '');
}

export function alternateContact(patient: Patient): Contact[] {
  return patient.contacts?.reduce((accumulator, current) => {
    if (current.isAlternate) {
      accumulator.push(current);
    }

    return accumulator;
  }, [] as Contact[]);
}

export function phoneNumber(patient: Patient): string {
  return patient?.contacts?.reduce((accumulator, current) => {
    if (!current.isAlternate && current?.type === Contact.type.PHONE) {
      accumulator = current?.contact;
    }

    return accumulator;
  }, '');
}

export function useCreateMonthlyContact(): UseMutationResult<MonthlyContact, unknown, string> {
  const queryClient = useQueryClient();
  return useMutation(Service.patientsControllerCreateMonthlyContact, {
    onSuccess: () => queryClient.invalidateQueries('patients'),
  });
}

export function useRemoveMonthlyContact(): UseMutationResult<number, unknown, string> {
  const queryClient = useQueryClient();
  return useMutation(Service.patientsControllerRemoveMonthlyContact, {
    onSuccess: () => queryClient.invalidateQueries('patients'),
  });
}

interface UseCreatePatientTime {
  patientId: string;
  timeAccrued: number;
}

export function useCreatePatientTime(): UseMutationResult<
  MonthlyContact,
  unknown,
  UseCreatePatientTime
> {
  const queryClient = useQueryClient();
  return useMutation(
    ({ patientId, timeAccrued }) =>
      Service.patientsControllerCreatePatientTime(patientId, { timeAccrued }),
    {
      onSuccess: () => queryClient.invalidateQueries('patients'),
    }
  );
}

export function useCreatePatientDataExport(
  patientId: string
): (payload: ExportPatientDataDto) => Promise<string> {
  const { authState } = useOktaAuth();
  const config = globalConfig.get();
  return async (payload: ExportPatientDataDto) => {
    const response = await fetch(`${config.apiUrl}/patients/${patientId}/export.pdf`, {
      headers: {
        Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error('Failed to download pdf');
    }
    const blob = await response.blob();
    return window.URL.createObjectURL(blob);
  };
}

interface Payload {
  id: string;
}

export function useDeletePatient(): UseMutationResult<unknown, unknown, Payload, unknown> {
  const { push } = useHistory();
  return useMutation(({ id }) => Service.deleteOneBasePatientsControllerPatient(id), {
    onSuccess: () => push('/patients'),
  });
}

interface ArchivePatientPayload {
  patientId: string;
  reason: string;
  optOutReason: string;
  notes: string;
}

export function useArchivePatient(): UseMutationResult<
  unknown,
  unknown,
  ArchivePatientPayload,
  unknown
> {
  const { push } = useHistory();
  return useMutation(
    (payload) => Service.patientsControllerArchivePatient(payload.patientId, payload),
    { onSuccess: () => push('/patients') }
  );
}

export function usePatientOrganization(): () => Promise<string> {
  const { authState } = useOktaAuth();
  const config = globalConfig.get();
  return async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
      },
    };
    const response = await fetch(
      `${config.apiUrl}/patients/update/organizationToPatients/`,
      requestOptions
    ).then((response) => response.json());
    return response;
  };
}

export function useGetPatientTimeZone(postalCode: string): () => Promise<string> {
  const { authState } = useOktaAuth();
  const config = globalConfig.get();
  return async () => {
    const response = await fetch(`${config.apiUrl}/billing/gettimezone/` + postalCode, {
      headers: {
        Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
      },
    }).then((response) => response.json());
    return response;
  };
}

export function useSetToPhysicinasDefaults(patientId: string): () => Promise<any> {
  const { authState } = useOktaAuth();
  const config = globalConfig.get();
  return async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
      },
    };
    const response = await fetch(
      `${config.apiUrl}/patients/${patientId}/setToPhysicinasDefaults`,
      requestOptions
    ).then((response) => response.json());
    return response;
  };
}

export function useGetLanguages(): UseQueryResult<any> {
  return useFetch<any>({
    key: ['languages'],
    path: `/patients/languages`,
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });
}
