import { Heading, Box, Flex, Divider, Button, IconButton, Icon } from '@rhythm/components';
import { CreatePatientDto, Patient, User } from '~generated';
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik';
import { DeviceDetailsForm, ICDCodesDataForm } from './formFields';
import { useClinicUsers, useListDeviceVendors } from '~src/services/clinics';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { createPatientRoles, editPatientUserRoles } from '~src/constants/roles';
import AltContactForm from './components/AltContactForm';
import InsuranceDetailsForm from './components/InsuranceDetailsForm';
import DeviceInformationForm from './components/DeviceInformationForm';
import PhysiologicThresholds from './components/PhysiologicThresholds';
import PhysicianForm from './components/PhysicianForm';
import ClinicalCareTeamForm from './components/ClinicalCareTeamForm';
import PatientInformationForm from './components/PatientInformationForm';
import { Options } from 'react-select';

export interface EnrollPatientFormValues {
  rhythmId: string;
  mrn2: string;
  enrollment?: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  age: string;
  gender?: Options<any>[] | CreatePatientDto.gender;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipcode: string;
  state: Options<any>[] | string;
  country: string;
  timeZone: string;
  landline: string;
  mobileNumber: string;
  preferredNumber: string;
  email: string;
  icdcodes: ICDCodesDataForm[];
  devices: DeviceDetailsForm[];
  bpDiastolic: number[];
  bpSystolic: number[];
  pulse: number[];
  glucose: number[];
  bloodOxygen: number[];
  weightChange24h: number;
  weightChange7Day: number;
  altFirstName: string;
  altLastName: string;
  altPhoneNumber: string;
  altEmail: string;
  altRelation: Options<any>[] | string;
  isPreferredContact: boolean;
  disclosePHI: boolean;
  patientHistory: File | null;
  faceSheet: File | null;
  status: string;
  primaryName: string | undefined;
  primaryMemberId: string | undefined;
  primaryGroupNumber: string | undefined;
  primaryContanctInformation: string | undefined;
  secondaryName: string | undefined;
  secondaryMemberId: string | undefined;
  secondaryGroupNumber: string | undefined;
  secondaryContanctInformation: string | undefined;
  copayAmount?: string;
  requestingPhysician?: Options<any>[] | string;
  requestingClinician?: Options<any>[] | string;
  isOnboardingAssignment?: Options<any>[] | number | string;
  isConsentsToEnrollment: string | boolean | Options<any>[];
  disabledFields?: string[];
  isEditing?: boolean;
  language: Options<any>[] | string;
  team: any;
  clinicians: User[];
  isPhysicianDefaults?: boolean;
  isActive?: boolean;
  firstDischargeDate?: string;
  firstReadmissionDate?: string;
  reasonForReadmission?: string;
  groupIds?: Options<any>[];
}

interface EnrollPatientProps {
  initialValues: EnrollPatientFormValues;
  handleSubmit: (
    values: EnrollPatientFormValues,
    { resetForm }: FormikHelpers<EnrollPatientFormValues>
  ) => Promise<void>;
  validationFunction: (values: EnrollPatientFormValues) => void;
  pageHeader: string;
  allowToggleEdit?: boolean;
  primaryCTAText?: string;
  handleArchivePatient?: () => Promise<void>;
  disabled?: boolean;
  patient?: Patient;
  isLoading: boolean;
}

export function EnrollPatientForm({
  initialValues,
  validationFunction,
  handleSubmit,
  pageHeader,
  allowToggleEdit = false,
  primaryCTAText = 'Create Patient',
  handleArchivePatient,
  disabled = false,
  patient,
  isLoading = false,
}: EnrollPatientProps) {
  const paginatedData = useClinicUsers({ bypassPagination: true });
  const userData = paginatedData?.data?.data;
  userData?.sort(function (a: User, b: User) {
    const nameA = a.firstName.toLowerCase(),
      nameB = b.firstName.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const deviceVendors = useListDeviceVendors({})?.data;

  const handleToggleEdit = (
    setFieldValue: (
      field: string,
      value: boolean
    ) => Promise<void | FormikErrors<EnrollPatientFormValues>>
  ) => {
    setFieldValue('isEditing', true);
  };

  const handleResetForm = (
    resetForm: () => void,
    setFieldValue: (
      field: string,
      value: boolean
    ) => Promise<void | FormikErrors<EnrollPatientFormValues>>
  ) => {
    const confirmReset = confirm("Are you sure? You'll lose the changes that you've made so far!");
    if (confirmReset) {
      setFieldValue('isEditing', false);
      resetForm();
    }
    return;
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validationFunction}>
      {({ values, setFieldValue, resetForm, dirty }) => (
        <Form id="create-patient">
          <Box sx={{ position: 'relative' }}>
            <Box bg="white" p="3xl" borderRadius="8px">
              <Flex align="center" mb="4xl" justify="space-between">
                <Flex align="center">
                  <Box bg="neutral.200" p={6} mr={5} borderRadius={4}>
                    <Icon color="primary.600" icon="patients" />
                  </Box>
                  <RoleBasedRoutes allowedRoles={editPatientUserRoles}>
                    <Flex
                      style={{ paddingTop: '20px' }}
                      _hover={{
                        textDecoration: 'underline',
                        textDecorationColor: 'blue',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleToggleEdit(setFieldValue)}
                    >
                      <Heading variant="h5">{pageHeader}</Heading>
                      {allowToggleEdit && (
                        <IconButton
                          variant="link"
                          transform="translateY(-5px)"
                          color="primary.500"
                          style={{ marginLeft: '0px', marginTop: '-5px' }}
                          aria-label="Edit patient profile"
                          icon="edit"
                        />
                      )}
                    </Flex>
                  </RoleBasedRoutes>
                </Flex>
              </Flex>
              <PatientInformationForm />
              <Divider />
              <AltContactForm values={values} />
              <Divider />
              <InsuranceDetailsForm />
              <Divider />
              <DeviceInformationForm deviceVendors={deviceVendors ?? []} />
              <Divider />
              <PhysiologicThresholds patient={patient} />
              <Divider />
              <PhysicianForm userData={userData || []} />
              <Divider />
              <ClinicalCareTeamForm initialTeamId={initialValues?.team} />
            </Box>
            <Flex
              p="xl"
              bg="neutral.black"
              borderRadius="8px 8px 0 0"
              justify="end"
              mt="6xl"
              height="72px"
            >
              {handleArchivePatient && initialValues.isActive && (
                <RoleBasedRoutes allowedRoles={createPatientRoles}>
                  <Button
                    onClick={handleArchivePatient}
                    background="feedback.error"
                    color="white"
                    mr="xl"
                  >
                    Unenroll Patient
                  </Button>
                </RoleBasedRoutes>
              )}
              {values.isEditing && (
                <Button
                  onClick={() => handleResetForm(resetForm, setFieldValue)}
                  background="neutral.900"
                  color="white"
                  mr="xl"
                >
                  Discard Changes
                </Button>
              )}
              <RoleBasedRoutes allowedRoles={createPatientRoles}>
                {values.isEditing && dirty && (
                  <Button
                    disabled={disabled || isLoading}
                    isLoading={isLoading}
                    form="create-patient"
                    type="submit"
                    id="submit-form"
                  >
                    {primaryCTAText}
                  </Button>
                )}
              </RoleBasedRoutes>
            </Flex>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
