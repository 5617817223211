import { Checkbox, Box, Text, FormControl, FormLabel } from '@rhythm/components';
import { useField } from 'formik';

interface FormCheckProps {
  name: string;
  label: string;
  children: string;
  hintText?: string;
  disabled?: boolean;
}

export function FormCheck({
  name,
  label,
  children,
  hintText = '',
  disabled = false,
}: FormCheckProps) {
  const [field, meta] = useField(name);
  const fieldHasError: boolean = meta.touched && !!meta.error;

  return (
    <FormControl isInvalid={fieldHasError} mb="2xl" sx={{ position: 'relative' }}>
      <FormLabel fontSize="xs">{label}</FormLabel>
      <Checkbox {...field} isChecked={field.value} isDisabled={disabled}>
        {children}
      </Checkbox>
      <Box sx={{ position: 'absolute', top: '100%' }}>
        <Text
          fontSize="xs"
          color={fieldHasError ? 'red.500' : 'neutral.800'}
          role={(fieldHasError && 'alert') || undefined}
        >
          {fieldHasError ? meta.error : hintText}
        </Text>
      </Box>
    </FormControl>
  );
}
