import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';
import { formattedDateRange } from '~src/utils/formattedDateRange';
import { pointResolverHelper } from '~src/utils/pointResolverHelper';
import { DateRange } from '../..';
import { WeightData } from '../Data';

interface WeightGraphDataProps {
  data: WeightData[] | undefined;
  dateRange: DateRange;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WeightGraph = ({ data, dateRange }: WeightGraphDataProps) => {
  const [daysAsString, daysAsDate] = formattedDateRange(dateRange);

  const completeData = daysAsDate.map((day: Date) => {
    const weightEntry = data
      ?.slice()
      .reverse()
      .find((entry) => dayjs(entry.timestamp).isSame(day, 'day'));
    return weightEntry || null;
  });

  const weightPointRadiusResolver = (context: ScriptableContext<'line'>) => {
    return pointResolverHelper(context, 3, 10, undefined, completeData);
  };

  const weightPointColorResolver = (context: ScriptableContext<'line'>) => {
    return pointResolverHelper(context, 'rgba(82, 208, 239, 1)', 'red', undefined, completeData);
  };

  const state = {
    labels: daysAsString,
    datasets: [
      {
        label: 'Weight',
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(82, 208, 239, 1)',
        borderColor: 'rgba(82, 208, 239, 1)',
        pointRadius: weightPointRadiusResolver,
        pointBackgroundColor: weightPointColorResolver,
        spanGaps: false,
        borderWidth: 3,
        tension: 0,
        data: completeData.map((reading: WeightData | null) => reading?.weight),
      },
    ],
  };

  return (
    <Line
      data={state}
      options={{
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',
            labels: {
              padding: 20,
              font: {
                size: 16,
              },
            },
          },
        },
      }}
    />
  );
};

export default WeightGraph;
