import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, HStack, Text, TextButton, Icon } from '@rhythm/components';
import type { Cell, Column, SortingRule } from 'react-table';
import { DataTable } from '~src/components/DataTable';
import { Search } from '~src/components/Search';
import { PaginatedResponse, UseListTeam, useListTeam } from '~src/services/teams';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { editTeamRoles, viewTeamRoles, createTeamRoles } from '~src/constants/roles';
import { OnFetchProps } from '@rhythm/components/dist/DataTable/DataTable';
import { useDebounce } from 'react-use';
import { getAccountData } from '../Settings/AccountManagement';

interface TeamRow extends Record<string, unknown> {
  id: string;
  teamName: string;
  teamLead: any;
  rns: any;
  mas: any;
  totalPatients: string;
}

interface TeamPaginationParams extends OnFetchProps {
  goToPage?: number | undefined;
  initialSize?: number | undefined;
  defaultSortBy?: SortingRule<unknown>[];
  searchWord?: string;
  totalItemsCount?: number;
}

interface GetAllTeamsProps {
  offset?: number;
  limit?: number;
  isNew?: boolean;
  searchTerm?: string;
  sortBy?: string;
  sort?: string;
}

const sortItems = (prev: any, curr: any, columnId: string) => {
  if (prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()) {
    return 1;
  } else if (prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()) {
    return -1;
  } else {
    return 0;
  }
};

function RnsRow({ rns }: any) {
  return (
    <Fragment>
      {rns?.map((member: { id: string; name: string }) => (
        <Fragment key={member?.id}>
          <Text>{member?.name?.substring(0, 24)}</Text>
        </Fragment>
      ))}
    </Fragment>
  );
}

function MasRow({ mas }: any) {
  return (
    <Fragment>
      {mas?.map((member: { id: string; name: string }) => (
        <Fragment key={member?.id}>
          <Text>{member?.name?.substring(0, 24)}</Text>
        </Fragment>
      ))}
    </Fragment>
  );
}

const Cells: React.FC = (): ReactElement => {
  return <Icon icon="edit" color="primary.400" boxSize="sm" />;
};

const sortRnMaAlphaOrder = (a: string, b: string) => {
  const nameA = a.toLowerCase();
  const nameB = b.toLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

const formatCliniciansData = (members: any) => {
  let rns: any[] = [];
  let mas: any[] = [];
  for (let i = 0; i < members.length; i++) {
    for (let j = 0; j < members[i].user.credentials.length; j++) {
      const cred = members[i].user.credentials[j].credential;
      if (cred === 'RN' || cred === 'LPN' || cred === 'DNP' || cred === 'BSN' || cred === 'NP') {
        rns.push({
          id: members[i].user.id,
          name:
            members[i].user.lastName?.trim() + ', ' + members[i].user.firstName?.trim().charAt(0),
        });
        break;
      } else if (cred === 'MA') {
        mas.push({
          id: members[i].user.id,
          name:
            members[i].user.lastName?.trim() + ', ' + members[i].user.firstName?.trim().charAt(0),
        });
      }
    }
  }
  // remove elements from mas that are also in rns
  for (let i = 0; i < rns.length; i++) {
    for (let j = 0; j < mas.length; j++) {
      if (rns[i].id === mas[j].id) {
        mas.splice(j, 1);
      }
    }
  }

  rns = rns.sort((a, b) => sortRnMaAlphaOrder(a.name, b.name));
  mas = mas.sort((a, b) => sortRnMaAlphaOrder(a.name, b.name));

  return { rns, mas };
};

export function TeamManagement(props: {
  setTeamCount: (count: number) => void;
  setEditTeamData: (data: any) => void;
}) {
  const DEFAULT_PAGE_SIZE = 10;

  const getLocalStorageData = () => {
    return {
      pageIndex: 0,
      initialSize: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      defaultSortBy: [],
      goToPage: 0,
      totalCount: 0,
      searchWord: '',
      filters: {},
    };
  };
  const [searchTerm, setSearchTerm] = useState('');
  const [queryData, setQueryData] = useState<UseListTeam>({
    sort: 'ASC',
    offset: 0,
    search: '',
    limit: DEFAULT_PAGE_SIZE,
    filter: {
      id: '',
    },
  });
  const [pagination, setPagination] = useState<TeamPaginationParams>({
    ...getLocalStorageData(),
  });
  const [pageParams, setPageParams] = useState({ totalSize: 0, totalPageCount: 0 });
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [sortBy, setSortBy] = useState<string>('');
  const [sort, setSort] = useState<string>('');
  const history = useHistory();

  const fetchData = useCallback(
    ({ offset, limit, sortBy, sort, searchTerm }: GetAllTeamsProps) => {
      const updatedQueryData: any = {};
      updatedQueryData.offset = offset;
      updatedQueryData.limit = limit;
      updatedQueryData.search = searchTerm;
      if (sortBy !== undefined && sort !== undefined) {
        updatedQueryData.sortBy = sortBy;
        updatedQueryData.sort = sort ? 'DESC' : 'ASC';
      }
      setQueryData(updatedQueryData);
    },
    [setQueryData]
  );

  useEffect(() => {
    fetchData({ offset, limit, sortBy, sort, searchTerm });
  }, [offset, limit, sortBy, sort, pagination.searchWord, fetchData]);

  const paginatedData = useListTeam({ ...queryData }) as unknown as PaginatedResponse;
  const teams = paginatedData?.data?.data;
  const paginationInfo = paginatedData?.data?.pagination;
  const totalTeamsCount = paginationInfo?.count || 0;

  useEffect(() => {
    if (teams) {
      if (totalTeamsCount < pagination.pageSize) {
        setPageParams({
          totalPageCount: 1,
          totalSize: totalTeamsCount,
        });
      } else {
        setPageParams({
          totalPageCount: Math.ceil(totalTeamsCount / pagination?.pageSize),
          totalSize: totalTeamsCount,
        });
      }
    }
  }, [teams]);

  const parseTeamSearchColumn = (name?: string) => {
    if (!name) return '';
    const splitName = name?.split(' ').filter((e) => e);
    if (splitName.length > 1) return `${splitName.join('&')}`;
    return splitName[0];
  };

  useDebounce(
    async () => {
      if (parseTeamSearchColumn(searchTerm) !== pagination?.searchWord) {
        setPagination({
          ...pagination,
          pageIndex: 0,
          goToPage: 0,
          initialSize: 0,
          searchWord: searchTerm,
        });
      }
    },
    250,
    [searchTerm]
  );

  const columns: Column<TeamRow>[] | any = [
    {
      Header: 'Team Name',
      accessor: 'teamName',
      sortType: (prev: any, curr: any) => sortItems(prev, curr, 'teamName'),
    },
    {
      Header: 'Team Lead',
      accessor: 'teamLead',
      sortType: (prev: any, curr: any) => sortItems(prev, curr, 'teamLead'),
    },
    {
      Header: 'RN',
      accessor: 'rns',
      Cell: ({ row: { original } }: Cell<TeamRow, string>) => {
        return <RnsRow {...original} />;
      },
      // disable sort on this column
      disableSortBy: true,
    },
    {
      Header: 'MA',
      accessor: 'mas',
      Cell: ({ row: { original } }: Cell<TeamRow, string>) => {
        return <MasRow {...original} />;
      },
      // disable sort on this column
      disableSortBy: true,
    },
    {
      Header: 'Total Patients',
      accessor: 'totalPatients',
    },
    {
      Header: '',
      accessor: 'editTeam',
      width: 2,
      Cell: Cells,
    },
  ];

  const formatTeamsData = (): TeamRow[] => {
    if (!teams?.length) return [];
    else {
      if (teams.length > 0) {
        props.setTeamCount(paginationInfo.count);
      }
      return teams?.map((team: any) => {
        const { id, teamName, teamLead } = team;
        const { rns, mas } = formatCliniciansData(team.members);
        return {
          id,
          teamName,
          teamLead: `${teamLead?.lastName}, ${teamLead?.firstName?.trim().charAt(0)}`,
          rns: rns,
          mas: mas,
          totalPatients: '',
        };
      });
    }
  };

  const handleRowClick = (row: any) => {
    const id: any = row.original.id;
    // find the team in the data using the id
    const team = teams?.find((team: any) => team.id === id);
    props.setEditTeamData(team);
    history.push(`/internalsettings/team/edit/${id}`);
  };

  const getData = (data: any): void => {
    getAccountData(
      data,
      pagination,
      setPagination,
      offset,
      setOffset,
      limit,
      setLimit,
      sortBy,
      setSortBy,
      setSort
    );
  };

  return (
    <Fragment>
      <HStack
        justifyContent="space-between"
        alignItems="right"
        padding="xl"
        marginTop="xl"
        marginBottom="l"
        background={'#FFFFFF'}
      >
        <Flex alignItems="center" marginLeft="auto">
          <RoleBasedRoutes allowedRoles={createTeamRoles}>
            <TextButton
              leftIcon="add"
              onClick={() => history.push('/internalsettings/team/create')}
            >
              Create a Team
            </TextButton>
          </RoleBasedRoutes>
          <Search placeholder="Search Teams" onSearchTerm={setSearchTerm} />
        </Flex>
      </HStack>
      <RoleBasedRoutes allowedRoles={editTeamRoles}>
        {formatTeamsData().length > 0 ? (
          <DataTable
            columns={columns}
            data={formatTeamsData()}
            onRowClick={handleRowClick}
            isSortable
            isSetting={true}
            fetchData={(data: any) => getData(data)}
            totalPageCount={pageParams.totalPageCount}
            totalRowCount={pageParams.totalSize}
            initialPageSize={DEFAULT_PAGE_SIZE}
          />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
            <span style={{ fontSize: '16px', fontWeight: '600' }}>No Accounts found</span>
          </div>
        )}
      </RoleBasedRoutes>
      <RoleBasedRoutes allowedRoles={viewTeamRoles}>
        <DataTable
          columns={columns}
          hasPagination
          data={formatTeamsData()}
          onRowClick={undefined}
          isSortable
          isSetting={true}
          fetchData={(data: any) => getData(data)}
          totalPageCount={pageParams.totalPageCount}
          totalRowCount={pageParams.totalSize}
          initialPageSize={DEFAULT_PAGE_SIZE}
        />
      </RoleBasedRoutes>
    </Fragment>
  );
}
