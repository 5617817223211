import { useEffect, useState } from 'react';
import {
  Tabs,
  Flex,
  Box,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  Button,
} from '@rhythm/components';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import { TeamManagement } from '~src/components/InternalSettings/TeamManagement';
import { useFetchListOrganization } from '~src/services/clinics';
import { Organization, Team } from '~generated';
import { CreateTeam } from '../CreateTeam';
import { InternalUserManagement } from '~src/components/InternalSettings/InternalUserManagement';
import { EditTeam } from '../EditTeam';
import { editPatientUserRoles } from '~src/constants/roles';
import RoleBasedRoutes from '~src/components/RoleBasedRoutes';

export function InternalSettings() {
  const [internalUserCount, setInternalUserCount] = useState(0);
  const [teamCount, setTeamCount] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [editTeamData, setEditTeamData] = useState<Team>();
  const location = useLocation();

  const { data: organizations, isLoading, status, refetch } = useFetchListOrganization({});
  const [rhythmOrgId, setRhythmOrgId] = useState('');
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    const rhythmOrg: Organization[] | undefined = organizations?.filter(
      (organization: Organization) => organization.isRhythmOrg == true
    );
    setRhythmOrgId(rhythmOrg?.[0]?.id ? rhythmOrg?.[0]?.id : '');
  }, [organizations]);

  useEffect(() => {
    switch (location.pathname) {
      case '/internalsettings/teams':
        setTabIndex(1);
        break;
      default:
        setTabIndex(0);
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingData(false);
    }
  }, [isLoading]);

  const setUserCount = (count: number) => {
    setInternalUserCount(count);
  };

  const setTeamsCount = (count: number) => {
    setTeamCount(count);
  };

  const handleReload = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error('Error while refetching:', error);
    }
  };

  return (
    <Switch>
      <Route path="/internalsettings/team/create" component={CreateTeam}>
        <Route path="/" component={CreateTeam} />
      </Route>

      <Route
        path="/internalsettings/team/edit/:id"
        render={() => <EditTeam editTeamData={editTeamData} />}
      />

      <Route>
        <RoleBasedRoutes allowedRoles={editPatientUserRoles}>
          {isLoading || isLoadingData ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
              <span>
                <Spinner />
              </span>
            </div>
          ) : status !== 'success' ? (
            <div>
              <span style={{ padding: '10px' }}>Could not load accounts.</span>
              <Button onClick={handleReload}>Reload</Button>
            </div>
          ) : (
            <Tabs>
              <Flex pb="20">
                <Box w="100%">
                  <Tabs index={tabIndex}>
                    <Flex justifyContent="space-between" paddingY="xl">
                      <TabList>
                        <Tab>
                          <Link
                            to={`/internalsettings/internalusers`}
                          >{`Internal Users (${internalUserCount})`}</Link>
                        </Tab>
                        <Tab>
                          <Link to={`/internalsettings/teams`}>{`Teams (${teamCount})`}</Link>
                        </Tab>
                      </TabList>
                    </Flex>
                    <Box maxW="1140px">
                      <TabPanels>
                        <TabPanel py="0">
                          <InternalUserManagement
                            id={rhythmOrgId}
                            setUserCount={setUserCount}
                          ></InternalUserManagement>
                        </TabPanel>
                        <TabPanel py="0">
                          <TeamManagement
                            setTeamCount={setTeamsCount}
                            setEditTeamData={setEditTeamData}
                          ></TeamManagement>
                        </TabPanel>
                      </TabPanels>
                    </Box>
                  </Tabs>
                </Box>
              </Flex>
            </Tabs>
          )}
        </RoleBasedRoutes>
      </Route>
    </Switch>
  );
}
