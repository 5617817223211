export enum ROLE_TYPES {
  RHYTHM_SUPER_ADMIN = 'roles:internal:super-admin',
  RHYTHM_ADMINISTRATOR = 'roles:internal:admin',
  RHYTHM_CLINICIAN = 'roles:internal:clinical',
  RHYTHM_NON_CLINICIAN = 'roles:internal:non-clinical',
  RHYTHM_LEAD_CLINICIAN = 'roles:internal:lead-clinician',
  NON_PROVIDER = 'roles:external:non-provider',
  PROVIDER = 'roles:external:provider',
  // ADMINISTRATOR = 'roles:external:administrator',
  // PROVIDER_ADMINSTRATOR = 'roles:external:provider-administrator',
  // NON_PROVIDER_ADMINISTRATOR = 'roles:external:non-provider-administrator',
  // CLERICAL = 'roles:external:clerical',
  // HEART_FAILURE = 'roles:external:heart-failure',
}

/** Depending on how roles are used i.e
 * if every level below can access the roles above
 * to reduce the redundance of passing roles, we grade the roles
 * and use numbers to determine if they are superior
 */
