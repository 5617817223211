import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import dayjs from 'dayjs';

export const useMixpanelUserTracking = (): void => {
  const { authState } = useOktaAuth();
  const MIXPANEL_EMAIL_KEY = 'mixpanel-userEmail';
  const MIXPANEL_LAST_LOGIN_DATE_KEY = 'mixpanel-lastLogindate';

  useEffect(() => {
    if (!authState?.isAuthenticated || !authState?.accessToken?.claims) {
      return;
    }

    const persistedUserEmail = localStorage.getItem(MIXPANEL_EMAIL_KEY);
    const persistedLastLoginDate = localStorage.getItem(MIXPANEL_LAST_LOGIN_DATE_KEY);
    const currentEmail = authState.accessToken.claims.sub;
    const currentDateStr = dayjs().format('YYYY-MM-DD');

    const isSameUser = persistedUserEmail === currentEmail;
    const isAfterPersistedDate = persistedLastLoginDate
      ? dayjs(currentDateStr).isAfter(dayjs(persistedLastLoginDate), 'day')
      : true;

    if (isSameUser && !isAfterPersistedDate) {
      // User has already been tracked today
      return;
    }

    // Update local storage and track login event for new user or new day
    localStorage.setItem(MIXPANEL_LAST_LOGIN_DATE_KEY, currentDateStr);
    localStorage.setItem(MIXPANEL_EMAIL_KEY, currentEmail);
    trackMixpanelLoginEvent();
  }, [authState]);

  function trackMixpanelLoginEvent() {
    window.analytics.track('UserLogin', {
      role: authState?.accessToken?.claims.roles[0],
      email: authState?.accessToken?.claims.sub,
    });
  }
};
