import { useEffect, useMemo, useState } from 'react';
import type { Cell, SortingRule } from 'react-table';
import { DataTable } from '~src/components/DataTable';
import { useDebounce } from 'react-use';

import {
  PatientCell,
  PatientStatusCell,
  MetricCell,
  MetricCellProps,
} from '~src/components/PatientsDataView';
import { AllDeviceThresholds } from '~src/types/AllDeviceThresholds';
import { BloodPressure, BloodSugar, PatientContact, Pulse, PulseOx, Weight } from '~generated';
import { PatientStatusCellProps } from './PatientStatusCell';
import { OnFetchProps } from '@rhythm/components/dist/DataTable/DataTable';
import { PatientLocalStorage, retrievePersistedData, savePersistentData } from '~src/utils';
import { useLogoutUser } from '~src/services/users';
import { UNAUTHROIZED } from '~src/constants';
import {
  PaginationSort,
  usePatientPagination,
  usePatientReviewCount,
} from '~src/services/patients';

import { FormFilterValues, getInitialFilters } from '~src/pages/Patients/PatientFilters';
import { PatientNewLink } from './PatientCell';
import { useApplicationSettings } from '~src/services/applicatonSettings';
import {
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Spinner,
} from '@rhythm/components';
import './style.css';
import { useHistory, useLocation } from 'react-router-dom';

interface PatientAPIResponse {
  id: string;
  firstName: string;
  lastName: string;
  statusType: string;
  daysTransmittedMonthly: number;
  physician: Physician;
  clinic: any;
  allDeviceThresholds: AllDeviceThresholds;
  monthlyDaysTransmitted: MonthlyDaysTransmitted;
  monthlyTimeAccrued: MonthlyTimeAccrued;
  hasMonthlyContact: MonthlyContact | null;
  latestBloodPressure: BloodPressure | null;
  latestBloodSugar: BloodSugar | null;
  latestPulse: Pulse;
  latestPulseOx: PulseOx | null;
  latestWeight: Weight | null;
  previousWeight: Weight | null;
  secondLatestWeight: Weight | null;
  daysTransmitted: number;
  contacts: Array<PatientContact>;
  isReviewed: boolean;
  minutes: number;
  latestTimeAccruedByUser: string;
  latestTimeAccruedByUserTimestamp: string;
}

interface Physician {
  firstName: string;
  lastName: string;
}

interface MonthlyDaysTransmitted {
  patientId: string;
  daysTransmitted: number;
}

interface MonthlyTimeAccrued {
  patientId: string;
  monthlytimeaccrued: string;
}

interface MonthlyContact {
  patientId: string;
  hasMonthlyContact: boolean;
}

interface PatientRow extends Record<string, unknown> {
  id: string;
  fullname: string;
  contactNumber: string;
  physicianName: string;
  daysTransmitted: number;
  deviceThresholds: AllDeviceThresholds;
  contacted: boolean;
  minutesLogged: string | number;
  bloodPressure?: BloodPressure | null;
  pulse?: Pulse;
  weight?: Weight | null;
  glucose?: BloodSugar | null;
  isReviewed: boolean;
  minutes?: number;
  statusType: PatientStatusCellProps;
  bloodOxygen?: PulseOx | null;
}

interface PatientPaginationParams extends OnFetchProps {
  offset?: number | undefined;
  pageIndex: number;
  goToPage?: number | undefined;
  initialSize?: number | undefined;
  defaultSortBy?: SortingRule<unknown>[];
  searchWord?: string;
  totalItemsCount?: number;
  sort?: SortingRule<unknown>[];
  totalCount?: number;
  search?: string;
  filters?: FormFilterValues;
}

export type PC<T> = Cell<PatientRow, T>;
export type PatientCellProps = Pick<PatientRow, 'fullname' | 'contactNumber' | 'id'>;

const PAGE_SIZE = 20;

export interface PatientsPaginateDataView {
  search?: string;
  filterSearch?: string;
  updatedFilterSearch?: FormFilterValues;
  active?: boolean;
  currentTab?: number;
  setTotalPatients?: any;
  setFastReviewCount?: any;
  setSearchTermError?: any;
  setFastReviewLoading?: any;
}

type BaseUser = {
  firstName: string;
  lastName: string;
};

export function getName(user?: BaseUser): string {
  if (!user) {
    return '';
  }
  return `${user.lastName}, ${user.firstName}`;
}

export function getPhysicianName(user?: BaseUser): string {
  if (!user) {
    return '';
  }
  return `${user.firstName} ${user.lastName}`;
}

function displayClinicName(data, value) {
  if (value == 'clinic') {
    if (data?.length > 0) {
      const filteredClinicOrganizations = data?.filter((org) => org.type === 'clinic');
      const clinicOrganizationNames = filteredClinicOrganizations.map((org) => org.name);
      return clinicOrganizationNames.join(', ');
    }
  } else if (value == 'account') {
    if (data?.length > 0) {
      const filteredClinicOrganizations = data?.filter((org) => org.type === 'account');
      const clinicOrganizationNames = filteredClinicOrganizations.map((org) => org.name);
      return clinicOrganizationNames.join(', ');
    }
  }
}

function usePatientRows(patients?: PatientAPIResponse[]): PatientRow[] {
  return useMemo(() => {
    if (!patients) return [];
    return patients.map((patient) => ({
      id: patient.id,
      fullname: getName(patient),
      contactNumber: patient.contacts?.length > 0 ? patient.contacts[0]?.contact : '',
      clinicName: displayClinicName(patient?.clinic, 'clinic'),
      accountName: displayClinicName(patient?.clinic, 'account'),
      physicianName: getPhysicianName(patient.physician),
      daysTransmitted: patient.monthlyDaysTransmitted?.daysTransmitted || 0,
      deviceThresholds: patient.allDeviceThresholds,
      contacted: patient.hasMonthlyContact ? patient?.hasMonthlyContact.hasMonthlyContact : false,
      minutesLogged: patient.monthlyTimeAccrued?.monthlytimeaccrued || 0,
      bloodPressure: patient.latestBloodPressure,
      pulse: patient.latestPulse,
      weight: patient.latestWeight,
      previousWeight: patient.secondLatestWeight,
      glucose: patient.latestBloodSugar,
      bloodOxygen: patient.latestPulseOx,
      isReviewed: patient.isReviewed,
      latestTimeAccruedByUser: patient.latestTimeAccruedByUser,
      latestTimeAccruedByUserTimestamp: patient.latestTimeAccruedByUserTimestamp,
      statusType: { statusType: patient.statusType },
      minutes: patient.minutes,
    }));
  }, [patients]);
}
const ITEMS_PER_PAGE = 20;
export function PatientsPaginateDataView({
  search,
  updatedFilterSearch,
  active = true,
  currentTab,
  setTotalPatients,
  setFastReviewCount,
  setSearchTermError,
  setFastReviewLoading,
}: PatientsPaginateDataView) {
  const { data: applicationSettingsData } = useApplicationSettings();
  const history = useHistory(); // Get history object
  const location = useLocation(); // Get location object
  const urlParams = new URLSearchParams(location.search);
  const pageIndex = parseInt(urlParams.get('page') ?? '1', 10) - 1; // Zero-based index
  const pageSize = parseInt(urlParams.get('pageSize') ?? ITEMS_PER_PAGE.toString(), 10);
  const pageSearch = urlParams.get('search') ?? '';
  const isPulseOxEnabled = applicationSettingsData
    ? applicationSettingsData[0].isPulseOxEnabled
    : false;

  const pushToURL = (pageNumber: number, pageSize?: number, pageSearch?: string) => {
    urlParams.set('page', pageNumber.toString());
    if (pageSize) urlParams.set('pageSize', pageSize.toString());
    if (pageSearch !== undefined) urlParams.set('search', pageSearch);
    history.push({ search: urlParams.toString() });
  };

  const parseSearchColumn = (name?: string) => {
    if (!name) return '';
    const splitName = name?.split(' ').filter((e) => e);
    if (splitName.length > 1) return `${splitName.join('&')}`;
    return splitName[0];
  };

  const getDefaultSortBy = (): SortingRule<unknown>[] => {
    return [{ id: 'Patient', desc: false }];
  };

  const getLocalStorageData = (): PatientPaginationParams => {
    const persistedFilter = retrievePersistedData(
      `rpm-filter-${currentTab}`
    ) as PatientLocalStorage;

    if (persistedFilter) {
      if (!persistedFilter?.sortBy || typeof persistedFilter?.sortBy === 'string') {
        persistedFilter.sortBy = getDefaultSortBy();
      }

      return {
        pageIndex,
        goToPage: pageIndex,
        initialSize: pageIndex,
        sort: persistedFilter?.sortBy || getDefaultSortBy(),
        sortBy: persistedFilter?.sortBy || getDefaultSortBy(),
        defaultSortBy: persistedFilter?.sortBy || getDefaultSortBy(),
        offset: pageIndex * pageSize,
        pageSize: pageSize,
        totalCount: persistedFilter?.totalCount,
        search: '', //do not persist search
        searchWord: pageSearch, //do not persist search
        filters: persistedFilter?.filters || getInitialFilters(),
      };
    }

    return {
      offset: pageIndex * pageSize,
      pageIndex: pageIndex,
      sort: getDefaultSortBy(),
      sortBy: getDefaultSortBy(),
      initialSize: pageIndex,
      pageSize: pageSize,
      defaultSortBy: getDefaultSortBy(),
      goToPage: pageIndex,
      totalCount: 0,
      search: '',
      searchWord: pageSearch,
      filters: getInitialFilters(),
    };
  };

  // Wrap usePatientPagination in a function
  const usePatientPaginationWrapper = (queryData: PaginationSort) => {
    return usePatientPagination({ ...queryData });
  };

  const persistedData = getLocalStorageData();

  const [pagination, setPagination] = useState<PatientPaginationParams>({
    ...persistedData,
  });
  const [patientsData, setPatientsData] = useState<any[]>([]);
  const [pageParams, setPageParams] = useState({ totalSize: 0, totalPageCount: 0 });
  const { mutate: logout } = useLogoutUser();

  const initialQueryData: PaginationSort = {
    sort: persistedData.sortBy ?? ([] as unknown as any),
    search: persistedData.searchWord,
    offset: persistedData.offset,
    limit: persistedData.pageSize,
    filters: persistedData.filters || (getInitialFilters() as FormFilterValues),
  };
  const [loading, setLoading] = useState(true);
  const [queryData, setQueryData] = useState<PaginationSort>(initialQueryData);

  const {
    data,
    isLoading: userLoading,
    refetch,
    status,
  } = usePatientPaginationWrapper({ ...queryData });

  useEffect(() => {
    setLoading(userLoading);
    if (!data) return;
  }, [data, status]);
  const { data: totalReviewCount, isLoading: fastReviewLoading } = usePatientReviewCount({
    search: queryData.search,
    filters: queryData.filters,
  });

  const [currentPage, setCurrentPage] = useState(pageIndex ? pageIndex : 1);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize ?? ITEMS_PER_PAGE);
  const totalPages = Math.ceil(pageParams.totalSize / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;

  useEffect(() => {
    // Update pagination state based on URL parameters
    setPagination({
      ...persistedData,
    });
    setCurrentPage(pageIndex + 1);
    setRowsPerPage(pageSize);
  }, [location.search]);

  useDebounce(
    async () => {
      setSearchTermError('');
      if (!search) search = '';
      if (parseSearchColumn(search) !== pagination?.searchWord) {
        setCurrentPage(1);
        savePersistentData(
          `rpm-filter-${currentTab}`,
          JSON.stringify({ ...pagination, offset: 0 })
        );
        const containsOnlyNumbersAndSpecialCharacters =
          /^[0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
        const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
        const phonePattern = /^\d{3}-\d{3}-\d{4}$/;
        if (containsOnlyNumbersAndSpecialCharacters.test(search)) {
          if (!datePattern.test(search) && !phonePattern.test(search)) {
            setSearchTermError(
              "You're trying to search with a partial phone number or date of birth. Please provide a complete phone number or date in the following format xxx-xxx-xxxx or mm/dd/yyyy"
            );
          } else {
            fetchDataForPage(1);
            setSearchTermError('');
            setPagination({
              ...pagination,
              pageIndex: 0,
              goToPage: 0,
              initialSize: 0,
              searchWord: search,
              offset: 0,
            });
          }
        } else {
          fetchDataForPage(1);
          setSearchTermError('');
          setPagination({
            ...pagination,
            pageIndex: 0,
            goToPage: 0,
            initialSize: 0,
            searchWord: search,
            offset: 0,
          });
        }
        pushToURL(1, pagination.pageSize, search.toString());
      }
    },
    250,
    [search]
  );

  useEffect(() => {
    setFastReviewLoading(fastReviewLoading);
    if (!totalReviewCount) return;
    setFastReviewCount(
      totalReviewCount?.total ? parseInt(totalReviewCount.total).toLocaleString() : '0'
    );
  }, [totalReviewCount]);

  useEffect(() => {
    setPatientsData(data?.patients ?? []);

    if (data?.patients) {
      setLoading(false);
      if (data?.patients?.length < pagination.pageSize) {
        setTotalPatients(data?.total);
        setPageParams({
          totalPageCount: 1,
          totalSize: data?.total ?? 0,
        });
      } else {
        setTotalPatients(data?.total);
        setPageParams({
          totalSize: data?.total,
          totalPageCount: Math.ceil(data?.total / data?.patients?.length),
        });
      }

      const persistedPaginationData = retrievePersistedData(
        `rpm-filter-${currentTab}`
      ) as PatientLocalStorage;

      if (persistedPaginationData) {
        savePersistentData(
          `rpm-filter-${currentTab}`,
          JSON.stringify({
            ...persistedPaginationData,
            totalCount: data?.total,
          })
        );
      }
    }
  }, [data]);

  useDebounce(
    () => {
      const fetchPaginatedData = async () => {
        setLoading(true);
        try {
          if (pagination.pageSize < pageParams.totalSize) {
            setPageParams({
              totalSize: data?.total ?? 0,
              totalPageCount: Math.ceil(pageParams.totalSize / pagination.pageSize),
            });
          } else {
            setPageParams({
              totalSize: data?.total ?? 0,
              totalPageCount: 1,
            });
          }

          const offset = pageIndex * pageSize;

          const persistedFilter = retrievePersistedData(
            `rpm-filter-${currentTab}`
          ) as PatientLocalStorage;

          if (offset && offset > pageParams.totalSize) {
            return;
          }

          const persistedPaginationData = {
            ...persistedFilter,
            totalCount: pageParams.totalSize,
            initial: offset,
            offset: offset,
            sortBy: pagination.sortBy ?? getDefaultSortBy(),
            pageSize: pagination.pageSize,
            search: '', //parseSearchColumn(pagination.searchWord),
          };

          savePersistentData(
            `rpm-filter-${currentTab}`,
            JSON.stringify({ ...persistedPaginationData })
          );

          const newQueryData = {
            sort: pagination?.sortBy ?? getDefaultSortBy(),
            search: parseSearchColumn(search),
            offset: offset,
            limit: pagination.pageSize,
            filters: persistedFilter.filters,
          };
          setTimeout(() => {
            setLoading(false);
          }, 500);

          return setQueryData(newQueryData);
        } catch (err: any) {
          if (err?.message == UNAUTHROIZED) {
            logout(null);
          }
        }
      };
      fetchPaginatedData();
    },
    200,
    [pagination, updatedFilterSearch]
  );

  const patients = usePatientRows(patientsData);

  const fetchData = ({ pageIndex, sortBy }: OnFetchProps) => {
    if (sortBy && sortBy?.length > 0 && pagination.sortBy) {
      if (
        pagination?.sortBy[0]?.id !== sortBy[0].id ||
        pagination.sortBy[0]?.desc !== sortBy[0].desc
      ) {
        setPagination({
          pageIndex,
          pageSize: pagination.pageSize,
          sortBy,
          searchWord: search,
        });
      }
      return;
    }
  };

  function Label({ data }: { data: any }) {
    return (
      <>
        {data?.clinicName && (
          <>
            <Text fontWeight="bold" color="neutral.100">
              Clinic Name:
            </Text>
            <span>{data?.clinicName}</span>
          </>
        )}

        {data?.accountName && (
          <>
            <Text fontWeight="bold" color="neutral.100">
              Account Name:
            </Text>
            <span>{data?.accountName}</span>
          </>
        )}
      </>
    );
  }

  let basicColumns = [
    {
      Header: 'Patient',
      width: 200,
      accessor: ({ id, fullname, contactNumber }: PatientRow) =>
        [fullname, id, contactNumber].join(' '),
      Cell: ({ row: { original } }: PC<PatientCellProps>) => <PatientCell {...original} />,
    },
    {
      Header: '  ',
      width: 20,
      paddingLeft: 0,
      accessor: ({ id }: PatientRow) => id,
      Cell: ({ row: { original } }: PC<PatientCellProps>) => <PatientNewLink {...original} />,
    },
    {
      Header: 'Status',
      accessor: 'statusType',
      width: 200,
      Cell: ({ value }: PC<PatientStatusCellProps>) => <PatientStatusCell {...value} />,
    },
    {
      Header: 'Physician',
      accessor: 'physicianName',
      width: 136,
      Cell: ({ row: { original } }: PC<PatientCellProps>) => (
        <Tooltip
          hasArrow
          label={<Label data={original} />}
          // label='dfg'
          placement="bottom-end"
        >
          <div>{original.physicianName}</div>
        </Tooltip>
      ),
    },
    {
      Header: 'Days',
      accessor: 'daysTransmitted',
      disableSortBy: true,
      width: 89,
    },
    {
      Header: 'Contact',
      accessor: 'contacted',
      width: 114,
      disableSortBy: true,
      Cell: ({ value }: PC<boolean>) => <Text>{value ? 'Yes' : 'No'}</Text>,
    },
    {
      Header: 'Minutes',
      width: 110,
      accessor: 'minutesLogged',
      disableSortBy: true,
    },
    {
      Header: 'BP',
      accessor: 'bloodPressure',
      maxWidth: 130,
      disableSortBy: true,
      Cell: ({ value, row }: PC<MetricCellProps>) => (
        <MetricCell {...value} deviceThresholds={row.original.deviceThresholds} />
      ),
    },
    {
      Header: 'Pulse',
      accessor: 'pulse',
      maxWidth: 190,
      disableSortBy: true,
      Cell: ({ value, row }: PC<MetricCellProps>) => (
        <MetricCell {...value} deviceThresholds={row.original.deviceThresholds} />
      ),
    },
    {
      Header: 'Weight',
      accessor: 'weight',
      maxWidth: 110,
      disableSortBy: true,
      Cell: ({ value, row }: PC<MetricCellProps>) => (
        <MetricCell
          {...value}
          previousWeight={row.original.previousWeight}
          deviceThresholds={row.original.deviceThresholds}
        />
      ),
    },
    {
      Header: 'Glucose',
      accessor: 'glucose',
      maxWidth: 115,
      disableSortBy: true,
      Cell: ({ value, row }: PC<MetricCellProps>) => (
        <MetricCell {...value} deviceThresholds={row.original.deviceThresholds} />
      ),
    },
    {
      Header: (
        <>
          S<small>p</small>O<sub>2</sub>
        </>
      ),
      accessor: 'bloodOxygen',
      maxWidth: 115,
      disableSortBy: true,
      Cell: ({ value, row }: PC<MetricCellProps>) => (
        <MetricCell {...value} deviceThresholds={row.original.deviceThresholds} />
      ),
    },
  ];

  if (!isPulseOxEnabled) {
    basicColumns = basicColumns.filter((column) => column.accessor !== 'bloodOxygen');
  }

  const completedColumns = basicColumns;

  const fetchDataForPage = (page: number) => {
    setLoading(true);
    const offset = (page - 1) * rowsPerPage;

    const newQueryData: PaginationSort = {
      sort: pagination.sortBy ?? getDefaultSortBy(),
      search: parseSearchColumn(search),
      offset,
      limit: pagination.pageSize,
      filters: { ...updatedFilterSearch, active } as any,
    };
    savePersistentData(
      `rpm-filter-${currentTab}`,
      JSON.stringify({ ...pagination, filters: updatedFilterSearch, offset: offset })
    );
    pushToURL(page, pagination.pageSize);
    setQueryData(newQueryData);
    setCurrentPage(page);
  };
  const getPageNumberRange = () => {
    const totalPageButtons = 5;
    const pageRange: number[] = [];

    const leftBoundary = Math.max(currentPage - Math.floor(totalPageButtons / 2), 1);
    const rightBoundary = Math.min(leftBoundary + totalPageButtons - 1, totalPages);

    for (let i = leftBoundary; i <= rightBoundary; i++) {
      pageRange.push(i);
    }

    return pageRange;
  };

  const changeDropDown = (newValue) => {
    setRowsPerPage(newValue);
    setPagination({
      ...pagination,
      pageSize: newValue,
    });
    fetchDataForPage(1);
    pushToURL(1, newValue);
  };

  const handleReload = async () => {
    try {
      setLoading(true);
      await refetch();
    } catch (error) {
      console.error('Error while refetching:', error);
    }
  };

  if (status === 'error') {
    return (
      <div>
        <span style={{ padding: '10px' }}>Could not load patients.</span>
        <Button onClick={handleReload}>Reload</Button>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <span>
            <Spinner />
          </span>
        </div>
      ) : data?.patients?.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <span style={{ fontSize: '16px', fontWeight: '600' }}>No patients found</span>
        </div>
      ) : (
        <DataTable
          fetchData={fetchData}
          columns={completedColumns}
          data={patients}
          search={''}
          totalPageCount={pageParams.totalPageCount}
          totalRowCount={pageParams.totalSize}
          initialSortBy={pagination.defaultSortBy as any}
          goToPageIndex={pagination.goToPage}
          initialPageIndex={pagination.initialSize}
          initialPageSize={PAGE_SIZE}
          isPatientManagementPage={true}
        />
      )}

      {patients.length != 0 ? (
        <div>
          <div
            className=""
            style={{
              backgroundColor: '#fff',
              borderRadius: '7px',
              padding: '12px 24px',
              fontSize: '14px',
              textAlign: 'right',
              marginTop: '2px',
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <div>
              <label>
                <span
                  style={{
                    color: 'rgb(108, 119, 137)',
                    display: 'inline-block',
                    marginRight: '15px',
                  }}
                >
                  {' '}
                  Rows per page:
                </span>
              </label>
              <span className="pagination-ddl">
                <Menu>
                  <MenuButton as={Button} rightIcon={'drop-down'}>
                    {rowsPerPage}
                  </MenuButton>
                  <MenuList>
                    <MenuItem value={10} onClick={() => changeDropDown(10)}>
                      10
                    </MenuItem>
                    <MenuItem value={20} onClick={() => changeDropDown(20)}>
                      {' '}
                      20
                    </MenuItem>
                    <MenuItem value={50} onClick={() => changeDropDown(50)}>
                      {' '}
                      50
                    </MenuItem>
                    <MenuItem value={100} onClick={() => changeDropDown(100)}>
                      {' '}
                      100
                    </MenuItem>
                  </MenuList>
                </Menu>
              </span>
            </div>
            <div className="pagination">
              {' '}
              {/* Display "1-20 of total" */}{' '}
              <span className="page-info" style={{ padding: '0 36px' }}>
                {' '}
                {startIndex + 1}-{Math.min(startIndex + rowsPerPage, pageParams.totalSize)} of{' '}
                {pageParams.totalSize}{' '}
              </span>
              <button
                style={{ marginRight: '30px', position: 'relative', top: '-4px' }}
                disabled={currentPage === 1}
                onClick={() => fetchDataForPage(1)}
              >
                {' '}
                <div style={{ display: 'flex' }}>
                  <div className="arrow left" style={{ position: 'relative', right: '-4px' }} />
                  <div className="arrow left" />
                </div>
              </button>{' '}
              {/* Previous arrow */}{' '}
              <button
                style={{ marginRight: '30px', position: 'relative', top: '-4px' }}
                disabled={currentPage === 1}
                onClick={() => fetchDataForPage(currentPage - 1)}
              >
                <div className="arrow left" />
              </button>{' '}
              {/* Page numbers */}{' '}
              {getPageNumberRange().map((pageNumber) => (
                <button
                  style={{ margin: '0 10px' }}
                  key={pageNumber}
                  onClick={() => fetchDataForPage(pageNumber)}
                  className={`pagination-button ${currentPage === pageNumber ? 'active' : ''}`}
                >
                  {' '}
                  {pageNumber}{' '}
                </button>
              ))}{' '}
              {/* Next arrow */}{' '}
              <button
                style={{ marginLeft: '30px' }}
                disabled={currentPage === totalPages}
                onClick={() => fetchDataForPage(currentPage + 1)}
              >
                <div className="arrow right" />
              </button>
              <button
                style={{ marginLeft: '30px' }}
                disabled={currentPage === totalPages}
                onClick={() => fetchDataForPage(totalPages)}
              >
                {' '}
                <div style={{ display: 'flex' }}>
                  <div className="arrow right" />
                  <div className="arrow right" style={{ position: 'relative', left: '-4px' }} />
                </div>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
