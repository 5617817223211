import {
  QueryKey,
  UseMutateFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import queryString, { StringifiableRecord } from 'query-string';
import { useOktaAuth } from '@okta/okta-react';
import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';
import { globalConfig } from '~src/configuration/config';
import { UNAUTHROIZED } from '~src/constants';
import { useLogoutUser } from '~src/services/users';
import dayjs from 'dayjs';

export type { UseQueryResult };

interface FetchOptions<ResponseBody = unknown, QueryParams = StringifiableRecord>
  extends RequestInit {
  key: QueryKey;
  path: string;
  queryParams?: QueryParams;
  initialData?: ResponseBody;
  enabled?: boolean;
  queryOptions?: UseQueryOptions<ResponseBody>;
}

export function useFetch<ResponseBody = unknown>({
  key,
  path,
  initialData,
  enabled = true,
  queryOptions,
  queryParams,
  ...rest
}: FetchOptions<ResponseBody>): UseQueryResult<ResponseBody> {
  const { authState, oktaAuth } = useOktaAuth();
  const { mutate: logout } = useLogoutUser();
  const config = globalConfig.get();
  const url = queryString.stringifyUrl({ url: `${config.apiUrl}${path}`, query: queryParams });

  return useQuery<ResponseBody>(
    key,
    async () => {
      const options = {
        ...rest,
        headers: {
          ...rest?.headers,
          Authorization: 'Bearer ' + (await oktaAuth.getAccessToken()),
          'X-Timezone-Offset': dayjs().utcOffset().toString(),
        },
      };
      return fetch(url, options).then((res) => clientResponseHandler<ResponseBody>(res, logout));
    },
    {
      enabled: Boolean(authState?.accessToken?.accessToken) && enabled,
      initialData,
      ...queryOptions,
    }
  );
}

export function useCrud<ResponseBody = unknown>({
  queryParams,
  ...rest
}: FetchOptions<ResponseBody, CreateQueryParams>): UseQueryResult<ResponseBody> {
  const { queryObject } = RequestQueryBuilder.create(queryParams);
  return useFetch({ queryParams: queryObject, ...rest });
}

async function clientResponseHandler<ResponseBody = unknown>(
  response: Response,
  logout: UseMutateFunction<unknown, unknown, unknown, unknown>
): Promise<ResponseBody> {
  if (response.status === 401) {
    logout(null);
    throw new Error(UNAUTHROIZED);
  }
  if (response.ok) {
    return response.status === 204 ? undefined : response.json();
  }
  const contentType = response.headers.get('content-type');
  if (contentType === 'application/json') {
    const error = await response.json();
    const errorMessage = typeof error === 'string' ? error : error.error?.message;
    throw new Error(errorMessage);
  }
  const error: string = await response.text();
  throw new Error(error);
}
