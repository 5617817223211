import {
  Box,
  Grid,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  Button,
} from '@rhythm/components';
import { useHistory, useParams } from 'react-router-dom';
import { CreateOrganizationDto, Organization } from '~generated';
import { UserManagement } from '~src/components/Settings';
import { ClinicManagement } from '~src/components/Settings/ClinicManagment';
import { useSingleOrganization } from '~src/services/clinics';
import { AccountInformation } from './AccountInformation';
import { AccountProfile } from './AccountProfile';
import { AccountSnapshot } from './AccountSnapshot';
import { OnbaordDate } from './OnboardDate';
export interface ParamsTypes {
  id: string;
}

const Account = () => {
  const { id } = useParams<ParamsTypes>();
  const history = useHistory();
  const { data, isLoading, status, refetch } = useSingleOrganization({ id });
  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '100px' }}>
        <span>
          <Spinner />
        </span>
      </div>
    );
  }

  const isClinic = data?.type === CreateOrganizationDto.type.CLINIC;

  const children = data?.children;
  const listOfChildrenIds = children?.map((child: Organization) => child.id);

  const handleReload = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error('Error while refetching:', error);
    }
  };

  if (status === 'error' || !data) {
    return (
      <div>
        <span style={{ padding: '10px' }}>Could not load accounts.</span>
        <Button onClick={handleReload}>Reload</Button>
      </div>
    );
  }

  return (
    <>
      <Button
        style={{
          width: '32px',
          height: '6px',
          color: '#6C7789',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        }}
        onClick={() => history.goBack()}
        leftIcon={'arrow-left'}
        mb="md"
      >
        Back
      </Button>
      <Grid templateColumns="434px 1fr" gap="2xl" pr="50px">
        <Box>
          <Box>
            <AccountProfile data={data} />
            <OnbaordDate data={data} />
          </Box>
        </Box>
        {isClinic ? (
          <Box>
            <Box>
              <Tabs>
                <Flex justifyContent="space-between" paddingBottom="20px">
                  <TabList>
                    <Tab>Users</Tab>
                    <Tab>Providers</Tab>
                    <Tab>Reports</Tab>
                  </TabList>
                </Flex>
                <TabPanels>
                  <TabPanel py="0">
                    <UserManagement type="non-provider" id={id} />
                  </TabPanel>
                  <TabPanel py="0">
                    <UserManagement type="providers" id={id} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box>
              <Tabs>
                <Flex justifyContent="space-between" paddingBottom="20px">
                  <TabList>
                    <Tab>Main</Tab>
                    <Tab>Users</Tab>
                    <Tab>Clinics</Tab>
                    <Tab>Providers</Tab>
                  </TabList>
                </Flex>
                <TabPanels>
                  <TabPanel py="0">
                    <AccountInformation data={data} />
                    <AccountSnapshot data={data} />
                  </TabPanel>
                  <TabPanel py="0">
                    <UserManagement childrenId={listOfChildrenIds} id={id} />
                  </TabPanel>
                  <TabPanel py="0">
                    <ClinicManagement id={id} />
                  </TabPanel>
                  <TabPanel py="0">
                    <UserManagement childrenId={listOfChildrenIds} type="providers" id={id} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default Account;
