import { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Flex,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  VStack,
} from '@rhythm/components';
import { HypertensionReportForm } from '~src/components/HypertensionReportForm';
import { GlucoseReportForm } from '~src/components/GlucoseReportForm/GlucoseReportForm';
import { Search } from '~src/components/Search';
import { Organization } from '~generated';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { createAccountRoles } from '~src/constants/roles';
import { AccountPaginatePage } from './AccountPaginatePage';

export interface GetAllAccountsProps {
  offset?: number;
  limit?: number;
  isNew?: boolean;
  searchTerm?: string;
  sortBy?: string;
  sort?: string;
  clinicianIds?: string[];
  isDefaultSearch?: boolean;
}
type AccountManagementReportType = {
  organizationIds: string[];
  physicianIds: null;
  clinicalIds: null;
};
type AccountManagementType = {
  setAccountsCounts?: (count: number) => void;
  currentTab?: number;
};

export const getAccountData = (
  data,
  pagination,
  setPagination,
  offset,
  setOffset,
  limit,
  setLimit,
  sortBy,
  setSortBy,
  setSort
): void => {
  const pageIndex = data?.pageIndex;
  const pageSize = data?.pageSize;
  if (data?.pageIndex !== pagination.pageIndex || data?.pageSize !== pagination.pageSize) {
    setPagination({
      pageIndex,
      pageSize,
    });
    return;
  }
  if (offset !== data.pageIndex) {
    setOffset(data.pageIndex * data.pageSize);
  }
  if (limit !== data.pageSize) {
    setLimit(data.pageSize);
  }
  const sort = data.sortBy[0];
  if (sort) {
    const { desc, id } = sort;
    if (sortBy !== id || sort !== desc) {
      setSortBy(id);
      setSort(desc);
    }
  } else {
    if (sortBy !== undefined || sort !== undefined) {
      setSortBy('');
      setSort('');
    }
  }
};

export function AccountManagement(props: Readonly<AccountManagementType>) {
  const location = useLocation(); // Get location object
  const urlParams = new URLSearchParams(location.search);
  const pageSearch = urlParams.get('search') ?? '';
  const [searchTerm, setSearchTerm] = useState(pageSearch);
  const [organizations, setOrganizations] = useState<Organization[] | undefined>(undefined);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const [currentReportType, setCurrentReportType] = useState('hypertension');

  const [selectedOrgs, setSelectedOrgs] = useState<AccountManagementReportType>({
    organizationIds: [],
    physicianIds: null,
    clinicalIds: null,
  });
  let selectedData = {};
  const history = useHistory();
  const onDataReceived = (data) => {
    selectedData = data;
  };
  const handleReportFormClick = (reportType: any) => {
    setIsDateRangePickerOpen(true);
    setCurrentReportType(reportType);
    if (Object.keys(selectedData).length > 0) {
      const orgIds: any = [];
      if (organizations) {
        Object.entries(selectedData).forEach(([key]: any[]) => {
          if (organizations) {
            orgIds.push(organizations[key]?.id);
          }
        });
      }
      setSelectedOrgs({ organizationIds: orgIds, physicianIds: null, clinicalIds: null });
    }
  };

  return (
    <Fragment>
      <HStack justifyContent="right">
        <RoleBasedRoutes allowedRoles={createAccountRoles}>
          <Button
            variant="secondaryDark"
            leftIcon="add"
            onClick={() => history.push('/settings/account/create')}
          >
            Add New Account
          </Button>
        </RoleBasedRoutes>
      </HStack>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        padding="xl"
        marginTop="xl"
        marginBottom="l"
        background={'#FFFFFF'}
      >
        <Flex alignItems="center">
          <HStack justifyContent="space-between" alignItems="center">
            <Box>
              <Menu
                onClose={() => {
                  setIsDateRangePickerOpen(false);
                }}
              >
                <MenuButton as={Button} rightIcon={'drop-down'}>
                  Clinical Report
                </MenuButton>
                {!isDateRangePickerOpen ? (
                  <MenuList>
                    <MenuItem
                      closeOnSelect={false}
                      onClick={() => handleReportFormClick('hypertension')}
                    >
                      Hypertensives
                    </MenuItem>
                    <MenuItem
                      closeOnSelect={false}
                      onClick={() => handleReportFormClick('glucose')}
                    >
                      Diabetes
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList>
                    <Flex
                      minWidth="max-content"
                      basis={'flex'}
                      direction={'row'}
                      align={'center'}
                      gap="2"
                    >
                      <VStack>
                        <Box>
                          {currentReportType === 'hypertension' ? (
                            <HypertensionReportForm
                              selectedData={selectedOrgs}
                              handleCancelClick={() => setIsDateRangePickerOpen(false)}
                            />
                          ) : (
                            <GlucoseReportForm
                              selectedData={selectedOrgs}
                              handleCancelClick={() => setIsDateRangePickerOpen(false)}
                            />
                          )}
                        </Box>
                      </VStack>
                    </Flex>
                  </MenuList>
                )}
              </Menu>
            </Box>
          </HStack>
        </Flex>
        <Flex>
          <Search
            placeholder="Search Accounts"
            value={searchTerm}
            onSearchTerm={setSearchTerm}
            fastReview={false}
          />
        </Flex>
      </HStack>

      <AccountPaginatePage
        setOrganizations={setOrganizations}
        search={searchTerm}
        setAccountsCounts={props.setAccountsCounts}
        setSelectedData={onDataReceived}
      />
    </Fragment>
  );
}
