import { ROLE_TYPES } from '~src/types';

export const editPatientRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,

  ROLE_TYPES.RHYTHM_SUPER_ADMIN,

  ROLE_TYPES.RHYTHM_NON_CLINICIAN,

  ROLE_TYPES.RHYTHM_CLINICIAN,

  ROLE_TYPES.PROVIDER,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,

  // ROLE_TYPES.PROVIDER_ADMINSTRATOR,

  // ROLE_TYPES.NON_PROVIDER_ADMINISTRATOR,

  ROLE_TYPES.NON_PROVIDER,

  // ROLE_TYPES.ADMINISTRATOR,

  // ROLE_TYPES.CLERICAL,
];
export const editPatientUserRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,

  ROLE_TYPES.RHYTHM_SUPER_ADMIN,

  ROLE_TYPES.RHYTHM_NON_CLINICIAN,

  ROLE_TYPES.RHYTHM_CLINICIAN,

  ROLE_TYPES.PROVIDER,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
];

export const createAccountRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  // ROLE_TYPES.PROVIDER_ADMINSTRATOR,
  // ROLE_TYPES.NON_PROVIDER_ADMINISTRATOR,
  // ROLE_TYPES.ADMINISTRATOR,
];

export const createTeamRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  // ROLE_TYPES.PROVIDER_ADMINSTRATOR,
  // ROLE_TYPES.NON_PROVIDER_ADMINISTRATOR,
  // ROLE_TYPES.ADMINISTRATOR,
];

export const createUserRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  // ROLE_TYPES.PROVIDER_ADMINSTRATOR,
  // ROLE_TYPES.NON_PROVIDER_ADMINISTRATOR,
  // ROLE_TYPES.ADMINISTRATOR,
];

export const viewUserRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_NON_CLINICIAN,
  ROLE_TYPES.RHYTHM_CLINICIAN,
];

export const editUserRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  // ROLE_TYPES.ADMINISTRATOR,
  // ROLE_TYPES.PROVIDER_ADMINSTRATOR,
  // ROLE_TYPES.NON_PROVIDER_ADMINISTRATOR,
];

export const createTeamRole: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
];

export const editTeamRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  // ROLE_TYPES.ADMINISTRATOR,
  // ROLE_TYPES.PROVIDER_ADMINSTRATOR,
  // ROLE_TYPES.NON_PROVIDER_ADMINISTRATOR,
];

export const viewTeamRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_CLINICIAN,
  ROLE_TYPES.RHYTHM_NON_CLINICIAN,
];

export const editUserRolesRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  // ROLE_TYPES.ADMINISTRATOR,
  // ROLE_TYPES.PROVIDER_ADMINSTRATOR,
  // ROLE_TYPES.NON_PROVIDER_ADMINISTRATOR,
];

export const createPatientRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_CLINICIAN,
  ROLE_TYPES.RHYTHM_NON_CLINICIAN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  // ROLE_TYPES.PROVIDER,
  // ROLE_TYPES.PROVIDER_ADMINSTRATOR,
  // ROLE_TYPES.NON_PROVIDER_ADMINISTRATOR,
  // ROLE_TYPES.NON_PROVIDER,
  // ROLE_TYPES.ADMINISTRATOR,
];

export const archivePatientRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_CLINICIAN,
  ROLE_TYPES.PROVIDER,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  // ROLE_TYPES.PROVIDER_ADMINSTRATOR,
  // ROLE_TYPES.NON_PROVIDER_ADMINISTRATOR,
  ROLE_TYPES.NON_PROVIDER,
  // ROLE_TYPES.ADMINISTRATOR,
];

export const alertToggleRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_CLINICIAN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  ROLE_TYPES.RHYTHM_NON_CLINICIAN,
];

export const alertToggleClinicians: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_CLINICIAN,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  ROLE_TYPES.RHYTHM_NON_CLINICIAN,
  ROLE_TYPES.PROVIDER,
  ROLE_TYPES.NON_PROVIDER,
];

export const sideNavRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_CLINICIAN,
  ROLE_TYPES.PROVIDER,
  ROLE_TYPES.NON_PROVIDER,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
  ROLE_TYPES.RHYTHM_NON_CLINICIAN,
  // ROLE_TYPES.CLERICAL,
  // ROLE_TYPES.HEART_FAILURE,
];

export const editDeleteNotes: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
];
export const lineGraphRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_CLINICIAN,
  ROLE_TYPES.RHYTHM_NON_CLINICIAN,
];

export const clearAlertRoles: ROLE_TYPES[] = [
  ROLE_TYPES.RHYTHM_ADMINISTRATOR,
  ROLE_TYPES.RHYTHM_SUPER_ADMIN,
  ROLE_TYPES.RHYTHM_CLINICIAN,
  ROLE_TYPES.RHYTHM_NON_CLINICIAN,
  ROLE_TYPES.RHYTHM_LEAD_CLINICIAN,
];

export const openSearchEditRoles: ROLE_TYPES[] = [ROLE_TYPES.RHYTHM_SUPER_ADMIN];
