/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BloodPressure } from './BloodPressure';
import type { BloodSugar } from './BloodSugar';
import type { DeviceVendor } from './DeviceVendor';
import type { Patient } from './Patient';
import type { Pulse } from './Pulse';
import type { PulseOx } from './PulseOx';
import type { Weight } from './Weight';

export type Device = {
    vendor: DeviceVendor;
    isActive: boolean;
    serialNumber: string;
    type: Device.type;
    bloodPressure: Array<BloodPressure>;
    bloodSugar: Array<BloodSugar>;
    pulse: Array<Pulse>;
    pulseOx: Array<PulseOx>;
    weight: Array<Weight>;
    patient: Patient;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace Device {

    export enum type {
        WEIGHT = 'weight',
        BLOOD_PRESSURE = 'blood_pressure',
        BLOOD_PRESSURE_SYSTOLIC = 'blood_pressure_systolic',
        BLOOD_PRESSURE_DIASTOLIC = 'blood_pressure_diastolic',
        BLOOD_SUGAR = 'blood_sugar',
        SEVEN_DAYS_CHANGE = 'seven_days_change',
        PULSE = 'pulse',
        BLOOD_OXYGEN = 'blood_oxygen',
    }


}

