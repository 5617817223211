import { Box, Flex, Text } from '@rhythm/components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import './style.css';

dayjs.extend(relativeTime);

export const PatientBloodPressureReading = (props: { measurement: any }) => {
  const { measurement } = props;
  if (!measurement.systolicReading && !measurement.diastolicReading) {
    return (
      <Flex direction="column">
        <Text mb="xs" fontWeight="bold" color={'neutral.black'}>
          —
        </Text>
      </Flex>
    );
  } else {
    return (
      <Flex direction="column" className="fast-review-card">
        <Box mb="xs" alignItems="center" display={'inline-flex'}>
          <Text
            mb="xs"
            fontWeight="bold"
            color={
              measurement.isReviewed
                ? '#B8C0CD'
                : measurement.systolicOutlier
                ? 'feedback.errorText'
                : 'neutral.black'
            }
          >
            {measurement?.systolicReading || '-'}
          </Text>
          <Text mb="xs" color={measurement.isReviewed ? '#B8C0CD' : 'neutral.black'}>
            /
          </Text>
          <Text
            mb="xs"
            fontWeight="bold"
            color={
              measurement.isReviewed
                ? '#B8C0CD'
                : measurement.diastolicOutlier
                ? 'feedback.errorText'
                : 'neutral.black'
            }
          >
            {measurement?.diastolicReading || '-'}
          </Text>
          <span>&nbsp;</span>
          <Text
            mb="xs"
            color={
              measurement.isReviewed
                ? '#B8C0CD'
                : measurement.systolicOutlier || measurement.diastolicOutlier
                ? 'feedback.errorText'
                : 'neutral.black'
            }
            fontWeight="bold"
          >
            {measurement?.unit || '-'}
          </Text>
        </Box>
        <Text mb="xs" color={measurement.isReviewed ? '#B8C0CD' : 'neutral.black'}>
          {measurement?.timestamp && dayjs(measurement?.timestamp).fromNow()}
        </Text>
      </Flex>
    );
  }
};
