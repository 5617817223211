import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { RhythmThemeProvider } from '@rhythm/components';

import { defaultConfig, globalConfig, globalConfigUrl } from './configuration/config';

import '@rhythm/components/dist/styles.css';

import { App } from '~src/components/App';

const queryClient = new QueryClient();

const app: ReactElement = (
  <React.StrictMode>
    <RhythmThemeProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </RhythmThemeProvider>
  </React.StrictMode>
);

fetch(globalConfigUrl)
  .then((response) => response.json())
  .then((data) => {
    globalConfig.set(data);
    console.log('Global config fetched: ', data);
    return app;
  })
  .catch((e) => {
    if (process.env.NODE_ENV === 'development') {
      // You cannot change the value of NODE_ENV. To test this if, change "development"
      console.warn(
        // eslint-disable-next-line max-len
        `Failed to load global configuration from '${globalConfigUrl}', using the default configuration instead:`,
        defaultConfig
      );
      globalConfig.set(defaultConfig);
      return app;
    } else {
      const errorMessage =
        // eslint-disable-next-line max-len
        'Error while fetching global config, the App wil not be rendered. (This is NOT a React error.)';
      console.error(errorMessage, `Have you provided the config file '${globalConfigUrl}'?`, e);
      return <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>;
    }
  })
  .then((reactElement: ReactElement) => {
    ReactDOM.render(reactElement, document.getElementById('root'));
  });
