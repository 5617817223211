export function createObjectWithOptionalProperties<T, U>(baseObject: T, properties: U): any {
  // Initialize the new object with baseObject's properties
  const newObject = { ...baseObject };

  // Add properties from the properties object that are not undefined
  Object.keys(properties).forEach((key) => {
    const value = properties[key];
    if (value) {
      newObject[key] = value;
    }
  });

  return newObject;
}
