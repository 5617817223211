import { Flex, Text } from '@rhythm/components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AllDeviceThresholds } from '~src/types/AllDeviceThresholds';
import { PatientReading } from '~generated';
import { PatientBloodPressureReading } from './PatientBloodPressureReading';

dayjs.extend(relativeTime);

export interface MetricCellProps {
  id?: string;
  type: PatientReading.type;
  deviceThresholds?: AllDeviceThresholds;
  timestamp?: string;
  systolic?: number;
  diastolic?: number;
  irregular?: boolean;
  deviceId?: string;
  meter_id?: string;
  reading?: number;
  before_meal?: boolean;
  battery?: number;
  volume?: number;
  event_flag?: string;
  pulse?: number;
  pulseox?: number;
  weight?: number;
  latestWeight?: number;
  previousWeight?: any;
  weightDifference?: number;
  isReviewed?: boolean;
}

export interface MetricElasticCellProps {
  id: string;
  type: any;
  value: any;
  timestamp: any;
  outlier: any;
}

type DataResponse = {
  reading?: string;
  timestamp?: string;
  outlier?: boolean;
  systolicReading?: string;
  diastolicReading?: string;
  systolicOutlier?: boolean;
  diastolicOutlier?: boolean;
  unit?: string;
  isReviewed?: boolean;
};

export function MetricCell({
  type,
  timestamp,
  systolic,
  deviceThresholds,
  diastolic,
  reading,
  pulse,
  pulseox,
  weight,
  previousWeight,
  isReviewed = false,
}: MetricCellProps) {
  const defaultColor = 'neutral.black';

  const data = {};

  if (pulseox) type = PatientReading.type.BLOOD_OXYGEN;
  if (systolic && diastolic) type = PatientReading.type.BLOOD_PRESSURE;
  if (pulse && !systolic) type = PatientReading.type.PULSE;
  if (reading) type = PatientReading.type.BLOOD_SUGAR;
  if (weight) type = PatientReading.type.WEIGHT;

  const formatPatient = (type: PatientReading.type): DataResponse => {
    switch (type) {
      case PatientReading.type.BLOOD_PRESSURE: {
        if (diastolic && systolic) {
          const reading = {
            diastolic: diastolic,
            systolic: systolic,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };

          let systolicOutlier = false;
          let diastolicOutlier = false;

          if (
            (deviceThresholds?.bloodPressureDiastolicUpperLimit &&
              reading.diastolic > parseInt(deviceThresholds?.bloodPressureDiastolicUpperLimit)) ||
            (deviceThresholds?.bloodPressureDiastolicLowerLimit &&
              reading.diastolic < parseInt(deviceThresholds?.bloodPressureDiastolicLowerLimit))
          ) {
            diastolicOutlier = true;
          }

          if (
            (deviceThresholds?.bloodPressureSystolicUpperLimit &&
              reading.systolic > parseInt(deviceThresholds?.bloodPressureSystolicUpperLimit)) ||
            (deviceThresholds?.bloodPressureSystolicLowerLimit &&
              reading.systolic < parseInt(deviceThresholds?.bloodPressureSystolicLowerLimit))
          ) {
            systolicOutlier = true;
          }

          return {
            systolicReading: `${reading.systolic}`,
            diastolicReading: `${reading.diastolic}`,
            timestamp: reading.timestamp,
            systolicOutlier,
            diastolicOutlier,
            unit: 'mmHg',
            isReviewed: reading?.isReviewed,
          };
        }
        return {};
      }

      case PatientReading.type.BLOOD_SUGAR: {
        if (reading && timestamp) {
          const bloodSugarReading = {
            reading: reading,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };

          let outlier = false;
          const sugar = bloodSugarReading;

          if (
            (deviceThresholds?.bloodSugarLowerLimit &&
              sugar.reading < parseInt(deviceThresholds?.bloodSugarLowerLimit)) ||
            (deviceThresholds?.bloodSugarUpperLimit &&
              sugar.reading > parseInt(deviceThresholds?.bloodSugarUpperLimit))
          ) {
            outlier = true;
          }

          return {
            reading: `${sugar.reading} mg/dL`,
            timestamp: sugar.timestamp,
            outlier,
            isReviewed: sugar?.isReviewed,
          };
        }

        return {};
      }

      case PatientReading.type.PULSE: {
        if (pulse) {
          const reading = {
            pulse: pulse,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };

          let outlier = false;

          if (
            (deviceThresholds?.pulseLowerLimit &&
              reading.pulse < parseInt(deviceThresholds?.pulseLowerLimit)) ||
            (deviceThresholds?.pulseUpperLimit &&
              reading.pulse > parseInt(deviceThresholds?.pulseUpperLimit))
          ) {
            outlier = true;
          }

          return {
            reading: `${reading.pulse} bpm`,
            timestamp: reading.timestamp,
            unit: 'mmHg',
            outlier: outlier,
            isReviewed: reading?.isReviewed,
          };
        }
        return {};
      }

      case PatientReading.type.WEIGHT: {
        if (weight) {
          const recentReading = {
            weight: weight,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };
          let outlier = false;
          if (previousWeight) {
            const weightDifferenceThreshold = deviceThresholds?.weightUpperLimit;
            const weightDifference = recentReading.weight - previousWeight.weight;

            if (
              weightDifferenceThreshold &&
              weightDifference >= parseInt(weightDifferenceThreshold)
            ) {
              outlier = true;
            }
          }

          return {
            reading: `${recentReading.weight} lb`,
            timestamp: recentReading.timestamp,
            outlier: outlier,
            isReviewed: recentReading?.isReviewed,
          };
        }
        return {};
      }

      case PatientReading.type.BLOOD_OXYGEN: {
        if (pulseox) {
          const reading = {
            pulseox: pulseox,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };

          let outlier = false;

          if (
            (deviceThresholds?.bloodOxygenLowerLimit &&
              reading.pulseox < parseInt(deviceThresholds?.bloodOxygenLowerLimit)) ||
            (deviceThresholds?.bloodOxygenUpperLimit &&
              reading.pulseox > parseInt(deviceThresholds?.bloodOxygenUpperLimit))
          ) {
            outlier = true;
          }

          return {
            reading: `${reading.pulseox}%`,
            timestamp: reading.timestamp,
            unit: '%',
            outlier: outlier,
            isReviewed: reading?.isReviewed,
          };
        }
        return {};
      }
    }

    return {};
  };

  if (!data)
    return (
      <Flex direction="column">
        <Text mb="xs" fontWeight="bold" color={defaultColor}>
          —
        </Text>
      </Flex>
    );

  const measurement = formatPatient(type);

  if (type == PatientReading.type.BLOOD_PRESSURE) {
    return <PatientBloodPressureReading measurement={measurement} />;
  }

  if (!measurement.reading) {
    return (
      <Flex direction="column">
        <Text mb="xs" fontWeight="bold" color={defaultColor}>
          —
        </Text>
      </Flex>
    );
  }
  return (
    <Flex direction="column">
      <Text
        mb="xs"
        fontWeight="bold"
        color={
          measurement.isReviewed
            ? '#B8C0CD'
            : measurement.outlier
              ? 'feedback.errorText'
              : defaultColor
        }
      >
        {measurement.reading}
      </Text>
      <Text mb="xs" color={measurement.isReviewed ? '#B8C0CD' : defaultColor}>
        {dayjs(measurement.timestamp).fromNow()}
      </Text>
    </Flex>
  );
}
export function MetricCellFastReview({
  type,
  timestamp,
  systolic,
  deviceThresholds,
  diastolic,
  reading,
  pulse,
  pulseox,
  weight,
  previousWeight,
  isReviewed = false,
}: MetricCellProps) {
  const defaultColor = 'neutral.black';

  const data = {};

  if (pulseox) type = PatientReading.type.BLOOD_OXYGEN;
  if (systolic && diastolic) type = PatientReading.type.BLOOD_PRESSURE;
  if (pulse) type = PatientReading.type.PULSE;
  if (reading) type = PatientReading.type.BLOOD_SUGAR;
  if (weight) type = PatientReading.type.WEIGHT;

  const formatPatient = (type: PatientReading.type): DataResponse | undefined => {
    switch (type) {
      case PatientReading.type.BLOOD_PRESSURE: {
        if (diastolic && systolic) {
          const reading = {
            diastolic: diastolic,
            systolic: systolic,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };

          let systolicOutlier = false;
          let diastolicOutlier = false;

          if (
            (deviceThresholds?.bloodPressureDiastolicUpperLimit &&
              reading.diastolic > parseInt(deviceThresholds?.bloodPressureDiastolicUpperLimit)) ||
            (deviceThresholds?.bloodPressureDiastolicLowerLimit &&
              reading.diastolic < parseInt(deviceThresholds?.bloodPressureDiastolicLowerLimit))
          ) {
            diastolicOutlier = true;
          }

          if (
            (deviceThresholds?.bloodPressureSystolicUpperLimit &&
              reading.systolic > parseInt(deviceThresholds?.bloodPressureSystolicUpperLimit)) ||
            (deviceThresholds?.bloodPressureSystolicLowerLimit &&
              reading.systolic < parseInt(deviceThresholds?.bloodPressureSystolicLowerLimit))
          ) {
            systolicOutlier = true;
          }

          return {
            systolicReading: `${reading.systolic}`,
            diastolicReading: `${reading.diastolic}`,
            timestamp: reading.timestamp,
            systolicOutlier,
            diastolicOutlier,
            unit: 'mmHg',
            isReviewed: reading?.isReviewed,
          };
        }
        return {};
      }

      case PatientReading.type.BLOOD_SUGAR: {
        if (reading && timestamp) {
          const bloodSugarReading = {
            reading: reading,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };

          let outlier = false;
          const sugar = bloodSugarReading;

          if (
            (deviceThresholds?.bloodSugarLowerLimit &&
              sugar.reading < parseInt(deviceThresholds?.bloodSugarLowerLimit)) ||
            (deviceThresholds?.bloodSugarUpperLimit &&
              sugar.reading > parseInt(deviceThresholds?.bloodSugarUpperLimit))
          ) {
            outlier = true;
          }

          return {
            reading: `${sugar.reading} mg/dL`,
            timestamp: sugar.timestamp,
            outlier,
            isReviewed: sugar?.isReviewed,
          };
        }

        return {};
      }

      case PatientReading.type.PULSE: {
        if (pulse) {
          const reading = {
            pulse: pulse,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };

          let outlier = false;

          if (
            (deviceThresholds?.pulseLowerLimit &&
              reading.pulse < parseInt(deviceThresholds?.pulseLowerLimit)) ||
            (deviceThresholds?.pulseUpperLimit &&
              reading.pulse > parseInt(deviceThresholds?.pulseUpperLimit))
          ) {
            outlier = true;
          }

          return {
            reading: `${reading.pulse} bpm`,
            timestamp: reading.timestamp,
            unit: 'mmHg',
            outlier: outlier,
            isReviewed: reading.isReviewed,
          };
        }
        return {};
      }

      case PatientReading.type.WEIGHT: {
        if (weight) {
          const recentReading = {
            weight: weight,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };
          let outlier = false;
          if (previousWeight) {
            const weightDifferenceThreshold = deviceThresholds?.weightUpperLimit;
            const weightDifference = recentReading.weight - previousWeight.weight;

            if (
              weightDifferenceThreshold &&
              weightDifference > parseInt(weightDifferenceThreshold)
            ) {
              outlier = true;
            }
          }

          return {
            reading: `${recentReading.weight} lb`,
            timestamp: recentReading.timestamp,
            outlier: outlier,
            isReviewed: recentReading?.isReviewed,
          };
        }
        return {};
      }

      case PatientReading.type.BLOOD_OXYGEN: {
        if (pulseox) {
          const reading = {
            pulseox: pulseox,
            timestamp: timestamp,
            isReviewed: isReviewed,
          };

          let outlier = false;

          if (
            (deviceThresholds?.bloodOxygenLowerLimit &&
              reading.pulseox < parseInt(deviceThresholds?.bloodOxygenLowerLimit)) ||
            (deviceThresholds?.bloodOxygenUpperLimit &&
              reading.pulseox > parseInt(deviceThresholds?.bloodOxygenUpperLimit))
          ) {
            outlier = true;
          }

          return {
            reading: `${reading.pulseox}%`,
            timestamp: reading.timestamp,
            unit: '%',
            outlier: outlier,
            isReviewed: reading?.isReviewed,
          };
        }
        return {};
      }
    }
  };

  if (!data)
    return (
      <Flex direction="column">
        <Text mb="xs" fontWeight="bold" color={defaultColor}>
          —
        </Text>
      </Flex>
    );

  const measurement = formatPatient(type);

  if (!measurement) return;

  if (type == PatientReading.type.BLOOD_PRESSURE) {
    return <PatientBloodPressureReading measurement={measurement} />;
  }

  if (!measurement.reading) {
    return (
      <Flex direction="column">
        <Text mb="xs" fontWeight="bold" color={defaultColor}>
          —
        </Text>
      </Flex>
    );
  }
  return (
    <Flex direction="column">
      <Text
        mb="xs"
        fontWeight="bold"
        color={
          measurement.isReviewed
            ? '#B8C0CD'
            : measurement.outlier
              ? 'feedback.errorText'
              : 'neutral.black'
        }
      >
        {measurement.reading}
      </Text>
      <Text mb="xs" color={measurement.isReviewed ? '#B8C0CD' : 'neutral.black'}>
        {dayjs(measurement.timestamp).fromNow()}
      </Text>
    </Flex>
  );
}
