import { useEffect, useMemo, useState } from 'react';
import { Text } from '@rhythm/components';
import type { Cell, SortingRule } from 'react-table';
import { DataTable } from '~src/components/DataTable';
import { useDebounce } from 'react-use';
import { PatientCell, PatientStatusCell } from '~src/components/PatientsDataView';
import { Contact, PatientTime } from '~generated';
import { LastInteractionCell } from './LastInteractionCell';
import { PatientStatusCellProps } from './PatientStatusCell';
import { OnFetchProps } from '@rhythm/components/dist/DataTable/DataTable';
import { PatientLocalStorage, retrievePersistedData, savePersistentData } from '~src/utils';
import { useLogoutUser } from '~src/services/users';
import { UNAUTHROIZED } from '~src/constants';
import { PaginationSort, usePatientOpenSearch } from '~src/services/patients';
import { FormFilterValues } from '~src/pages/Patients/PatientFilters';
import { MetricElasticCellProps } from './MetricCell';
import dayjs from 'dayjs';
import { PatientNewLink } from './PatientCell';
import { useUserRoleAndEmail } from '~src/hooks';

interface PatientRow extends Record<string, unknown> {
  id: string;
  fullname: string;
  contactNumber: string;
  physicianName: string;
  daysTransmitted: number;
  contacted: boolean;
  minutesLogged: number;
  bloodPressure?: MetricElasticCellProps;
  pulse?: MetricElasticCellProps;
  weight?: MetricElasticCellProps;
  glucose?: MetricElasticCellProps;
  isReviewed: boolean;
  minutes?: PatientTime[];
  statusType: PatientStatusCellProps;
}

interface PatientPaginationParams extends OnFetchProps {
  goToPage?: number | undefined;
  initialSize?: number | undefined;
  pageSize: number;
  defaultSortBy?: SortingRule<unknown>[];
  searchWord?: string;
  totalItemsCount?: number;
}

export type PC<T> = Cell<PatientRow, T>;
export type PatientCellProps = Pick<PatientRow, 'fullname' | 'contactNumber' | 'id'>;

const PAGE_SIZE = 20;

export interface PatientsDataView {
  search?: string;
  filterSearch?: string;
  updatedFilterSearch?: FormFilterValues;
  active?: boolean;
  currentTab?: number;
  setTotalPatients?: any;
}

export interface PatientsSearchData {
  id: string;
  fullName: string;
  status: string;
  physicianId: string;
  clinicianId: string;
  clinicians: any;
  account: string;
  physicianFullName: string;
  daysTransmitted: number;
  minutes: PatientTime[];
  minutesLogged: number;
  contact: Contact[];
  statusType: string;
  isContacted: boolean;
  isReviewed: boolean;
  latestBloodPressureReading: any;
  latestPulseReading: any;
  latestWeightReading: any;
  latestGlucoseReading: any;
  timestamp: any;
  outlier: boolean;
  latestSystolicReading: any;
  latestDiastoicReading: any;
  latestBloodPressureTimeStamp: any;
  latestPulseReadingTimeStamp: any;
  latestWeightReadingTimeStamp: any;
  latestGlucoseReadingTimeStamp: any;
  latestSystolicOutlier: boolean;
  latestDiastoicOutlier: boolean;
  latestPulseOutlier: boolean;
  latestWeightOutlier: boolean;
  latestGlucoseOutlier: boolean;
}

function createMeasurement(
  id: string,
  type: string,
  value: any,
  timestamp: any,
  outlier:
    | boolean
    | {
        systolicOutlier?: boolean;
        diastolicOutlier?: boolean;
      }
): MetricElasticCellProps | undefined {
  if (type === 'BP') {
    return {
      id,
      type: 'blood_pressure',
      value: {
        systolicReading: value?.systolicReading,
        diastolicReading: value?.diastolicReading,
      },
      timestamp: timestamp,
      outlier: outlier,
    };
  }
  if (type === 'Pulse') {
    return {
      id,
      type: 'pulse',
      value: value,
      timestamp: timestamp,
      outlier: outlier,
    };
  }
  if (type === 'Glucose') {
    return {
      id,
      type: 'blood_sugar',
      value: value,
      timestamp: timestamp,
      outlier: outlier,
    };
  }
  if (type === 'Weight') {
    return {
      id,
      type: 'weight',
      value: value,
      timestamp: timestamp,
      outlier: outlier,
    };
  }
  return;
}

const currentMonthHasTransimission = (
  latestBloodPressureTimeStamp: string,
  latestPulseReadingTimeStamp: string,
  latestWeightReadingTimeStamp: string,
  latestGlucoseReadingTimeStamp: string
) => {
  const currentMonth = dayjs().month();
  const currentYear = dayjs().year();
  const latestBloodPressureMonth = dayjs(latestBloodPressureTimeStamp).month();
  const latestBloodPressureYear = dayjs(latestBloodPressureTimeStamp).year();
  const latestPulseReadingMonth = dayjs(latestPulseReadingTimeStamp).month();
  const latestPulseReadingYear = dayjs(latestPulseReadingTimeStamp).year();
  const latestWeightReadingMonth = dayjs(latestWeightReadingTimeStamp).month();
  const latestWeightReadingYear = dayjs(latestWeightReadingTimeStamp).year();
  const latestGlucoseReadingMonth = dayjs(latestGlucoseReadingTimeStamp).month();
  const latestGlucoseReadingYear = dayjs(latestGlucoseReadingTimeStamp).year();
  if (
    currentMonth === latestBloodPressureMonth &&
    currentYear === latestBloodPressureYear &&
    latestBloodPressureTimeStamp
  ) {
    return true;
  }
  if (
    currentMonth === latestPulseReadingMonth &&
    currentYear === latestPulseReadingYear &&
    latestPulseReadingTimeStamp
  ) {
    return true;
  }
  if (
    currentMonth === latestWeightReadingMonth &&
    currentYear === latestWeightReadingYear &&
    latestWeightReadingTimeStamp
  ) {
    return true;
  }
  if (
    currentMonth === latestGlucoseReadingMonth &&
    currentYear === latestGlucoseReadingYear &&
    latestGlucoseReadingTimeStamp
  ) {
    return true;
  }
  return false;
};

function usePatientRows(patients?: PatientsSearchData[]): PatientRow[] {
  return useMemo(() => {
    if (!patients) return [];
    return patients.map((patient) => ({
      id: patient.id,
      fullname: patient?.fullName,
      contactNumber: patient?.contact?.[0]?.contact || '',
      physicianName: patient.physicianFullName,
      daysTransmitted: currentMonthHasTransimission(
        patient?.latestBloodPressureTimeStamp,
        patient.latestPulseReadingTimeStamp,
        patient.latestWeightReadingTimeStamp,
        patient.latestGlucoseReadingTimeStamp
      )
        ? patient.daysTransmitted
        : 0,
      contacted: patient.isContacted,
      statusType: { statusType: patient.statusType },
      isReviewed: patient?.isReviewed,
      minutesLogged: patient?.minutesLogged || 0,
      minutes: patient?.minutes,
      bloodPressure: createMeasurement(
        patient.id,
        'BP',
        {
          systolicReading: patient?.latestSystolicReading,
          diastolicReading: patient?.latestDiastoicReading,
        },
        patient.latestBloodPressureTimeStamp,
        {
          systolicOutlier: patient.latestSystolicOutlier,
          diastolicOutlier: patient.latestDiastoicOutlier,
        }
      ),
      pulse: createMeasurement(
        patient.id,
        'Pulse',
        patient.latestPulseReading,
        patient.latestPulseReadingTimeStamp,
        patient.latestPulseOutlier
      ),
      weight: createMeasurement(
        patient.id,
        'Glucose',
        patient.latestWeightReading,
        patient.latestWeightReadingTimeStamp,
        patient.latestWeightOutlier
      ),
      glucose: createMeasurement(
        patient.id,
        'Weight',
        patient.latestGlucoseReading,
        patient.latestGlucoseReadingTimeStamp,
        patient.latestGlucoseOutlier
      ),
      //minutesAcrruedMonth(patient)
    }));
  }, [patients]);
}

export function PatientsDataView({
  search,
  updatedFilterSearch,
  active = true,
  currentTab,
  setTotalPatients,
}: PatientsDataView) {
  const reverseColumn = (sortType: string): SortingRule<unknown>[] => {
    switch (sortType) {
      case 'physician.firstName&desc':
        return [{ id: 'physicianName', desc: true }];

      case 'physician.firstName&asc':
        return [{ id: 'physicianName', desc: false }];

      case 'patients.lastName&desc':
        return [{ id: 'Patient', desc: true }];

      case 'patients.lastName&asc':
        return [{ id: 'Patient', desc: false }];

      default:
        return [];
    }
  };

  const { userRole } = useUserRoleAndEmail();
  const isInternal = userRole.includes('roles:internal');

  const parseSortColumn = (sortType: SortingRule<unknown>[]) => {
    /**From Local Storage it is returned as a string */
    if (typeof sortType == 'string') {
      return sortType;
    }

    const value = sortType[0];

    switch (value?.id) {
      case 'physicianName': {
        return `physician.firstName&${value.desc ? 'desc' : 'asc'}`;
      }

      case 'Patient': {
        return `patients.lastName&${value.desc ? 'desc' : 'asc'}`;
      }

      case 'Review': {
        return `patients.isReviewed&${value.desc ? 'desc' : 'asc'}`;
      }

      case 'statusType': {
        return `patients.statusType&${value.desc ? 'desc' : 'asc'}`;
      }

      default:
        return '';
    }
  };

  const parseSearchColumn = (name?: string) => {
    if (name) return `${name?.split(' ').join('&')}`;

    return '';
  };

  const getLocalStorageData = () => {
    const persistedFilter = retrievePersistedData(
      `rpm-filter-${currentTab}`
    ) as PatientLocalStorage;

    if (persistedFilter) {
      const hasInitialGreaterThanZero = persistedFilter?.initial && persistedFilter?.initial > 0;
      const pageIndex = hasInitialGreaterThanZero ? persistedFilter?.initial : 0;
      return {
        pageIndex: pageIndex || 0,
        goToPage: parseInt(`${pageIndex || 0 / persistedFilter?.pageSize || PAGE_SIZE}`),
        initialSize: pageIndex,
        sortBy: persistedFilter?.sortBy || `patients.lastName&asc`,
        defaultSortBy: reverseColumn(persistedFilter?.sortBy),
        pageSize: persistedFilter?.pageSize || PAGE_SIZE,
        totalCount: persistedFilter?.totalCount,
        search: persistedFilter?.search,
        searchWord: persistedFilter?.search,
        filters: persistedFilter?.filters || {},
      };
    }

    return {
      pageIndex: 0,
      sortBy: `patients.lastName&asc`,
      initialSize: 0,
      pageSize: PAGE_SIZE,
      defaultSortBy: [],
      goToPage: 0,
      totalCount: 0,
      search: '',
      searchWord: '',
      filters: {},
    };
  };

  const [pagination, setPagination] = useState<PatientPaginationParams>({
    ...getLocalStorageData(),
  });
  const [patientsData, setPatientsData] = useState<PatientsSearchData[]>([]);
  const [pageParams, setPageParams] = useState({ totalSize: 0, totalPageCount: 0 });
  const { mutate: logout } = useLogoutUser();
  const [queryData, setQueryData] = useState<PaginationSort>({
    sort: pagination.sortBy as unknown as any,
    search: pagination.searchWord,
    limit: pagination.pageSize,
    offset: pagination.pageIndex,
    filters: {} as FormFilterValues,
  });
  const { data, isLoading, refetch } = usePatientOpenSearch({ ...queryData });

  useDebounce(
    () => {
      if (search !== pagination?.searchWord) {
        setPagination({
          ...pagination,
          pageIndex: 0,
          goToPage: 0,
          searchWord: search,
        });
      }
    },
    300,
    [search]
  );

  useEffect(() => {
    setPatientsData(data?.patients ?? []);

    if (data?.patients) {
      const pageSize = pagination?.pageSize || PAGE_SIZE;
      if (data?.patients?.length < pageSize) {
        setTotalPatients(data?.total);
        setPageParams({
          totalPageCount: 1,
          totalSize: data?.total ?? 0,
        });
      } else {
        setTotalPatients(data?.total);
        setPageParams({
          totalSize: data?.total,
          totalPageCount: Math.ceil(data?.total / data?.patients?.length),
        });
      }

      const persistedPaginationData = retrievePersistedData(
        `rpm-filter-${currentTab}`
      ) as PatientLocalStorage;

      if (persistedPaginationData) {
        savePersistentData(
          `rpm-filter-${currentTab}`,
          JSON.stringify({
            ...persistedPaginationData,
            totalCount: data?.total,
          })
        );
      }
    }
  }, [data]);

  useEffect(() => {
    const fetchPaginatedData = async () => {
      try {
        const pageSize = pagination?.pageSize || PAGE_SIZE;
        if (pageSize < pageParams.totalSize) {
          setPageParams({
            totalSize: data?.total ?? 0,
            totalPageCount: Math.ceil(pageParams.totalSize / pageSize),
          });
        } else {
          setPageParams({
            totalSize: data?.total ?? 0,
            totalPageCount: 1,
          });
        }

        const offset =
          pagination.pageIndex > 0
            ? pagination.pageSize * pagination.pageIndex
            : pagination.pageIndex;

        const persistedFilter = retrievePersistedData(
          `rpm-filter-${currentTab}`
        ) as PatientLocalStorage;

        if (offset > persistedFilter?.totalCount) {
          return;
        }

        const persistedPaginationData = {
          ...persistedFilter,
          totalCount: pageParams.totalSize,
          initial: offset,
          sortBy: parseSortColumn(pagination.sortBy ?? []),
          pageSize: pagination.pageSize,
          search: parseSearchColumn(pagination?.searchWord ?? persistedFilter?.search),
        };

        savePersistentData(
          `rpm-filter-${currentTab}`,
          JSON.stringify({ ...persistedPaginationData })
        );

        setQueryData({
          sort: pagination?.sortBy ?? [],
          search: parseSearchColumn(pagination?.searchWord ?? persistedFilter?.search),
          offset: offset,
          limit: pagination.pageSize,
          filters: { ...updatedFilterSearch, active } as any,
        });
      } catch (err: any) {
        if (err?.message == UNAUTHROIZED) {
          logout(null);
        }
      }
    };
    fetchPaginatedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, updatedFilterSearch]);

  const patients = usePatientRows(patientsData);

  const fetchData = ({ pageIndex, pageSize, sortBy }: OnFetchProps) => {
    if (pageIndex !== pagination.pageIndex || pageSize !== pagination.pageSize) {
      setPagination({
        pageIndex,
        pageSize,
        sortBy,
      });

      return;
    }

    if (sortBy && sortBy?.length > 0 && pagination.sortBy) {
      if (
        pagination?.sortBy[0]?.id !== sortBy[0].id ||
        pagination.sortBy[0]?.desc !== sortBy[0].desc
      ) {
        setPagination({
          pageIndex,
          pageSize,
          sortBy,
        });
      }
      return;
    }
  };

  const basicColumns = [
    {
      Header: 'Patient',
      width: 200,
      accessor: ({ id, fullname, contactNumber }: PatientRow) =>
        [fullname, id, contactNumber].join(' '),
      Cell: ({ row: { original } }: PC<PatientCellProps>) => <PatientCell {...original} />,
    },
    {
      Header: '  ',
      width: 20,
      paddingLeft: 0,
      accessor: ({ id }: PatientRow) => id,
      Cell: ({ row: { original } }: PC<PatientCellProps>) => <PatientNewLink {...original} />,
    },
    {
      Header: 'Status',
      accessor: 'statusType',
      width: 200,
      Cell: ({ value }: PC<PatientStatusCellProps>) => <PatientStatusCell {...value} />,
    },
    {
      Header: 'Physician',
      accessor: 'physicianName',
      width: 136,
    },
    {
      Header: 'Days',
      accessor: 'daysTransmitted',
      disableSortBy: true,
      width: 89,
    },
    {
      Header: 'Contact',
      accessor: 'contacted',
      width: 114,
      disableSortBy: true,
      Cell: ({ value }: PC<boolean>) => <Text>{value ? 'Yes' : 'No'}</Text>,
    },
    {
      Header: 'Minutes',
      width: 110,
      accessor: 'minutesLogged',
      disableSortBy: true,
    },
    {
      Header: 'BP',
      accessor: 'bloodPressure',
      maxWidth: 130,
      disableSortBy: true,
    },
    {
      Header: 'Pulse',
      accessor: 'pulse',
      maxWidth: 190,
      disableSortBy: true,
    },
    {
      Header: 'Weight',
      accessor: 'weight',
      maxWidth: 110,
      disableSortBy: true,
    },
    {
      Header: 'Glucose',
      accessor: 'glucose',
      maxWidth: 115,
      disableSortBy: true,
    },
  ];

  const reviewColumn = {
    Header: 'Review',
    maxWidth: 120,
    accessor: ({ id, isReviewed, minutes }: PatientRow) => [id, isReviewed, minutes].join(' '),
    Cell: ({ row: { original } }: PC<PatientCellProps>) => (
      <LastInteractionCell minutes={original.minutes ?? []} refetch={refetch} {...original} />
    ),
  };

  const completedColumns = isInternal ? [...basicColumns, reviewColumn] : basicColumns;
  return (
    <DataTable
      fetchData={fetchData}
      columns={completedColumns}
      isLoading={isLoading}
      data={patients}
      search={''}
      totalPageCount={pageParams.totalPageCount}
      totalRowCount={pageParams.totalSize}
      initialSortBy={pagination.defaultSortBy as any}
      goToPageIndex={pagination.goToPage}
      initialPageIndex={pagination.initialSize}
      initialPageSize={PAGE_SIZE}
    />
  );
}
