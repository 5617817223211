import { Flex } from '@chakra-ui/layout';
import { useOktaAuth } from '@okta/okta-react';
import { Formik, FormikHelpers, Form } from 'formik';
import { useEffect, useState } from 'react';
import { GetUserOnboardedPatientsView, Organization, User } from '~generated';
import { FormMenuSelect } from '~src/components/FormSelect';
import { FormMenuSelectRange } from '~src/components/FormSelect/FormMenuSelect';
import { CLINICIAN_ROLE, LEAD_CLINICIAN_ROLE, NON_CLINICIAN_ROLE } from '~src/constants';
import {
  useClinicUsers,
  useGetOnboardedPatients,
  useOrganizationBySortingOrder,
} from '~src/services/clinics';
import { Roles } from '~generated/models/Roles';
import { PatientLocalStorage, retrievePersistedData, savePersistentData } from '~src/utils';
import RoleBasedRoutes from '~src/components/RoleBasedRoutes';
import { Box, Button } from '@rhythm/components';
import { useGroupList } from '~src/services/group';
import { useHistory } from 'react-router-dom';

export interface Props {
  setUpdatedFilterSearch: React.Dispatch<React.SetStateAction<FormFilterValues>>;
  currentTab: number;
  fetchPhysiciansData: any;
  totalPatients?: any;
}

export type Range = {
  type: string;
  value: string;
};

export type FormFilterValues = {
  accounts: string[];
  physicians: string[];
  clinicians: string[];
  days: Range[];
  minutes: Range[];
  contacted: string[];
  active?: boolean;
  status?: string[];
  onboarding?: string[];
  group?: string[];
};

export const getInitialFilters = () => {
  return {
    accounts: [],
    physicians: [],
    clinicians: [],
    contacted: [],
    days: [],
    minutes: [],
    onboarding: [],
    active: true,
    status: [],
    group: [],
  };
};

export const handleStorageUpdate = (
  initialFilter,
  setaccountFilter,
  setUpdatedFilterSearch,
  initialValues,
  currentTab
) => {
  const storage = initialFilter?.filters;

  setaccountFilter(storage?.accounts?.length ? storage.accounts : []);

  if (storage && Object.keys(storage).length > 0 && storage.clinicians) {
    setUpdatedFilterSearch(storage);
  } else {
    const persistedPaginationData = {
      ...initialFilter,
      filters: { ...initialValues },
    };
    savePersistentData(`rpm-filter-${currentTab}`, JSON.stringify({ ...persistedPaginationData }));
  }
};

export const setPhysicianOptionsValue = (usersData, isProvider, setPhysicianOptions) => {
  setPhysicianOptions(
    usersData
      ?.filter((user: User) => user.role !== CLINICIAN_ROLE)
      ?.filter((user: User) => {
        if (isProvider) {
          return user.role === Roles.ROLES_EXTERNAL_PROVIDER;
        }

        return user.role === Roles.ROLES_EXTERNAL_PROVIDER;
      })
      ?.map((user: User) => {
        return {
          label: `${user.lastName}, ${user.firstName}`,
          value: user.id,
        };
      }) as any
  );
};

export const setOnboardingOptionsvalue = (setOnboardingOptions, userOnboardedPatientsData) => {
  setOnboardingOptions(
    userOnboardedPatientsData
      ?.filter(
        (user: GetUserOnboardedPatientsView) =>
          user.role === NON_CLINICIAN_ROLE && user.isActive && user.onboardedPatientsCount
      )
      ?.map((user: GetUserOnboardedPatientsView) => {
        return {
          label: `${user.lastName}, ${user.firstName}`,
          value: user.userId,
        };
      }) as any
  );
};

export const setPhysicianOptionsValueOnAccountFilter = (
  usersData,
  isProvider,
  setPhysicianOptions
) => {
  setPhysicianOptions(
    usersData
      ?.filter((user: User) => user.role !== CLINICIAN_ROLE)
      ?.filter((user: User) => {
        if (isProvider) {
          return user.role === Roles.ROLES_EXTERNAL_PROVIDER;
        }

        return user.role === Roles.ROLES_EXTERNAL_PROVIDER;
      })
      ?.map((user: User) => {
        return {
          label: `${user.lastName}, ${user.firstName}`,
          value: user.id,
        };
      })
  );
};

export const setOnboardingOptionsvalueOnAccountFilter = (setOnboardingOptions, usersData) => {
  setOnboardingOptions(
    usersData
      ?.filter((user: User) => user.role === NON_CLINICIAN_ROLE && user.isActive == true)
      ?.map((user: User) => {
        return {
          label: `${user.lastName}, ${user.firstName}`,
          value: user.id,
        };
      })
  );
};

export const accountFilterChecking = (physicianData, setPhysicianOptions, isProvider) => {
  physicianData?.sort(function (a: any, b: any) {
    const nameA = a.lastName.toLowerCase(),
      nameB = b.lastName.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  setPhysicianOptions(
    physicianData
      ?.filter((user: User) => user.role !== CLINICIAN_ROLE)
      ?.filter((user: User) => {
        if (isProvider) {
          // return user.email === userEmail;
          return user.role === Roles.ROLES_EXTERNAL_PROVIDER;
        }

        return user.role === Roles.ROLES_EXTERNAL_PROVIDER;
      })
      ?.map((user: User) => {
        return {
          label: `${user.lastName}, ${user.firstName}`,
          value: user.id,
        };
      })
  );
};

export const subOptions = [
  { label: '', value: '' },
  { label: '>= (greater than or equal)', value: '>=' },
  { label: '<= (less than or equal)', value: '<=' },
  { label: '= (equals)', value: '=' },
];

export const generateAccountOptions = (organizations: Organization[] | undefined) => {
  if (organizations && organizations.length > 0) {
    return organizations.map((organization: Organization) => {
      if (organization && organization.id && organization.name) {
        return {
          label: `${organization.name}`,
          value: organization.id,
        };
      }
    });
  }
  return [];
};

const PatientFilters: React.FC<Props> = ({
  setUpdatedFilterSearch,
  currentTab = 0,
  fetchPhysiciansData,
}: Props) => {
  const history = useHistory(); // Get history object
  const { isLoading, data: paginatedData } = useClinicUsers({ bypassPagination: true });
  const [physicianOption, setPhysicianOptions] = useState([]);
  const [onboardingOption, setOnboardingOptions] = useState([]);
  const [accountFilter, setaccountFilter] = useState<string[]>();
  const organizations = useOrganizationBySortingOrder({}).data;
  const oktaAuth = useOktaAuth();
  const isProvider = ((oktaAuth.authState?.accessToken?.claims?.roles as string[]) ||
    [])[0].startsWith('roles:external');
  // Conditionally call the useGetOnboardedPatients & groups hook
  const { data: groupsData } = useGroupList(!isProvider);
  const { data: userOnboardedPatientsData } = useGetOnboardedPatients(!isProvider);

  let initialValues: FormFilterValues = getInitialFilters();

  const initialFilter = retrievePersistedData(`rpm-filter-${currentTab}`) as PatientLocalStorage;

  const usersData = paginatedData?.data;

  useEffect(() => {
    handleStorageUpdate(
      initialFilter,
      setaccountFilter,
      setUpdatedFilterSearch,
      initialValues,
      currentTab
    );
  }, []);

  useEffect(() => {
    if (usersData) {
      setPhysicianOptionsValue(usersData, isProvider, setPhysicianOptions);
      setOnboardingOptionsvalue(setOnboardingOptions, userOnboardedPatientsData);
    }
    if (accountFilter && accountFilter?.length > 0) {
      (async () => {
        const physicianData = await fetchPhysiciansData(accountFilter?.toString());
        accountFilterChecking(physicianData, setPhysicianOptions, isProvider);
      })();
    } else {
      setPhysicianOptionsValueOnAccountFilter(usersData, isProvider, setPhysicianOptions);
      setOnboardingOptionsvalueOnAccountFilter(setOnboardingOptions, usersData);
    }
  }, [
    accountFilter,
    accountFilter?.length,
    usersData,
    userOnboardedPatientsData,
    setPhysicianOptions,
    setOnboardingOptions,
  ]);

  if (isLoading) return null;

  if (!usersData) return null;

  const clinicianOption = usersData
    ?.filter(
      (user: User) =>
        (user.role === CLINICIAN_ROLE || user.role === LEAD_CLINICIAN_ROLE) && user.patientCount > 0
    )
    ?.map((user: User) => {
      return {
        label: ` ${user.lastName}, ${user.firstName}`,
        value: user.id,
      };
    });

  const groups =
    groupsData?.map((group) => ({
      label: group.name,
      value: group.id,
    })) ?? [];

  let accountOptions: any;
  if (organizations) {
    accountOptions = generateAccountOptions(organizations);
  }

  const storage = initialFilter?.filters;

  /**
   * This is to ensure old data structure does not fail
   * We used an array previously
   */
  if (storage && Object.keys(storage).length > 0 && storage.clinicians) {
    initialValues = {
      ...storage,
    };
  }
  const handleSubmit = async (
    values: FormFilterValues,
    _formikHelpers?: FormikHelpers<FormFilterValues>
  ): Promise<void> => {
    const persistedFilter = retrievePersistedData(
      `rpm-filter-${currentTab}`
    ) as PatientLocalStorage;
    const persistedPaginationData = {
      ...persistedFilter,
      offset: 0,
      filters: values,
      goToPage: 0,
      initial: 0,
      initialSize: 0,
      pageIndex: 0,
    };
    savePersistentData(`rpm-filter-${currentTab}`, JSON.stringify({ ...persistedPaginationData }));

    setUpdatedFilterSearch({ ...values });
    setaccountFilter(values?.accounts);
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('page', '1');
    history.push({ search: urlParams.toString() });
  };

  const defaultValidate = (): FormFilterValues => {
    const errors = {} as FormFilterValues;
    return errors;
  };
  const resetForm = () => {
    handleSubmit(getInitialFilters());
    setUpdatedFilterSearch(getInitialFilters());
    setaccountFilter([]);
    history.push('/patients');
    location.reload();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={defaultValidate}>
      <Form id="create-filter">
        <Flex justify="space-between" alignItems="center" wrap="wrap">
          <RoleBasedRoutes internalUsersOnly>
            <FormMenuSelect
              menuTitle="All"
              title="Status"
              name="status"
              options={[
                { label: 'New', value: 'new' },
                { label: 'Active', value: 'active' },
                { label: 'Non-Adherent', value: 'non-adherent' },
                { label: 'Paused', value: 'paused' },
                { label: 'Unenrolled', value: 'unenrolled' },
              ]}
            />
          </RoleBasedRoutes>
          <RoleBasedRoutes internalUsersOnly>
            <FormMenuSelect
              menuTitle="All"
              title="Accounts"
              name="accounts"
              options={accountOptions!}
            />
          </RoleBasedRoutes>
          <FormMenuSelect
            menuTitle="All"
            title="Physicians"
            name="physicians"
            options={physicianOption}
          />
          <RoleBasedRoutes internalUsersOnly>
            <FormMenuSelect
              menuTitle="All"
              title="Contacted"
              name="contacted"
              options={[
                { label: 'Yes', value: '1' },
                { label: 'No', value: '0' },
              ]}
              isContacted="true"
            />
          </RoleBasedRoutes>

          <RoleBasedRoutes internalUsersOnly>
            <FormMenuSelectRange
              menuTitle="All"
              title="Days"
              subOptions={subOptions}
              name="days"
              options={physicianOption}
            />
          </RoleBasedRoutes>
          <RoleBasedRoutes internalUsersOnly>
            <FormMenuSelectRange
              menuTitle="All"
              title="Minutes"
              subOptions={subOptions}
              name="minutes"
              options={physicianOption}
            />
          </RoleBasedRoutes>
          <RoleBasedRoutes internalUsersOnly>
            <FormMenuSelect
              menuTitle="All"
              title="Clinicians"
              name="clinicians"
              options={clinicianOption}
            />
          </RoleBasedRoutes>
          <RoleBasedRoutes internalUsersOnly>
            <FormMenuSelect
              menuTitle="All"
              title="Onboarding"
              name="onboarding"
              options={onboardingOption}
            />
          </RoleBasedRoutes>
          <RoleBasedRoutes internalUsersOnly>
            <FormMenuSelect menuTitle="All" title="Groups" name="group" options={groups} />
          </RoleBasedRoutes>

          <Button
            onClick={() => resetForm()}
            className="css-ix6mbv"
            size="sm"
            style={{
              background: '#FFFFFF',
              border: '1px solid #C1CBD7',
              borderRadius: '8px',
              color: '#5F7895',
              marginTop: '3px',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            }}
          >
            Reset
          </Button>
          <Box
            style={{
              textAlign: 'left',
              height: 34,
              width: 250,
            }}
          ></Box>
        </Flex>
      </Form>
    </Formik>
  );
};

export default PatientFilters;
