import { Input, Box, Textarea, Text, FormLabel, FormControl } from '@rhythm/components';
import { PatternFormat } from 'react-number-format';
import { FieldHookConfig, FormikProps, useField, useFormikContext } from 'formik';
import { EnrollPatientFormValues } from '../EnrollPatientForm/EnrollPatientForm';

interface StyleObject {
  [key: string]: string | number;
}

interface FormFieldProps {
  label?: string;
  disabled?: boolean;
  textarea?: boolean;
  placeholder?: string;
  hintText?: string;
  fullHeight?: boolean;
  sx?: StyleObject;
  resize?: 'none' | 'both' | 'vertical';
  bottomGutter?: boolean;
  isHyperlink?: boolean;
  readOnly?: boolean;
  maxLength?: number;
}

export function FormField(props: FieldHookConfig<string> & FormFieldProps) {
  const { values }: FormikProps<EnrollPatientFormValues> = useFormikContext();
  const {
    disabled,
    placeholder,
    label,
    textarea = false,
    hintText = '',
    sx,
    resize = 'none',
    fullHeight,
    bottomGutter = true,
    isHyperlink = false,
    readOnly = false,
    maxLength,
  } = props;
  const [field, meta] = useField(props.name);
  const fieldHasError: boolean = meta.touched && !!meta.error;
  const displayAsText = values.isEditing === undefined ? false : !values.isEditing;

  if (displayAsText) {
    return (
      <Box>
        {label && <FormLabel fontSize="sm">{label}</FormLabel>}
        <Text mb="3xl">
          {isHyperlink ? (
            <a style={{ color: '#0645AD' }} href={field.value} target="_blank" rel="noreferrer">
              {field.value}
            </a>
          ) : (
            field.value
          )}
        </Text>
      </Box>
    );
  }

  const handleBlur = (e: any) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  return (
    <FormControl
      isInvalid={fieldHasError}
      mb={bottomGutter ? '2xl' : '0'}
      sx={{ position: 'relative' }}
      h={fullHeight ? '100%' : ''}
    >
      {label && <FormLabel fontSize="sm">{label}</FormLabel>}
      {textarea ? (
        <Textarea
          mb="xs"
          variant="default"
          isDisabled={disabled ?? values.disabledFields?.includes(field.name)}
          placeholder={placeholder}
          sx={sx}
          h={fullHeight ? '100%' : ''}
          resize={resize}
          {...field}
          isReadOnly={readOnly}
          // onKeyDown={handleKeyDown}
          maxLength={maxLength}
        />
      ) : (
        <>
          {isHyperlink ? (
            <a href={field.value} style={{ color: '#0645AD' }} target="_blank" rel="noreferrer">
              {field.value}
            </a>
          ) : (
            <Input
              mb="xs"
              isDisabled={disabled ?? values.disabledFields?.includes(field.name)}
              placeholder={placeholder}
              sx={sx}
              {...field}
              onBlur={handleBlur}
              isReadOnly={readOnly}
            />
          )}
        </>
      )}
      <Box sx={{ position: 'absolute', top: '100%' }}>
        <Text
          fontSize="xs"
          color={fieldHasError ? 'red.500' : 'neutral.800'}
          role={(fieldHasError && 'alert') || undefined}
        >
          {fieldHasError ? meta.error : hintText}
        </Text>
      </Box>
    </FormControl>
  );
}

interface MaskedInputProps extends FormFieldProps {
  format: string;
  hintText?: string;
  disabled?: boolean;
}

export function MaskedInput(props: FieldHookConfig<string> & MaskedInputProps) {
  const { label, format, textarea, disabled, placeholder, hintText = '' } = props;
  const [field, meta] = useField(props.name);
  const { values }: FormikProps<EnrollPatientFormValues> = useFormikContext();
  const fieldHasError: boolean = !!meta.touched && !!meta.error;

  const displayAsText = values.isEditing === undefined ? false : !values.isEditing;

  if (displayAsText) {
    return (
      <Box>
        {label && <FormLabel fontSize="sm">{label}</FormLabel>}
        <Text mb="3xl">{field.value}</Text>
      </Box>
    );
  }

  const handleBlur = (e: any) => {
    if (props.onBlur) {
      props.onBlur(e.target.value);
    }
  };

  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <FormControl
      onBlur={handleBlur}
      onChange={handleChange}
      isInvalid={fieldHasError}
      mb="2xl"
      sx={{ position: 'relative' }}
    >
      <FormLabel fontSize="xs">{label}</FormLabel>
      <PatternFormat
        {...field}
        customInput={(textarea ? Textarea : Input) as any}
        format={format}
        disabled={disabled}
        placeholder={placeholder}
      />
      <Box sx={{ position: 'absolute', top: '100%' }}>
        <Text fontSize="xs" color={fieldHasError ? 'red.500' : 'neutral.300'}>
          {fieldHasError ? meta.error : hintText}
        </Text>
      </Box>
    </FormControl>
  );
}
