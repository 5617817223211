import { Box, Card, Flex, Heading, Text } from '@rhythm/components';
import dayjs from 'dayjs';
import { Organization } from '~generated';

export type DataProp = {
  data: Organization;
};

const OnboardDate = ({ data }: DataProp) => {
  return (
    <Box mt="32px">
      <Heading variant="h6" mb="16px">
        {' '}
        Key Dates{' '}
      </Heading>
      <Card>
        <Flex alignItems="center">
          <Flex direction="column">
            <Text fontWeight="700">{dayjs(data.createdAt).format('MMM')} </Text>
            <Text fontSize="21px" fontWeight="700">
              {dayjs(data.createdAt).format('D')}
            </Text>
          </Flex>
          <Flex ml="25px">
            <Text fontWeight="700"> Go-Live Date ({dayjs(data.createdAt).format('YYYY')}) </Text>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
};

export default OnboardDate;
