import { Fragment, useState } from 'react';
import dayjs from 'dayjs';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import type { Column, Cell } from 'react-table';
import {
  Flex,
  HStack,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  VStack,
} from '@rhythm/components';
import { DataTable } from '~src/components/DataTable';
import { Search } from '~src/components/Search';
import { useFetchListOrganization } from '~src/services/clinics';
import { Organization } from '~generated';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { createAccountRoles } from '~src/constants/roles';
import { HypertensionReportForm } from '~src/components/HypertensionReportForm';
import { GlucoseReportForm } from '~src/components/GlucoseReportForm/GlucoseReportForm';
import { UserPatientCount } from './UserPatientCount';

interface ClinicRow extends Record<string, unknown> {
  id: string;
  name: string;
  patientCount: number;
  providerCount: number;
  createdAt: number;
}

interface AccountRow extends Record<string, unknown> {
  parentId: string;
  parentName: string;
}

function OrgName({ id, name }: ClinicRow) {
  return (
    <RouterLink to={`/settings/clinic/${id}`}>
      <Text fontWeight="bold">{name}</Text>
    </RouterLink>
  );
}

function DateTime({ createdAt }: ClinicRow) {
  return <Text>{dayjs(createdAt).format('MMMM D, YYYY')}</Text>;
}

const columns: Column<AccountRow>[] = [
  {
    Header: 'Clinic',
    accessor: 'name',
    Cell: ({ row: { original } }: Cell<ClinicRow, string>) => <OrgName {...original} />,
  },
  {
    Header: 'Patients',
    accessor: 'patientCount',
    Cell: ({ row: { original } }: Cell<ClinicRow, string>) => (
      <UserPatientCount organizationId={original.id} />
    ),
  },
  {
    Header: 'Providers',
    accessor: 'providerCount',
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: ({ row: { original } }: Cell<ClinicRow, string>) => <DateTime {...original} />,
  },
];

type ClinicProps = {
  id?: string;
  setClinicCount?: (count: number) => void;
};

export function ClinicManagement({ id, setClinicCount }: ClinicProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  let selectedData = {};
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const [selectedClinics, setSelectedClinics] = useState({
    organizationIds: null,
    physicianIds: null,
    clinicalIds: null,
  });
  const [currentReportType, setCurrentReportType] = useState('hypertension');
  const organizations = useFetchListOrganization({
    filter: { clinic: true, id },
  }).data;

  const formatData = (): AccountRow[] => {
    if (!organizations?.length) return [];
    if (setClinicCount) {
      setClinicCount(organizations?.length);
    }
    return organizations?.map((org: Organization) => {
      return {
        id: org.id,
        parentName: org.parent.name,
        parentId: org.parent.id,
        name: org.name,
        // patientCount: org?.patients?.length,
        patientCount: org?.patients?.length || 0,
        // providerCount: org?.users?.length,
        providerCount: org?.users?.length || 0,
        createdAt: Date.parse(org.createdAt),
      };
    });
  };

  const handleRowSelection = (state: any) => {
    selectedData = state;
  };

  const handleReportFormClick = (reportType: any) => {
    setIsDateRangePickerOpen(true);
    setCurrentReportType(reportType);
    if (Object.keys(selectedData).length > 0) {
      const clinicIds: any = [];
      if (organizations) {
        Object.entries(selectedData).forEach(([key]: any[]) => {
          clinicIds.push(organizations[key]?.id);
        });
      }
      setSelectedClinics({ clinicalIds: clinicIds, physicianIds: null, organizationIds: null });
    }
  };

  return (
    <Fragment>
      <HStack justifyContent="right">
        <RoleBasedRoutes allowedRoles={createAccountRoles}>
          <Button
            variant="secondaryDark"
            leftIcon="add"
            onClick={() => history.push('/settings/clinic/create')}
          >
            Add New Clinic
          </Button>
        </RoleBasedRoutes>
      </HStack>

      <HStack
        justifyContent="space-between"
        alignItems="center"
        padding="xl"
        marginTop="xl"
        marginBottom="l"
        background={'#FFFFFF'}
      >
        <Flex alignItems="center">
          <HStack justifyContent="space-between" alignItems="center">
            <Box>
              <Menu
                onClose={() => {
                  setIsDateRangePickerOpen(false);
                }}
              >
                <MenuButton as={Button} rightIcon={'drop-down'}>
                  Clinical Report
                </MenuButton>
                {!isDateRangePickerOpen ? (
                  <MenuList>
                    <MenuItem
                      closeOnSelect={false}
                      onClick={() => handleReportFormClick('hypertension')}
                    >
                      Hypertensives
                    </MenuItem>
                    <MenuItem
                      closeOnSelect={false}
                      onClick={() => handleReportFormClick('glucose')}
                    >
                      Diabetes
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList>
                    <Flex
                      minWidth="max-content"
                      basis={'flex'}
                      direction={'row'}
                      align={'center'}
                      gap="2"
                    >
                      <VStack>
                        <Box>
                          {currentReportType === 'hypertension' ? (
                            <HypertensionReportForm
                              selectedData={selectedClinics}
                              handleCancelClick={() => setIsDateRangePickerOpen(false)}
                            />
                          ) : (
                            <GlucoseReportForm
                              selectedData={selectedClinics}
                              handleCancelClick={() => setIsDateRangePickerOpen(false)}
                            />
                          )}
                        </Box>
                      </VStack>
                    </Flex>
                  </MenuList>
                )}
              </Menu>
            </Box>
          </HStack>
        </Flex>
        <Flex>
          <Search placeholder="Search Clinics" onSearchTerm={setSearchTerm} />
        </Flex>
      </HStack>
      {formatData().length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <span style={{ fontSize: '16px', fontWeight: '600' }}>No Accounts found</span>
        </div>
      ) : (
        <DataTable
          hasPagination
          isSelectable
          columns={columns}
          data={formatData()}
          search={searchTerm}
          onRowsSelected={handleRowSelection}
        />
      )}
    </Fragment>
  );
}
