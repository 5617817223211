import dayjs from 'dayjs';
import { DateRange } from '~src/pages/Patients/PatientOverview';

export const formattedDateRange = (dateRange: DateRange): [Array<string>, Array<Date>] => {
  let currentDate = dayjs(dateRange.startDate);
  const daysAsString: any[] = [];
  const daysAsDate: any[] = [];

  while (currentDate.isBefore(dateRange.endDate) || currentDate.isSame(dateRange.endDate)) {
    daysAsString.push(currentDate.format('MMM D'));
    daysAsDate.push(currentDate.toDate());
    currentDate = currentDate.add(1, 'day');
  }
  return [daysAsString, daysAsDate];
};
