/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Alert } from '../models/Alert';
import type { ApplicationSettings } from '../models/ApplicationSettings';
import type { ClinicalNote } from '../models/ClinicalNote';
import type { CreateClinicalNoteDto } from '../models/CreateClinicalNoteDto';
import type { CreateGroupDto } from '../models/CreateGroupDto';
import type { CreateOrganizationDto } from '../models/CreateOrganizationDto';
import type { CreatePatientDto } from '../models/CreatePatientDto';
import type { CreatePatientTimeDto } from '../models/CreatePatientTimeDto';
import type { CreateSocialNoteDto } from '../models/CreateSocialNoteDto';
import type { CreateTeamDto } from '../models/CreateTeamDto';
import type { CreateUserDto } from '../models/CreateUserDto';
import type { Device } from '../models/Device';
import type { DeviceVendor } from '../models/DeviceVendor';
import type { ExportPatientDataDto } from '../models/ExportPatientDataDto';
import type { GetManyClinicalNoteResponseDto } from '../models/GetManyClinicalNoteResponseDto';
import type { GetManyDeviceResponseDto } from '../models/GetManyDeviceResponseDto';
import type { GetManyPatientResponseDto } from '../models/GetManyPatientResponseDto';
import type { GetManySocialNoteResponseDto } from '../models/GetManySocialNoteResponseDto';
import type { GetUserOnboardedPatientsView } from '../models/GetUserOnboardedPatientsView';
import type { Group } from '../models/Group';
import type { MonthlyContact } from '../models/MonthlyContact';
import type { Organization } from '../models/Organization';
import type { PaginatedResponse } from '../models/PaginatedResponse';
import type { Patient } from '../models/Patient';
import type { PatientAdmission } from '../models/PatientAdmission';
import type { PatientAdmissionDto } from '../models/PatientAdmissionDto';
import type { PatientAlertEscalate } from '../models/PatientAlertEscalate';
import type { PatientMetricThreshold } from '../models/PatientMetricThreshold';
import type { PatientOptOut } from '../models/PatientOptOut';
import type { PatientOptOutDto } from '../models/PatientOptOutDto';
import type { PatientReading } from '../models/PatientReading';
import type { PatientStatusTypeHistory } from '../models/PatientStatusTypeHistory';
import type { PatientStatusTypeHistoryDto } from '../models/PatientStatusTypeHistoryDto';
import type { PatientThreshold } from '../models/PatientThreshold';
import type { PatientTime } from '../models/PatientTime';
import type { SocialNote } from '../models/SocialNote';
import type { Team } from '../models/Team';
import type { UpdateAlertDto } from '../models/UpdateAlertDto';
import type { UpdateApplicationSettingsDto } from '../models/UpdateApplicationSettingsDto';
import type { UpdateNoteDto } from '../models/UpdateNoteDto';
import type { UpdateOrganizationDto } from '../models/UpdateOrganizationDto';
import type { UpdatePatientDto } from '../models/UpdatePatientDto';
import type { UpdateSocialNoteDto } from '../models/UpdateSocialNoteDto';
import type { UpdateTeamDto } from '../models/UpdateTeamDto';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { UploadCPTReportToS3Dto } from '../models/UploadCPTReportToS3Dto';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static appControllerGetHello(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerGetLanguages(): CancelablePromise<Array<Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/languages',
        });
    }

    /**
     * Retrieve a single Patient
     * @param id
     * @param fields Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     * @param join Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     * @param cache Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     * @returns Patient Get one base response
     * @throws ApiError
     */
    public static getOneBasePatientsControllerPatient(
        id: string,
        fields?: Array<string>,
        join?: Array<string>,
        cache?: number,
    ): CancelablePromise<Patient> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'join': join,
                'cache': cache,
            },
        });
    }

    /**
     * Update a single Patient
     * @param id
     * @param requestBody
     * @returns Patient Response
     * @throws ApiError
     */
    public static updateOneBasePatientsControllerPatient(
        id: string,
        requestBody: Patient,
    ): CancelablePromise<Patient> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/patients/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a single Patient
     * @param id
     * @returns any Delete one base response
     * @throws ApiError
     */
    public static deleteOneBasePatientsControllerPatient(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/patients/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Create a single Patient
     * @param requestBody
     * @returns Patient
     * @throws ApiError
     */
    public static createOneBasePatientsControllerPatient(
        requestBody: CreatePatientDto,
    ): CancelablePromise<Patient> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve multiple Patients
     * @param fields Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     * @param s Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     * @param filter Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     * @param or Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     * @param sort Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     * @param join Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     * @param limit Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     * @param offset Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     * @param page Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     * @param cache Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     * @returns any Get many base response
     * @throws ApiError
     */
    public static getManyBasePatientsControllerPatient(
        fields?: Array<string>,
        s?: string,
        filter?: Array<string>,
        or?: Array<string>,
        sort?: Array<string>,
        join?: Array<string>,
        limit?: number,
        offset?: number,
        page?: number,
        cache?: number,
    ): CancelablePromise<(GetManyPatientResponseDto | Array<Patient>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients',
            query: {
                'fields': fields,
                's': s,
                'filter': filter,
                'or': or,
                'sort': sort,
                'join': join,
                'limit': limit,
                'offset': offset,
                'page': page,
                'cache': cache,
            },
        });
    }

    /**
     * @param device
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerSyncHistory(
        device: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/{device}/reading',
            path: {
                'device': device,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerCreateReview(
        id: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/review/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param sort
     * @param search
     * @param currentTimestamp
     * @param filters
     * @param offset
     * @param limit
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerFindAllPatients(
        sort?: string,
        search?: string,
        currentTimestamp?: string,
        filters?: string,
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/patients/paginate',
            query: {
                'sort': sort,
                'search': search,
                'currentTimestamp': currentTimestamp,
                'filters': filters,
                'offset': offset,
                'limit': limit,
            },
        });
    }

    /**
     * @param sort
     * @param search
     * @param currentTimestamp
     * @param filters
     * @param offset
     * @param limit
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerFindAllFastReviewPatients(
        sort?: string,
        search?: string,
        currentTimestamp?: string,
        filters?: string,
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/fast-review/paginate',
            query: {
                'sort': sort,
                'search': search,
                'currentTimestamp': currentTimestamp,
                'filters': filters,
                'offset': offset,
                'limit': limit,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerGetFastReviewReading(
        requestBody: PatientReading,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/fast-review/reading',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sort
     * @param search
     * @param currentTimestamp
     * @param filters
     * @param offset
     * @param limit
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerFindAllPatientsReviewCount(
        sort?: string,
        search?: string,
        currentTimestamp?: string,
        filters?: string,
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/review/count',
            query: {
                'sort': sort,
                'search': search,
                'currentTimestamp': currentTimestamp,
                'filters': filters,
                'offset': offset,
                'limit': limit,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Patient
     * @throws ApiError
     */
    public static patientsControllerUpdate(
        id: string,
        requestBody: UpdatePatientDto,
    ): CancelablePromise<Patient> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/patients/{id}/update',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns PatientStatusTypeHistory
     * @throws ApiError
     */
    public static patientsControllerCreateStatusTypeHistory(
        id: string,
        requestBody: PatientStatusTypeHistoryDto,
    ): CancelablePromise<PatientStatusTypeHistory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/{id}/statustype',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerFindOne(
        id: string,
        requestBody: PatientMetricThreshold,
    ): CancelablePromise<Array<Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/metric/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns MonthlyContact
     * @throws ApiError
     */
    public static patientsControllerCreateMonthlyContact(
        id: string,
    ): CancelablePromise<MonthlyContact> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/{id}/monthly-contact',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerRemoveMonthlyContact(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/patients/monthly-contact',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns PatientTime
     * @throws ApiError
     */
    public static patientsControllerCreatePatientTime(
        id: string,
        requestBody: CreatePatientTimeDto,
    ): CancelablePromise<PatientTime> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/{id}/time',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerGetPatient(
        id: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/{id}/profile',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerGetThreshold(
        id: string,
        requestBody: PatientThreshold,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/{id}/threshold',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns boolean
     * @throws ApiError
     */
    public static patientsControllerGetNonAdherent(
        id: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/{id}/nonadherent',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Patient
     * @throws ApiError
     */
    public static patientsControllerGetReading(
        requestBody: PatientReading,
    ): CancelablePromise<Patient> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/reading',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns PatientOptOut
     * @throws ApiError
     */
    public static patientsControllerArchivePatient(
        id: string,
        requestBody: PatientOptOutDto,
    ): CancelablePromise<PatientOptOut> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/{id}/inactive',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerReEnrollPatient(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/re-enroll',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerGetPatientData(
        id: string,
        requestBody: ExportPatientDataDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/{id}/export.pdf',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerUpdateOrganizationInfo(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/update/organizationToPatients',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerCreateBulkReview(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/bulkreview',
        });
    }

    /**
     * @param page
     * @param perPage
     * @param clinicianIds
     * @param isDefaultSearch
     * @param secondarySortBy
     * @param search
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerAlertListing(
        page: number,
        perPage: number,
        clinicianIds: Array<string>,
        isDefaultSearch: string,
        secondarySortBy: string,
        search?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/patients/alerts',
            query: {
                'page': page,
                'perPage': perPage,
                'clinicianIds': clinicianIds,
                'isDefaultSearch': isDefaultSearch,
                'secondarySortBy': secondarySortBy,
                'search': search,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static patientsControllerAlertData(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/patients/{id}/alertData',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Patient
     * @throws ApiError
     */
    public static patientsControllerAddEscalateData(
        id: string,
        requestBody: PatientAlertEscalate,
    ): CancelablePromise<Array<Patient>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/{id}/escalate',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns PatientAdmission
     * @throws ApiError
     */
    public static patientsControllerGetPatientReadmissions(
        id: string,
    ): CancelablePromise<Array<PatientAdmission>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/patients/{id}/readmission',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns PatientAdmission
     * @throws ApiError
     */
    public static patientsControllerCreatePatientReadmission(
        id: string,
        requestBody: PatientAdmissionDto,
    ): CancelablePromise<PatientAdmission> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/patients/{id}/readmission',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param admissionId
     * @param requestBody
     * @returns PatientAdmission
     * @throws ApiError
     */
    public static patientsControllerUpdatePatientReadmission(
        admissionId: string,
        requestBody: PatientAdmissionDto,
    ): CancelablePromise<PatientAdmission> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/patients/{id}/readmission/{admissionId}',
            path: {
                'admissionId': admissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param admissionId
     * @returns PatientAdmission
     * @throws ApiError
     */
    public static patientsControllerDeletePatientReadmission(
        admissionId: string,
    ): CancelablePromise<PatientAdmission> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/patients/{id}/readmission/{admissionId}',
            path: {
                'admissionId': admissionId,
            },
        });
    }

    /**
     * @param isGlobal
     * @param deviceId
     * @param metricType
     * @returns any
     * @throws ApiError
     */
    public static deviceControllerGetThreshold(
        isGlobal: boolean,
        deviceId?: number,
        metricType?: 'weight' | 'blood_pressure' | 'blood_pressure_systolic' | 'blood_pressure_diastolic' | 'blood_sugar' | 'seven_days_change' | 'pulse' | 'blood_oxygen',
    ): CancelablePromise<Array<Record<string, any>>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/device/threshold',
            query: {
                'deviceId': deviceId,
                'metricType': metricType,
                'isGlobal': isGlobal,
            },
        });
    }

    /**
     * @returns DeviceVendor
     * @throws ApiError
     */
    public static deviceControllerGetVendors(): CancelablePromise<Array<DeviceVendor>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/device/vendors',
        });
    }

    /**
     * Retrieve a single Device
     * @param id
     * @param fields Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     * @param join Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     * @param cache Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     * @returns Device Get one base response
     * @throws ApiError
     */
    public static getOneBaseDeviceControllerDevice(
        id: string,
        fields?: Array<string>,
        join?: Array<string>,
        cache?: number,
    ): CancelablePromise<Device> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/device/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'join': join,
                'cache': cache,
            },
        });
    }

    /**
     * Update a single Device
     * @param id
     * @param requestBody
     * @returns Device Response
     * @throws ApiError
     */
    public static updateOneBaseDeviceControllerDevice(
        id: string,
        requestBody: Device,
    ): CancelablePromise<Device> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/device/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a single Device
     * @param id
     * @returns any Delete one base response
     * @throws ApiError
     */
    public static deleteOneBaseDeviceControllerDevice(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/device/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Retrieve multiple Devices
     * @param fields Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     * @param s Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     * @param filter Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     * @param or Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     * @param sort Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     * @param join Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     * @param limit Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     * @param offset Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     * @param page Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     * @param cache Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     * @returns any Get many base response
     * @throws ApiError
     */
    public static getManyBaseDeviceControllerDevice(
        fields?: Array<string>,
        s?: string,
        filter?: Array<string>,
        or?: Array<string>,
        sort?: Array<string>,
        join?: Array<string>,
        limit?: number,
        offset?: number,
        page?: number,
        cache?: number,
    ): CancelablePromise<(GetManyDeviceResponseDto | Array<Device>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/device',
            query: {
                'fields': fields,
                's': s,
                'filter': filter,
                'or': or,
                'sort': sort,
                'join': join,
                'limit': limit,
                'offset': offset,
                'page': page,
                'cache': cache,
            },
        });
    }

    /**
     * Create a single Device
     * @param requestBody
     * @returns Device Get create one base response
     * @throws ApiError
     */
    public static createOneBaseDeviceControllerDevice(
        requestBody: Device,
    ): CancelablePromise<Device> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/device',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static userControllerCreate(
        requestBody: CreateUserDto,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param limit
     * @param offset
     * @param search
     * @param bypassPagination
     * @param filter
     * @returns PaginatedResponse
     * @throws ApiError
     */
    public static userControllerFindAll(
        limit: number,
        offset: number,
        search: string,
        bypassPagination: boolean,
        filter?: string,
    ): CancelablePromise<PaginatedResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
            query: {
                'filter': filter,
                'limit': limit,
                'offset': offset,
                'search': search,
                'bypassPagination': bypassPagination,
            },
        });
    }

    /**
     * @returns GetUserOnboardedPatientsView
     * @throws ApiError
     */
    public static userControllerGetUsersWhoOnboardedPatients(): CancelablePromise<Array<GetUserOnboardedPatientsView>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/onboardedPatients',
        });
    }

    /**
     * @param id
     * @returns User
     * @throws ApiError
     */
    public static userControllerFindOne(
        id: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static userControllerUpdate(
        id: string,
        requestBody: UpdateUserDto,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static userControllerRemove(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param email
     * @returns User
     * @throws ApiError
     */
    public static userControllerDeactivate(
        id: string,
        email: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user/{id}/{email}/deactivate',
            path: {
                'id': id,
                'email': email,
            },
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static userControllerFetchPhysiciansByOrg(
        requestBody: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/fetchPhysiciansByOrgId',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static userControllerUpdateClinicianPatientCount(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/getPatientCount/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public static userControllerGetCliniciansDropdown(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/users/getClinicians',
        });
    }

    /**
     * @param requestBody
     * @returns Organization
     * @throws ApiError
     */
    public static organizationControllerCreate(
        requestBody: CreateOrganizationDto,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organization',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sort
     * @param search
     * @param filter
     * @param includePatientAndUserCounts
     * @param offset
     * @param limit
     * @returns any
     * @throws ApiError
     */
    public static organizationControllerFindAll(
        sort?: string,
        search?: string,
        filter?: string,
        includePatientAndUserCounts?: boolean,
        offset?: number,
        limit: number = 10,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization',
            query: {
                'sort': sort,
                'search': search,
                'filter': filter,
                'includePatientAndUserCounts': includePatientAndUserCounts,
                'offset': offset,
                'limit': limit,
            },
        });
    }

    /**
     * @param filter
     * @param sort
     * @param offset
     * @param limit
     * @returns Organization
     * @throws ApiError
     */
    public static organizationControllerFindAllOrgnaizations(
        filter?: string,
        sort?: string,
        offset?: number,
        limit: number = 10,
    ): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization/organizations',
            query: {
                'filter': filter,
                'sort': sort,
                'offset': offset,
                'limit': limit,
            },
        });
    }

    /**
     * @param filter
     * @returns Organization
     * @throws ApiError
     */
    public static organizationControllerFindAllOrganizationsBySort(
        filter?: string,
    ): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization/organizationBySort',
            query: {
                'filter': filter,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static organizationControllerFetchPatientsCount(
        id: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization/patientsCount/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static organizationControllerFetchUsersCount(
        id: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization/usersCount/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns Organization
     * @throws ApiError
     */
    public static organizationControllerFindOne(
        id: string,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Organization
     * @throws ApiError
     */
    public static organizationControllerUpdate(
        id: string,
        requestBody: UpdateOrganizationDto,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/organization/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns string
     * @throws ApiError
     */
    public static organizationControllerRemove(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/organization/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns Organization
     * @throws ApiError
     */
    public static organizationControllerUpdateAccountStatus(
        id: string,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organization/{id}/accountStatusToInActive',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param filters
     * @returns Alert
     * @throws ApiError
     */
    public static alertControllerFindAll(
        filters?: string,
    ): CancelablePromise<Array<Alert>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/alert',
            query: {
                'filters': filters,
            },
        });
    }

    /**
     * @param id
     * @returns string
     * @throws ApiError
     */
    public static alertControllerFindOne(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/alert/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static alertControllerUpdate(
        id: string,
        requestBody: UpdateAlertDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/alert/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns string
     * @throws ApiError
     */
    public static alertControllerRemove(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/alert/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns number
     * @throws ApiError
     */
    public static alertControllerFindAllEscalatedCount(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/alert/escalated/count',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static webhooksControllerProcessIGlucose(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webhooks/iglucose',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static webhooksControllerProcessImpilo(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webhooks/impilo',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static webhooksControllerProcessBodyTrace(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webhooks/bodytrace',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static webhooksControllerProcessTelliHealth(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webhooks/tellihealth',
        });
    }

    /**
     * @param serialNumber
     * @returns void
     * @throws ApiError
     */
    public static webhooksControllerSyncPatientReadings(
        serialNumber: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/webhooks/sync/{serialNumber}',
            path: {
                'serialNumber': serialNumber,
            },
        });
    }

    /**
     * @returns ApplicationSettings
     * @throws ApiError
     */
    public static applicationSettingsControllerFindAllApplicationSettings(): CancelablePromise<Array<ApplicationSettings>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/application-settings',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ApplicationSettings
     * @throws ApiError
     */
    public static applicationSettingsControllerUpdate(
        id: string,
        requestBody: UpdateApplicationSettingsDto,
    ): CancelablePromise<ApplicationSettings> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/application-settings/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns Group
     * @throws ApiError
     */
    public static groupsControllerCreate(
        requestBody: CreateGroupDto,
    ): CancelablePromise<Group> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/groups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Group
     * @throws ApiError
     */
    public static groupsControllerFindAll(): CancelablePromise<Array<Group>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/groups',
        });
    }

    /**
     * @param id
     * @returns Group
     * @throws ApiError
     */
    public static groupsControllerFindOne(
        id: string,
    ): CancelablePromise<Group> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Group
     * @throws ApiError
     */
    public static groupsControllerUpdate(
        id: string,
        requestBody: CreateGroupDto,
    ): CancelablePromise<Group> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a single ClinicalNote
     * @param requestBody
     * @returns ClinicalNote
     * @throws ApiError
     */
    public static createOneBaseNotesControllerClinicalNote(
        requestBody: CreateClinicalNoteDto,
    ): CancelablePromise<ClinicalNote> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve multiple ClinicalNotes
     * @param fields Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     * @param s Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     * @param filter Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     * @param or Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     * @param sort Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     * @param join Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     * @param limit Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     * @param offset Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     * @param page Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     * @param cache Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     * @returns any Get many base response
     * @throws ApiError
     */
    public static getManyBaseNotesControllerClinicalNote(
        fields?: Array<string>,
        s?: string,
        filter?: Array<string>,
        or?: Array<string>,
        sort?: Array<string>,
        join?: Array<string>,
        limit?: number,
        offset?: number,
        page?: number,
        cache?: number,
    ): CancelablePromise<(GetManyClinicalNoteResponseDto | Array<ClinicalNote>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notes',
            query: {
                'fields': fields,
                's': s,
                'filter': filter,
                'or': or,
                'sort': sort,
                'join': join,
                'limit': limit,
                'offset': offset,
                'page': page,
                'cache': cache,
            },
        });
    }

    /**
     * Update a single ClinicalNote
     * @param id
     * @param requestBody
     * @returns ClinicalNote Response
     * @throws ApiError
     */
    public static updateOneBaseNotesControllerClinicalNote(
        id: string,
        requestBody: UpdateNoteDto,
    ): CancelablePromise<ClinicalNote> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/notes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve a single ClinicalNote
     * @param id
     * @param fields Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     * @param join Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     * @param cache Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     * @returns ClinicalNote Get one base response
     * @throws ApiError
     */
    public static getOneBaseNotesControllerClinicalNote(
        id: string,
        fields?: Array<string>,
        join?: Array<string>,
        cache?: number,
    ): CancelablePromise<ClinicalNote> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notes/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'join': join,
                'cache': cache,
            },
        });
    }

    /**
     * Delete a single ClinicalNote
     * @param id
     * @returns any Delete one base response
     * @throws ApiError
     */
    public static deleteOneBaseNotesControllerClinicalNote(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/notes/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param from
     * @param to
     * @returns any
     * @throws ApiError
     */
    public static billingControllerGetBilling(
        from?: string,
        to?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/report.csv',
            query: {
                'from': from,
                'to': to,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static billingControllerUploadCptReportToS3(
        requestBody: UploadCPTReportToS3Dto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/billing/uploadCPTReportToS3',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param organisationId
     * @param from
     * @param to
     * @returns any
     * @throws ApiError
     */
    public static billingControllerGetBillingByOrganisation(
        organisationId: string,
        from?: string,
        to?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/{organisationId}/report.csv',
            path: {
                'organisationId': organisationId,
            },
            query: {
                'from': from,
                'to': to,
            },
        });
    }

    /**
     * @param postalCode
     * @returns any
     * @throws ApiError
     */
    public static billingControllerGetPatientTimeZone(
        postalCode: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/gettimezone/{postalCode}',
            path: {
                'postalCode': postalCode,
            },
        });
    }

    /**
     * @param from
     * @param to
     * @returns any
     * @throws ApiError
     */
    public static billingControllerGetHypertensionReport(
        from?: string,
        to?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/billing/hypertensionreport.csv',
            query: {
                'from': from,
                'to': to,
            },
        });
    }

    /**
     * @param from
     * @param to
     * @returns any
     * @throws ApiError
     */
    public static billingControllerGetGloucoseReport(
        from?: string,
        to?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/billing/glucosereport.csv',
            query: {
                'from': from,
                'to': to,
            },
        });
    }

    /**
     * Retrieve a single SocialNote
     * @param id
     * @param fields Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     * @param join Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     * @param cache Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     * @returns SocialNote Get one base response
     * @throws ApiError
     */
    public static getOneBaseSocialNotesControllerSocialNote(
        id: string,
        fields?: Array<string>,
        join?: Array<string>,
        cache?: number,
    ): CancelablePromise<SocialNote> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/social-notes/{id}',
            path: {
                'id': id,
            },
            query: {
                'fields': fields,
                'join': join,
                'cache': cache,
            },
        });
    }

    /**
     * Update a single SocialNote
     * @param id
     * @param requestBody
     * @returns SocialNote Response
     * @throws ApiError
     */
    public static updateOneBaseSocialNotesControllerSocialNote(
        id: string,
        requestBody: UpdateSocialNoteDto,
    ): CancelablePromise<SocialNote> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/social-notes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a single SocialNote
     * @param id
     * @returns any Delete one base response
     * @throws ApiError
     */
    public static deleteOneBaseSocialNotesControllerSocialNote(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/social-notes/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Create a single SocialNote
     * @param requestBody
     * @returns SocialNote
     * @throws ApiError
     */
    public static createOneBaseSocialNotesControllerSocialNote(
        requestBody: CreateSocialNoteDto,
    ): CancelablePromise<SocialNote> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/social-notes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieve multiple SocialNotes
     * @param fields Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a>
     * @param s Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a>
     * @param filter Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a>
     * @param or Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a>
     * @param sort Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a>
     * @param join Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a>
     * @param limit Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a>
     * @param offset Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a>
     * @param page Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a>
     * @param cache Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
     * @returns any Get many base response
     * @throws ApiError
     */
    public static getManyBaseSocialNotesControllerSocialNote(
        fields?: Array<string>,
        s?: string,
        filter?: Array<string>,
        or?: Array<string>,
        sort?: Array<string>,
        join?: Array<string>,
        limit?: number,
        offset?: number,
        page?: number,
        cache?: number,
    ): CancelablePromise<(GetManySocialNoteResponseDto | Array<SocialNote>)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/social-notes',
            query: {
                'fields': fields,
                's': s,
                'filter': filter,
                'or': or,
                'sort': sort,
                'join': join,
                'limit': limit,
                'offset': offset,
                'page': page,
                'cache': cache,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Team
     * @throws ApiError
     */
    public static teamControllerCreate(
        requestBody: CreateTeamDto,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/team',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param search
     * @param limit
     * @param offset
     * @param bypassPagination
     * @returns PaginatedResponse
     * @throws ApiError
     */
    public static teamControllerFindAll(
        search: string,
        limit: number,
        offset: number,
        bypassPagination: boolean,
    ): CancelablePromise<PaginatedResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team',
            query: {
                'search': search,
                'limit': limit,
                'offset': offset,
                'bypassPagination': bypassPagination,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Team
     * @throws ApiError
     */
    public static teamControllerUpdate(
        id: string,
        requestBody: UpdateTeamDto,
    ): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/team/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
