/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Patient } from './Patient';

export type PatientContact = {
    patient: Patient;
    isPreferred: boolean;
    disclosePHI: boolean;
    disclosePHIUpdatedAt: string;
    disclosePHIUpdatedBy: string;
    isAlternate: boolean;
    acceptsTextMessages: boolean;
    agreesToEmail: boolean;
    phoneType: PatientContact.phoneType;
    relation: PatientContact.relation;
    contact: string;
    name: string;
    type: PatientContact.type;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace PatientContact {

    export enum phoneType {
        MOBILE = 'mobile',
        LANDLINE = 'landline',
        WORK = 'work',
    }

    export enum relation {
        MOTHER = 'mother',
        FATHER = 'father',
        SIBLING = 'sibling',
        OTHER = 'other',
    }

    export enum type {
        EMAIL = 'email',
        PHONE = 'phone',
        FAX = 'fax',
    }


}

