import { Button, Card, Box, HStack, Text } from '@rhythm/components';
import { Formik, Form, FormikProps } from 'formik';
import { useBloodSugarReport } from '~src/services/clinical';
import { DateRangePicker } from '../DateRangePicker';
import moment from 'moment';

interface GlucoseReportFormValues {
  dateRange: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
}

const initialValues: GlucoseReportFormValues = {
  dateRange: {
    startDate: undefined,
    endDate: undefined,
  },
};

export function GlucoseReportForm(props: {
  selectedData: {
    organizationIds: Array<string> | null;
    physicianIds: Array<string> | null;
    clinicalIds: Array<string> | null;
  };
  handleCancelClick: () => void;
}) {
  const createExport = useBloodSugarReport();

  const handleSubmit = async (values: GlucoseReportFormValues) => {
    const file = await createExport(
      values.dateRange.startDate,
      values.dateRange.endDate,
      props.selectedData
    );
    window.open(file, '_blank')?.focus();
    props.handleCancelClick();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }: FormikProps<GlucoseReportFormValues>) => (
        <Form>
          <Card py="xl" px="xl">
            <Box mb="4">
              <DateRangePicker
                name="dateRange"
                startDate={moment()}
                endDate={moment()}
                maxDate={moment()}
                initialVisibleMonth={moment().subtract(1, 'month')}
              />
            </Box>

            <HStack justifyContent={'space-between'}>
              <Box>
                <Text fontSize="s" color="#455468">
                  {`${
                    props.selectedData?.organizationIds?.length ||
                    props.selectedData?.physicianIds?.length ||
                    0
                  } Accounts Selected`}
                </Text>
                <HStack>
                  <Text fontSize="s" color="#455468">
                    {`Type of Disease:`}
                  </Text>
                  <Text fontSize="s" color="#455468" fontWeight={'bold'}>
                    {`Diabetes`}
                  </Text>
                </HStack>
              </Box>
              <Box>
                <Button variant="secondaryLight" mr="xl" onClick={() => props.handleCancelClick()}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary" isLoading={isSubmitting}>
                  Download
                </Button>
              </Box>
            </HStack>
          </Card>
        </Form>
      )}
    </Formik>
  );
}
