import { useState, useRef } from 'react';
import {
  IconButton,
  Box,
  Grid,
  Flex,
  Text,
  Badge,
  Button,
  Heading,
  Divider,
} from '@rhythm/components';
import { Formik, Form } from 'formik';
import { useTimer } from '~src/utils/useTimer';
import { useClickOff } from '~src/utils/useClickOff';
import { FormSelect } from '../FormSelect';
import { FormField } from '../FormField';

const outcomeOptions = [
  {
    label: 'Enrolled',
    value: 'enrolled',
  },
  {
    label: 'Opt-out',
    value: 'opt_out',
  },
  {
    label: 'No Answer',
    value: 'did_not_answer',
  },
  {
    label: 'Voicemail',
    value: 'voicemail',
  },
];

interface InitialValues {
  outcome: string;
  notes: string;
}

const PatientInfoPanel = () => {
  const initialValues: InitialValues = {
    outcome: '',
    notes: '',
  };

  const handleSubmit = (values: InitialValues) => alert(JSON.stringify(values));

  return (
    <Box
      pos="absolute"
      p="4xl"
      bottom="5xl"
      bg="white"
      width="100%"
      borderRadius="md"
      boxShadow="tooltip"
      borderColor="primary.500"
      borderWidth="2px"
    >
      <Flex alignItems="center" justifyContent="space-between" mb="2xl">
        <Heading variant="h3">Call Log</Heading>
        <Badge variant="success">Connected</Badge>
      </Flex>
      <Text variant="body" fontWeight="bold" mb="xl">
        Candidate Details
      </Text>
      <Box borderColor="neutral.500" borderWidth="1px" borderRadius="sm" p="xl" mb="2xl">
        <Text variant="smallCaps">Name</Text>
        <Text mb="xl">Sterling Archer</Text>
        <Text variant="smallCaps">Phone Number</Text>
        <Text>+1 (123) 456 8790</Text>
      </Box>
      <Divider mb="2xl" />
      <Text variant="body" fontWeight="bold" mb="xl">
        Call Logging
      </Text>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <FormSelect options={outcomeOptions} name="outcome" label="Call Outcome" />
          <FormField textarea name="notes" label="Notes" />
          <Button type="submit">Save Log</Button>
        </Form>
      </Formik>
    </Box>
  );
};

interface CallWidgetProps {
  handleMute: () => void;
  handleEndCall: () => void;
}

export function CallWidget({ handleEndCall, handleMute }: CallWidgetProps) {
  const { formattedTime } = useTimer();
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((prevState) => !prevState);
  const panelRef = useRef(null);
  useClickOff(panelRef, () => setExpanded(false));

  return (
    <Box pos="absolute" bottom="2xl" right="3xl" bg="white" ref={panelRef}>
      <Grid templateColumns="48px 100px 190px 48px" pos="relative">
        {expanded && <PatientInfoPanel />}
        <IconButton
          icon="close"
          aria-label="mute call"
          onClick={handleMute}
          borderLeftRadius="sm"
          borderRightRadius="0"
          height="100%"
          width="100%"
        />
        <Button bg="feedback.errorText" color="white" borderRadius="0" onClick={handleEndCall}>
          End Call
        </Button>
        <Flex alignItems="center" justifyContent="space-between" px="xl">
          <Text variant="smallCaps">Call Duration</Text>
          <Badge>{formattedTime}</Badge>
        </Flex>
        <IconButton
          bg="black"
          aria-label="expand"
          icon="expand"
          onClick={toggleExpanded}
          borderRightRadius="sm"
          borderLeftRadius="0"
          height="100%"
          width="100%"
        />
      </Grid>
    </Box>
  );
}
