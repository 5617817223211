import { makeOptionsArray } from '~src/utils';
import type { Option } from '~src/utils';

export const diseaseStates = {
  diabetes: 'Diabetes',
  heart_failure: 'Heart Failure',
  hypertension: 'Hypertension',
};

export const diseaseStatesOptionsArray: Option[] = makeOptionsArray(diseaseStates);
