/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { ClinicalNote } from './ClinicalNote';
import type { Contact } from './Contact';
import type { CreateDeviceThresholdDto } from './CreateDeviceThresholdDto';
import type { Device } from './Device';
import type { Insurance } from './Insurance';
import type { PatientICDCode } from './PatientICDCode';
import type { User } from './User';

export type CreatePatientDto = {
    firstName: string;
    gender: CreatePatientDto.gender;
    status: CreatePatientDto.status;
    contacts?: Array<Contact>;
    photoUrl?: string;
    birthDate: string;
    deviceThresholds: Array<CreateDeviceThresholdDto>;
    notes: Array<ClinicalNote>;
    icdcodes: Array<PatientICDCode>;
    devices: Array<Device>;
    insurance: Array<Insurance>;
    lastName: string;
    prefix: string;
    isActive: boolean;
    address: Address;
    clinicIds: Array<string>;
    physician: User;
    clinician: User;
    onboarding: User;
    mobileNumber?: string;
    groupIds: Array<string>;
    firstDischargeDate?: string;
    firstReadmissionDate?: string;
    reasonForReadmission?: string;
    language?: string;
};

export namespace CreatePatientDto {

    export enum gender {
        FEMALE = 'female',
        MALE = 'male',
    }

    export enum status {
        PENDING = 'pending',
        REGISTERED = 'registered',
        DECEASED = 'deceased',
        OPTEDIN = 'optedin',
        ENROLLED = 'enrolled',
        OPTEDOUT = 'optedout',
    }


}

