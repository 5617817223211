import type { SortingRule } from 'react-table';
import { Fragment, useCallback, useMemo, useEffect, useState } from 'react';
import { DataTable } from '~src/components/DataTable';
import { OnFetchProps } from '@rhythm/components/dist/DataTable/DataTable';
import { Patient, DefaultService as Service, User } from '~generated';
import { getAccountData, GetAllAccountsProps } from '~src/components/Settings/AccountManagement';
import { AlertPaginationResult, usePatientsAlertList } from '~src/services/patients';
import {
  AlertMetricCell,
  alertPatientMetricCellProps,
  PatientModalLink,
} from '~src/components/PatientsDataView/AlertPatientReadingMetricCell';
import { PC } from '~src/components/PatientsDataView/PatientsDataView';
import {
  EscalatedCellProps,
  EscalatedInteractionCell,
} from '~src/components/EscalatedInteractionCell/EscalatedInteractionCell';
import {
  getName,
  getPhysicianName,
} from '~src/components/PatientsDataView/PatientsPaginateDataView';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Spinner,
  Text,
} from '@rhythm/components';
import { Search } from '~src/components/Search';
import { useDebounce } from 'react-use';
import RoleBasedRoutes from '~src/components/RoleBasedRoutes';
import { Form, Formik } from 'formik';
import { FormMenuSelect } from '~src/components/FormSelect';
import { useGetUser } from '~src/services/users';
import { ROLE_TYPES } from '~src/types';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { useClinicUsers } from '~src/services/clinics';
import { useUserRoleAndEmail } from '~src/hooks';
interface AlertPaginationParams extends OnFetchProps {
  goToPage?: number | undefined;
  initialSize?: number | undefined;
  defaultSortBy?: SortingRule<unknown>[];
  searchWord?: string;
  totalItemsCount?: number;
}

interface AlertFilterProps {
  setUpdatedFilterSearch: (values: FormFilterValues) => void;
  setClinicianIds: (clinicians: string[]) => void;
  setIsDefaultSearch: (isDefault: boolean) => void;
  initialValues: FormFilterValues;
}

export type FormFilterValues = {
  clinicians: string[];
};

export const getInitialFilters = () => {
  return {
    clinicians: [],
  };
};
export interface AlertReadingMetricCellProp {
  id: string;
  Patient?: string;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<AlertPaginationResult, unknown>>;
  cliniciansData: any;
}

function usePatientRows(patients?: Patient[]) {
  return useMemo(() => {
    if (!patients) return [];

    return patients.map((patient) => ({
      id: patient.id,
      Patient: getName(patient),
      physicianName: getPhysicianName(patient.physician),
      bloodOxygen: patient.latestPulseOxAlert,
      bloodPressure: patient.latestBloodPressureAlert,
      pulse: patient.latestPulseAlert,
      glucose: patient.latestBloodSugarAlert,
      weight: patient.latestWeightAlert,
      escalatedAt: patient.escalatedAt,
    }));
  }, [patients]);
}

export function FilterIcon(props: Readonly<React.SVGProps<SVGSVGElement>>) {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 7H16M1 1H19M7 13H13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Alerts() {
  const DEFAULT_PAGE_SIZE = 20;
  const [queryData, setQueryData] = useState({
    sort: 'DESC',
    sortBy: '',
    offset: 0,
    search: '',
    limit: 20,
    clinicianIds: [],
    isDefaultSearch: true,
  });
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [sortBy, setSortBy] = useState<string>('');
  const [sort, setSort] = useState<string>('');
  const [updatedFilterSearch, setUpdatedFilterSearch] =
    useState<FormFilterValues>(getInitialFilters());
  const [clinicianIds, setClinicianIds] = useState<string[]>([]);
  const [isDefaultSearch, setIsDefaultSearch] = useState<boolean>(true);

  const { userRole } = useUserRoleAndEmail();

  let filterLength = Object.values(updatedFilterSearch).reduce(
    (a, b) => a + (Array.isArray(b) ? b.length : 0),
    0
  );

  if (isDefaultSearch) {
    if (userRole === ROLE_TYPES.RHYTHM_CLINICIAN || userRole === ROLE_TYPES.RHYTHM_LEAD_CLINICIAN) {
      filterLength += 1;
    } else {
      setIsDefaultSearch(false);
    }
  }

  const getLocalStorageData = () => {
    return {
      pageIndex: 0,
      initialSize: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      defaultSortBy: [],
      goToPage: 0,
      totalCount: 0,
      search: '',
      searchWord: '',
      filters: {},
    };
  };

  const [pagination, setPagination] = useState<AlertPaginationParams>({
    ...getLocalStorageData(),
  });
  const [alertData, setAlertData] = useState<Patient[]>([]);
  const [pageParams, setPageParams] = useState({ totalSize: 0, totalPageCount: 0 });
  const { data, refetch, isLoading } = usePatientsAlertList({ ...queryData });
  const { data: cliniciansData } = useClinicUsers({
    bypassPagination: true,
  });
  const patientData = data as any;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermError, setSearchTermError] = useState('');
  const [usersData, setUsersData] = useState<User[]>([]);
  const { data: userData } = useGetUser();
  const [clinicianOption, setClinicianOption] = useState<{ label: string; value: string }[]>([]);
  const [initialValues, setInitialValues] = useState<FormFilterValues>({
    clinicians: [],
  });

  useEffect(() => {
    setAlertData(patientData?.data || []);
    if (patientData?.data) {
      if (patientData?.data?.length < pagination.pageSize) {
        setPageParams({
          totalPageCount: 1,
          totalSize: patientData?.totalCount || 0,
        });
      } else {
        setPageParams({
          totalPageCount: Math.ceil(patientData?.totalCount / patientData?.data?.length),
          totalSize: patientData?.totalCount || 0,
        });
      }
    }
  }, [patientData]);

  useEffect(() => {
    const fetchCliniciansData = async () => {
      try {
        const usersData = await Service.userControllerGetCliniciansDropdown();
        setUsersData(usersData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCliniciansData();
  }, []);

  useEffect(() => {
    const clinicianOption = usersData?.map((user: User) => {
      return {
        label: `${user.lastName}, ${user.firstName}`,
        value: user.id,
      };
    });

    const initialValues = getInitialValues();

    if (initialValues?.clinicians?.length > 0) {
      setInitialValues(initialValues);
    }

    setClinicianOption(clinicianOption);
  }, [usersData, data, userData]);

  useEffect(() => {
    if (updatedFilterSearch?.clinicians?.length > 0) {
      setIsDefaultSearch(false);
    }
  }, [updatedFilterSearch]);

  const getInitialValues = () => {
    let initialSelect;
    if (isDefaultSearch) {
      if (userRole === ROLE_TYPES.RHYTHM_CLINICIAN) {
        if (updatedFilterSearch.clinicians.length < 1) {
          filterLength = 1;
          usersData?.forEach((clinician) => {
            if (clinician.email === userData?.email) {
              initialSelect = {
                clinicians: [clinician.id],
              };
            }
          });
        }
      }

      if (userRole === ROLE_TYPES.RHYTHM_LEAD_CLINICIAN) {
        if (updatedFilterSearch.clinicians.length < 1) {
          filterLength = 2;
          usersData?.forEach((clinician) => {
            if (data?.teamClinicians) {
              const temCliniciansIds = data.teamClinicians.map((clinic) => {
                return clinic.userId?.toString();
              });

              if (clinician.email === userData?.email) {
                const uniqueTemCliniciansIds = new Set(temCliniciansIds);
                uniqueTemCliniciansIds.add(clinician.id);
                const newInitialValues = Array.from(uniqueTemCliniciansIds);
                initialSelect = {
                  clinicians: newInitialValues,
                };
              }
            }
          });
        }
      }
    }
    return initialSelect;
  };

  const createMetricColumn = (header, accessor) => {
    return {
      Header: header,
      accessor,
      width: 110,
      Cell: ({ value }: PC<alertPatientMetricCellProps>) => <AlertMetricCell {...value} />,
    };
  };

  const basicColumns: any = [
    {
      Header: 'Patient',
      width: 120,
      accessor: ({ id, Patient }: AlertReadingMetricCellProp) => [Patient, id].join(' '),
      Cell: ({ row: { original } }: PC<AlertReadingMetricCellProp>) => (
        <PatientModalLink {...original} refetch={refetch} cliniciansData={cliniciansData} />
      ),
    },
    {
      Header: '  ',
      width: 20,
      paddingLeft: 0,
      accessor: 'id',
      Cell: ({ value }: PC<string>) => (
        <PatientModalLink id={value} refetch={refetch} cliniciansData={cliniciansData} />
      ),
    },
    {
      Header: 'Physician',
      accessor: 'physicianName',
      width: 120,
    },
    createMetricColumn('BP', 'bloodPressure'),
    createMetricColumn('Pulse', 'pulse'),
    createMetricColumn('Weight', 'weight'),
    createMetricColumn('Glucose', 'glucose'),
    {
      Header: (
        <>
          S<small>p</small>O<sub>2</sub>
        </>
      ),
      accessor: 'bloodOxygen',
      width: 110,
      Cell: ({ value }: PC<alertPatientMetricCellProps>) => <AlertMetricCell {...value} />,
    },
    ,
    {
      Header: 'Escalate',
      accessor: 'escalatedAt',
      width: 110,
      disableSortBy: true,
      Cell: ({ value }: PC<EscalatedCellProps>) => <EscalatedInteractionCell escalatedAt={value} />,
    },
  ];

  const fetchData = useCallback(
    ({
      offset,
      limit,
      sortBy,
      sort,
      searchTerm,
      clinicianIds,
      isDefaultSearch,
    }: GetAllAccountsProps) => {
      const updatedQueryData: any = {};
      updatedQueryData.offset = offset;
      updatedQueryData.limit = limit;
      updatedQueryData.clinicianIds = clinicianIds;
      updatedQueryData.isDefaultSearch = isDefaultSearch;
      updatedQueryData.search = searchTerm;
      if (sortBy !== undefined && sort !== undefined) {
        updatedQueryData.sortBy = sortBy;
        if (sort === '') {
          updatedQueryData.sort = 'DESC';
        } else {
          updatedQueryData.sort = sort ? 'DESC' : 'ASC';
        }
      }
      setQueryData({ ...updatedQueryData });
    },
    [setQueryData]
  );

  const getData = (data: any): void => {
    getAccountData(
      data,
      pagination,
      setPagination,
      offset,
      setOffset,
      limit,
      setLimit,
      sortBy,
      setSortBy,
      setSort
    );
  };

  useEffect(() => {
    fetchData({ offset, limit, sortBy, sort, searchTerm, clinicianIds, isDefaultSearch });
  }, [
    offset,
    limit,
    sortBy,
    sort,
    pagination.searchWord,
    clinicianIds,
    fetchData,
    isDefaultSearch,
  ]);

  const patients: any = usePatientRows(alertData);

  const parseSearchColumn = (name?: string) => {
    if (!name) return '';
    const splitName = name?.split(' ').filter((e) => e);
    if (splitName.length > 1) return `${splitName.join('&')}`;
    return splitName[0];
  };

  useDebounce(
    async () => {
      if (parseSearchColumn(searchTerm) !== pagination?.searchWord) {
        const containsOnlyNumbersAndSpecialCharacters =
          /^[0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
        const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
        const phonePattern = /^\d{3}-\d{3}-\d{4}$/;
        const mrnPattern = /^\d+$/;
        if (containsOnlyNumbersAndSpecialCharacters.test(searchTerm)) {
          if (
            !datePattern.test(searchTerm) &&
            !phonePattern.test(searchTerm) &&
            !mrnPattern.test(searchTerm)
          ) {
            setSearchTermError(
              "You're trying to search with a partial phone number or date of birth. Please provide a complete phone number or date in the following format xxx-xxx-xxxx or mm/dd/yyyy"
            );
          } else {
            setSearchTermError('');
            setPagination({
              ...pagination,
              pageIndex: 0,
              goToPage: 0,
              initialSize: 0,
              searchWord: searchTerm,
            });
          }
        } else {
          setSearchTermError('');
          setPagination({
            ...pagination,
            pageIndex: 0,
            goToPage: 0,
            initialSize: 0,
            searchWord: searchTerm,
          });
        }
      }
    },
    250,
    [searchTerm]
  );

  const AlertFilter: React.FC<AlertFilterProps> = ({
    setUpdatedFilterSearch,
    setClinicianIds,
    setIsDefaultSearch,
    initialValues,
  }: AlertFilterProps) => {
    const handleSubmit = (values) => {
      setUpdatedFilterSearch({ ...values });
      setClinicianIds(values?.clinicians);
      setIsDefaultSearch(false);
    };

    const resetForm = () => {
      handleSubmit(getInitialFilters());
      initialValues.clinicians = [];
    };

    const defaultValidate = (): FormFilterValues => {
      const errors = {} as FormFilterValues;
      return errors;
    };

    return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={defaultValidate}>
        <Form id="create-filter">
          <Flex justify="space-between" alignItems="center" wrap="wrap">
            <RoleBasedRoutes internalUsersOnly>
              <FormMenuSelect
                menuTitle="All"
                title="Clinicians"
                name="clinicians"
                options={clinicianOption ? clinicianOption : []}
              />
            </RoleBasedRoutes>
            <Button
              onClick={resetForm}
              className="css-ix6mbv"
              size="sm"
              style={{
                background: '#FFFFFF',
                border: '1px solid #C1CBD7',
                borderRadius: '8px',
                color: '#5F7895',
                marginTop: '3px',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              }}
            >
              Reset
            </Button>
          </Flex>
        </Form>
      </Formik>
    );
  };
  if (isLoading) return <Spinner />;

  return (
    <>
      <Fragment>
        <Flex
          alignItems={'center'}
          paddingRight={'4%'}
          display={'flex'}
          justifyContent={'space-between'}
          paddingBottom={'20px'}
        >
          <Box w="50%">
            <Search
              value={searchTerm}
              onSearchTerm={setSearchTerm}
              fastReview={false}
              searchTermError={searchTermError}
            />
          </Box>
        </Flex>
        <RoleBasedRoutes>
          <Box>
            <Accordion
              background={'#FFFFFF'}
              borderRadius={'8px 8px 0px 0px'}
              width={'1475px'}
              minW={'100%'}
              allowMultiple
            >
              <AccordionItem border={'none'} style={{ padding: '8px' }}>
                <h2
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '8px',
                  }}
                >
                  <AccordionButton _hover={{ bg: 'white' }} w={'50'}>
                    <FilterIcon color={`${filterLength > 0 ? ' #1083CB' : '#516880'}`} />{' '}
                    <Box as="span" flex="1" textAlign="left" color={'#516880'} marginLeft={'10px'}>
                      Filter <AccordionIcon style={{ fontSize: '1.5rem' }} />
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  borderTop="2px"
                  paddingTop={'10px'}
                  paddingBottom={'5px'}
                  borderColor={'#eff2f6'}
                >
                  <AlertFilter
                    setUpdatedFilterSearch={setUpdatedFilterSearch}
                    setClinicianIds={setClinicianIds}
                    setIsDefaultSearch={setIsDefaultSearch}
                    initialValues={initialValues}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </RoleBasedRoutes>
        {patients.length > 0 && (
          <DataTable
            columns={basicColumns}
            search={''}
            hasPagination
            isPatientManagementPage={true}
            isSortable
            data={patients}
            fetchData={(data: any) => getData(data)}
            totalPageCount={pageParams.totalPageCount}
            totalRowCount={pageParams.totalSize}
            initialPageSize={DEFAULT_PAGE_SIZE}
          />
        )}
        {patients.length === 0 && (
          <Flex style={{ justifyContent: 'center', padding: '10px' }}>
            <Box style={{ paddingTop: '30px' }}>
              <Text style={{ fontSize: '16px', fontWeight: '600' }}>
                No alerts to manage at this time
              </Text>
              <br />
            </Box>
          </Flex>
        )}
      </Fragment>
    </>
  );
}
