import { Modal, Box, Flex, Icon, Text, Heading, Button } from '@rhythm/components';
import { Formik, Form } from 'formik';
import { Patient } from '~generated';
import { FormField } from '~src/components/FormField';
import { FormSelect } from '~src/components/FormSelect';
import { useArchivePatient } from '~src/services/patients';
import { archivingReasonOptions, optOutReasonOptions } from '../../../constants/archivePatient';

interface FooterProps {
  onClose: () => void;
  isLoading: boolean;
}

function ModalFooter({ onClose, isLoading }: FooterProps) {
  return (
    <>
      <Button onClick={onClose} variant="secondaryDark" mr="xl">
        Cancel
      </Button>
      <Button form="archive-patient" isLoading={isLoading} type="submit" variant="danger">
        Unenroll
      </Button>
    </>
  );
}

interface ArchivePatientModalProps {
  isOpen: boolean;
  onClose: () => void;
  patient: Patient;
}

interface ArchivePatientValues {
  reason: string;
  optOutReason: string;
  notes: string;
}

export function ArchivePatientModal({ isOpen, onClose, patient }: ArchivePatientModalProps) {
  const archivePatient = useArchivePatient();
  const archivePatientValues: ArchivePatientValues = {
    reason: '',
    optOutReason: '',
    notes: '',
  };

  async function handleSubmit(values: ArchivePatientValues) {
    const payload = {
      patientId: patient.id,
      notes: values.notes || '',
      reason: values.reason,
      optOutReason: values.optOutReason,
    };
    await archivePatient.mutate(payload);
  }

  function validationFunction(values: ArchivePatientValues) {
    const errors = {} as Partial<ArchivePatientValues>;
    if (!values.reason) {
      errors.reason = 'This field is required';
    }
    if (values.reason === 'PHYSICIAN_REQUEST ' || values.reason === 'PATIENT_REQUEST') {
      if (!values.optOutReason) {
        errors.optOutReason = 'This field is required';
      }
    }
    if (values.notes.length > 200 || values.notes.length < 1) {
      errors.notes =
        values.notes.length < 1 ? 'This field is required' : 'Notes are limited to 200 characters';
    }

    return errors;
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validationFunction}
      initialValues={archivePatientValues}
    >
      {({ values }) => (
        <Form id="archive-patient">
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            footer={<ModalFooter onClose={onClose} isLoading={archivePatient.isLoading} />}
          >
            <Flex mb="2xl">
              <Box bg="feedback.warningLight" p="lg" borderRadius="md" mr="xl">
                <Icon icon="status-warning" color="feedback.warningText" />
              </Box>
              <Box>
                <Text>RPM Patients</Text>
                <Heading variant="h4">Unenroll From Monitoring</Heading>
              </Box>
            </Flex>
            <FormSelect
              label="Unenrollment Reason*"
              name="reason"
              options={archivingReasonOptions}
            />
            {(values.reason === 'PATIENT_REQUEST' || values.reason === 'PHYSICIAN_REQUEST') && (
              <FormSelect
                label="Opt-out Reason*"
                name="optOutReason"
                options={optOutReasonOptions}
              />
            )}
            <FormField name="notes" label="Notes (max 200 characters)" textarea />
          </Modal>
        </Form>
      )}
    </Formik>
  );
}
