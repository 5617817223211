/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PatientReading = {
    id: string;
    type: PatientReading.type;
};

export namespace PatientReading {

    export enum type {
        WEIGHT = 'weight',
        BLOOD_PRESSURE = 'blood_pressure',
        BLOOD_PRESSURE_SYSTOLIC = 'blood_pressure_systolic',
        BLOOD_PRESSURE_DIASTOLIC = 'blood_pressure_diastolic',
        BLOOD_SUGAR = 'blood_sugar',
        SEVEN_DAYS_CHANGE = 'seven_days_change',
        PULSE = 'pulse',
        BLOOD_OXYGEN = 'blood_oxygen',
    }


}

