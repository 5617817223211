import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';
import { BloodSugar, DeviceThreshold } from '~generated';
import { formattedDateRange } from '~src/utils/formattedDateRange';
import { pointResolverHelper } from '~src/utils/pointResolverHelper';
import { DateRange } from '../..';

interface GlucoseGraphDataProps {
  data: BloodSugar[] | undefined;
  dateRange: DateRange;
  sugarThreshold: DeviceThreshold | undefined;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const GlucoseGraph = ({ data, dateRange, sugarThreshold }: GlucoseGraphDataProps) => {
  const [daysAsString, daysAsDate] = formattedDateRange(dateRange);

  const completeData = daysAsDate.map((day: Date) => {
    const glucoseEntry = data
      ?.slice()
      .reverse()
      .find((entry) => dayjs(entry.timestamp).isSame(day, 'day'));
    return glucoseEntry || null;
  });

  const glucosePointRadiusResolver = (context: ScriptableContext<'line'>) => {
    return pointResolverHelper(context, 3, 10, sugarThreshold);
  };

  const glucosePointColorResolver = (context: ScriptableContext<'line'>) => {
    return pointResolverHelper(context, 'rgba(82, 208, 239, 1)', 'red', sugarThreshold);
  };

  const state = {
    labels: daysAsString,
    datasets: [
      {
        label: 'Glucose',
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(82, 208, 239, 1)',
        borderColor: 'rgba(82, 208, 239, 1)',
        pointRadius: glucosePointRadiusResolver,
        pointBackgroundColor: glucosePointColorResolver,
        spanGaps: false,
        borderWidth: 3,
        tension: 0,
        data: completeData?.map((entry: BloodSugar | null) => entry?.reading),
      },
    ],
  };

  return (
    <Line
      data={state}
      options={{
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',
            labels: {
              padding: 20,
              font: {
                size: 16,
              },
            },
          },
          annotation: {
            annotations: {
              glucose: {
                drawTime: 'afterDatasetsDraw',
                display: true,
                type: 'box',
                yMin: sugarThreshold?.threshHoldLowerLimit,
                yMax: sugarThreshold?.threshHoldUpperLimit,
                backgroundColor: 'rgba(82, 208, 239, 0.2)',
              },
            },
          },
        },
      }}
    />
  );
};

export default GlucoseGraph;
