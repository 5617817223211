import { useOktaAuth } from '@okta/okta-react';
import { globalConfig } from '../configuration/config';
import qs from 'query-string';

export function useHypertensionReport(): (
  from?: Date,
  to?: Date,
  bodyObj?: any
) => Promise<string> {
  const { authState } = useOktaAuth();
  const config = globalConfig.get();

  return async (from?: Date, to?: Date, bodyObj?: any) => {
    const queryParams = qs.stringify({
      from: from?.toISOString?.(),
      to: to?.toISOString?.(),
    });
    const response = await fetch(
      `${config.apiUrl}/billing/hypertension_report.csv?${queryParams}`,
      {
        method: 'POST',
        body: JSON.stringify(bodyObj),
        headers: {
          Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
          'content-type': 'application/json',
        },
      }
    );
    if (!response.ok) {
      throw new Error('Failed to download clinical report');
    }
    const blob = await response.blob();
    return window.URL.createObjectURL(blob);
  };
}

export function useBloodSugarReport(): (from?: Date, to?: Date, bodyObj?: any) => Promise<string> {
  const { authState } = useOktaAuth();
  const config = globalConfig.get();

  return async (from?: Date, to?: Date, bodyObj?: any) => {
    const queryParams = qs.stringify({
      from: from?.toISOString?.(),
      to: to?.toISOString?.(),
    });
    const response = await fetch(`${config.apiUrl}/billing/glucose_report.csv?${queryParams}`, {
      method: 'POST',
      body: JSON.stringify(bodyObj),
      headers: {
        Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
        'content-type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to download clinical report');
    }
    const blob = await response.blob();
    return window.URL.createObjectURL(blob);
  };
}
