import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';
import { BloodPressure, DeviceThreshold } from '~generated';
import { formattedDateRange } from '~src/utils/formattedDateRange';
import { pointResolverHelper } from '~src/utils/pointResolverHelper';
import { DateRange } from '../..';

interface BloodPressureGraphDataProps {
  data: BloodPressure[] | undefined;
  dateRange: DateRange;
  systolicThreshold: DeviceThreshold | undefined;
  diastolicThreshold: DeviceThreshold | undefined;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const BloodPressureGraph = ({
  data,
  dateRange,
  systolicThreshold,
  diastolicThreshold,
}: BloodPressureGraphDataProps) => {
  const [daysAsString, daysAsDate] = formattedDateRange(dateRange);

  const completeData = daysAsDate.map((day: Date) => {
    const bpEntry = data
      ?.slice()
      .reverse()
      .find((entry) => dayjs(entry.timestamp).isSame(day, 'day'));
    return bpEntry || null;
  });

  const systolicPointRadiusResolver = (context: ScriptableContext<'line'>) => {
    return pointResolverHelper(context, 3, 10, systolicThreshold);
  };

  const diastolicPointRadiusResolver = (context: ScriptableContext<'line'>) => {
    return pointResolverHelper(context, 3, 10, diastolicThreshold);
  };

  const systolicPointColorResolver = (context: ScriptableContext<'line'>) => {
    return pointResolverHelper(context, 'rgba(82, 208, 239, 1)', 'red', systolicThreshold);
  };

  const diastolicPointColorResolver = (context: ScriptableContext<'line'>) => {
    return pointResolverHelper(context, 'rgba(82, 208, 239, 1)', 'red', systolicThreshold);
  };
  const state = {
    labels: daysAsString,
    datasets: [
      {
        label: 'Systolic',
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(82, 208, 239, 1)',
        borderColor: 'rgba(82, 208, 239, 1)',
        pointRadius: systolicPointRadiusResolver,
        pointBackgroundColor: systolicPointColorResolver,
        pointBorderWidth: 3,
        borderWidth: 3,
        spanGaps: false,
        tension: 0,
        data: completeData?.map((reading: BloodPressure | null) => reading?.systolic),
      },
      {
        label: 'Diastolic',
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(230, 135, 44, 1)',
        borderColor: 'rgba(230, 135, 44, 1)',
        pointRadius: diastolicPointRadiusResolver,
        pointBackgroundColor: diastolicPointColorResolver,
        spanGaps: false,
        borderWidth: 3,
        tension: 0,
        data: completeData?.map((reading: BloodPressure | null) => reading?.diastolic),
      },
    ],
  };

  return (
    <Line
      data={state}
      options={{
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',
            labels: {
              padding: 20,
              font: {
                size: 16,
              },
            },
          },
          annotation: {
            annotations: {
              systolic: {
                drawTime: 'afterDatasetsDraw',
                display: true,
                type: 'box',
                yMin: systolicThreshold?.threshHoldLowerLimit,
                yMax: systolicThreshold?.threshHoldUpperLimit,
                backgroundColor: 'rgba(82, 208, 239, 0.2)',
              },
              diastolic: {
                drawTime: 'afterDatasetsDraw',
                display: true,
                type: 'box',
                yMin: diastolicThreshold?.threshHoldLowerLimit,
                yMax: diastolicThreshold?.threshHoldUpperLimit,
                backgroundColor: 'rgba(230, 135, 44, 0.2)',
              },
            },
          },
        },
      }}
    />
  );
};

export default BloodPressureGraph;
