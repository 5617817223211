import { Fragment } from 'react';
import { Text, Heading, Flex } from '@rhythm/components';

export function PatientWidgetData({ patient }: any) {
  return (
    <Fragment>
      <Flex flexDirection="column" mb="2">
        <Heading
          variant="h4"
          fontWeight="bold"
          mb="lg"
          style={{ color: '#0E6DAD' }}
          overflowWrap="anywhere"
        >
          {patient.firstName} {patient.lastName}
        </Heading>
        <Text variant="smallCaps" color="neutral.800">
          Mobile
        </Text>
        <Text mb="xl">{patient?.mobileNumber?.length > 0 ? patient.mobileNumber : 'NA'}</Text>
        <Text variant="smallCaps" color="neutral.800">
          DOB
        </Text>
        <Text mb="xl">{patient.birthDate ?? 'NA'}</Text>
        <Text variant="smallCaps" color="neutral.800">
          Disclose PHI
        </Text>
        <Text mb="xl">
          {patient?.contacts && patient?.contacts.length > 0
            ? patient?.contacts[0]?.disclosePHI
            : 'NA'}
        </Text>
      </Flex>
    </Fragment>
  );
}
