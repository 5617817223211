import { Box, Divider, Text } from '@rhythm/components';
import { formatPhoneNumber } from '~src/utils/formatPhoneNumber';
import { AlternateContact } from '../../pages/Patients/PatientProfile';

interface ContactInformationProps {
  homePhone: string;
  alternateContact?: AlternateContact;
  mobileNumber: string;
  language?: string;
  city?: string;
  state?: string;
}

export function ContactInformation({
  homePhone,
  alternateContact,
  mobileNumber,
  language = '',
  city,
  state,
}: ContactInformationProps) {
  return (
    <Box>
      <>
        <Box mb="xl">
          <Text variant="smallCaps" color="neutral.800">
            Preferred Language
          </Text>
          <Text>{language}</Text>
        </Box>
        {!alternateContact?.isPreferred && (
          <Box mb="xl">
            <Text variant="smallCaps" color="neutral.800">
              Home Phone
            </Text>
            <Text>{formatPhoneNumber(homePhone)}</Text>
          </Box>
        )}
        <Box mb="xl">
          <Text variant="smallCaps" color="neutral.800">
            Mobile Number
          </Text>
          <Text>{formatPhoneNumber(mobileNumber)}</Text>
        </Box>
        <Box mb="xl">
          <Text variant="smallCaps" color="neutral.800">
            Location
          </Text>
          <Text>
            {city}, {state}
          </Text>
        </Box>
        <Divider my="xl" />
      </>
      {!!alternateContact && (
        <Box>
          <Text variant="body" fontWeight="bold" mb="lg">
            Alternate Contact
          </Text>
          <Box mb="xl">
            <Text variant="smallCaps" color="neutral.800">
              Phone
            </Text>
            <Text mb="lg">{formatPhoneNumber(alternateContact.contact)}</Text>
            <Text variant="smallCaps" color="neutral.800">
              Name
            </Text>
            <Text mb="lg">{alternateContact.name}</Text>
            <Text variant="smallCaps" color="neutral.800">
              disclosePHI
            </Text>
            <Text mb="lg">{alternateContact.disclosePHI ? 'true' : 'false'}</Text>
          </Box>
        </Box>
      )}
    </Box>
  );
}
