import { Fragment, useState } from 'react';
import { Box, Button, Flex, Grid, Spinner, Text } from '@rhythm/components';
import { useParams, useHistory } from 'react-router-dom';
import { PatientWidget } from '~src/components/PatientWidget';
import { phoneNumber, useSinglePatient } from '~src/services/patients';
import { DataReading } from './PatientOverview';
import Cpt from './Cpt';
import { Notes } from '~src/components/Notes';
import { CallWidget } from '~src/components/CallWidget';
import { getAgeFromDate } from '~src/helpers';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { Patient, Contact } from 'generated';
import { SocialNotes } from '~src/components/SocialNotes';

export interface PatientProp {
  patient: Patient;
  systolicValue?: number;
  diastolicValue?: number;
  systolicOutlier?: boolean;
  diastolicOutlier?: boolean;
  bpSystolicValue?: { bpSysValue: number; timeStamp: string };
  bpDiastoicValue?: { bpDiastoicValue: number; timeStamp: string };
  bpPulseValue?: { bpPulseData: number; timeStamp: string };
  weightTotalValue?: { weightValue: number; timeStamp: string };
  bloodSugarTotalValue?: { bloodSugarData: number; timeStamp: string };
  titleColor?: boolean;
  pulseOutlier?: boolean;
  titleColorPulse?: boolean;
  weightValue?: number;
  pulseValue?: number;
  bloodSugarOutlier?: boolean;
  titleColorGlucose?: boolean;
  weightOutlier?: boolean;
  titleColorWeight?: boolean;
  bloodSugarValue?: number;
  deviceVendor?: boolean;
  bloodOxygenValue?: number;
  bloodOxygenOutlier?: boolean;
  titleColorOxygen?: boolean;
  bloodOxygen?: number;
  setPulseOxPulseValue?: number;
  pulseOxPulseTotalValue?: { pulseOxPulseData: number; timeStamp: string };
  bloodOxygenTotalValue?: { bloodOxygen: number; timeStamp: string };
  handleEdit?: () => void;
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<Patient, unknown>>;
}

export interface AlternateContact extends Contact {
  isPreferred: boolean;
  isAlternate: boolean;
  relation: string;
}

const CptBorder = ({ patient }: PatientProp) => <Cpt patient={patient} />;

interface ParamsTypes {
  id: string;
}

export function PatientProfile() {
  const history = useHistory();
  const { id } = useParams<ParamsTypes>();
  const [showCallModal, setShowCallModal] = useState(false);

  const { data, isLoading, refetch, error } = useSinglePatient(id);
  const errorMessage: any = error;

  const handlePhoneClick = (phoneNumber: string) => {
    alert(phoneNumber);
    setShowCallModal(true);
  };

  const handleEndCall = (): void => {
    alert('Ending phone call!');
    setShowCallModal(false);
  };

  const handleMute = (): void => alert('Muting Call!');

  const handleEditPatient = () => history.push(`/patients/edit/${id}`);

  if (errorMessage && errorMessage.message) {
    return (
      <Flex style={{ justifyContent: 'center', padding: '10px' }}>
        <Box style={{ paddingTop: '30px' }}>
          <Text style={{ fontSize: '16px', fontWeight: '600' }}>
            {errorMessage.message.includes('No Patient found')
              ? 'No Patient found'
              : 'Could not load patient'}{' '}
          </Text>
          <br />
          <Button style={{ marginLeft: '20px' }} onClick={() => history.push('/patients')}>
            Back
          </Button>
        </Box>
      </Flex>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  const patient = data!;
  const patientData: any = data!;
  let organizationName = '';
  if (patientData?.organization?.length > 0) {
    const filteredOrganizations = patientData?.organization.filter((org) => org.type === 'account');
    const organizationNames = filteredOrganizations.map((org) => org.name);
    organizationName = organizationNames.join(', ');
  }

  let clinicName = '';
  if (patientData?.organization?.length > 0) {
    const filteredClinicOrganizations = patientData?.organization.filter(
      (org) => org.type === 'clinic'
    );
    const clinicOrganizationNames = filteredClinicOrganizations.map((org) => org.name);
    clinicName = clinicOrganizationNames.join(', ');
  }
  const age = getAgeFromDate(new Date(patient?.birthDate));
  const alternateContact: AlternateContact | undefined = patient?.contacts?.filter(
    ({ isAlternate, type }) => isAlternate && type === 'phone'
  )[0];
  const scrollToNotes = () => {
    const notesElement = document.getElementById('notes-section');
    if (notesElement) {
      notesElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Fragment>
      <Button
        style={{
          width: '32px',
          height: '6px',
          color: '#6C7789',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        }}
        onClick={() => history.goBack()}
        leftIcon={'arrow-left'}
        mb="md"
      >
        Back
      </Button>
      <Grid templateColumns="300px 1fr" gap="2xl" w={'85%'}>
        <Box>
          <PatientWidget
            name={`${patient.firstName} ${patient.lastName}`}
            id={patient.id}
            city={patient?.address.city}
            state={patient?.address.state}
            mobilePhone={phoneNumber(patient)}
            homePhone={phoneNumber(patient)}
            onPhoneClick={handlePhoneClick}
            alternateContact={alternateContact}
            physician={patient?.physician}
            language={patient?.language}
            age={age}
            clinicians={patient?.clinicians}
            devices={patient?.devices}
            statusType={patient?.statusType}
            handleEditPatient={handleEditPatient}
            mrn={patient?.mrn}
            mobileNumber={patient?.mobileNumber}
            dob={patient?.birthDate}
            gender={patient.gender}
            enrollmentDate={patient?.createdAt}
            accountName={organizationName}
            clinicName={clinicName}
            firstDischargeDate={patient?.firstDischargeDate}
            groups={patient?.groups}
            readmissions={patient?.readmissions}
          />
          <Box mt="3xl">
            <SocialNotes />
          </Box>
        </Box>
        <Box pl="2xl" pr="3xl">
          <Box p={'8px'} mb={'12px'} bg={'#fff'} borderRadius={'10px'}>
            <Text
              textColor={'rgb(16, 131, 203)'}
              ml={'8px'}
              cursor={'pointer'}
              onClick={scrollToNotes}
            >
              Notes
            </Text>
          </Box>
          <Box w="100%">
            <CptBorder patient={patient} />
          </Box>
          <Grid mb="6">
            <DataReading patient={patient} refetch={refetch} />
          </Grid>
          <Notes data={data} devices={patient?.devices} />
        </Box>
        {showCallModal && <CallWidget handleEndCall={handleEndCall} handleMute={handleMute} />}
      </Grid>
    </Fragment>
  );
}
