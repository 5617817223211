import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { SideNav } from '../SideNav';
import { Box, Flex, TextButton, Heading } from '@rhythm/components';
import { useLogoutUser, useGetUser } from '~src/services/users';
import SideBar from '../SideBar';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { createPatientRoles, sideNavRoles } from '~src/constants/roles';
import { MyContextProvider } from '~src/pages/createContext';
interface PageLocationState {
  backButton?: {
    label: string;
    path?: string;
  };
}

interface AppLayoutProps {
  children: React.ReactNode;
}

interface RouteParams {
  path: string;
}
interface RouteSubParams {
  subPath: string;
  id: string;
}

const routeNames: Record<string, string> = {
  dashboard: 'Dashboard',
  patients: 'Patient Management',
  profile: 'Patient Chart ',
  edit: 'Patient Demographics',
  enroll: 'Patient Enrollment',
  candidates: 'Candidate Management',
  reports: 'Reports',
  settings: 'Manage Your Settings',
  internalsettings: 'Settings',
  alerts: 'Alert Management',
};

const teamRouteNames: Record<string, string> = {
  create: ' ',
  edit: ' ',
};

function RouteHeader() {
  const history = useHistory();
  const enrolPatient = () => history.push('/patients/enroll');
  const match = useRouteMatch<RouteParams>('/:path');
  let headername: any = null;
  if (match?.params?.path && routeNames[match.params.path]) {
    headername = routeNames[match.params.path];
  }
  const subPath = useRouteMatch<RouteSubParams>('/:subPath/:subPath');
  const subPathMatch = useRouteMatch<RouteSubParams>('/:subPath/:subPath/:id');
  if (subPath?.params?.subPath && routeNames[subPath.params.subPath]) {
    headername = routeNames[subPath.params.subPath];
  }
  if (subPathMatch?.params?.subPath && routeNames[subPathMatch.params.subPath]) {
    headername = routeNames[subPathMatch.params.subPath];
  }
  if (subPathMatch?.params?.subPath && teamRouteNames[subPathMatch.params.id]) {
    headername = teamRouteNames[subPathMatch.params.id];
  }
  return (
    <Heading variant="h3">
      {headername}{' '}
      {match?.url === '/patients' && match?.isExact ? (
        <RoleBasedRoutes allowedRoles={createPatientRoles}>
          <TextButton leftIcon="add" onClick={enrolPatient}>
            Enroll Patient
          </TextButton>
        </RoleBasedRoutes>
      ) : null}
    </Heading>
  );
}

export function AppLayout({ children }: AppLayoutProps) {
  const history = useHistory();
  const location = useLocation<PageLocationState>();
  const { data: user } = useGetUser();
  const { mutate: logout } = useLogoutUser();

  const onClickBack = (path?: string) => {
    path ? history.push(path) : history.goBack();
  };

  const fullname = `${user?.given_name} ${user?.family_name}`;

  return (
    <MyContextProvider>
      <Flex minHeight="100vh" bg="neutral.200">
        <SideNav fullname={fullname} logout={logout} />
        <RoleBasedRoutes allowedRoles={sideNavRoles}>
          <SideBar />
        </RoleBasedRoutes>
        <Flex flex={1} flexDirection="column" ml={'lg'} bgColor="neutral.200">
          <Flex
            justifyContent="space-between"
            bgColor="neutral.200"
            color="neutral.800"
            alignItems="center"
            py="20px"
            zIndex="0"
          >
            <RouteHeader />
            <Box mt="20px">
              {location.state?.backButton && (
                <TextButton
                  variant="secondary"
                  leftIcon="arrow-left"
                  onClick={() => onClickBack(location.state.backButton?.path)}
                  padding={{ base: 'none', md: 'none' }}
                >
                  {location.state.backButton.label}
                </TextButton>
              )}
            </Box>
          </Flex>
          <Box bg="neutral.200">{children}</Box>
        </Flex>
      </Flex>
    </MyContextProvider>
  );
}
