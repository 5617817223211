import { useEffect } from 'react';
import { Heading, Box, Grid, Spinner } from '@rhythm/components';
import { useAlertModalData } from '~src/services/patients';
import { Patient, SocialNote, ClinicalNote, User, Roles } from '~generated';
import { PatientWidgetNotesData } from './PatientPinnedNotes';
import { PatientWidgetData } from './PatientAlertWidgetData';
import { AlertReadingDataTable } from './PatientAlertReadingTable';
import { ClinicalNotes } from './PatientClinicalNotes';
import { AlertModalForm } from './AlertModalForm';
import { useUserRoleAndEmail } from '~src/hooks';
interface FormValues {
  note: string;
  userId: string;
  subject: string;
  header: string;
  body: string;
  emailTo: string;
  bcc: string;
  escalate: boolean;
}
export interface AlertDataModalProp {
  id: string;
  setOpen: (open: boolean) => void;
  escalate: boolean;
  setEscalate: (escalate: boolean) => void;
  handleSubmit: (values: FormValues) => void;
  cliniciansData: User[];
}
export interface AlertPatientDataModalProps {
  patient: Patient[];
}

export interface NewSocialNote extends SocialNote {
  convertedTimestamp: string;
}
export interface AlertPinnedNotesDataModalProps {
  pinnedNotes: NewSocialNote[];
}
export interface AlertClinicalNotesDataModalProps {
  clinicalNotes: ClinicalNote[];
}
export interface AlertReadingDataModalProps {
  data: any;
  readingType: string;
}
export function DataModal({
  id,
  setOpen,
  escalate,
  setEscalate,
  handleSubmit,
  cliniciansData,
}: AlertDataModalProp) {
  const { data, isLoading } = useAlertModalData(id);

  const { userRole } = useUserRoleAndEmail();
  const isNonClinician = userRole === Roles.ROLES_INTERNAL_NON_CLINICAL;

  useEffect(() => {
    if (data) {
      setEscalate(data?.patient[0].escalatedToUser === null ? false : true);
    }
  }, [data]);
  return (
    <>
      {isLoading && <Spinner />}
      <Grid templateColumns="300px 1fr" gap="2xl" w={'85%'}>
        <Box marginRight={'2px'}>
          <PatientWidgetData patient={data && data?.patient ? data?.patient[0] : {}} />
          <PatientWidgetNotesData pinnedNotes={data && data?.pinnedNotes ? data.pinnedNotes : []} />
        </Box>
        <AlertReadingDataTable data={data} />
      </Grid>
      <Box>
        <Heading variant="h6">Notes :</Heading>
        <ClinicalNotes
          clinicalNotes={data && data.clinicalNotes ? data.clinicalNotes : []}
          timeZone={data && data?.patient.length > 0 ? data?.patient[0].address.timeZone : ' '}
        />
      </Box>
      {!isLoading && !isNonClinician && (
        <AlertModalForm
          setOpen={setOpen}
          isEescalate={escalate}
          setEscalate={setEscalate}
          handleSubmit={handleSubmit}
          data={data}
          cliniciansData={cliniciansData}
        />
      )}
    </>
  );
}
