import { Icon } from '~src/types/Icon';

export const CrossCircle: React.FC<Icon> = ({
  dimension = '12',
  stroke = '#213147',
  fill = 'none',
}) => {
  return (
    <svg
      width={dimension}
      height={dimension}
      viewBox="0 0 12 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 9311">
        <path
          id="Vector"
          d="M6 11.25C8.89949 11.25 11.25 8.89949 11.25 6C11.25 3.1005 8.89949 0.75 6 0.75C3.1005 0.75 0.75 3.1005 0.75 6C0.75 8.89949 3.1005 11.25 6 11.25Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.57505 4.42505L4.42505 7.57505"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M4.42505 4.42505L7.57505 7.57505"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
