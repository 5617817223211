import { useEffect, RefObject } from 'react';

export const useClickOff = (ref: RefObject<HTMLInputElement>, callback: () => void): void => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    const handleEscapePress = (event: KeyboardEvent): void => {
      if (event.key !== 'Escape') return;
      callback();
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keyup', handleEscapePress);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keyup', handleEscapePress);
    };
  }, [ref, callback]);
};
