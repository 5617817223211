import { Box, Heading, Text, Divider, Badge, Flex } from '@rhythm/components';
import { ContactInformation } from './ContactInformation';
import { Patient, User, Device, Group, PatientAdmission } from '~generated';
import { AlternateContact } from '../../pages/Patients/PatientProfile';
import { DeviceTypes } from '../../constants/devices';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { editPatientRoles } from '~src/constants/roles';
import { capitalizeFirstWord, formatDate } from '~src/utils';
import dayjs from 'dayjs';

interface PatientWidgetProps {
  name: string;
  patient?: Patient;
  id?: string;
  homePhone: string;
  mobilePhone: string;
  city: string;
  state: string;
  onPhoneClick: (phoneNumber: string) => void;
  alternateContact?: AlternateContact;
  physician: User;
  language: string;
  age: number | string;
  clinicians?: User[];
  devices?: Device[];
  statusType: string;
  handleEditPatient: () => void;
  mrn: string;
  mobileNumber: string;
  dob: string;
  gender: string;
  enrollmentDate?: string;
  accountName?: string;
  clinicName?: string;
  firstDischargeDate?: string;
  groups?: Group[];
  readmissions?: PatientAdmission[];
}

export function PatientWidget({
  name,
  statusType,
  homePhone,
  city,
  state,
  alternateContact,
  physician,
  language,
  age,
  clinicians,
  devices,
  accountName,
  clinicName,
  handleEditPatient,
  mrn,
  mobileNumber,
  dob,
  gender,
  enrollmentDate,
  firstDischargeDate,
  groups,
  readmissions,
}: PatientWidgetProps) {
  const groupedDevicesByVendor = () => {
    const map = new Map();
    devices?.forEach((device) => {
      if (device?.isActive) {
        const key = device.vendor.name;
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [device]);
        } else {
          collection.push(device);
        }
      }
    });
    return map;
  };
  const groupedDevices = groupedDevicesByVendor();

  let firstReadmission: PatientAdmission | undefined;

  if (readmissions?.length) {
    const temp = readmissions.find((item) => item.admissionNumber === 1);

    if (temp?.admissionReason && temp?.admissionDate) {
      firstReadmission = temp;
    }
  } else {
    firstReadmission = undefined;
  }
  return (
    <Box bg="white" p="3xl" borderRadius="8px">
      <Flex justifyContent="space-between" alignItems="center">
        <RoleBasedRoutes allowedRoles={editPatientRoles}>
          <Heading
            variant="h3"
            fontWeight="bold"
            mb="lg"
            style={{ color: '#0E6DAD' }}
            _hover={{
              textDecoration: 'underline',
              textDecorationColor: '#213147',
              cursor: 'pointer',
            }}
            onClick={handleEditPatient}
            overflowWrap="anywhere"
          >
            {name}
          </Heading>
        </RoleBasedRoutes>
      </Flex>
      <Flex>
        {statusType == 'new' && (
          <Badge mb="2xl" background="#E0E5EB" border="1px solid #B8C0CD" color="#213147">
            {statusType}
          </Badge>
        )}
        {statusType == 'active' && (
          <Badge mb="2xl" background="#E5F9ED" border="1px solid #068923" color="#213147">
            {statusType}
          </Badge>
        )}
        {statusType == 'non-adherent' && (
          <Badge mb="2xl" background="#FFF3E1" border="1px solid #E18B00" color="#213147">
            {statusType}
          </Badge>
        )}
      </Flex>
      <Text variant="smallCaps" color="neutral.800">
        DATE OF ENROLLMENT
      </Text>
      <Text mb="xl">{dayjs(enrollmentDate).format('MM/DD/YYYY')}</Text>
      <Text variant="smallCaps" color="neutral.800">
        MRN
      </Text>
      <Text mb="xl">{mrn}</Text>
      <Text variant="smallCaps" color="neutral.800">
        DOB
      </Text>
      <Text mb="xl">{formatDate(dob)}</Text>
      <Text variant="smallCaps" color="neutral.800">
        Gender
      </Text>
      <Text variant="title" mb="xl">
        {capitalizeFirstWord(gender)}
      </Text>
      <Text variant="smallCaps" color="neutral.800">
        Age
      </Text>
      <Text mb="xl">{age}</Text>
      <ContactInformation
        homePhone={homePhone}
        alternateContact={alternateContact}
        mobileNumber={mobileNumber}
        language={language}
        city={city}
        state={state}
      />

      {!!devices?.length && (
        <>
          {groupedDevices.size > 0 && (
            <Text variant="smallCaps" color="neutral.800" mb="sm">
              Devices
            </Text>
          )}
          {[...groupedDevices.keys()].map((vendor) => (
            <Box mb="md" key={vendor}>
              <Text fontSize="10px" fontWeight="black" color="neutral.800">
                {vendor}
              </Text>
              {groupedDevices.get(vendor).map((device: Device) => (
                <Text key={device.id}>{DeviceTypes[device.type as keyof typeof DeviceTypes]}</Text>
              ))}
            </Box>
          ))}
        </>
      )}
      <Divider my="2xl" />
      <Box mb="2xl">
        <Text variant="smallCaps" color="neutral.800">
          Account
        </Text>
        <Text mb="xl">{accountName || ''}</Text>
        <Text variant="smallCaps" color="neutral.800">
          Clinic
        </Text>
        <Text mb="xl">{clinicName || ''}</Text>
        <Text variant="smallCaps" color="neutral.800">
          Physician
        </Text>
        <Text mb="xl">
          {physician?.lastName}, {physician?.firstName}
        </Text>
        {clinicians && clinicians.length > 0 && (
          <>
            <Text variant="smallCaps" color="neutral.800">
              Rhythm Clinician
            </Text>
            {clinicians.map((clinician) => {
              let creds;
              if (clinician?.credentials) {
                creds = clinician?.credentials
                  ?.map((cred, i) => {
                    if (i < 2) {
                      return cred?.credential;
                    }
                  })
                  .join(', ');
              }
              return (
                <Text key={clinician.id}>
                  {`${clinician.lastName}, ${clinician.firstName}${creds ? `, ${creds}` : ''}`}
                </Text>
              );
            })}
          </>
        )}
      </Box>

      <Box mb="2xl">
        <Divider my="2xl" />
        {groups && groups.length > 0 && (
          <div>
            <Text mb="md" variant="smallCaps" color="neutral.800">
              Groups
            </Text>
            {groups.map((item, index) => (
              <Text key={item.id} mb={index === groups.length - 1 ? 'xl' : 'sm'}>
                {item.name}
              </Text>
            ))}
          </div>
        )}
        {firstDischargeDate && (
          <div>
            <Text variant="smallCaps" color="neutral.800">
              First Discharge Date
            </Text>
            <Text mb="xl">{formatDate(firstDischargeDate)}</Text>
          </div>
        )}
        {firstReadmission && (
          <div>
            <Text variant="smallCaps" color="neutral.800">
              First Readmission Date
            </Text>
            <Text mb="xl">{formatDate(firstReadmission?.admissionDate)}</Text>
            <Text variant="smallCaps" color="neutral.800">
              Readmission Reason
            </Text>
            <Text maxLength={100} mb="xl">
              {firstReadmission?.admissionReason}
            </Text>
          </div>
        )}
      </Box>
    </Box>
  );
}
