import { Box, DatePicker, FormControl, FormLabel, Text } from '@rhythm/components';
import dayjs from 'dayjs';
import { FormikProps, useField, useFormikContext } from 'formik';
import { EnrollPatientFormValues } from '../EnrollPatientForm/EnrollPatientForm';

interface FormCalendarProps {
  label?: string;
  name: string;
  hintText?: string;
  placeholder?: string;
  minDate?: Date;
  mb?: string;
  dateFormat?: string;
  disabled?: boolean;
  style?: React.CSSProperties | undefined;
  applyTimezoneOffset?: boolean;
  showErrorOnTouch?: boolean;
}

export function FormCalendar({
  name,
  label,
  hintText,
  placeholder,
  minDate,
  mb = '2xl',
  dateFormat,
  disabled = false,
  style,
  applyTimezoneOffset,
  showErrorOnTouch = true,
}: FormCalendarProps) {
  const [field, meta, helpers] = useField(name);
  const { values }: FormikProps<EnrollPatientFormValues> = useFormikContext();

  const fieldHasError: boolean =
    (!!meta.touched && !!meta.error && showErrorOnTouch) || !showErrorOnTouch;

  const offset = new Date().getTimezoneOffset();
  const selectedDate: Date = meta.value
    ? applyTimezoneOffset
      ? offset < 0
        ? dayjs(meta.value).subtract(offset, 'minutes').toDate()
        : dayjs(meta.value).add(offset, 'minutes').toDate()
      : new Date(meta.value)
    : meta.value;

  const fieldIsDisabled = disabled || values.disabledFields?.includes(field.name);
  const displayAsText = values.isEditing === undefined ? false : !values.isEditing;

  const handleChange = (value: Date): void => {
    helpers.setValue(value);
  };

  if (displayAsText) {
    if (applyTimezoneOffset) {
      return (
        <Box>
          {label && <FormLabel fontSize="sm">{label}</FormLabel>}
          <Text mb="3xl">{field.value ? dayjs(field.value).format('MMM D YYYY') : ' '}</Text>
        </Box>
      );
    }
    return (
      <Box>
        {label && <FormLabel fontSize="sm">{label}</FormLabel>}
        <Text mb="3xl">{field.value ? dayjs(field.value).utc().format('MMM D YYYY') : ' '}</Text>
      </Box>
    );
  }

  const getDateValue = (value: string): any => {
    if (applyTimezoneOffset) {
      const dateValue = value ? dayjs(value, 'MM/DD/YYYY').toDate() : new Date(value);
      return dateValue;
    } else {
      const dateValue = value ? new Date(dayjs(value).format('MM/DD/YYYY')) : new Date(value);
      return dateValue;
    }
  };

  return (
    <FormControl style={style} mb={mb} sx={{ position: 'relative' }}>
      {label && (
        <FormLabel fontSize="xs" color={fieldHasError && !!meta.error ? 'red.500' : 'neutral.800'}>
          {label}
        </FormLabel>
      )}
      <DatePicker
        {...field}
        showMonthAndYearDropdowns={true}
        placeholderText={placeholder}
        selected={selectedDate}
        minDate={minDate}
        value={getDateValue(meta.value)}
        onChange={(value: Date) => handleChange(value)}
        dateFormat={dateFormat}
        disabled={fieldIsDisabled}
        extraStyles={{
          '.react-datepicker-wrapper': {
            width: '100%',
          },
          '.react-datepicker__current-month': {
            display: 'none',
          },
          '.react-datepicker__month-dropdown-container--select, .react-datepicker__year-dropdown-container--select':
            {
              fontSize: '16px',
              fontWeight: 'bold',
              color: 'neutral.black',
              marginBottom: '16px',
            },
          '.react-datepicker__navigation-icon': {
            top: '9px',
          },
        }}
      />
      <Box sx={{ position: 'absolute', top: '100%' }}>
        <Text
          fontSize="xs"
          color={fieldHasError ? 'red.500' : 'neutral.800'}
          role={(fieldHasError && 'alert') || undefined}
        >
          {fieldHasError ? meta.error : hintText}
        </Text>
      </Box>
    </FormControl>
  );
}
