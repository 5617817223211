import { Route, useHistory, Switch } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import type { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';

import { AppLayout } from '~src/components/Layout';
import { OpenAPI } from '~generated';
import './pace_theme_flash.css';

import { globalConfig } from '../../configuration/config';
import AuthRoutes from './Routes';

declare global {
  interface Window {
    analytics: any;
  }
}

export function App() {
  const config = globalConfig.get();

  const OktaConfig = {
    clientId: config.clientId,
    issuer: config.issuer,
    redirectUri: config.redirectUri,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  };

  const oktaAuth = new OktaAuth(OktaConfig);

  OpenAPI.TOKEN = async () => {
    return oktaAuth.getAccessToken() ?? '';
  };

  if (config.apiUrl) {
    OpenAPI.BASE = config.apiUrl;
  }

  const history = useHistory();
  const restoreOriginalUri: RestoreOriginalUriFunction = (_oktaAuth, originalUri) => {
    if (originalUri.includes('fast_review')) {
      console.log(originalUri, 'inside fast review');
    } else {
      localStorage.removeItem('rpm-filter-0');
    }
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Toaster />
      <AppLayout>
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <AuthRoutes />
        </Switch>
      </AppLayout>
    </Security>
  );
}
