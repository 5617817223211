import { Flex, Box } from '@rhythm/components';
import { useContext } from 'react';
import { FindAllEscalatedCounts } from '~src/services/alert';
import { MyContext } from '~src/pages/createContext';
import { useHistory } from 'react-router-dom';

export type Reading = 'Blood Pressure' | 'Weight' | 'Blood Sugar' | 'Pulse' | 'PulseOx';

const SideBar = () => {
  const { pathname } = window.location;
  const { setMyState } = useContext(MyContext);
  const { push } = useHistory();
  const { data, isLoading } = FindAllEscalatedCounts();

  const onClickAlert = () => {
    if (pathname === '/patients/fast_review') {
      setMyState(true);
    } else {
      push('/alerts');
    }
  };

  return (
    <Box>
      <Flex
        bg="primary.600"
        borderRightRadius="40px"
        position="sticky"
        alignItems="center"
        p="12px"
        top="3xl"
        justifyContent="center"
        _hover={{
          cursor: 'pointer',
        }}
        onClick={onClickAlert}
      >
        <Flex
          fontWeight="700"
          color="white"
          bg={data && data > 0 ? 'feedback.error' : 'feedback.success'}
          alignItems="center"
          justifyContent="center"
          p="md"
          borderRadius="50%"
          w="24px"
          h="24px"
        >
          {isLoading ? '-' : data ?? 0}
        </Flex>
      </Flex>
    </Box>
  );
};

export default SideBar;
