import dayjs from 'dayjs';
import { SortingRule } from 'react-table';
import { DeviceVendor, Patient } from '~generated';
import { VendorList } from '~src/pages/Patients/EditPatient/EditPatient';
import { FormFilterValues } from '~src/pages/Patients/PatientFilters/Filters';

export { makeOptionsArray, getLanguageOptionsArray } from './makeOptionsArray';
export { phoneRegex, stripCharacters } from './regex';
export { telMask, zipMask } from './masks';
export { createObjectWithOptionalProperties } from './createObjectWithOptionalProperties';

export type { Option } from './makeOptionsArray';

export const globalThresholdDefaults = {
  bpSystolic: [90, 180],
  bpDiastolic: [50, 110],
  glucose: [60, 300],
  pulse: [50, 120],
  weightChangeGeneric: [3, 5],
  bloodOxygen: [88, 92],
};

const startDate = dayjs();

type LocalStorage =
  | {
      [key: string]: number | string | FormFilterValues | [];
    }
  | string
  | number
  | []
  | null;

export type PatientLocalStorage = {
  initial?: number;
  sortBy: string | SortingRule<unknown>[] | undefined | any;
  offset: number;
  pageSize: number;
  search: string;
  totalCount: number;
  filters?: FormFilterValues;
};

export const retrievePersistedData = (key: string): LocalStorage => {
  if (window && localStorage) {
    const persistedValue = localStorage.getItem(key);
    if (persistedValue && persistedValue !== undefined && persistedValue !== 'undefined') {
      return JSON.parse(persistedValue);
    }
  }

  return null;
};

export const savePersistentData = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

/**
 *
 * @param deviceVendors  [{name: 'bodyTrace', url: 'www.bodytrace.com'}]
 * @returns {'www.bodyTrace.com': {name: 'bodyTrace', url: 'www.bodytrave.com'}}
 */
export const generateVendorsParameters = (deviceVendors: DeviceVendor[]): VendorList => {
  return deviceVendors?.reduce((acc: VendorList, current: DeviceVendor) => {
    acc[`${current.url}`] = current;

    return acc;
  }, {} as VendorList);
};

export const isContacted = (data: Patient): boolean => {
  return data.hasMonthlyContact ? data.hasMonthlyContact.hasMonthlyContact : false;
};

export const minutesAcrruedMonth = (patient: Patient): number => {
  return patient?.minutes
    ?.filter((minute) => {
      if (dayjs(minute.createdAt).isSame(startDate, 'month')) return true;
    })
    .reduce((acc, curr) => acc + curr.timeAccrued, 0);
};

export const capitalizeFirstWord = (word: string): string => {
  if (word?.toLowerCase() === 'doctor') return 'Dr';

  return word?.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export function getTimeZoneShortCode(timeZone: string): string {
  interface IDictionary {
    [index: string]: string;
  }
  const timezoneShortCodes = {} as IDictionary;
  timezoneShortCodes['America/New_York'] = 'EST';
  timezoneShortCodes['America/Chicago'] = 'CST';
  timezoneShortCodes['America/Denver'] = 'MST';
  timezoneShortCodes['America/Los_Angeles'] = 'PST';
  timezoneShortCodes['America/Kentucky/Louisville'] = 'EST';
  timezoneShortCodes['America/Indiana/Indianapolis'] = 'EST';
  timezoneShortCodes['America/Detroit'] = 'EST';
  timezoneShortCodes['America/Boise'] = 'MST';
  timezoneShortCodes['America/Phoenix'] = 'MST';
  timezoneShortCodes['America/Anchorage'] = 'AST';
  timezoneShortCodes['Pacific/Honolulu'] = 'HST';
  timezoneShortCodes['America/Indiana/Knox'] = 'CST';
  timezoneShortCodes['America/Indiana/Winamac'] = 'EST';
  timezoneShortCodes['America/Indiana/Vevay'] = 'EST';
  timezoneShortCodes['America/Indiana/Marengo'] = 'EST';
  timezoneShortCodes['America/Indiana/Vincennes'] = 'EST';
  timezoneShortCodes['America/Indiana/Tell_City'] = 'CST';
  timezoneShortCodes['America/Indiana/Petersburg'] = 'EST';
  timezoneShortCodes['America/Menominee'] = 'CST';
  timezoneShortCodes['America/Shiprock'] = 'MST';
  timezoneShortCodes['America/Nome'] = 'AST';
  timezoneShortCodes['America/Juneau'] = 'AST';
  timezoneShortCodes['America/Kentucky/Monticello'] = 'EST';
  timezoneShortCodes['America/North_Dakota/Center'] = 'CST';
  timezoneShortCodes['America/Yakutat'] = 'AST';
  return timezoneShortCodes[timeZone];
}

export const formatDate = (dob: string): string => {
  if (dob) {
    const date = dayjs(dob);
    return date.format('MMMM DD, YYYY');
  } else {
    return '';
  }
};
