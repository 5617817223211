/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateDeviceThresholdDto = {
    sign: CreateDeviceThresholdDto.sign;
    metricType: CreateDeviceThresholdDto.metricType;
    threshHoldUpperLimit: number;
    threshHoldLowerLimit: number;
    unit: number;
    deviceId: number;
    userId: number;
    patientId: number;
    organizationId: number;
};

export namespace CreateDeviceThresholdDto {

    export enum sign {
        GREATER_THAN = 'greater_than',
        LESS_THAN = 'less_than',
        GREATER_THAN_EQUAL = 'greater_than_equal',
        LESS_THAN_EQUAL = 'less_than_equal',
        EQUAL = 'equal',
        RANGE = 'range',
        GREATER_THAN_OR_LESS_THAN = 'greater_than_or_less_than',
    }

    export enum metricType {
        WEIGHT = 'weight',
        BLOOD_PRESSURE = 'blood_pressure',
        BLOOD_PRESSURE_SYSTOLIC = 'blood_pressure_systolic',
        BLOOD_PRESSURE_DIASTOLIC = 'blood_pressure_diastolic',
        BLOOD_SUGAR = 'blood_sugar',
        SEVEN_DAYS_CHANGE = 'seven_days_change',
        PULSE = 'pulse',
        BLOOD_OXYGEN = 'blood_oxygen',
    }


}

