/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Contact } from './Contact';
import type { CreateDeviceThresholdDto } from './CreateDeviceThresholdDto';
import type { Organization } from './Organization';
import type { UserCredential } from './UserCredential';

export type CreateUserDto = {
    firstName: string;
    gender: CreateUserDto.gender;
    status: CreateUserDto.status;
    threshold: Array<CreateDeviceThresholdDto>;
    contacts?: Array<Contact>;
    photoUrl?: string;
    /**
     * Role of User. Required value..
     */
    role: CreateUserDto.role;
    clinics?: Array<Organization>;
    credentials?: Array<UserCredential>;
    lastName: string;
    email: string;
    prefix: string;
    isActive: boolean;
    organizations?: Array<Organization>;
};

export namespace CreateUserDto {

    export enum gender {
        FEMALE = 'female',
        MALE = 'male',
    }

    export enum status {
        PENDING = 'pending',
        REGISTERED = 'registered',
        DECEASED = 'deceased',
        OPTEDIN = 'optedin',
        ENROLLED = 'enrolled',
        OPTEDOUT = 'optedout',
    }

    /**
     * Role of User. Required value..
     */
    export enum role {
        ROLES_INTERNAL_SUPER_ADMIN = 'roles:internal:super-admin',
        ROLES_INTERNAL_ADMIN = 'roles:internal:admin',
        ROLES_INTERNAL_NON_CLINICAL = 'roles:internal:non-clinical',
        ROLES_INTERNAL_CLINICAL = 'roles:internal:clinical',
        ROLES_INTERNAL_LEAD_CLINICIAN = 'roles:internal:lead-clinician',
        ROLES_EXTERNAL_PROVIDER = 'roles:external:provider',
        ROLES_EXTERNAL_NON_PROVIDER = 'roles:external:non-provider',
        ROLES_EXTERNAL_PRACTITIONER = 'roles:external:practitioner',
        ROLES_EXTERNAL_NON_PRACTITIONER = 'roles:external:non-practitioner',
    }


}

