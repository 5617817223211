import { Box, Divider, Flex, Grid, Heading, IconButton } from '@rhythm/components';
import { FieldArray, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { DeviceThreshold, DeviceVendor } from '~generated';
import { FormCheck } from '~src/components/FormCheck';
import { FormField } from '~src/components/FormField';
import { FormSelect } from '~src/components/FormSelect';
import { devicesOptionsArray } from '~src/constants';
import { EnrollPatientFormValues } from '../EnrollPatientForm';
import { DeviceDetailsFieldsEnrollPatientForm } from '../formFields';

type DeviceInformationFormProps = {
  deviceVendors: DeviceVendor[];
};

const DeviceInformationForm: React.FC<DeviceInformationFormProps> = ({
  deviceVendors,
}): ReactElement => {
  const {
    setFieldValue,
    values: { devices, isEditing },
  } = useFormikContext<EnrollPatientFormValues>();

  const formattedVendors = deviceVendors?.map((vendor: DeviceVendor) => {
    return {
      label: vendor.name,
      value: vendor.id,
    };
  });

  return (
    <Box mt="4xl" mb="2xl">
      <FieldArray
        name="devices"
        render={(arrayHelpers) => (
          <>
            {devices.map((device, index) => (
              <Box key={index}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Heading variant="h5" mt="4xl" mb="2xl">
                    Device Information
                  </Heading>
                </Flex>
                <Box style={{ width: '100%' }}>
                  <FormCheck
                    name={`devices.${index}.isActive`}
                    label={DeviceDetailsFieldsEnrollPatientForm.IS_ACTIVE}
                    disabled={!device.id}
                  >
                    Active
                  </FormCheck>
                </Box>
                <Grid templateColumns="repeat(3, 1fr)" gap="5xl" mb="5xl">
                  <Box>
                    <FormSelect
                      name={`devices.${index}.vendorName`}
                      label={DeviceDetailsFieldsEnrollPatientForm.VENDOR_NAME}
                      onNextEvent={(value: string | boolean): void => {
                        const currentVendor: any = deviceVendors?.find(
                          (deviceVendor: DeviceVendor) => deviceVendor.id === value
                        );
                        setFieldValue(`devices.${index}.vendorSite`, currentVendor?.url || '');
                        if (currentVendor?.name == 'tellihealth') {
                          setFieldValue(
                            `devices.${index}.deviceType`,
                            DeviceThreshold.metricType.BLOOD_PRESSURE
                          );
                        }
                      }}
                      options={formattedVendors}
                    />

                    <FormField
                      name={`devices.${index}.vendorSite`}
                      label={DeviceDetailsFieldsEnrollPatientForm.VENDOR_SITE}
                      readOnly
                      isHyperlink
                    />
                  </Box>
                  <Box>
                    <FormSelect
                      name={`devices.${index}.deviceType`}
                      label={DeviceDetailsFieldsEnrollPatientForm.DEVICE_TYPE}
                      options={devicesOptionsArray}
                    />
                  </Box>
                  <Box>
                    <div style={{ display: 'flex' }}>
                      <FormField
                        name={`devices.${index}.deviceSerial`}
                        label={DeviceDetailsFieldsEnrollPatientForm.DEVICE_SERIAL}
                        placeholder="Device Serial"
                        value={devices[index].deviceSerial}
                      />

                      {isEditing && devices.length > 1 && (
                        <IconButton
                          onClick={() => {
                            if (!devices[index]?.id) {
                              arrayHelpers.remove(index);
                            }
                          }}
                          aria-label="delete device"
                          color="primary.600"
                          variant="plain"
                          icon="delete"
                          disabled={!!devices[index]?.id}
                          style={{ minWidth: 100, height: 17, marginTop: 25, background: '#fff' }}
                        />
                      )}
                    </div>
                  </Box>
                </Grid>
                {isEditing && <Divider />}
              </Box>
            ))}
            {isEditing && (
              <Flex
                type="button"
                as="button"
                justifyContent="center"
                alignItems="center"
                w="100%"
                my="4xl"
                color="primary.600"
                fontWeight="700"
                onClick={() =>
                  arrayHelpers.push({
                    vendorName: '',
                    deviceType: '',
                    deviceSerial: '',
                    isActive: true,
                  })
                }
              >
                + Add Additional Device
              </Flex>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default DeviceInformationForm;
