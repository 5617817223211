import { Box, Grid, Heading } from '@rhythm/components';
import React, { ReactElement } from 'react';
import { FormField } from '~src/components/FormField';
import { InsuranceDetailsFieldsEnrollPatientForm } from '../formFields';

const InsuranceDetailsForm: React.FC = (): ReactElement => {
  return (
    <Box mt="4xl" mb="2xl">
      <Grid templateColumns="repeat(3, 1fr)" gap="5xl">
        <Box>
          <Heading variant="h5" mb="2xl">
            Primary Insurance Details
          </Heading>
          <FormField
            name="primaryName"
            label={InsuranceDetailsFieldsEnrollPatientForm.PRIMARY_NAME}
            placeholder="Primary Insurer Name"
          />
          <FormField
            name="primaryMemberId"
            label={InsuranceDetailsFieldsEnrollPatientForm.PRIMARY_ID}
            placeholder="Primary Member ID"
          />
          <FormField
            name="primaryGroupNumber"
            label={InsuranceDetailsFieldsEnrollPatientForm.PRIMARY_GROUP_NUMBER}
            placeholder="Primary Group Number"
          />
          <FormField
            textarea
            name="primaryContanctInformation"
            label={InsuranceDetailsFieldsEnrollPatientForm.PRIMARY_CONTACT_INFORMATION}
            placeholder="Primary Contact Information"
          />
        </Box>
        <Box>
          <Heading variant="h5" mb="2xl">
            Secondary Insurance Details
          </Heading>
          <FormField
            name="secondaryName"
            label={InsuranceDetailsFieldsEnrollPatientForm.SECONDARY_NAME}
            placeholder="Secondary Insurer Name"
          />
          <FormField
            name="secondaryMemberId"
            label={InsuranceDetailsFieldsEnrollPatientForm.SECONDARY_ID}
            placeholder="SecondaryMember ID"
          />
          <FormField
            name="secondaryGroupNumber"
            label={InsuranceDetailsFieldsEnrollPatientForm.SECONDARY_GROUP_NUMBER}
            placeholder="Secondary Group Number"
          />
          <FormField
            textarea
            name="secondaryContanctInformation"
            label={InsuranceDetailsFieldsEnrollPatientForm.SECONDARY_CONTACT_INFORMATION}
            placeholder="Secondary Contact Information"
          />
        </Box>
        <Box>
          <Heading variant="h5" mb="2xl">
            Copay
          </Heading>
          <FormField
            name="copayAmount"
            label={InsuranceDetailsFieldsEnrollPatientForm.COPAY}
            placeholder="Copay amount"
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default InsuranceDetailsForm;
