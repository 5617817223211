import { useOktaAuth } from '@okta/okta-react';
import React, { Fragment } from 'react';
import { ROLE_TYPES } from '~src/types';

/**
 *
 * @param {Object} Props.children - children to always render if user has access
 * @return {React.FC} or null
 */

interface Props {
  allowedRoles?: ROLE_TYPES[] | undefined;
  children: React.ReactNode;
  overrideCondition?: boolean;
  internalUsersOnly?: boolean;
}

const useCheckRoles = (
  allowedRoles: ROLE_TYPES[] | undefined,
  internalUsersOnly: boolean | undefined
) => {
  const oktaAuth = useOktaAuth();
  const userRoles = (oktaAuth.authState?.accessToken?.claims?.roles as string[]) || [];

  const isExternal = internalUsersOnly && userRoles.some((role) => role.includes('external'));
  const isAllowed =
    !allowedRoles || userRoles.some((role) => allowedRoles.includes(role as ROLE_TYPES));

  return { isExternal, isAllowed };
};

const RoleBasedRoutes: React.FC<Props> = (props) => {
  const { allowedRoles, internalUsersOnly, children, overrideCondition } = props;
  const { isExternal, isAllowed } = useCheckRoles(allowedRoles, internalUsersOnly);
  if (isExternal) return null;
  if (isAllowed || overrideCondition) return <Fragment>{children}</Fragment>;
  return null;
};

export default React.memo(RoleBasedRoutes);
