import { FormOption } from '~src/components/FormSelect/FormSelect';
import { makeOptionsArray } from '~src/utils';

export const archivingReasons = {
  NON_ADHERENCE_UNREACHABLE: 'Non-Adherence: Unreachable',
  NON_ADHERENCE_NON_COMPLIANCE: 'Non-Adherence: Non-compliance (minimal transmission protocols)',
  PAYER_RPM_NOT_COVERED_BY_INSURANCE: 'Payer: RPM not covered by insurance',
  PAYER_COVERAGE_CHANGES_RPM_NOT_COVERED: 'Payer: Coverage changes - RPM not covered',
  PAYER_PATIENT_UNABLE_TO_PAY_COPAY: 'Payer: Patient unable to pay copay',
  LIFESTYLE_TIME_CONSTRAINTS: 'Lifestyle: Time constraints',
  LIFESTYLE_POOR_HOME_LOCATION_SIGNAL: 'Lifestyle: Poor home location signal',
  VOLUNTARY_FREQUENCY_OF_CALLS_READINGS_OVERWHELMING:
    'Voluntary: Frequency of calls/readings overwhelming',
  VOLUNTARY_LIMITED_CLINIC_INVOLVEMENT: 'Voluntary: Limited clinic involvement',
  VOLUNTARY_PATIENT_DENIES_HEALTH_ISSUES: 'Voluntary: Patient denies health issues',
  VOLUNTARY_PATIENT_DISPUTES_DEVICE_READINGS: 'Voluntary: Patient disputes device readings',
  INVOLUNTARY_PATIENT_TRANSFERRED_TO_LONG_TERM_CARE_OR_HOSPICE:
    'InVoluntary: Patient transferred to long-term care or hospice',
  INVOLUNTARY_PATIENT_DECEASED: 'InVoluntary: Patient deceased',
  INVOLUNTARY_PATIENT_ENROLLED_IN_ANOTHER_PROGRAM:
    'InVoluntary: Patient enrolled in another program',
  INVOLUNTARY_TECHNICAL_ISSUES_NOT_RESOLVED: 'InVoluntary: Technical issues not resolved',
  INVOLUNTARY_PATIENT_MOVED: 'InVoluntary: Patient moved',
  INVOLUNTARY_PATIENT_CHANGED_PHYSICIANS: 'InVoluntary: Patient changed physicians',
  INVOLUNTARY_RPM_ACCOUNT_CANCELLATION: 'InVoluntary: RPM account cancellation',
  INVOLUNTARY_PATIENT_REQUIRES_PHYSICAL_ASSISTANCE_TO_USE_DEVICE:
    'InVoluntary: Patient requires physical assistance to use device',
};

export const optOutReasons = {
  FINANCIAL_BURDEN: 'Financial Burden',
  NOT_NECESSARY: 'Remote Monitoring No Longer Necessary',
  OTHER: 'Other',
};

export const archivingReasonOptions: FormOption[] = makeOptionsArray(archivingReasons);
export const optOutReasonOptions: FormOption[] = makeOptionsArray(optOutReasons);
