import { Box, Select, FormLabel, Text, FormControl } from '@rhythm/components';
import { useField, useFormikContext, FormikProps } from 'formik';
import { components, MultiValueRemoveProps } from 'react-select';
import { EnrollPatientFormValues } from '../EnrollPatientForm/EnrollPatientForm';
import { CrossCircle } from '../Icons/CrossCircle';

export interface FormOption {
  value: string | boolean;
  label: string;
  disabled?: boolean;
}

export interface FormSelectProps {
  label: string;
  options: FormOption[];
  name: string;
  hintText?: string;
  style?: React.CSSProperties | undefined;
  formStyle?: React.CSSProperties | undefined;
  multi?: boolean;
  onNextEvent?: (value: string | boolean) => void;
  disabled?: boolean;
  onInputChange?: (value: string | boolean) => void;
  clearable?: boolean;
  placeholder?: string;
  optionsDisabled?: (option: FormOption) => boolean | undefined;
  isClinician?: boolean;
  clinicianName?: (value: string) => void;
  creatable?: boolean;
}

export function MultiValueRemove(props: Readonly<MultiValueRemoveProps>) {
  return (
    <components.MultiValueRemove {...props}>
      <CrossCircle dimension="16" />
    </components.MultiValueRemove>
  );
}

export function FormSelect(props: FormSelectProps) {
  const {
    label,
    options,
    onNextEvent,
    hintText = '',
    multi = false,
    style = {},
    formStyle = {},
    onInputChange,
    disabled = false,
    clearable = false,
    placeholder = 'Select...',
    optionsDisabled,
    clinicianName,
    isClinician,
    creatable = false,
  } = props;
  const [field, meta, helpers] = useField(props);
  const fieldHasError: boolean = !!meta.touched && !!meta.error;
  const { values }: FormikProps<EnrollPatientFormValues> = useFormikContext();

  const handleChange = (option: FormOption | FormOption[]): void => {
    if (option instanceof Array) {
      if (multi) {
        helpers.setValue(option);
      } else {
        const optionValues = option.map(({ value }) => value);
        helpers.setValue(optionValues);
      }
    }
    if (!option) {
      onInputChange?.('');
      helpers.setValue('');
    }

    if (!multi) {
      onInputChange?.((option as FormOption).value);
      onNextEvent?.((option as FormOption).value);
      if (isClinician) {
        const ClinicianName = (option as FormOption).label.split('Patients -');
        clinicianName?.(ClinicianName[1]);
        return;
      }
      helpers.setValue((option as FormOption)?.value ? (option as FormOption).value : undefined);
    }
  };

  const displayAsText = values.isEditing === undefined ? false : !values.isEditing;

  if (displayAsText) {
    if (!multi) {
      const optionText = options?.find((option) => option.value === field.value);
      return (
        <Box>
          {label && <FormLabel fontSize="sm">{label}</FormLabel>}
          <Text mb="3xl">{optionText?.label}</Text>
        </Box>
      );
    } else {
      return (
        <Box>
          {label && <FormLabel fontSize="sm">{label}</FormLabel>}
          <Text>{field?.value?.map((option) => option.label).join(', ')}</Text>
        </Box>
      );
    }
  }

  return (
    <FormControl isInvalid={fieldHasError} mb="2xl" style={formStyle} sx={{ position: 'relative' }}>
      <FormLabel fontSize="xs">{label}</FormLabel>
      <Box style={style}>
        <Select
          styles={{
            multiValue: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: '#ECF7FE',
              borderColor: '#1083CB',
              borderWidth: '2px',
              borderRadius: '6px',
            }),
            multiValueLabel: (baseStyles) => ({
              ...baseStyles,
              fontWeight: '600',
              fontSize: '12px',
              padding: '4px 0px 4px 6px',
            }),
            multiValueRemove: (baseStyles) => ({
              ...baseStyles,
              paddingRight: '6px',
              ':hover': {
                backgroundColor: '#ECF7FE',
              },
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              zIndex: 3,
            }),
          }}
          components={{ MultiValueRemove }}
          options={options}
          {...field}
          isMulti={multi}
          closeMenuOnSelect={!multi}
          isDisabled={disabled}
          isClearable={clearable}
          placeholder={placeholder}
          isInvalid={fieldHasError}
          value={
            typeof field.value === 'string'
              ? options?.find((option) => option.value === field.value)
              : field.value
          }
          onChange={handleChange as any}
          isOptionDisabled={optionsDisabled as any}
          isCreatable={creatable}
        />
      </Box>
      <Box sx={{ position: 'absolute', top: '100%' }}>
        <Text
          fontSize="xs"
          color={fieldHasError ? 'red.500' : 'neutral.800'}
          role={(fieldHasError && 'alert') || undefined}
        >
          {fieldHasError ? meta.error : hintText}
        </Text>
      </Box>
    </FormControl>
  );
}
