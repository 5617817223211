import { useState } from 'react';
import { Box, Button, Spinner } from '@rhythm/components';
import { useHistory, useParams } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { DeviceDetailsForm, ICDCodesDataForm } from '~src/components/EnrollPatientForm/formFields';
import {
  Address,
  Contact,
  CreateDeviceThresholdDto,
  User,
  Device,
  DeviceThreshold,
  Insurance,
  Organization,
  Patient,
  PatientContact,
  DeviceVendor,
} from '~generated';
import { useSinglePatientDetail, useUpdatePatient } from '~src/services/patients';
import { EnrollPatientFormValues } from '~src/components/EnrollPatientForm/EnrollPatientForm';
import { EnrollPatientForm } from '~src/components/EnrollPatientForm';
import {
  createObjectWithOptionalProperties,
  generateVendorsParameters,
  stripCharacters,
} from '~src/utils';
import { useListDeviceVendors } from '~src/services/clinics';
import { ArchivePatientModal } from './ArchivePatientModal';
import dayjs from 'dayjs';
import { PatientICDCode } from '~generated/models/PatientICDCode';
import { errorhandling, validation } from '../EnrollPatient/EnrollPatient';

interface Params {
  id: string;
}

interface MergedContact {
  email: string;
  phone: string;
  fax?: string;
  firstName: string;
  lastName: string;
  relation: string | null;
  isPreferred: boolean;
  disclosePHI: boolean;
}

export interface VendorList {
  [key: string]: DeviceVendor;
}

function makeInitialValues(
  patient: Patient,
  deviceVendors: DeviceVendor[]
): EnrollPatientFormValues {
  const { address, contacts, insurance, deviceThresholds, thresholds, mobileNumber, isActive } =
    patient;
  const dataThreshold = deviceThresholds?.length > 0 ? deviceThresholds : thresholds;

  const primaryInsurance = insurance.find((ins) => ins.isPrimary);
  const secondaryInsurance = insurance.find((ins) => !ins.isPrimary);

  const alternateContacts = contacts.filter(({ isAlternate }) => isAlternate);
  const patientContacts = contacts
    .filter(({ isAlternate }) => !isAlternate)
    .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());

  const patientGroups =
    patient?.groups?.map((group) => ({
      label: group.name,
      value: group.id,
    })) || [];

  const threshold = dataThreshold
    ?.map((threshold) => ({
      [threshold.metricType]: threshold,
    }))
    .reduce((accum, val) => {
      Object.assign(accum, val);
      return accum;
    }, {});

  const mergeContactInfo = (contacts: PatientContact[]): MergedContact => {
    return contacts.reduce(
      (obj, contact) => (
        (obj[contact.type] = contact.contact),
        (obj.firstName = contact.name.split(' ')[0]),
        (obj.lastName = contact.name.split(' ')[1]),
        (obj.relation = contact.relation),
        (obj.isPreferred = contact.isPreferred),
        (obj.disclosePHI = contact.disclosePHI),
        obj
      ),
      {} as MergedContact
    );
  };

  const altContact = mergeContactInfo(alternateContacts);
  const patientContact = mergeContactInfo(patientContacts);

  const parseDevice = generateVendorsParameters(deviceVendors ?? []);

  const patientReadmission = patient?.readmissions?.[0];

  const devices = patient.devices?.map((device) => ({
    deviceSerial: device.serialNumber,
    deviceType: device.type,
    id: device.id,
    vendorId: parseDevice[device?.vendor?.url]?.id,
    vendorName: parseDevice[device?.vendor?.url]?.id,
    vendorSite: device?.vendor?.url,
    isActive: device.isActive,
  }));

  const icdcodes = patient.icdcodes?.map((icdcode) => ({
    id: icdcode.id,
    diagnosis: icdcode.diagnosis,
    icd10Code: icdcode.icd10Code,
  }));

  const clinicians = patient.clinicians;

  return {
    rhythmId: '',
    mrn2: patient?.mrn,
    enrollment: dayjs(patient?.createdAt).format('MM/DD/YYYY'),
    firstName: patient?.firstName,
    lastName: patient?.lastName,
    dateOfBirth: patient?.birthDate,
    age: '',
    gender: patient.gender,
    addressLine1: address?.line1 || '',
    addressLine2: address?.line2 || '',
    city: address?.city || '',
    zipcode: address?.postalCode || '',
    state: address?.state || '',
    country: address?.country || '',
    timeZone: address?.timeZone || '',
    landline: patientContact?.phone,
    mobileNumber: mobileNumber,
    preferredNumber: '',
    email: patientContact?.email,
    devices,
    icdcodes,
    bpDiastolic: [
      threshold?.blood_pressure_diastolic?.threshHoldLowerLimit || 0,
      threshold?.blood_pressure_diastolic?.threshHoldUpperLimit || 100,
    ],
    bpSystolic: [
      threshold?.blood_pressure_systolic?.threshHoldLowerLimit || 0,
      threshold?.blood_pressure_systolic?.threshHoldUpperLimit || 100,
    ],
    pulse: [
      threshold?.pulse?.threshHoldLowerLimit || 0,
      threshold?.pulse?.threshHoldUpperLimit || 100,
    ],
    glucose: [
      threshold?.blood_sugar?.threshHoldLowerLimit || 0,
      threshold?.blood_sugar?.threshHoldUpperLimit || 100,
    ],
    bloodOxygen: [
      threshold?.blood_oxygen?.threshHoldLowerLimit || 88,
      threshold?.blood_oxygen?.threshHoldUpperLimit || 92,
    ],
    weightChange24h: threshold?.weight?.unit || 0,
    weightChange7Day: threshold?.seven_days_change?.unit || 0,
    altFirstName: altContact?.firstName,
    altLastName: altContact?.lastName,
    altPhoneNumber: altContact?.phone,
    altEmail: altContact?.email,
    altRelation: altContact?.relation || '',
    isPreferredContact: altContact?.isPreferred || false,
    disclosePHI: altContact?.disclosePHI || false,
    patientHistory: null,
    faceSheet: null,
    status: '',
    isActive: isActive,
    primaryName: primaryInsurance?.name,
    primaryMemberId: primaryInsurance?.memberId,
    primaryGroupNumber: primaryInsurance?.groupName,
    primaryContanctInformation: primaryInsurance?.contactInformation,
    secondaryName: secondaryInsurance?.name,
    secondaryMemberId: secondaryInsurance?.memberId,
    secondaryGroupNumber: secondaryInsurance?.groupName,
    secondaryContanctInformation: secondaryInsurance?.contactInformation,
    copayAmount: primaryInsurance?.copay ?? undefined,
    requestingPhysician: patient?.physician?.id,
    requestingClinician: patient?.clinician?.id,
    isOnboardingAssignment: patient?.onBoardingClosed == true ? 'Closed' : patient?.onboarding?.id,
    isConsentsToEnrollment: patient?.isConsentsToEnrollment?.toString(),
    // disabledFields: ['firstName', 'lastName', 'dateOfBirth'],
    disabledFields: [''],
    isEditing: false,
    language: patient?.language,
    team: patient?.team,
    clinicians,
    groupIds: patientGroups as any,
    firstDischargeDate: patient?.firstDischargeDate,
    firstReadmissionDate: patientReadmission?.admissionDate,
    reasonForReadmission: patientReadmission?.admissionReason,
  };
}

export function EditPatient() {
  const history = useHistory();
  const [showArchivePatientModal, setShowArchivePatientModal] = useState(false);
  const { id } = useParams<Params>();
  const { isLoading, isError, data } = useSinglePatientDetail(id);
  const updatePatient = useUpdatePatient();
  const { data: deviceVendors, isLoading: deviceLoading } = useListDeviceVendors({});

  if (isLoading || isError || !deviceVendors || deviceLoading) {
    return <Spinner />;
  }

  const patient = data!;
  const initialValues: EnrollPatientFormValues = makeInitialValues(patient, deviceVendors);

  const formatPatientClinicians = (clinicians: User[]) => {
    return clinicians.map((clinician) => {
      if (clinician.id) {
        return {
          id: clinician.id,
        };
      }
    });
  };

  const formatUnassignedClinicians = (clinicians: User[]) => {
    return clinicians.map((clinician) => {
      if (clinician.id) {
        return clinician.id;
      }
    });
  };

  const getPatientContactId = (
    isAlternate = false,
    contactType: PatientContact.type = PatientContact.type.PHONE
  ): string | undefined => {
    const patientContacts = patient.contacts
      .filter((contact) => contact.isAlternate === isAlternate && contact.type === contactType)
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    return patientContacts && patientContacts[0] ? patientContacts[0].id : undefined;
  };

  const handleSubmit = async (
    values: EnrollPatientFormValues,
    { setErrors }: { setErrors: (errors: any) => void }
  ): Promise<void> => {
    // TODO: These will need to be chosen dynamically
    for (const key in values) {
      if (typeof values[key] === 'string') {
        values[key] = values[key].trim();
      }
    }
    const oldData = initialValues;
    function areValuesEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }

      return true;
    }

    function areThresholdMetricsEqual(obj1, obj2) {
      const metricsToCheck = ['glucose', 'pulse', 'bpDiastolic', 'bpSystolic', 'bloodOxygen'];

      for (const metric of metricsToCheck) {
        if (!areValuesEqual(obj1[metric], obj2[metric])) {
          return false;
        }
      }

      return true;
    }

    const thresholdsMetricsAreEqual = areThresholdMetricsEqual(oldData, values);
    const weightThreshholdsAreEqual =
      oldData.weightChange7Day == values.weightChange7Day &&
      oldData.weightChange24h == values.weightChange24h;

    const organizationIds = ['2'];
    const clinicIds = ['2'] as unknown as Organization[];

    const parseDevice = generateVendorsParameters(deviceVendors || []);

    const insurance: Insurance[] = [];
    const physician = {} as User;
    const clinician = {} as User;
    const onboarding = {} as any;
    const devices = [] as Device[];
    if (values?.devices && values?.devices?.length > 0 && values?.devices[0].vendorName) {
      devices.push(
        ...values?.devices
          ?.filter(
            (item: DeviceDetailsForm) => item.deviceSerial && item.deviceType && item.vendorName
          )
          ?.map((device: DeviceDetailsForm) => {
            return {
              serialNumber: device.deviceSerial,
              type: device.deviceType,
              id: device.id as unknown,
              isActive: device.isActive,
              vendor: {
                ...parseDevice[device.vendorSite],
              },
            } as unknown as Device;
          })
      );
    }
    let icdcodes = [] as PatientICDCode[];
    if (values?.icdcodes && values?.icdcodes?.length > 0) {
      icdcodes = values?.icdcodes?.map((icdcode: ICDCodesDataForm) => {
        return {
          id: icdcode.id as unknown,
          diagnosis: icdcode.diagnosis,
          icd10Code: icdcode.icd10Code,
        } as unknown as PatientICDCode;
      });
    }

    if (values?.requestingPhysician) {
      physician.id = `${values?.requestingPhysician}`;
    }

    if (values?.requestingClinician) {
      clinician.id = `${values?.requestingClinician}`;
    }

    if (values?.isOnboardingAssignment === 'Closed') {
      onboarding.id = null;
    } else {
      onboarding.id = `${values?.isOnboardingAssignment}`;
    }

    if (
      values?.primaryName ||
      values?.primaryMemberId ||
      values?.primaryGroupNumber ||
      values?.primaryContanctInformation ||
      values?.copayAmount
    ) {
      insurance.push({
        name: values?.primaryName,
        copay: values?.copayAmount,
        isPrimary: true,
        groupName: values?.primaryGroupNumber,
        memberId: values?.primaryMemberId,
        contactInformation: values?.primaryContanctInformation,
      } as unknown as Insurance);
    }

    if (
      values?.secondaryName ||
      values?.secondaryMemberId ||
      values?.secondaryGroupNumber ||
      values?.secondaryContanctInformation
    ) {
      insurance.push({
        name: values?.secondaryName,
        copay: values?.copayAmount,
        isPrimary: false,
        groupName: values?.secondaryGroupNumber,
        memberId: values?.secondaryMemberId,
        contactInformation: values?.secondaryContanctInformation,
      } as unknown as Insurance);
    }

    const contacts: any[] = [];
    /**Contacts */

    if (values.altFirstName && values?.altPhoneNumber) {
      const patientContactBaseObject = {
        isAlternate: true,
        name: `${values.altFirstName} ${values.altLastName}`,
        contact: stripCharacters(values.altPhoneNumber),
        isPreferred: values?.isPreferredContact,
        disclosePHI: values?.disclosePHI,
        relation: values?.altRelation,
        type: Contact.type.PHONE,
      };

      const patientContactOptionalProperties = {
        id: getPatientContactId(true),
      };

      const patientContact = createObjectWithOptionalProperties(
        patientContactBaseObject,
        patientContactOptionalProperties
      ) as PatientContact;

      contacts.push(patientContact);
    }

    if (values.altFirstName && values?.altEmail) {
      const patientContactBaseObject = {
        isAlternate: true,
        name: `${values.altFirstName} ${values.altLastName}`,
        isPreferred: values?.isPreferredContact,
        disclosePHI: values?.disclosePHI,
        contact: values.altEmail,
        relation: values?.altRelation,
        type: Contact.type.EMAIL,
      };

      const patientContactOptionalProperties = {
        id: getPatientContactId(true, PatientContact.type.EMAIL),
      };

      const patientContact = createObjectWithOptionalProperties(
        patientContactBaseObject,
        patientContactOptionalProperties
      ) as PatientContact;

      contacts.push(patientContact);
    }

    if (values?.landline) {
      const patientContactBaseObject = {
        isAlternate: false,
        name: `${values.firstName} ${values.lastName}`,
        contact: stripCharacters(values.landline),
        isPreferred: values?.preferredNumber == 'landline',
        type: Contact.type.PHONE,
      };

      const patientContactOptionalProperties = {
        id: getPatientContactId(false, PatientContact.type.PHONE),
      };

      const patientContact = createObjectWithOptionalProperties(
        patientContactBaseObject,
        patientContactOptionalProperties
      ) as PatientContact;

      contacts.push(patientContact);
    }

    if (values?.email) {
      const patientContactBaseObject = {
        isAlternate: false,
        name: `${values.firstName} ${values.lastName}`,
        contact: values.email,
        type: Contact.type.EMAIL,
      };

      const patientContactOptionalProperties = {
        id: getPatientContactId(false, PatientContact.type.EMAIL),
      };

      const patientContact = createObjectWithOptionalProperties(
        patientContactBaseObject,
        patientContactOptionalProperties
      ) as PatientContact;

      contacts.push(patientContact);
    }

    /** Add address */
    const address = {
      line1: values?.addressLine1,
      line2: values?.addressLine2,
      state: values?.state,
      postalCode: values?.zipcode,
      country: values?.country,
      timeZone: values?.timeZone,
      city: values?.city,
    } as Address;

    const thresholdValues: CreateDeviceThresholdDto[] = [];

    const bloodPressureDiastolic = {
      threshHoldUpperLimit: values.bpDiastolic[1],
      threshHoldLowerLimit: values.bpDiastolic[0],
      metricType: DeviceThreshold.metricType.BLOOD_PRESSURE_DIASTOLIC,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const bloodPressureSystolic = {
      threshHoldUpperLimit: values.bpSystolic[1],
      threshHoldLowerLimit: values.bpSystolic[0],
      metricType: DeviceThreshold.metricType.BLOOD_PRESSURE_SYSTOLIC,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const glucose = {
      threshHoldUpperLimit: values.glucose[1],
      threshHoldLowerLimit: values.glucose[0],
      metricType: DeviceThreshold.metricType.BLOOD_SUGAR,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const bloodOxygen = {
      threshHoldUpperLimit: values.bloodOxygen[1],
      threshHoldLowerLimit: values.bloodOxygen[0],
      metricType: DeviceThreshold.metricType.BLOOD_OXYGEN,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    const weightChange = {
      threshHoldUpperLimit: values.weightChange24h,
      threshHoldLowerLimit: values.weightChange24h,
      unit: values.weightChange24h,
      metricType: DeviceThreshold.metricType.WEIGHT,
      sign: DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN,
    } as CreateDeviceThresholdDto;

    const weightChange7days = {
      threshHoldUpperLimit: values.weightChange7Day,
      threshHoldLowerLimit: values.weightChange7Day,
      unit: values.weightChange7Day,
      metricType: DeviceThreshold.metricType.SEVEN_DAYS_CHANGE,
      sign: DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN,
    } as CreateDeviceThresholdDto;

    const pulse = {
      threshHoldUpperLimit: values.pulse[1],
      threshHoldLowerLimit: values.pulse[0],
      metricType: DeviceThreshold.metricType.PULSE,
      sign: DeviceThreshold.sign.RANGE,
    } as CreateDeviceThresholdDto;

    if (!weightThreshholdsAreEqual || !thresholdsMetricsAreEqual) {
      thresholdValues.push(
        bloodPressureDiastolic,
        bloodPressureSystolic,
        glucose,
        bloodOxygen,
        weightChange,
        pulse,
        weightChange7days
      );
    }

    const formatedInitialClinicians = formatUnassignedClinicians(initialValues.clinicians);
    const formatedValuesClinicians = formatUnassignedClinicians(values.clinicians);
    const unassignedClinicians = formatedInitialClinicians.filter(
      (clinicianId: any) => !formatedValuesClinicians.includes(clinicianId)
    );

    const birthDate = values.dateOfBirth;
    const patientData = {
      id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      gender: values.gender === Patient.gender.MALE ? Patient.gender.MALE : Patient.gender.FEMALE,
      prefix: values.gender === 'male' ? 'Mr.' : 'Ms.',
      devices,
      icdcodes,
      mrn: values?.mrn2,
      mobileNumber: values?.mobileNumber,
      deviceThresholds: thresholdValues,
      organizationIds,
      birthDate,
      clinicIds,
      physician,
      clinician,
      onboarding,
      isConsentsToEnrollment: values?.isConsentsToEnrollment == 'true',
      onBoardingClosed: values?.isOnboardingAssignment == 'Closed',
      contacts,
      address,
      insurance,
      language: values.language,
      team: values.team,
      clinicians: formatPatientClinicians(values.clinicians),
      unassignedClinicians: unassignedClinicians,
      isPhysicianDefaults: values.isPhysicianDefaults,
      groupIds: values.groupIds?.map((item: any) => item.value) ?? [],
      firstDischargeDate: values.firstDischargeDate,
      firstReadmissionDate: values.firstReadmissionDate,
      reasonForReadmission: values.reasonForReadmission,
    } as unknown as Patient;

    try {
      await updatePatient.mutateAsync(
        { ...patientData },
        {
          onError: async (error: any) => {
            const errorMessage = JSON.parse(await error?.body?.message);
            errorhandling(errorMessage, values, setErrors);
          },
        }
      );
    } catch (err) {
      throw new Error(err as string);
    }
  };

  interface Errors {
    [key: string]: string | { id: string }[];
  }

  const validationFunction = (values: EnrollPatientFormValues): Errors => {
    const errors: Errors = {};

    validation(values, errors);
    // This is going to be changed later once we have feedback from users what all validation required

    // const devices = [] as any;
    // if (values?.devices?.length && values?.devices[0].vendorName?.length) {
    //   values?.devices?.forEach((device: DeviceDetailsForm) => {
    //     const deviceSerialValue = device?.deviceSerial.replace(/ /g, '');
    //     if (
    //       (device?.deviceType === 'blood_sugar' && deviceSerialValue.length !== 7) ||
    //       (['blood_pressure', 'weight', 'blood_oxygen'].includes(device?.deviceType as string) &&
    //         deviceSerialValue.length !== 15)
    //     ) {
    //       devices.push({
    //         deviceSerial:
    //           deviceSerialValue.length === 0 ? 'This field is required' : 'Invalid Device ID',
    //       });
    //       errors.devices = devices;
    //     } else {
    //       devices.push({
    //         deviceSerial: '',
    //       });
    //     }
    //     return {} as unknown as Device;
    //   });
    // }
    if (values.altFirstName && !values.altPhoneNumber) {
      errors.altPhoneNumber = 'This field is required';
    }
    if (values.altPhoneNumber && !values.altFirstName) {
      errors.altFirstName = 'This field is required';
    }
    if (values.altFirstName && !values.altRelation) {
      errors.altRelation = 'This field is required';
    }
    if (!values.state?.length) {
      errors.state = 'This field is required';
    }
    return errors;
  };

  const handleCloseModal = () => {
    setShowArchivePatientModal(false);
  };

  const handleArchivePatient = async (): Promise<void> => {
    setShowArchivePatientModal(true);
  };

  return (
    <Box>
      <Button
        style={{
          width: '100px',
          height: '8px',
          color: '#6C7789',
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          marginLeft: '10px',
        }}
        onClick={() => history.goBack()}
        leftIcon={'arrow-left'}
        mb="md"
      >
        Back to Chart
      </Button>
      <Toaster />
      <ArchivePatientModal
        isOpen={showArchivePatientModal}
        onClose={handleCloseModal}
        patient={patient}
      />
      <EnrollPatientForm
        initialValues={initialValues}
        pageHeader="Edit Patient"
        handleSubmit={handleSubmit}
        validationFunction={validationFunction}
        allowToggleEdit
        primaryCTAText="Confirm Changes"
        handleArchivePatient={handleArchivePatient}
        patient={patient}
        isLoading={updatePatient.isLoading}
      />
    </Box>
  );
}
