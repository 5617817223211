/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Roles {
    ROLES_INTERNAL_SUPER_ADMIN = 'roles:internal:super-admin',
    ROLES_INTERNAL_ADMIN = 'roles:internal:admin',
    ROLES_INTERNAL_NON_CLINICAL = 'roles:internal:non-clinical',
    ROLES_INTERNAL_CLINICAL = 'roles:internal:clinical',
    ROLES_INTERNAL_LEAD_CLINICIAN = 'roles:internal:lead-clinician',
    ROLES_EXTERNAL_PROVIDER = 'roles:external:provider',
    ROLES_EXTERNAL_NON_PROVIDER = 'roles:external:non-provider',
    ROLES_EXTERNAL_PRACTITIONER = 'roles:external:practitioner',
    ROLES_EXTERNAL_NON_PRACTITIONER = 'roles:external:non-practitioner',
}
