/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Organization } from './Organization';

export type OrganizationContact = {
    organization: Organization;
    contact: string;
    name: string;
    type: OrganizationContact.type;
    isAlternate: boolean;
    disclosePHI: boolean;
    disclosePHIUpdatedBy: string;
    disclosePHIUpdatedAt: string;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace OrganizationContact {

    export enum type {
        EMAIL = 'email',
        PHONE = 'phone',
        FAX = 'fax',
    }


}

