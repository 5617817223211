import { Box, Flex, Text } from '@rhythm/components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import './style.css';
dayjs.extend(relativeTime);

export const PatientBloodPressureFastReviewReading = (props: { measurements: any }) => {
  const { measurements } = props;
  if (!measurements?.reading?.length) {
    return <Flex direction="column"></Flex>;
  } else {
    return (
      <>
        <Box className="fast-review">
          {measurements?.reading &&
            measurements?.reading.map((measurement: any, index: any) => (
              <Flex
                direction="column"
                key={index}
                className="fast-review-card"
                marginBottom={index < (measurements?.reading?.length ?? 0) - 1 ? '10px' : '0px'}
              >
                <Box mb="xs" alignItems="center" display={'inline-flex'}>
                  <Text mb="xs" fontWeight="bold" color="#0E6DAD">
                    {measurement?.systolic || '-'}
                  </Text>
                  <Text mb="xs" color="#0E6DAD">
                    /
                  </Text>
                  <Text mb="xs" fontWeight="bold" color="#0E6DAD">
                    {measurement?.diastolic || '-'}
                  </Text>
                  <span>&nbsp;</span>
                  <Text mb="xs" color="#0E6DAD" fontWeight="bold">
                    {'mmHg' || '-'}
                  </Text>
                </Box>
                <Text mb="xs" color="#1083CB" marginTop={'-2px'}>
                  {measurement?.createdAt && dayjs(measurement?.createdAt).fromNow()}
                </Text>
              </Flex>
            ))}
        </Box>
      </>
    );
  }
};
