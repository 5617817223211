import { makeOptionsArray } from '~src/utils';
import type { Option } from '~src/utils';
export const credentials = {
  BSN: 'BSN',
  DNP: 'DNP',
  DO: 'DO',
  EMS: 'EMS',
  LPN: 'LPN',
  MA: 'MA',
  MD: 'MD',
  NP: 'NP',
  PA: 'PA',
  RN: 'RN',
};
export const credentialOptions: Option[] = makeOptionsArray(credentials);
export const getUserCredential = (credential: string | undefined): string => {
  if (!credential || credential === 'BLANK') return '';
  const correctCredential = credentials[credential as keyof typeof credentials];
  return correctCredential ? correctCredential : credential;
};
