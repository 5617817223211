import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useEffect, useState } from 'react';

interface Timer {
  elapsedTime: number;
  formattedTime: string;
}

export const useTimer = (): Timer => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [formattedTime, setFormattedTime] = useState('00:00');
  useEffect(() => {
    const timer = setTimeout(() => {
      dayjs.extend(duration);
      const minutes = Math.floor(elapsedTime / 60);
      const seconds = elapsedTime - minutes * 60;
      const timeString = dayjs.duration({ seconds, minutes }).format('mm:ss');
      setFormattedTime(timeString);
      setElapsedTime((prevState) => prevState + 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [elapsedTime]);

  return { elapsedTime, formattedTime };
};
