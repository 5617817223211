import { Box, Column, DataTable, Flex, Icon, Text } from '@rhythm/components';
import orderBy from 'lodash/orderBy';
import { Cell } from 'react-table';
import {
  BloodPressure,
  BloodSugar,
  Device,
  DeviceThreshold,
  Patient,
  Pulse,
  PulseOx,
} from '~generated';
import {
  BloodPressureData,
  BloodSugarCell,
  BpPulseCell,
  BpPulseCellProps,
  BloodOxygenCell,
  DateCell,
  DateCellBp,
  DateRange,
  PulseCell,
  PulseCellProps,
  PulseOxProps,
  ShortDateCell,
  SystolicDiastolicCell,
  SystolicDiastolicCellProps,
  WEIGHT,
  WeightCell,
  WeightChange,
  WW,
  PulseCellPulseOx,
  DataReading,
} from '..';
import BloodPressureGraph from './BloodPressureGraph/BloodPressureGraph';
import GlucoseGraph from './GlucoseGraph/GlucoseGraph';
import PulseGraph from './PulseGraph/PulseGraph';
import WeightGraph from './WeightGraph/WeightGraph';
import './style.css';
import { PatientProp } from '../../PatientProfile';
import { useEffect } from 'react';
import OxygenGraph from './PulseOxGraph/PulseOxGraph';

interface ReadingTableProps {
  visible: boolean;
  id: string;
  dateRange: DateRange;
}

type CC<T> = Cell<BloodPressureData, T>;

interface BloodPressureReadingTable extends ReadingTableProps {
  visible: boolean;
  id: string;
  isTableView: boolean;
  rangeThreshold: DeviceThreshold | undefined;
  bp_diastolic: DeviceThreshold | undefined;
  bp_systolic: DeviceThreshold | undefined;
  timeZone: string;
  deviceVendor: boolean;
  setSystolicValue: (value: number) => void;
  setDiastolicValue: (value: number) => void;
  setSystolicOutlier: (value: boolean) => void;
  setDiastolicOutlier: (value: boolean) => void;
  setBpSystolicValue: any;
  setBpDiastolicValue: any;
  setTitleColor: (value: boolean) => void;
  postalCode: string;
  chartsReadings?: BloodPressure[];
  reading?: DataReading;
  isLoading: boolean;
}

interface PulseReadingTable extends ReadingTableProps {
  visible: boolean;
  id: string;
  isTableView: boolean;
  rangeThreshold: DeviceThreshold | undefined;
  timeZone: string;
  deviceVendor: boolean;
  setPulseValue: (value: number) => void;
  setPulseOutlier: (value: boolean) => void;
  setBpPulseValue: any;
  setTitleColorPulse: (value: boolean) => void;
  postalCode: string;
  chartPulseReadings?: PulseReadingData[];
  reading?: DataReading;
  isLoading?: boolean;
}

interface BloodOxygenReadingTable extends ReadingTableProps {
  visible: boolean;
  id: string;
  isTableView: boolean;
  oxygen_threshold: DeviceThreshold | undefined;
  rangeThreshold: DeviceThreshold | undefined;
  timeZone: string;
  setBloodOxygenValue: (value: number) => void;
  setBloodOxygenOutlier: (value: boolean) => void;
  setBloodOxygenTotalValue: any;
  setTitleColorOxygen: (value: boolean) => void;
  setPulseOxPulseValue: (value: number) => void;
  setPulseOxPulseOutlier: (value: boolean) => void;
  postalCode: string;
  bloodOxygenReadings?: PulseOx[];
  thresholdData?: DataReading;
  isLoading: boolean;
}
interface BloodSugarReadingTable extends ReadingTableProps {
  visible: boolean;
  id: string;
  isTableView: boolean;
  sugar_threshold: DeviceThreshold | undefined;
  timeZone: string;
  setBloodSugarValue: (value: number) => void;
  setBloodSugarOutlier: (value: boolean) => void;
  setBloodSugarTotalValue: any;
  setTitleColorGlucose: (value: boolean) => void;
  postalCode: string;
  bloodSugarReadings?: BloodSugar[];
  thresholdData?: DataReading;
  isLoading: boolean;
}

interface WeightReadingTable extends ReadingTableProps {
  visible: boolean;
  id: string;
  isTableView: boolean;
  timeZone: string;
  deviceVendor: boolean;
  setWeightValue: (value: number) => void;
  setWeightOutlier: (value: boolean) => void;
  setWeightTotalValue: any;
  setTitleColorWeight: (value: boolean) => void;
  postalCode: string;
  weightChartsReading: any;
  rangeSelectedThresholdResponse?: DataReading;
  isLoading: boolean;
}

export interface WeightData {
  weightChange: string | number;
  outlier: boolean;
  weight: number;
  id: string;
  timestamp: string;
  device: Device;
  patient: Patient;
  newTimestamp?: string;
  newDate?: string;
}

export interface BloodPressureReadingData extends BloodPressure {
  newTimestamp?: string;
  newDate?: string;
}
export interface PulseReadingData extends Pulse {
  newTimestamp?: string;
  newDate?: string;
}
export interface BloodSugarReadingData extends BloodSugar {
  newTimestamp?: string;
  newDate?: string;
}
export interface PulseOxReadingData extends PulseOx {
  newTimestamp?: string;
  newDate?: string;
}

const customStyle: any = {
  textTransform: 'none',
  fontFamily: 'var(--chakra-fonts-heading)',
  fontWeight: 'var(--chakra-fontWeights-bold)',
  fontSize: 'var(--chakra-fontSizes-xs)',
};

const styles: any = {
  tabTitle: {
    color: '#6C7789',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  tabTitleColor: {
    color: '#1083CB',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  tabInfoIcon: {
    color: '#EC1824',
    width: '15px',
    marginLeft: '5px',
    marginTop: '-5px',
  },
  tabCheckedIcon: {
    color: '#6C7789',
    width: '15px',
    marginLeft: '5px',
    marginTop: '-5px',
  },
  tabCheckedRedIcon: {
    color: '#EC1824',
    width: '15px',
    marginLeft: '5px',
    marginTop: '-5px',
  },
  readingRedTitle: {
    fontWeight: '300',
    fontSize: '35px',
    color: '#EC1824',
    height: '40px',
  },
  readingNormalTitle: {
    fontWeight: '300',
    fontSize: '35px',
    color: '#6C7789',
    height: '40px',
  },
  readingSeperator: {
    fontWeight: '300',
    fontSize: '50px',
    color: '#B8C0CE',
    width: '20px',
    marginLeft: '5px',
  },
  readingChangeBox: {
    height: '12px',
    marginLeft: '2px',
  },
  readingChangeTitle: {
    fontSize: '14px',
    fontWeight: '300',
    color: '#EC1824',
  },
  readingChangeForBpTitle: {
    fontSize: '14px',
    fontWeight: '300',
    color: '#6C7789',
  },
  readingUpIcon: {
    color: '#EC1824',
    width: '12px',
    marginTop: '-5px',
  },
  readingDownIcon: {
    color: '#6C7789',
    width: '12px',
    marginTop: '-5px',
  },
  readingCaptionBox: {
    height: '10px',
    fontSize: '10px',
    color: '#B8C0CD',
    marginLeft: '5px',
  },
  dateTimeBox: {
    fontSize: '10px',
    color: '#6C7789',
  },
};

export const BloodPressureDataTable = ({
  rangeThreshold,
  isTableView,
  bp_diastolic,
  bp_systolic,
  dateRange,
  timeZone,
  setSystolicValue,
  setDiastolicValue,
  setSystolicOutlier,
  setDiastolicOutlier,
  setBpSystolicValue,
  setBpDiastolicValue,
  setTitleColor,
  chartsReadings,
  reading,
  isLoading,
}: BloodPressureReadingTable) => {
  /**
   * We need to capture diastolic and systolic
   * @returns
   */
  let outlier = false;
  let pulseOutlier = false;
  let diastolicoutlier = false;
  let systolicValue = 0;
  let diastolicValue = 0;
  const deriveBloodPressureThreshold = (): BloodPressure[] | undefined => {
    const bloodPressureReading = reading?.bloodPressure?.map((bloodPressure: BloodPressure) => {
      const diastolic = bloodPressure?.diastolic;
      const systolic = bloodPressure?.systolic;
      if (
        rangeThreshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        rangeThreshold?.sign === DeviceThreshold.sign.RANGE
      ) {
        const pulseReadingNewData: any = bloodPressure;
        if (
          pulseReadingNewData?.pulse < rangeThreshold?.threshHoldLowerLimit ||
          pulseReadingNewData?.pulse > rangeThreshold?.threshHoldUpperLimit
        ) {
          pulseOutlier = true;
        } else {
          pulseOutlier = false;
        }
      }

      if (
        rangeThreshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        rangeThreshold?.sign === DeviceThreshold.sign.RANGE ||
        bp_diastolic?.sign === DeviceThreshold.sign.RANGE ||
        (bp_diastolic?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN && bp_diastolic)
      ) {
        // if (
        //   (bp_diastolic && diastolic > bp_diastolic?.threshHoldUpperLimit) ||
        //   (bp_diastolic && diastolic < bp_diastolic?.threshHoldLowerLimit) ||
        //   (bp_systolic && systolic < bp_systolic?.threshHoldLowerLimit) ||
        //   (bp_systolic && systolic > bp_systolic?.threshHoldUpperLimit)
        // ) {
        //   outlier = true;
        // }
        if (
          (bp_diastolic && diastolic > bp_diastolic?.threshHoldUpperLimit) ||
          (bp_diastolic && diastolic < bp_diastolic?.threshHoldLowerLimit)
        ) {
          diastolicoutlier = true;
        } else {
          diastolicoutlier = false;
        }
        if (
          (bp_systolic && systolic < bp_systolic?.threshHoldLowerLimit) ||
          (bp_systolic && systolic > bp_systolic?.threshHoldUpperLimit)
        ) {
          outlier = true;
        } else {
          outlier = false;
        }
      }
      const date = bloodPressure.timestamp;
      return {
        ...bloodPressure,
        outlier,
        pulseOutlier,
        diastolicoutlier,
        date,
      };
    });

    const reversedBloodPressureReading = orderBy(bloodPressureReading, ['timestamp'], ['desc']);
    return reversedBloodPressureReading;
  };

  const data: BloodPressure[] | undefined = deriveBloodPressureThreshold();
  if (data && data?.length >= 2) {
    const [firstValue, secondValue] = data;
    systolicValue =
      firstValue?.systolic && secondValue?.systolic
        ? firstValue.systolic - secondValue.systolic
        : 0;
    diastolicValue =
      firstValue?.diastolic && secondValue?.diastolic
        ? firstValue.diastolic - secondValue.diastolic
        : 0;
  }

  let outlierBpCharts = false;
  let diastolicoutlierBpCharts = false;
  const deriveBloodPressureChartData = (): BloodPressure[] | undefined => {
    const bloodPressureReading = chartsReadings?.map((bloodPressure: BloodPressure) => {
      const diastolic = bloodPressure?.diastolic;
      const systolic = bloodPressure?.systolic;

      if (
        rangeThreshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        rangeThreshold?.sign === DeviceThreshold.sign.RANGE ||
        bp_diastolic?.sign === DeviceThreshold.sign.RANGE ||
        (bp_diastolic?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN && bp_diastolic)
      ) {
        // if (
        //   (bp_diastolic && diastolic > bp_diastolic?.threshHoldUpperLimit) ||
        //   (bp_diastolic && diastolic < bp_diastolic?.threshHoldLowerLimit) ||
        //   (bp_systolic && systolic < bp_systolic?.threshHoldLowerLimit) ||
        //   (bp_systolic && systolic > bp_systolic?.threshHoldUpperLimit)
        // ) {
        //   outlier = true;
        // }
        if (
          (bp_diastolic && diastolic > bp_diastolic?.threshHoldUpperLimit) ||
          (bp_diastolic && diastolic < bp_diastolic?.threshHoldLowerLimit)
        ) {
          diastolicoutlierBpCharts = true;
        } else {
          diastolicoutlierBpCharts = false;
        }
        if (
          (bp_systolic && systolic < bp_systolic?.threshHoldLowerLimit) ||
          (bp_systolic && systolic > bp_systolic?.threshHoldUpperLimit)
        ) {
          outlierBpCharts = true;
        } else {
          outlierBpCharts = false;
        }
      }
      const date = bloodPressure.timestamp;
      return {
        ...bloodPressure,
        outlierBpCharts,
        diastolicoutlierBpCharts,
        date,
      };
    });

    const reversedBloodPressureReading = orderBy(bloodPressureReading, ['timestamp'], ['desc']);
    return reversedBloodPressureReading;
  };

  const chartsData: BloodPressureReadingData[] | undefined = deriveBloodPressureChartData();
  useEffect(() => {
    setSystolicOutlier(outlierBpCharts);
    setDiastolicOutlier(diastolicoutlierBpCharts);
    setSystolicValue(systolicValue);
    setDiastolicValue(diastolicValue);
    setBpSystolicValue({
      bpSysValue: chartsData && chartsData[0]?.systolic,
      timeStamp: chartsData ? chartsData[0]?.newTimestamp : '',
    });
    setBpDiastolicValue({
      bpDiastoicValue: chartsData && chartsData[0]?.diastolic,
      timeStamp: chartsData ? chartsData[0]?.newTimestamp : '',
    });
    setTitleColor(false);
  }, [
    outlierBpCharts,
    systolicValue,
    setSystolicOutlier,
    setDiastolicOutlier,
    setDiastolicValue,
    setSystolicValue,
    setBpSystolicValue,
    setBpDiastolicValue,
    chartsReadings,
    reading,
  ]);

  return (
    <>
      {isTableView ? (
        <DataTable
          className="borderedDataTable"
          borderCollapse="separate"
          borderSpacing="0 2px"
          isLoading={isLoading}
          variant="striped"
          hasPagination={false}
          columns={
            [
              {
                Header: 'Date',
                accessor: 'newDate',
                Cell: ({ value }: CC<string>) => <ShortDateCell timestamp={value} />,
                width: 30,
              },
              {
                id: 'SYSTOLIC',
                Header: () => <Text style={customStyle}>SYSTOLIC/DIASTOLIC (mmHg)</Text>,
                accessor: ({ systolic, diastolic, outlier }) =>
                  [systolic, diastolic, outlier, diastolicoutlier].join(' '),
                Cell: ({ row: { original } }: CC<SystolicDiastolicCellProps>) => (
                  <SystolicDiastolicCell {...original} />
                ),
                width: 78,
              },
              {
                id: 'PULSE',
                Header: () => <Text style={customStyle}>PULSE (bpm)</Text>,
                accessor: ({ pulse, pulseOutlier }) => [pulse, pulseOutlier].join(' '),
                Cell: ({ row: { original } }: CC<BpPulseCellProps>) => (
                  <BpPulseCell {...original} />
                ),
                width: 42,
              },
              {
                Header: 'Full Date & Time',
                accessor: 'newTimestamp',
                Cell: ({ value }: CC<string>) => <DateCell timestamp={value} timeZone={timeZone} />,
              },
            ] as Column<BloodPressureData>[]
          }
          data={data || []}
        />
      ) : (
        <BloodPressureGraph
          data={orderBy(data, ['timestamp'], ['asc'])}
          dateRange={dateRange}
          systolicThreshold={bp_systolic}
          diastolicThreshold={bp_diastolic}
        />
      )}
    </>
  );
};

export const BloodPulseDataTable = ({
  rangeThreshold,
  isTableView,
  dateRange,
  timeZone,
  setPulseValue,
  setPulseOutlier,
  setBpPulseValue,
  chartPulseReadings,
  reading,
  isLoading,
}: PulseReadingTable) => {
  let outlier = false;
  let pulseValue = 0;
  const derivePulseThreshold = (): PulseReadingData[] | undefined => {
    const pulseThreshold = reading?.pulse?.map((pulseData: PulseReadingData) => {
      const pulse = pulseData?.pulse;

      if (
        rangeThreshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        rangeThreshold?.sign === DeviceThreshold.sign.RANGE
      ) {
        if (
          pulse < rangeThreshold?.threshHoldLowerLimit ||
          pulse > rangeThreshold?.threshHoldUpperLimit
        ) {
          outlier = true;
        } else {
          outlier = false;
        }
      }
      const date = pulseData.timestamp;
      return {
        ...pulseData,
        outlier,
        date,
      };
    });

    const reversedPulseThreshold = orderBy(pulseThreshold, ['timestamp'], ['desc']);
    return reversedPulseThreshold;
  };

  let pulseChartOutlier = false;
  let pulseChartValue = 0;
  const derivePulseCharts = (): PulseReadingData[] | undefined => {
    const pulseThresholdCharts = chartPulseReadings?.map((pulseData: PulseReadingData) => {
      const pulse = pulseData?.pulse;

      if (
        rangeThreshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        rangeThreshold?.sign === DeviceThreshold.sign.RANGE
      ) {
        if (
          pulse < rangeThreshold?.threshHoldLowerLimit ||
          pulse > rangeThreshold?.threshHoldUpperLimit
        ) {
          pulseChartOutlier = true;
        } else {
          pulseChartOutlier = false;
        }
      }
      const date = pulseData.timestamp;
      return {
        ...pulseData,
        pulseChartOutlier,
        date,
      };
    });

    const reversedPulseThresholdCharts = orderBy(pulseThresholdCharts, ['timestamp'], ['desc']);
    pulseChartValue = reversedPulseThresholdCharts ? reversedPulseThresholdCharts[0]?.pulse : 0;
    return reversedPulseThresholdCharts;
  };

  const data = derivePulseThreshold();
  const pulseData = derivePulseCharts();

  if (data && data?.length >= 2) {
    pulseValue = data ? (data[0]?.pulse && data[1].pulse ? data[0].pulse - data[1].pulse : 0) : 0;
    pulseValue = pulseValue;
  }
  useEffect(() => {
    setPulseOutlier(pulseChartOutlier);
    setPulseValue(pulseValue);
    setBpPulseValue(pulseChartValue);
    setBpPulseValue({
      bpPulseData: pulseData && pulseData[0]?.pulse,
      timeStamp: pulseData ? pulseData[0]?.newTimestamp : '',
    });
  }, [
    pulseChartOutlier,
    pulseValue,
    pulseChartValue,
    setPulseOutlier,
    setPulseValue,
    setBpPulseValue,
  ]);

  return (
    <>
      {isTableView ? (
        <DataTable
          className="borderedDataTable"
          borderCollapse="separate"
          borderSpacing="0 2px"
          isLoading={isLoading}
          variant="striped"
          hasPagination={false}
          columns={
            [
              {
                Header: 'Date',
                accessor: 'newDate',
                Cell: ({ value }: CC<string>) => <ShortDateCell timestamp={value} />,
                width: 30,
              },
              {
                id: 'PULSE',
                Header: () => <Text style={customStyle}>PULSE (bpm)</Text>,
                accessor: ({ pulse, outlier }) => [pulse, outlier].join(' '),
                Cell: ({ row: { original } }: CC<PulseCellProps>) => <PulseCell {...original} />,
                width: 60,
              },
              {
                Header: 'Full Date & Time',
                accessor: 'newTimestamp',
                Cell: ({ value }: CC<string>) => <DateCell timestamp={value} timeZone={timeZone} />,
              },
            ] as Column<BloodPressureData>[]
          }
          data={data || []}
        />
      ) : (
        <PulseGraph
          data={orderBy(data, ['timestamp'], ['asc'])}
          dateRange={dateRange}
          pulseThreshold={rangeThreshold}
        />
      )}
    </>
  );
};

export const BloodSugarDataTable = ({
  sugar_threshold,
  isTableView,
  dateRange,
  timeZone,
  setBloodSugarValue,
  setBloodSugarOutlier,
  setBloodSugarTotalValue,
  bloodSugarReadings,
  thresholdData,
  isLoading,
}: BloodSugarReadingTable) => {
  let outlier = false;
  let bloodSugarValue = 0;
  let bloodSugarTotalValue = 0;
  const DeriveBloodSugarThreshold = (): BloodSugar[] | undefined => {
    const bloodSugarReading = thresholdData?.bloodSugar?.map((bloodSugar: BloodSugar) => {
      const reading = bloodSugar?.reading;

      if (
        sugar_threshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        sugar_threshold?.sign === DeviceThreshold.sign.RANGE
      ) {
        if (
          reading > sugar_threshold?.threshHoldUpperLimit ||
          reading < sugar_threshold?.threshHoldLowerLimit
        ) {
          outlier = true;
        } else {
          outlier = false;
        }
      }
      const date = bloodSugar.timestamp;
      return {
        ...bloodSugar,
        outlier,
        date,
      };
    });

    const reversedBloodSugarReading = orderBy(bloodSugarReading, ['timestamp'], ['desc']);
    bloodSugarTotalValue = reversedBloodSugarReading ? reversedBloodSugarReading[0]?.reading : 0;
    if (reversedBloodSugarReading.length >= 2) {
      bloodSugarValue =
        reversedBloodSugarReading[0]?.reading && reversedBloodSugarReading[1].reading
          ? reversedBloodSugarReading[0].reading - reversedBloodSugarReading[1].reading
          : 0;
    }
    return reversedBloodSugarReading;
  };

  let outlierBloodSugar = false;
  const DeriveBloodSugarCharts = (): BloodSugarReadingData[] | undefined => {
    const bloodSugarReading = bloodSugarReadings?.map((bloodSugar: BloodSugar) => {
      const reading = bloodSugar?.reading;

      if (
        sugar_threshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        sugar_threshold?.sign === DeviceThreshold.sign.RANGE
      ) {
        if (
          reading > sugar_threshold?.threshHoldUpperLimit ||
          reading < sugar_threshold?.threshHoldLowerLimit
        ) {
          outlierBloodSugar = true;
        } else {
          outlierBloodSugar = false;
        }
      }
      const date = bloodSugar.timestamp;
      return {
        ...bloodSugar,
        outlierBloodSugar,
        date,
      };
    });
    const reversedBloodSugarReading = orderBy(bloodSugarReading, ['timestamp'], ['desc']);
    bloodSugarTotalValue = reversedBloodSugarReading ? reversedBloodSugarReading[0]?.reading : 0;
    if (reversedBloodSugarReading.length > 2) {
      bloodSugarValue =
        reversedBloodSugarReading[0]?.reading && reversedBloodSugarReading[1].reading
          ? reversedBloodSugarReading[0].reading - reversedBloodSugarReading[1].reading
          : 0;
    }
    return reversedBloodSugarReading;
  };

  const data = DeriveBloodSugarThreshold();
  const bloodSugarChartsData = DeriveBloodSugarCharts();

  useEffect(() => {
    setBloodSugarOutlier(outlierBloodSugar);
    setBloodSugarValue(bloodSugarValue);
    // setBloodSugarTotalValue(bloodSugarChartsData ? bloodSugarChartsData[0]?.reading : 0);
    setBloodSugarTotalValue({
      bloodSugarData: bloodSugarChartsData && bloodSugarChartsData[0]?.reading,
      timeStamp: bloodSugarChartsData ? bloodSugarChartsData[0]?.newTimestamp : '',
    });
  }, [outlierBloodSugar, bloodSugarValue, bloodSugarTotalValue, setBloodSugarTotalValue]);

  return (
    <>
      {isTableView ? (
        <DataTable
          className="borderedDataTable"
          borderCollapse="separate"
          borderSpacing="0 2px"
          isLoading={isLoading}
          variant="striped"
          hasPagination={false}
          columns={
            [
              {
                Header: 'Date',
                accessor: 'newDate',
                Cell: ({ value }: CC<string>) => <ShortDateCell timestamp={value} />,
                width: 30,
              },
              {
                id: 'GLUCOSE',
                Header: () => <Text style={customStyle}>BLOOD GLUCOSE (mg/dL)</Text>,
                accessor: ({ reading }) => [reading].join(' '),
                Cell: ({ row: { original } }: WW<WEIGHT>) => <BloodSugarCell {...original} />,
                width: 60,
              },
              {
                Header: 'Full Date & Time',
                accessor: 'newTimestamp',
                Cell: ({ value }: CC<string>) => <DateCell timestamp={value} timeZone={timeZone} />,
              },
            ] as Column<BloodSugarReadingData>[]
          }
          data={data || []}
        />
      ) : (
        <GlucoseGraph
          data={orderBy(data, ['timestamp'], ['asc'])}
          dateRange={dateRange}
          sugarThreshold={sugar_threshold}
        />
      )}
    </>
  );
};

export const BloodOxygenDataTable = ({
  oxygen_threshold,
  rangeThreshold,
  isTableView,
  dateRange,
  timeZone,
  setBloodOxygenValue,
  setBloodOxygenOutlier,
  setBloodOxygenTotalValue,
  setPulseOxPulseValue,
  setPulseOxPulseOutlier,
  bloodOxygenReadings,
  thresholdData,
  isLoading,
}: BloodOxygenReadingTable) => {
  let outlier = false;
  let bloodOxygenValue = 0;
  let bloodOxygenTotalValue = 0;
  let pulseOutlier = false;
  let outlierBloodOxygen = false;
  const pulseValue = 0;

  const DeriveBloodOxygenThreshold = (): any[] => {
    const bloodOxygenReading = thresholdData?.bloodOxygen?.map((bloodOxygen: PulseOx) => {
      const reading = bloodOxygen?.pulseox;

      if (
        rangeThreshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        rangeThreshold?.sign === DeviceThreshold.sign.RANGE
      ) {
        const pulseReadingNewData: any = bloodOxygen;
        if (
          pulseReadingNewData?.pulse < rangeThreshold?.threshHoldLowerLimit ||
          pulseReadingNewData?.pulse > rangeThreshold?.threshHoldUpperLimit
        ) {
          pulseOutlier = true;
        } else {
          pulseOutlier = false;
        }
      }

      if (
        oxygen_threshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        oxygen_threshold?.sign === DeviceThreshold.sign.RANGE
      ) {
        if (
          reading > oxygen_threshold?.threshHoldUpperLimit ||
          reading < oxygen_threshold?.threshHoldLowerLimit
        ) {
          outlier = true;
        } else {
          outlier = false;
        }
      }
      const date = bloodOxygen.timestamp;

      return {
        ...bloodOxygen,
        outlier,
        pulseOutlier,
        date,
      };
    });

    const reversedBloodOxygenReading = orderBy(bloodOxygenReading, ['timestamp'], ['desc']);
    bloodOxygenTotalValue = reversedBloodOxygenReading ? reversedBloodOxygenReading[0]?.pulseox : 0;
    if (reversedBloodOxygenReading.length >= 2) {
      bloodOxygenValue =
        reversedBloodOxygenReading[0]?.pulseox && reversedBloodOxygenReading[1].pulseox
          ? reversedBloodOxygenReading[0].pulseox - reversedBloodOxygenReading[1].pulseox
          : 0;
    }
    return reversedBloodOxygenReading;
  };

  const DeriveBloodOxygenCharts = (): any[] => {
    const bloodOxygenReading = bloodOxygenReadings?.map((bloodOxygen: PulseOx) => {
      const reading = bloodOxygen?.pulseox;

      if (
        oxygen_threshold?.sign === DeviceThreshold.sign.GREATER_THAN_OR_LESS_THAN ||
        oxygen_threshold?.sign === DeviceThreshold.sign.RANGE
      ) {
        if (
          reading > oxygen_threshold?.threshHoldUpperLimit ||
          reading < oxygen_threshold?.threshHoldLowerLimit
        ) {
          outlierBloodOxygen = true;
        } else {
          outlierBloodOxygen = false;
        }
      }
      const date = bloodOxygen.timestamp;
      return {
        ...bloodOxygen,
        outlierBloodOxygen,
        date,
      };
    });
    const reversedBloodOxygenReading = orderBy(bloodOxygenReading, ['timestamp'], ['desc']);
    bloodOxygenTotalValue = reversedBloodOxygenReading ? reversedBloodOxygenReading[0]?.pulseox : 0;
    if (reversedBloodOxygenReading.length > 2) {
      bloodOxygenValue =
        reversedBloodOxygenReading[0]?.pulseox && reversedBloodOxygenReading[1].pulseox
          ? reversedBloodOxygenReading[0].pulseox - reversedBloodOxygenReading[1].pulseox
          : 0;
    }
    return reversedBloodOxygenReading;
  };

  const pulseChartOutlier = false;

  const pulseOxData = DeriveBloodOxygenThreshold();
  const data = pulseOxData;
  const bloodOxygenChartsData = DeriveBloodOxygenCharts();

  useEffect(() => {
    setBloodOxygenOutlier(outlierBloodOxygen);
    setBloodOxygenValue(bloodOxygenValue);
    setBloodOxygenTotalValue({
      bloodOxygen: bloodOxygenChartsData && bloodOxygenChartsData[0]?.pulseox,
      timeStamp: bloodOxygenChartsData ? bloodOxygenChartsData[0]?.newTimestamp : '',
    });
    setPulseOxPulseValue(pulseValue);
    setPulseOxPulseOutlier(pulseChartOutlier);
  }, [
    outlierBloodOxygen,
    bloodOxygenValue,
    bloodOxygenTotalValue,
    setBloodOxygenTotalValue,
    pulseChartOutlier,
    pulseValue,
    pulseChartOutlier,
  ]);

  return (
    <>
      {isTableView ? (
        <DataTable
          className="borderedDataTable"
          borderCollapse="separate"
          borderSpacing="0 2px"
          isLoading={isLoading}
          variant="striped"
          hasPagination={false}
          columns={
            [
              {
                Header: 'Date',
                accessor: 'newDate',
                Cell: ({ value }: CC<string>) => <ShortDateCell timestamp={value} />,
                width: 30,
              },
              {
                id: 'OXYGEN',
                Header: () => (
                  <Text style={customStyle}>
                    BLOOD OXYGEN (SpO<sub>2</sub>)
                  </Text>
                ),
                accessor: ({ reading }: any) => [reading].join(' '),
                Cell: ({ row: { original } }: WW<PulseOxProps>) => (
                  <BloodOxygenCell {...original} />
                ),
                width: 70,
              },
              {
                id: 'PULSE',
                Header: () => <Text style={customStyle}>PULSE (bpm)</Text>,
                accessor: ({ pulse, pulseOutlier }: any) => [pulse, pulseOutlier].join(' '),
                Cell: ({ row: { original } }: CC<PulseCellProps>) => (
                  <PulseCellPulseOx {...original} />
                ),
                width: 50,
              },
              {
                Header: 'Full Date & Time',
                accessor: 'newTimestamp',
                Cell: ({ value }: CC<string>) => <DateCell timestamp={value} timeZone={timeZone} />,
              },
            ] as Column<PulseOxReadingData>[]
          }
          data={data || []}
        />
      ) : (
        <OxygenGraph
          data={orderBy(data, ['timestamp'], ['asc'])}
          dateRange={dateRange}
          oxygenThreshold={oxygen_threshold}
        />
      )}
    </>
  );
};

export const WeightDataTable = ({
  dateRange,
  isTableView,
  timeZone,
  setWeightValue,
  setWeightOutlier,
  setWeightTotalValue,
  weightChartsReading,
  rangeSelectedThresholdResponse,
  isLoading,
}: WeightReadingTable) => {
  const deriveWeightData = (): Array<WeightData> => {
    const weightReading = rangeSelectedThresholdResponse?.weight?.map((weight, index) => {
      const difference = rangeSelectedThresholdResponse?.weightChange[index]?.unit || 0;
      const date = weight.timestamp;
      return {
        ...weight,
        weightChange: difference.toString(),
        outlier:
          rangeSelectedThresholdResponse?.weightChange[index]?.outlier === true ? true : false,
        date,
      };
    });

    return orderBy(weightReading, ['timestamp'], ['desc']);
  };

  const deriveWeightChartData = (): Array<WeightData> => {
    const weightReading = weightChartsReading?.map((weight, index) => {
      const date = weight.timestamp;
      return {
        ...weight,
        weightChange: '',
        outlier:
          rangeSelectedThresholdResponse?.weightChange[index]?.outlier === true ? true : false,
        date,
      };
    });

    return orderBy(weightReading, ['timestamp'], ['desc']);
  };
  const data = deriveWeightData();
  const weightData = deriveWeightChartData();
  const weightValue = data ? Number(data[0]?.weightChange ? data[0]?.weightChange : 0) : 0;

  useEffect(() => {
    setWeightOutlier(
      weightData ? (weightData[0]?.outlier ? weightData[0]?.outlier : false) : false
    );
    setWeightValue(weightValue);
    setWeightTotalValue(weightData ? weightData[0]?.weight : 0);
    setWeightTotalValue({
      weightValue: weightData && weightData[0]?.weight,
      timeStamp: weightData ? weightData[0]?.newTimestamp : '',
    });
  }, [weightData[0]?.outlier, weightValue, setWeightOutlier, setWeightValue, setWeightTotalValue]);

  return (
    <>
      {isTableView ? (
        <DataTable
          className="borderedDataTable"
          borderCollapse="separate"
          borderSpacing="0 2px"
          isLoading={isLoading}
          variant="striped"
          hasPagination={false}
          columns={
            [
              {
                Header: 'Date',
                accessor: 'newDate',
                Cell: ({ value }: CC<string>) => <ShortDateCell timestamp={value} />,
                width: 30,
              },
              {
                id: 'WEIGHT',
                Header: () => <Text style={customStyle}>WEIGHT CHANGE (lb)</Text>,
                accessor: ({ weightChange, weight }) => [weightChange, weight].join(' '),
                Cell: ({ row: { original } }: WW<WEIGHT>) => <WeightChange {...original} />,
                width: 60,
              },
              {
                id: 'WEIGHT_lb',
                Header: () => <Text style={customStyle}>WEIGHT (lb)</Text>,
                accessor: ({ weightChange, weight }) => [weightChange, weight].join(' '),
                Cell: ({ row: { original } }: WW<WEIGHT>) => <WeightCell {...original} />,
                width: 60,
              },
              {
                Header: 'Full Date & Time',
                accessor: 'newTimestamp',
                Cell: ({ value }: CC<string>) => <DateCell timestamp={value} timeZone={timeZone} />,
              },
            ] as Column<WEIGHT>[]
          }
          data={data || []}
        />
      ) : (
        <WeightGraph data={orderBy(data, ['timestamp'], ['asc'])} dateRange={dateRange} />
      )}
    </>
  );
};
export const BloodPressureSysDia = ({
  patient,
  systolicValue,
  diastolicValue,
  systolicOutlier,
  diastolicOutlier,
  bpSystolicValue,
  bpDiastoicValue,
  titleColor,
}: PatientProp) => {
  return (
    <div className="bloodPressureSysDia">
      <Flex>
        {titleColor === false ? (
          <>
            <Box style={styles.tabTitleColor}>Blood Pressure SYS/DIA</Box>
          </>
        ) : (
          <>
            <Box style={styles.tabTitle}>Blood Pressure SYS/DIA</Box>
          </>
        )}
        {systolicOutlier ? (
          <Icon icon="information-filled" style={styles.tabInfoIcon} />
        ) : (
          <Icon icon="check-circle-filled" style={styles.tabCheckedIcon} />
        )}
      </Flex>

      {
        <>
          <Flex mt="4" mb="4">
            <Flex>
              {systolicOutlier ? (
                <Box style={styles.readingRedTitle}>{bpSystolicValue?.bpSysValue}</Box>
              ) : (
                <Box style={styles.readingNormalTitle}>{bpSystolicValue?.bpSysValue}</Box>
              )}

              {systolicOutlier ? (
                <Box>
                  {systolicValue! > 0 ? (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{systolicValue!}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>mmHg</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{systolicValue!}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>mmHg</Box>
                    </>
                  )}
                </Box>
              ) : (
                <Box>
                  {systolicValue! > 0 ? (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{Math.abs(systolicValue!)}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>mmHg</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{Math.abs(systolicValue!)}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>mmHg</Box>
                    </>
                  )}
                </Box>
              )}
            </Flex>
            <Box display="flex" style={styles.readingSeperator}>
              /
            </Box>
            <Flex>
              {diastolicOutlier ? (
                <Box style={styles.readingRedTitle}>{bpDiastoicValue?.bpDiastoicValue}</Box>
              ) : (
                <Box style={styles.readingNormalTitle}>{bpDiastoicValue?.bpDiastoicValue}</Box>
              )}

              <Box>
                <Flex style={styles.readingChangeBox}>
                  {diastolicValue! > 0 ? (
                    <Box>
                      <Icon icon="arrow-up" style={styles.readingDownIcon} />
                    </Box>
                  ) : (
                    <Box>
                      <Icon icon="arrow-down" style={styles.readingDownIcon} />
                    </Box>
                  )}
                  <Box style={styles.readingChangeForBpTitle}>{Math.abs(diastolicValue!)}</Box>
                </Flex>
                <Box style={styles.readingCaptionBox}>mmHg</Box>
              </Box>
            </Flex>
          </Flex>
          <Box style={styles.dateTimeBox}>
            {bpSystolicValue && bpSystolicValue?.bpSysValue > 0 ? (
              <>
                <DateCellBp
                  timestamp={bpSystolicValue ? bpSystolicValue?.timeStamp : ''}
                  timeZone={patient?.address?.timeZone}
                />
              </>
            ) : (
              <div style={{ height: '13px' }}></div>
            )}
          </Box>
        </>
      }
    </div>
  );
};
export const BloodPressurePulse = ({
  patient,
  pulseValue,
  pulseOutlier,
  titleColorPulse,
  bpPulseValue,
}: PatientProp) => {
  return (
    <div className="bloodPressurePulse">
      <Flex>
        {titleColorPulse === false ? (
          <>
            <Box style={styles.tabTitleColor}>Pulse</Box>
          </>
        ) : (
          <>
            <Box style={styles.tabTitle}>Pulse</Box>
          </>
        )}

        <Box>
          {pulseOutlier ? (
            <Icon icon="information-filled" style={styles.tabInfoIcon} />
          ) : (
            <Icon icon="check-circle-filled" style={styles.tabCheckedIcon} />
          )}
        </Box>
      </Flex>
      {
        <>
          <Flex mt="4" mb="4">
            <Flex>
              {pulseOutlier ? (
                <Box style={styles.readingRedTitle}>{bpPulseValue?.bpPulseData}</Box>
              ) : (
                <Box style={styles.readingNormalTitle}>{bpPulseValue?.bpPulseData}</Box>
              )}
              {pulseOutlier ? (
                <Box>
                  {pulseValue! > 0 ? (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{Math.abs(pulseValue!)}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>bpm</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{Math.abs(pulseValue!)}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>bpm</Box>
                    </>
                  )}
                </Box>
              ) : (
                <Box>
                  {pulseValue! > 0 ? (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{Math.abs(pulseValue!)}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>bpm</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{Math.abs(pulseValue!)}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>bpm</Box>
                    </>
                  )}
                </Box>
              )}
            </Flex>
          </Flex>
          <Box>
            {bpPulseValue && bpPulseValue?.bpPulseData > 0 ? (
              <>
                <DateCellBp
                  timestamp={bpPulseValue ? bpPulseValue.timeStamp : ''}
                  timeZone={patient?.address?.timeZone}
                />
              </>
            ) : (
              <div style={{ height: '13px' }}></div>
            )}
          </Box>
        </>
      }
    </div>
  );
};
export const BloodPressureWeight = ({
  patient,
  weightValue,
  weightOutlier,
  titleColorWeight,
  weightTotalValue,
}: PatientProp) => {
  return (
    <div className="bloodPressureWeight">
      <Flex>
        {titleColorWeight === false ? (
          <>
            <Box style={styles.tabTitleColor}>Weight</Box>
          </>
        ) : (
          <>
            <Box style={styles.tabTitle}>Weight</Box>
          </>
        )}
        <Box>
          {weightOutlier ? (
            <Icon icon="information-filled" style={styles.tabInfoIcon} />
          ) : (
            <Icon icon="check-circle-filled" style={styles.tabCheckedIcon} />
          )}
        </Box>
      </Flex>

      {
        <>
          <Flex mt="4" mb="4">
            <Flex>
              {weightOutlier ? (
                <Box style={styles.readingRedTitle}>{weightTotalValue?.weightValue}</Box>
              ) : (
                <Box style={styles.readingNormalTitle}>{weightTotalValue?.weightValue}</Box>
              )}
              {weightOutlier ? (
                <Box>
                  {weightValue! > 0 ? (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{weightValue!}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>lb</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{weightValue!}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>lb</Box>
                    </>
                  )}
                </Box>
              ) : (
                <Box>
                  {weightValue! > 0 ? (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{Math.abs(weightValue!)}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>lb</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>{Math.abs(weightValue!)}</Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>lb</Box>
                    </>
                  )}
                </Box>
              )}
            </Flex>
          </Flex>
          <Box>
            {weightTotalValue && weightTotalValue?.weightValue > 0 ? (
              <>
                <DateCellBp
                  timestamp={weightTotalValue ? weightTotalValue?.timeStamp : ''}
                  timeZone={patient?.address?.timeZone}
                />
              </>
            ) : (
              <div style={{ height: '13px' }}></div>
            )}
          </Box>
        </>
      }
    </div>
  );
};
export const BloodPressureSugar = ({
  patient,
  bloodSugarValue,
  bloodSugarOutlier,
  titleColorGlucose,
  bloodSugarTotalValue,
}: PatientProp) => {
  return (
    <div className="bloodPressureSugar">
      <Flex>
        {titleColorGlucose === false ? (
          <>
            <Box style={styles.tabTitleColor}>Blood Glucose</Box>
          </>
        ) : (
          <>
            <Box style={styles.tabTitle}>Blood Glucose</Box>
          </>
        )}
        <Box>
          {bloodSugarOutlier ? (
            <Icon icon="information-filled" style={styles.tabInfoIcon} />
          ) : (
            <Icon icon="check-circle-filled" style={styles.tabCheckedIcon} />
          )}
        </Box>
      </Flex>
      {
        <>
          <Flex mt="4" mb="4">
            <Flex>
              {bloodSugarOutlier ? (
                <Box style={styles.readingRedTitle}>{bloodSugarTotalValue?.bloodSugarData}</Box>
              ) : (
                <Box style={styles.readingNormalTitle}>{bloodSugarTotalValue?.bloodSugarData}</Box>
              )}

              {bloodSugarOutlier ? (
                <Box>
                  {bloodSugarValue! > 0 ? (
                    <>
                      {' '}
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>
                          {Math.abs(bloodSugarValue!)}
                        </Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>mg/dL</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>
                          {Math.abs(bloodSugarValue!)}
                        </Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>mg/dl</Box>
                    </>
                  )}
                </Box>
              ) : (
                <Box>
                  {bloodSugarValue! > 0 ? (
                    <>
                      {' '}
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>
                          {Math.abs(bloodSugarValue!)}
                        </Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>mg/dL</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>
                          {Math.abs(bloodSugarValue!)}
                        </Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>mg/dL</Box>
                    </>
                  )}
                </Box>
              )}
            </Flex>
          </Flex>
          <Box>
            {bloodSugarTotalValue && bloodSugarTotalValue?.bloodSugarData > 0 ? (
              <>
                <DateCellBp
                  timestamp={bloodSugarTotalValue ? bloodSugarTotalValue?.timeStamp : ''}
                  timeZone={patient?.address?.timeZone}
                />
              </>
            ) : (
              <div style={{ height: '13px' }}></div>
            )}
          </Box>
        </>
      }
    </div>
  );
};

export const BloodOxygen = ({
  patient,
  bloodOxygenValue,
  bloodOxygenOutlier,
  titleColorOxygen,
  bloodOxygenTotalValue,
}: PatientProp) => {
  return (
    <div className="bloodOxygen">
      <Flex>
        {titleColorOxygen === false ? (
          <>
            <Box style={styles.tabTitleColor}>PulseOx</Box>
          </>
        ) : (
          <>
            <Box style={styles.tabTitle}>PulseOx</Box>
          </>
        )}
        <Box>
          {bloodOxygenOutlier ? (
            <Icon icon="information-filled" style={styles.tabInfoIcon} />
          ) : (
            <Icon icon="check-circle-filled" style={styles.tabCheckedIcon} />
          )}
        </Box>
      </Flex>
      {
        <>
          <Flex mt="4" mb="4">
            <Flex>
              {bloodOxygenOutlier ? (
                <Box style={styles.readingRedTitle}>{bloodOxygenTotalValue?.bloodOxygen}</Box>
              ) : (
                <Box style={styles.readingNormalTitle}>{bloodOxygenTotalValue?.bloodOxygen}</Box>
              )}

              {bloodOxygenOutlier ? (
                <Box>
                  {bloodOxygenValue! > 0 ? (
                    <>
                      {' '}
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>
                          {Math.abs(bloodOxygenValue!)}
                        </Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>%</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>
                          {Math.abs(bloodOxygenValue!)}
                        </Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>%</Box>
                    </>
                  )}
                </Box>
              ) : (
                <Box>
                  {bloodOxygenValue! > 0 ? (
                    <>
                      {' '}
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-up" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>
                          {Math.abs(bloodOxygenValue!)}
                        </Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>%</Box>
                    </>
                  ) : (
                    <>
                      <Flex style={styles.readingChangeBox}>
                        <Box>
                          <Icon icon="arrow-down" style={styles.readingDownIcon} />
                        </Box>
                        <Box style={styles.readingChangeForBpTitle}>
                          {Math.abs(bloodOxygenValue!)}
                        </Box>
                      </Flex>
                      <Box style={styles.readingCaptionBox}>%</Box>
                    </>
                  )}
                </Box>
              )}
            </Flex>
          </Flex>
          <Box>
            {bloodOxygenTotalValue && bloodOxygenTotalValue?.bloodOxygen > 0 ? (
              <>
                <DateCellBp
                  timestamp={bloodOxygenTotalValue ? bloodOxygenTotalValue?.timeStamp : ''}
                  timeZone={patient?.address?.timeZone}
                />
              </>
            ) : (
              <div style={{ height: '13px' }}></div>
            )}
          </Box>
        </>
      }
    </div>
  );
};
