/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { Contact } from './Contact';
import type { CreateDeviceThresholdDto } from './CreateDeviceThresholdDto';
import type { OrganizationNotes } from './OrganizationNotes';

export type CreateOrganizationDto = {
    isActive: boolean;
    language: string;
    name: string;
    type: CreateOrganizationDto.type;
    parentId?: string;
    contacts?: Array<Contact>;
    threshold: Array<CreateDeviceThresholdDto>;
    notes?: Array<OrganizationNotes>;
    address: Address;
};

export namespace CreateOrganizationDto {

    export enum type {
        CLINIC = 'clinic',
        ACCOUNT = 'account',
    }


}

