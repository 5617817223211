/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Contact = {
    contact: string;
    name: string;
    type: Contact.type;
    isAlternate: boolean;
    disclosePHI: boolean;
    disclosePHIUpdatedBy: string;
    disclosePHIUpdatedAt: string;
    id: string;
    createdAt: string;
    updatedAt: string;
};

export namespace Contact {

    export enum type {
        EMAIL = 'email',
        PHONE = 'phone',
        FAX = 'fax',
    }


}

