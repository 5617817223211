import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react';
import type { Column, Cell, SortingRule } from 'react-table';
import { Box, Button, Flex, Grid, Heading, HStack, Icon, Spinner, Text } from '@rhythm/components';
import { useMutation } from 'react-query';
import { DataTable } from '~src/components/DataTable';
import { Search } from '../Search';
import { stripCharacters, telMask, globalThresholdDefaults } from '~src/utils';
import { formatPhoneNumber } from '~src/utils/formatPhoneNumber';
import { UserManagementModal } from '../Settings/UserManagementModal';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import toast from 'react-hot-toast';
import { Errors } from '~src/pages/CreateAccount/CreateAccount';
import { FormField, MaskedInput } from '../FormField';
import { FormSelect } from '../FormSelect';
import {
  Contact,
  CreateDeviceThresholdDto,
  CreateUserDto,
  Organization,
  PatientContact,
  DefaultService as Service,
  UserContact,
  User,
} from '~generated';
import { useFetchListOrganization, useClinicUsers, UseListUsers } from '~src/services/clinics';
import { FormOption } from '../FormSelect/FormSelect';
import { thresholdInformation } from '~src/pages/CreateAccount/formFields';
import RoleBasedRoutes from '../RoleBasedRoutes';
import {
  editUserRoles,
  editUserRolesRoles,
  viewUserRoles,
  createUserRoles,
} from '~src/constants/roles';
import { credentialOptions } from '~src/constants/credential';
import { useUpdateUser, useDeactivateUser } from '~src/services/users';
import { ROLE_TYPES } from '~src/types';
import { TextButton } from '@rhythm/components';
import { OnFetchProps } from '@rhythm/components/dist/DataTable/DataTable';
import { PaginatedResponse } from '~src/services/teams';
import { useDebounce } from 'react-use';
import { ResponseError } from '~src/services/patients';
import { formatUserTable } from '../Settings/UserManagement';
import { getAccountData } from '../Settings/AccountManagement';

const Cells: React.FC = (): ReactElement => {
  return <Icon icon="edit" color="primary.400" boxSize="sm" />;
};

interface UserRow extends Record<string, unknown> {
  id: string;
  name: string;
  accountName: string;
  phoneNumber?: string;
  clinics: Organization[];
  role: string;
  email: string;
}

export interface UserPaginationParams extends OnFetchProps {
  goToPage?: number | undefined;
  initialSize?: number | undefined;
  defaultSortBy?: SortingRule<unknown>[];
  searchWord?: string;
  totalItemsCount?: number;
}

interface InitialValues {
  firstName: string;
  lastName: string;
  credentials: string[] | unknown;
  phone: string;
  bpSystolic: number[];
  bpDiastolic: number[];
  pulse: number[];
  glucose: number[];
  weightChange24h: number;
  weightChange7days: number;
  email: string;
  organizations: string[] | unknown;
  role: string;
  clinics: Array<string> | unknown;
}

type UserManagementType = {
  id?: string;
  role?: string;
  type?: 'providers' | 'non-provider';
  childrenId?: string[];
  setUserCount?: (count: number) => void;
};

export interface GetAllUsersProps {
  offset?: number;
  limit?: number;
  isNew?: boolean;
  searchTerm?: string;
  sortBy?: string;
  sort?: string;
}

const formatUserNameForDeactivationAllert = (name: string) => {
  if (!name) {
    return name;
  }
  const nameArray = name.split(' ');
  if (nameArray.length > 2) {
    nameArray.splice(2);
    nameArray[1] = nameArray[1].replace(',', '');
    return nameArray.join(' ');
  }
  return name;
};

export function InternalUserManagement(props: UserManagementType) {
  const DEFAULT_PAGE_SIZE = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [queryData, setQueryData] = useState<UseListUsers>({
    sort: 'ASC',
    offset: 0,
    search: '',
    limit: DEFAULT_PAGE_SIZE,
    filter: {
      isActive: true,
      ...(props.id && { id: props.id }),
      ...(props.id && props.childrenId?.length && { childrenId: [...props.childrenId, props.id] }),
    },
  });
  const getLocalStorageData = () => {
    return {
      pageIndex: 0,
      initialSize: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      defaultSortBy: [],
      goToPage: 0,
      totalCount: 0,
      searchWord: '',
      filters: {},
    };
  };

  const [pagination, setPagination] = useState<UserPaginationParams>({
    ...getLocalStorageData(),
  });
  const [pageParams, setPageParams] = useState({ totalSize: 0, totalPageCount: 0 });
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [sortBy, setSortBy] = useState<string>('');
  const [sort, setSort] = useState<string>('');
  const updateUser = useUpdateUser();

  const [open, setOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserDetails, setSelectedUserDetails] = useState({} as any);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const { data: orgData, isLoading: orgLoading } = useFetchListOrganization({});
  let organizations = orgData as Organization[];
  const { data: clinicData, isLoading: clinicLoading } = useFetchListOrganization({
    filter: { clinic: true, id: props.id },
  });

  useEffect(() => {
    setIsLoadingData(true);
    if (!orgLoading) {
      setIsLoadingData(false);
    }
  }, [queryData, orgLoading, clinicLoading]);

  let clinics = clinicData as Organization[];

  const paginatedData = useClinicUsers({ ...queryData }) as unknown as PaginatedResponse;

  const users = paginatedData?.data?.data;
  const paginationInfo = paginatedData?.data?.pagination;

  const totalUsersCount = paginationInfo?.count || 0;

  useEffect(() => {
    if (users) {
      if (totalUsersCount < pagination.pageSize) {
        setPageParams({
          totalPageCount: 1,
          totalSize: totalUsersCount,
        });
      } else {
        setPageParams({
          totalPageCount: Math.ceil(totalUsersCount / pagination?.pageSize),
          totalSize: totalUsersCount,
        });
      }
    }
  }, [users]);

  const parseSearchColumn = (name?: string) => {
    if (!name) return '';
    const splitName = name?.split(' ').filter((e) => e);
    if (splitName.length > 1) return `${splitName.join('&')}`;
    return splitName[0];
  };

  useDebounce(
    async () => {
      if (parseSearchColumn(searchTerm) !== pagination?.searchWord) {
        setPagination({
          ...pagination,
          pageIndex: 0,
          goToPage: 0,
          initialSize: 0,
          searchWord: searchTerm,
        });
      }
    },
    250,
    [searchTerm]
  );

  const fetchData = useCallback(
    ({ offset, limit, sortBy, sort, searchTerm }: GetAllUsersProps) => {
      const updatedQueryData: any = {};
      updatedQueryData.offset = offset;
      updatedQueryData.limit = limit;
      updatedQueryData.search = searchTerm;
      updatedQueryData.filter = {
        isActive: true,
      };
      if (props.id) {
        updatedQueryData.filter = {
          ...updatedQueryData.filter,
          ...(props.id && { id: props.id }),
          ...(props.id &&
            props.childrenId?.length && { childrenId: [...props.childrenId, props.id] }),
        };
      }
      if (sortBy !== undefined && sort !== undefined) {
        updatedQueryData.sortBy = sortBy;
        updatedQueryData.sort = sort ? 'DESC' : 'ASC';
      }
      setQueryData(updatedQueryData);
    },
    [setQueryData]
  );

  useEffect(() => {
    fetchData({ offset, limit, sortBy, sort, searchTerm });
  }, [offset, limit, sortBy, sort, pagination.searchWord, fetchData]);

  const refetchUserData = (paginatedData as any).refetch;
  const clinicId = props?.id;
  const deactivateUser = useDeactivateUser(selectedUserDetails);

  if (clinicId) {
    organizations = organizations?.filter((users) => {
      if (users?.id === clinicId) return true;
      return false;
    });

    clinics = clinics?.filter((users) => {
      if (users?.id === clinicId) return true;
      return false;
    });
  }

  const handleShowClick = (row: any) => {
    const id: any = row.original.id;
    const email: any = row.original.email;
    const name: any = row.original.name;
    setSelectedUser(id);
    setSelectedUserDetails({ id, email, name: formatUserNameForDeactivationAllert(name) });
    setShowViewModal(true);
  };

  const handleEditClick = (row: any) => {
    const id: any = row.original.id;
    const email: any = row.original.email;
    const name: any = row.original.name;
    setSelectedUser(id);
    setSelectedUserDetails({ id, email, name: formatUserNameForDeactivationAllert(name) });
    setShowEditModal(true);
  };

  const internalrole = {
    ROLES_INTERNAL_SUPER_ADMIN: 'roles:internal:super-admin',
    ROLES_INTERNAL_ADMIN: 'roles:internal:admin',
    ROLES_INTERNAL_NON_CLINICAL: 'roles:internal:non-clinical',
    ROLES_INTERNAL_CLINICAL: 'roles:internal:clinical',
    ROLES_INTERNAL_LEAD_CLINICIAN: 'roles:internal:lead-clinician',
    ROLES_EXTERNAL_NON_PROVIDER: 'roles:external:non-provider',
  };
  const rolesValues = Object.values(internalrole);

  const rolesFormatted = Object.keys(internalrole).map((role, index) => ({
    label: role,
    value: rolesValues[index],
  }));

  const formatOrganization = (organization: Organization[]): FormOption[] => {
    return organization?.map((org: Organization) => ({ value: org.id, label: org.name }));
  };

  let orgFormatted: FormOption[] = [];
  orgFormatted = [...formatOrganization(organizations || []), ...orgFormatted];
  orgFormatted = [...formatOrganization(clinics || []), ...orgFormatted];

  function PrintPhoneNumber({ phoneNumber }: UserRow) {
    return <>{phoneNumber && <Text>{formatPhoneNumber(phoneNumber)}</Text>}</>;
  }

  const sortItems = (prev: any, curr: any, columnId: string) => {
    if (prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()) {
      return 1;
    } else if (prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()) {
      return -1;
    } else {
      return 0;
    }
  };

  const columns: Column<UserRow>[] | any = [
    {
      Header: 'User',
      accessor: 'name',
      sortType: (prev: any, curr: any) => sortItems(prev, curr, 'name'),
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: (prev: any, curr: any) => sortItems(prev, curr, 'email'),
    },
    {
      Header: 'Phone',
      accessor: 'phoneNumber',
      Cell: ({ row: { original } }: Cell<UserRow, string>) => {
        return <PrintPhoneNumber {...original} />;
      },
    },
    {
      Header: 'Role',
      accessor: 'role',
      sortType: (prev: any, curr: any) => sortItems(prev, curr, 'role'),
    },
    {
      Header: '',
      accessor: 'editTeam',
      width: 2,
      Cell: Cells,
    },
  ];

  const initialValues: InitialValues = {
    firstName: '',
    lastName: '',
    credentials: [],
    phone: '',
    email: '',
    bpSystolic: globalThresholdDefaults.bpSystolic,
    bpDiastolic: globalThresholdDefaults.bpDiastolic,
    pulse: globalThresholdDefaults.pulse,
    weightChange24h: thresholdInformation.weightChange24h,
    weightChange7days: thresholdInformation.weightChange7days,
    glucose: thresholdInformation.glucose,
    organizations: [],
    role: '',
    clinics: [],
  };

  const userInitialValues = users?.find((user: User) => user.id === selectedUser);
  const formattedCredentials = userInitialValues?.credentials?.map((credential) => ({
    label: credential.credential,
    value: credential.id,
  }));
  const filteredClinics = userInitialValues?.organizations.filter((org) => {
    return org.type === 'clinic';
  });
  const editInitialValues: InitialValues = {
    firstName: userInitialValues?.firstName || '',
    lastName: userInitialValues?.lastName || '',
    credentials: formattedCredentials || [],
    phone: (userInitialValues?.contacts && userInitialValues?.contacts[0]?.contact) || '',
    email: userInitialValues?.email || '',
    bpSystolic: globalThresholdDefaults.bpSystolic,
    bpDiastolic: globalThresholdDefaults.bpDiastolic,
    pulse: globalThresholdDefaults.pulse,
    weightChange24h: thresholdInformation.weightChange24h,
    weightChange7days: thresholdInformation.weightChange7days,
    glucose: thresholdInformation.glucose,
    organizations: userInitialValues?.organizations[0]?.id || [],
    role: userInitialValues?.role,
    clinics: [...formatOrganization(filteredClinics || [])],
  };

  formatUserTable(users, props);

  const notifySuccess = (name: string) => toast.success(`${name} has been successfully created.`);

  const notifyError = (name: string) =>
    toast.error(`There was an error while creating ${name}'s' account.`);

  const saveUser = async (data: InitialValues) => {
    const userData = data as unknown as FormikValues;

    const thresholdValues: CreateDeviceThresholdDto[] = [];

    const contacts: any[] = [];

    if (data.phone) {
      contacts.push({
        isAlternate: false,
        name: `${data.firstName} ${data.lastName}`,
        contact: stripCharacters(data.phone),
        isPreferred: true,
        type: Contact.type.PHONE,
      } as PatientContact);
    }

    try {
      const formattedPayload = {
        ...userData,
        gender: User.gender.MALE,
        isActive: true,
        organizations: [{ id: userData.organizations }],
        contacts,
        threshold: thresholdValues,
        status: CreateUserDto.status.REGISTERED,
        role: userData.role,
      } as unknown as CreateUserDto;
      await Service.userControllerCreate(formattedPayload);
      setOpen(false);
      notifySuccess(`${userData.firstName}`);
    } catch (err) {
      notifyError(`${userData.firstName}`);
      // throw new Error(err as string);
      return err as ResponseError;
    }
  };

  const { isLoading } = useMutation(saveUser, {
    onSuccess: () => {
      setOpen(false);
      refetchUserData();
    },
  });

  const handleSubmit = async (
    values: InitialValues,
    { setErrors }: FormikHelpers<CreateUserDto>
  ): Promise<void> => {
    if (values.role !== ROLE_TYPES.PROVIDER && values.role !== ROLE_TYPES.NON_PROVIDER) {
      values.clinics = [];
    }
    const res = await saveUser(values);
    const errorMessage = await res?.body.message;
    if (errorMessage.toLowerCase().includes('already exists')) {
      setErrors({ email: 'Email already exists.' });
    }
  };

  const handleUpdate = async (values: InitialValues): Promise<void> => {
    const org = organizations.find((org) => org.id === values.organizations);
    if (values.role !== ROLE_TYPES.PROVIDER && values.role !== ROLE_TYPES.NON_PROVIDER) {
      values.clinics = [];
    }

    const formattedOrg = {
      ...org,
    };

    delete formattedOrg.patients;

    const contacts: any[] = [];
    contacts.push({
      name: `${values.firstName} ${values.lastName}`,
      contact: stripCharacters(values.phone),
      type: Contact.type.PHONE,
    } as UserContact);

    const payload = {
      id: selectedUser,
      ...values,
      status: userInitialValues?.status,
      organizations: [formattedOrg],
      contacts,
    };

    await updateUser.mutate(payload);
    refetchUserData();
    setShowEditModal(false);
  };

  const handleValidate = (values: FormikValues): Errors => {
    const errors: Errors = {};

    if (!values?.firstName.trim()) {
      errors.firstName = 'This field is required';
    } else {
      // trim the value to remove any trailing spaces
      values.firstName = values.firstName.trim();
    }

    if (!values?.lastName.trim()) {
      errors.lastName = 'This field is required';
    } else {
      // trim the value to remove any trailing spaces
      values.lastName = values.lastName.trim();
    }

    if (!values?.phone) {
      errors.phone = 'This field is required';
    }

    if (!values?.email) {
      errors.email = 'This field is required';
    }

    if (!values?.organizations) {
      errors.organizations = 'This field is required';
    }

    if (!values?.role) {
      errors.role = 'This field is required';
    }
    return errors;
  };

  const handleUserDeactivation = (userDetails: { name: string; email: any; id: string }) => {
    const confirmDeactivation = confirm(`Do you wish to delete user ${userDetails.name}?`);
    if (confirmDeactivation) {
      deactivateUser.mutate(userDetails);
      setShowEditModal(false);
    }
  };

  function UserAccountForm(props: {
    organization: Organization[];
    setFieldValue: (field: string, value: number[] | number) => void;
    isEditing: boolean;
    disabled?: boolean;
    getFieldProps: (field: string) => { value: string };
  }) {
    return (
      <Grid>
        <Box mt="2" mr="lg">
          <FormField
            disabled={props.disabled}
            label={'First Name'}
            name="firstName"
            placeholder="Enter a first name"
          />
          <FormField
            disabled={props.disabled}
            label={'Last Name'}
            name="lastName"
            placeholder="Enter a last name"
          />
          <FormSelect
            disabled={props.disabled}
            label={'Credentials'}
            name="credentials"
            options={credentialOptions}
            multi
            clearable
            optionsDisabled={
              props.getFieldProps('credentials').value.length > 1 ? () => true : () => false
            }
          />
          <MaskedInput
            disabled={props.disabled}
            label={'Phone'}
            name="phone"
            format={telMask}
            placeholder="(123) 456 7890"
          />
          <FormField
            disabled={props.disabled}
            label={'Email'}
            name="email"
            placeholder="Enter an email"
          />
          <FormSelect
            label={'Account'}
            disabled={props.isEditing ? true : false}
            name="organizations"
            options={orgFormatted}
          />
          {props.isEditing ? (
            <RoleBasedRoutes allowedRoles={editUserRolesRoles}>
              <FormSelect label={'Role'} name="role" options={rolesFormatted} />
            </RoleBasedRoutes>
          ) : (
            <FormSelect label={'Role'} name="role" options={rolesFormatted} />
          )}
        </Box>
        {props.disabled ? null : (
          <Flex p="xl" borderRadius="8px 8px 0 0" justify="end">
            {props.isEditing ? (
              <Button
                mr={'2'}
                variant="danger"
                disabled={props.disabled}
                onClick={() => handleUserDeactivation(selectedUserDetails)}
              >
                Remove User
              </Button>
            ) : null}
            <Button
              form="create-user"
              type="submit"
              id="submit-form"
              disabled={props.disabled}
              isLoading={isLoading}
            >
              {props.isEditing ? `Save Changes` : `Create User`}
            </Button>
          </Flex>
        )}
      </Grid>
    );
  }

  interface UserFormProps {
    initialValues: InitialValues;
    handleSubmit: any;
    isEditing?: boolean;
    disabled?: boolean;
  }

  const UserForm = ({
    initialValues,
    handleSubmit,
    isEditing = false,
    disabled = false,
  }: UserFormProps) => {
    return (
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={(values: FormikValues) => handleValidate(values)}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, getFieldProps }) => (
          <Form id="create-user">
            <Box sx={{ position: 'relative' }}>
              <Box bg="white" p="3xl" borderRadius="8px">
                <Flex align="center" mb="2xl">
                  <Box bg="neutral.200" p={6} mr={5} borderRadius={4}>
                    <Icon color="primary.600" icon="file" />
                  </Box>
                  <Heading variant="h5">
                    {isEditing ? (disabled ? 'View a User' : 'Edit a User') : 'Create a User'}
                  </Heading>
                </Flex>
                <UserAccountForm
                  organization={values.organizations as Organization[]}
                  setFieldValue={setFieldValue}
                  isEditing={isEditing}
                  disabled={disabled}
                  getFieldProps={getFieldProps}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  if (!users || !organizations) return null;

  const formattedUsers = formatUserTable(users, props);

  if (formattedUsers) {
    if (props.setUserCount) {
      props.setUserCount(paginationInfo?.count);
    }
  }

  const getData = (data: any): void => {
    getAccountData(
      data,
      pagination,
      setPagination,
      offset,
      setOffset,
      limit,
      setLimit,
      sortBy,
      setSortBy,
      setSort
    );
  };

  if (clinicLoading || orgLoading || isLoadingData) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '100px' }}>
        <span>
          <Spinner />
        </span>
      </div>
    );
  }

  return (
    <Fragment>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        padding="xl"
        marginTop="xl"
        marginBottom="l"
        background={'#FFFFFF'}
      >
        <Flex alignItems="center"></Flex>
        <Flex alignItems={'center'}>
          <RoleBasedRoutes allowedRoles={createUserRoles}>
            <TextButton leftIcon="add" onClick={(): void => setOpen(true)}>
              Add New User
            </TextButton>
          </RoleBasedRoutes>
          <Search placeholder="Search Users" onSearchTerm={setSearchTerm} />
        </Flex>
      </HStack>
      <RoleBasedRoutes allowedRoles={viewUserRoles}>
        <DataTable
          columns={columns}
          data={formattedUsers}
          onRowClick={handleShowClick}
          isSetting={true}
          hasPagination
          fetchData={(data: any) => getData(data)}
          totalPageCount={pageParams.totalPageCount}
          totalRowCount={pageParams.totalSize}
          initialPageSize={DEFAULT_PAGE_SIZE}
        />
      </RoleBasedRoutes>
      <RoleBasedRoutes allowedRoles={editUserRoles}>
        {users.length === 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
            <span style={{ fontSize: '16px', fontWeight: '600' }}>No Accounts found</span>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={formattedUsers}
            onRowClick={handleEditClick}
            isSetting={true}
            hasPagination
            fetchData={(data: any) => getData(data)}
            totalPageCount={pageParams.totalPageCount}
            totalRowCount={pageParams.totalSize}
            initialPageSize={DEFAULT_PAGE_SIZE}
          />
        )}
      </RoleBasedRoutes>
      <UserManagementModal
        nextStep={() => ''}
        setClose={() => {
          setOpen(false);
        }}
        buttonText={'Save'}
        open={open}
      >
        <UserForm initialValues={initialValues} handleSubmit={handleSubmit} />
      </UserManagementModal>
      <UserManagementModal open={showEditModal} setClose={() => setShowEditModal(false)}>
        <UserForm initialValues={editInitialValues} isEditing handleSubmit={handleUpdate} />
      </UserManagementModal>

      <UserManagementModal open={showViewModal} setClose={() => setShowViewModal(false)}>
        <UserForm
          initialValues={editInitialValues}
          isEditing
          disabled
          handleSubmit={handleUpdate}
        />
      </UserManagementModal>
    </Fragment>
  );
}
