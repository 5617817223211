export const timezones = [
  {
    timezoneName: 'America/Adak',
    stdAbbreviation: 'HST',
    dstAbbreviation: 'HDT',
    label: 'America/Adak [HST/HDT]',
    value: 'America/Adak',
  },
  {
    timezoneName: 'America/Anchorage',
    stdAbbreviation: 'AKST',
    dstAbbreviation: 'AKDT',
    label: 'America/Anchorage [AKST/AKDT]',
    value: 'America/Anchorage',
  },
  {
    timezoneName: 'America/Atka',
    stdAbbreviation: 'HST',
    dstAbbreviation: 'HDT',
    label: 'America/Atka [HST/HDT]',
    value: 'America/Atka',
  },
  {
    timezoneName: 'America/Boise',
    stdAbbreviation: 'MST',
    dstAbbreviation: 'MDT',
    label: 'America/Boise [MST/MDT]',
    value: 'America/Boise',
  },
  {
    timezoneName: 'America/Chicago',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'America/Chicago [CST/CDT]',
    value: 'America/Chicago',
  },
  {
    timezoneName: 'America/Denver',
    stdAbbreviation: 'MST',
    dstAbbreviation: 'MDT',
    label: 'America/Denver [MST/MDT]',
    value: 'America/Denver',
  },
  {
    timezoneName: 'America/Phoenix',
    stdAbbreviation: 'MST',
    dstAbbreviation: 'MDT',
    label: 'America/Phoenix [MST/MDT]',
    value: 'America/Phoenix',
  },
  {
    timezoneName: 'America/Detroit',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Detroit [EST/EDT]',
    value: 'America/Detroit',
  },
  {
    timezoneName: 'America/Fort_Wayne',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Fort_Wayne [EST/EDT]',
    value: 'America/Fort_Wayne',
  },
  {
    timezoneName: 'America/Indiana/Indianapolis',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Indiana/Indianapolis [EST/EDT]',
    value: 'America/Indiana/Indianapolis',
  },
  {
    timezoneName: 'America/Indiana/Knox',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'America/Indiana/Knox [CST/CDT]',
    value: 'America/Indiana/Knox',
  },
  {
    timezoneName: 'America/Indiana/Marengo',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Indiana/Marengo [EST/EDT]',
    value: 'America/Indiana/Marengo',
  },
  {
    timezoneName: 'America/Indiana/Petersburg',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Indiana/Petersburg [EST/EDT]',
    value: 'America/Indiana/Petersburg',
  },
  {
    timezoneName: 'America/Indiana/Tell_City',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'America/Indiana/Tell_City [CST/CDT]',
    value: 'America/Indiana/Tell_City',
  },
  {
    timezoneName: 'America/Indiana/Vevay',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Indiana/Vevay [EST/EDT]',
    value: 'America/Indiana/Vevay',
  },
  {
    timezoneName: 'America/Indiana/Vincennes',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Indiana/Vincennes [EST/EDT]',
    value: 'America/Indiana/Vincennes',
  },
  {
    timezoneName: 'America/Indiana/Winamac',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Indiana/Winamac [EST/EDT]',
    value: 'America/Indiana/Winamac',
  },
  {
    timezoneName: 'America/Indianapolis',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Indianapolis [EST/EDT]',
    value: 'America/Indianapolis',
  },
  {
    timezoneName: 'America/Juneau',
    stdAbbreviation: 'AKST',
    dstAbbreviation: 'AKDT',
    label: 'America/Juneau [AKST/AKDT]',
    value: 'America/Juneau',
  },
  {
    timezoneName: 'America/Kentucky/Louisville',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Kentucky/Louisville [EST/EDT]',
    value: 'America/Kentucky/Louisville',
  },
  {
    timezoneName: 'America/Kentucky/Monticello',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Kentucky/Monticello [EST/EDT]',
    value: 'America/Kentucky/Monticello',
  },
  {
    timezoneName: 'America/Knox_IN',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'America/Knox_IN [CST/CDT]',
    value: 'America/Knox_IN',
  },
  {
    timezoneName: 'America/Los_Angeles',
    stdAbbreviation: 'PST',
    dstAbbreviation: 'PDT',
    label: 'America/Los_Angeles [PST/PDT]',
    value: 'America/Los_Angeles',
  },
  {
    timezoneName: 'America/Louisville',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/Louisville [EST/EDT]',
    value: 'America/Louisville',
  },
  {
    timezoneName: 'America/Menominee',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'America/Menominee [CST/CDT]',
    value: 'America/Menominee',
  },
  {
    timezoneName: 'America/Metlakatla',
    stdAbbreviation: 'AKST',
    dstAbbreviation: 'AKDT',
    label: 'America/Metlakatla [AKST/AKDT]',
    value: 'America/Metlakatla',
  },
  {
    timezoneName: 'America/New_York',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'America/New_York [EST/EDT]',
    value: 'America/New_York',
  },
  {
    timezoneName: 'America/Nome',
    stdAbbreviation: 'AKST',
    dstAbbreviation: 'AKDT',
    label: 'America/Nome [AKST/AKDT]',
    value: 'America/Nome',
  },
  {
    timezoneName: 'America/North_Dakota/Beulah',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'America/North_Dakota/Beulah [CST/CDT]',
    value: 'America/North_Dakota/Beulah',
  },
  {
    timezoneName: 'America/North_Dakota/Center',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'America/North_Dakota/Center [CST/CDT]',
    value: 'America/North_Dakota/Center',
  },
  {
    timezoneName: 'America/North_Dakota/New_Salem',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'America/North_Dakota/New_Salem [CST/CDT]',
    value: 'America/North_Dakota/New_Salem',
  },
  {
    timezoneName: 'America/Shiprock',
    stdAbbreviation: 'MST',
    dstAbbreviation: 'MDT',
    label: 'America/Shiprock [MST/MDT]',
    value: 'America/Shiprock',
  },
  {
    timezoneName: 'America/Sitka',
    stdAbbreviation: 'AKST',
    dstAbbreviation: 'AKDT',
    label: 'America/Sitka [AKST/AKDT]',
    value: 'America/Sitka',
  },
  {
    timezoneName: 'America/Yakutat',
    stdAbbreviation: 'AKST',
    dstAbbreviation: 'AKDT',
    label: 'America/Yakutat [AKST/AKDT]',
    value: 'America/Yakutat',
  },
  {
    timezoneName: 'Navajo',
    stdAbbreviation: 'MST',
    dstAbbreviation: 'MDT',
    label: 'Navajo [MST/MDT]',
    value: 'Navajo',
  },
  {
    timezoneName: 'Pacific/Honolulu',
    stdAbbreviation: 'HST',
    dstAbbreviation: 'HST',
    label: 'Pacific/Honolulu [HST/HST]',
    value: 'Pacific/Honolulu',
  },
  {
    timezoneName: 'Pacific/Johnston',
    stdAbbreviation: 'HST',
    dstAbbreviation: 'HST',
    label: 'Pacific/Johnston [HST/HST]',
    value: 'Pacific/Johnston',
  },
  {
    timezoneName: 'US/Alaska',
    stdAbbreviation: 'AKST',
    dstAbbreviation: 'AKDT',
    label: 'US/Alaska [AKST/AKDT]',
    value: 'US/Alaska',
  },
  {
    timezoneName: 'US/Aleutian',
    stdAbbreviation: 'HST',
    dstAbbreviation: 'HDT',
    label: 'US/Aleutian [HST/HDT]',
    value: 'US/Aleutian',
  },
  {
    timezoneName: 'US/Arizona',
    stdAbbreviation: 'MST',
    dstAbbreviation: 'MST',
    label: 'US/Arizona [MST/MST]',
    value: 'US/Arizona',
  },
  {
    timezoneName: 'US/Central',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'US/Central [CST/CDT]',
    value: 'US/Central',
  },
  {
    timezoneName: 'US/East-Indiana',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'US/East-Indiana [EST/EDT]',
    value: 'US/East-Indiana',
  },
  {
    timezoneName: 'US/Eastern',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'US/Eastern [EST/EDT]',
    value: 'US/Eastern',
  },
  {
    timezoneName: 'US/Hawaii',
    stdAbbreviation: 'HST',
    dstAbbreviation: 'HST',
    label: 'US/Hawaii [HST/HST]',
    value: 'US/Hawaii',
  },
  {
    timezoneName: 'US/Indiana-Starke',
    stdAbbreviation: 'CST',
    dstAbbreviation: 'CDT',
    label: 'US/Indiana-Starke [CST/CDT]',
    value: 'US/Indiana-Starke',
  },
  {
    timezoneName: 'US/Michigan',
    stdAbbreviation: 'EST',
    dstAbbreviation: 'EDT',
    label: 'US/Michigan [EST/EDT]',
    value: 'US/Michigan',
  },
  {
    timezoneName: 'US/Mountain',
    stdAbbreviation: 'MST',
    dstAbbreviation: 'MDT',
    label: 'US/Mountain [MST/MDT]',
    value: 'US/Mountain',
  },
  {
    timezoneName: 'US/Pacific',
    stdAbbreviation: 'PST',
    dstAbbreviation: 'PDT',
    label: 'US/Pacific [PST/PDT]',
    value: 'US/Pacific',
  },
  {
    timezoneName: 'America/Puerto_Rico',
    stdAbbreviation: 'AST',
    label: 'America/Puerto_Rico [AST]',
    value: 'America/Puerto_Rico',
  },
];
