interface SavedNote {
  label: number | string;
  userId?: string;
  value: string;
}

const savedNotes: SavedNote[] = [
  {
    label: 'HTN',
    value: `Alert: 
Assessment:  
    ▪ Medication review: 
    ▪ Signs and Symptoms: 
    ▪ Diet: 
    ▪ Concerns: 
    ▪ Trends: 
    ▪ Recommendation: 
Educate: When to call 911, chest discomfort > 15 mins, does not improve with nitroglycerin, severe, sudden headache, no relief with typical medications.`,
  },
  {
    label: 'Hypotension',
    value: `Alert:   
Assessment:   
    ▪ Medication review:  
    ▪ Signs and Symptoms: 
    ▪ Hydration:   
    ▪ Concerns:  
    ▪ Trends:
    ▪ Recommendation:
Educate: When to call 911, chest discomfort > 15 mins, does not improve with nitroglycerin, severe, sudden headache, no relief with typical medications, severe or continuing SOB, fainting / passing out.`,
  },
  {
    label: 'Tachycardia',
    value: `Alert:   
Assessment:   
    ▪ Medication review:  
    ▪ Signs and Symptoms: 
    ▪ Trends: 
    ▪ Concerns:  
    ▪ Recommendation:
Educate: When to call 911, chest discomfort > 15 mins, does not improve with nitroglycerin, severe, sudden headache, no relief with typical medications, severe or continuing SOB, fainting / passing out. `,
  },
  {
    label: 'Bradycardia',
    value: `Alert:  
Assessment:   
    ▪ Medication review:  
    ▪ Signs and Symptoms: 
    ▪ Trends: 
    ▪ Concerns:  
    ▪ Recommendation:
Educate: When to call 911, chest discomfort > 15 mins, does not improve with nitroglycerin, severe, sudden headache, no relief with typical medications, severe or continuing SOB, fainting / passing out. `,
  },
  {
    label: 'Hypoglycemia',
    value: `Alert:   
Assessment:   
    ▪ Medication review:  
    ▪ Signs and Symptoms: 
    ▪ Diet: 
    ▪ Trends: 
    ▪ Concerns:
    ▪ Recommendation:
Educate: When to call 911, can't control blood sugar with typical interventions (juice, meds, etc.), can't resolve symptoms with typical intervention `,
  },
  {
    label: 'Hyperglycemia',
    value: `Alert:  
Assessment:   
    ▪ Medication review:  
    ▪ Signs and Symptoms: 
    ▪ Diet: 
    ▪ Trends: 
    ▪ Concerns: 
    ▪ Recommendation:
Educate: When to call 911, can't control blood sugar with typical interventions (juice, meds, etc.), can't resolve symptoms with typical intervention`,
  },
  {
    label: 'Heart Failure',
    value: `Alert:  
Assessment:   
    ▪ Medication review:  
    ▪ Signs and Symptoms: 
    ▪ Diet: 
    ▪ Trends: 
    ▪ Concerns: 
    ▪ Recommendation:
Educate: When to call 911 or seek emergency care, chest discomfort >15 mins, does not improve with nitroglycerin, severe, continuing SOB, Fainting/passing out, wake up suddenly struggling to breath `,
  },
  {
    label: 'COPD',
    value: `Alert:  
Assessment: 
    ▪ Medication review:  
    ▪ Signs and Symptoms: 
    ▪ Diet: 
    ▪ Trends:
    ▪ Concerns: 	 
    ▪ Recommendation:
Educate: When to call 911 or seek emergency care, chest pain. difficulty breathing or talking, fever, racing heart rate, fingernails that turn blue/gray (poor perfusion), recommended treatment is not working, and symptoms are getting worse, slurred Speech, disorientation, confusion, dizziness, difficulty awakening from sleep. `,
  },
];

export { savedNotes };
