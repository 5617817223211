import { Box } from '@rhythm/components';
import { ReactNode } from 'react';

interface SurfaceProps {
  children: ReactNode | ReactNode[];
  gutterBottom?: boolean;
}

export function Surface({ children, gutterBottom = true }: SurfaceProps) {
  return (
    <Box bg="white" p="3xl" borderRadius="md" mb={gutterBottom ? '4' : ''}>
      {children}
    </Box>
  );
}
