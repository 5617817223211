import {
  DeviceVendor,
  GetUserOnboardedPatientsView,
  Roles,
  DeviceThreshold,
  Organization,
} from '~generated';
import { ThresholdInformation, thresholdInformation } from '../pages/CreateAccount/formFields';
import { useFetch, UseQueryResult } from '~src/services/query';
import { globalConfig } from '../configuration/config';
import { AuthState } from '@okta/okta-auth-js';
import { PaginatedResponse } from './teams';

type Order = 'ASC' | 'DSC';
export type Sort = `${keyof DeviceThreshold}:${Order}`;

export interface Filter {
  limit?: number;
  offset?: number;
  sort?: Sort;
  id?: string;
}
export interface OrgId {
  id: string;
}
export interface OrganizationsList {
  id?: string;
  page?: number;
  path?: string;
  filter?: OrgFilter;
  sort?: string;
  offset?: number;
  limit?: number;
}

type OrgFilter = {
  clinic?: boolean;
  id?: string;
};

type ClinicFilter = {
  id?: string;
  childrenId?: string[];
  isActive?: boolean;
  forAlertsDropDown?: boolean;
  userRoles?: Roles[];
};

export interface UseListUsers {
  page?: number;
  path?: string;
  filter?: ClinicFilter;
  isActive?: boolean;
  sort?: string;
  offset?: number;
  limit?: number;
  search?: string;
  bypassPagination?: boolean;
}
export interface UseListOrganization {
  page?: number;
  path?: string;
  filter?: OrgFilter;
  sort?: string;
  offset?: number;
  limit?: number;
  includePatientAndUserCounts?: boolean;
  search?: string;
}
export interface PhysiciansList {
  ids?: string;
}
export interface UsePatientsCountList {
  id?: string;
  page?: number;
  path?: string;
  filter?: OrgFilter;
}
export interface UseUsersCountList {
  id?: string;
  page?: number;
  path?: string;
  filter?: OrgFilter;
  sort?: string;
  offset?: number;
  limit?: number;
}

export interface PaginationResult {
  organizations: Organization[];
  total: number;
  currentOffset: number;
}
export interface PhysiciansList {
  ids?: string;
}

export function useListDeviceThreshold({
  limit,
  offset,
  sort,
}: Filter): UseQueryResult<DeviceThreshold[]> {
  return useFetch({
    key: ['threshold', limit, offset, sort],
    path: '/device/threshold',
    queryParams: {
      sort,
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useSingleOrganization({ id }: OrgId): UseQueryResult<Organization> {
  try {
    return useFetch({
      key: ['organization', id],
      path: `/organization/${id}`,
      queryOptions: {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      },
    });
  } catch (err) {}

  return {} as any;
}

export function useListDeviceVendors({
  limit,
  offset,
  sort,
}: Filter): UseQueryResult<DeviceVendor[]> {
  return useFetch({
    key: ['vendors', limit, offset, sort],
    path: '/device/vendors',
    queryParams: {
      sort,
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useClinicUsers({
  page,
  filter,
  limit = 10,
  offset = 0,
  sort = 'ASC',
  search,
  bypassPagination = false,
}: UseListUsers): UseQueryResult<PaginatedResponse> {
  return useFetch<PaginatedResponse>({
    key: ['user', filter, sort, limit, offset, search],
    path: '/user',
    queryParams: {
      page,
      filter: JSON.stringify(filter),
      limit,
      offset,
      sort,
      search,
      ...(bypassPagination && { bypassPagination }),
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useGetOnboardedPatients(
  shouldFetch: boolean
): UseQueryResult<GetUserOnboardedPatientsView[]> {
  return useFetch<GetUserOnboardedPatientsView[]>({
    key: ['userOnboardedPatients'],
    path: '/user/onboardedPatients',
    enabled: shouldFetch,
  });
}

export const deriveThresholds = (): ThresholdInformation => {
  return { ...thresholdInformation } as ThresholdInformation;
};

export function useListOrganization({
  page,
  filter = { clinic: false, id: '' },
  sort = 'ASC',
  offset,
  limit,
  search,
  includePatientAndUserCounts = true,
}: UseListOrganization): UseQueryResult<PaginationResult> {
  return useFetch({
    key: ['organization', page, filter, sort, offset, limit, search],
    path: '/organization',
    queryParams: {
      filter: JSON.stringify(filter),
      sort,
      offset,
      limit,
      search,
      includePatientAndUserCounts,
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useFetchListOrganization({
  page,
  filter = { clinic: false, id: '' },
  sort = 'ASC',
  offset,
  limit,
}: UseListOrganization): UseQueryResult<Organization[]> {
  return useFetch<Organization[]>({
    key: ['organization', page, filter, sort, offset, limit, 'org'],
    path: '/organization/organizations',
    queryParams: {
      filter: JSON.stringify(filter),
      sort,
      offset,
      limit,
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useOrganizationBySortingOrder({
  page,
  filter = { clinic: false, id: '' },
}: UseListOrganization): UseQueryResult<Organization[]> {
  return useFetch<Organization[]>({
    key: ['organization', page, filter],
    path: '/organization/organizationBySort',
    queryParams: {
      filter: JSON.stringify(filter),
    },
    queryOptions: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  });
}

export function useFetchPatientsCount({
  id,
  page,
  filter,
}: UsePatientsCountList): UseQueryResult<any> {
  try {
    return useFetch({
      key: ['organization', id, page, filter, 'patient'],
      path: `/organization/patientsCount/${id}`,
      queryOptions: {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      },
    });
  } catch (err) {}

  return {} as any;
}

export function useFetchUsersCount({ id, page, filter }: UseUsersCountList): UseQueryResult<any> {
  try {
    return useFetch({
      key: ['organization', id, page, filter, 'users'],
      path: `/organization/usersCount/${id}`,
      queryOptions: {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      },
    });
  } catch (err) {}

  return {} as any;
}

export async function useFetchPhysiciansByAccount(
  ids: { ids: any },
  authState: AuthState | null
): Promise<any> {
  const config = globalConfig.get();
  const response = await fetch(`${config.apiUrl}/user/fetchPhysiciansByOrgId`, {
    method: 'POST',
    body: JSON.stringify(ids),
    headers: {
      Authorization: 'Bearer ' + authState?.accessToken?.accessToken,
      'content-type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch physicians report');
  }
  return JSON.parse(await response?.text());
}
