import { Box, Grid, Heading } from '@rhythm/components';
import React, { ReactElement } from 'react';
import { FormCheck } from '~src/components/FormCheck';
import { FormField, MaskedInput } from '~src/components/FormField';
import { FormSelect } from '~src/components/FormSelect';
import { relationsOptionsArray } from '~src/constants';
import { telMask } from '~src/utils';
import { EnrollPatientFormValues } from '../EnrollPatientForm';
import { AltContactFields } from '../formFields';

type AltContactFormProps = {
  values: EnrollPatientFormValues;
};

const AltContactForm: React.FC<AltContactFormProps> = (props): ReactElement => {
  return (
    <Box>
      <Heading variant="h5" mt="4xl" mb="2xl">
        Alternate Contact Information
      </Heading>
      <Grid templateColumns="repeat(3, 1fr)" gap="5xl">
        <Box>
          <FormField
            name="altFirstName"
            label={AltContactFields.ALT_FIRST_NAME}
            placeholder="First Name"
          />
          <FormField
            name="altLastName"
            label={AltContactFields.ALT_LAST_NAME}
            placeholder="Last Name"
          />
        </Box>
        <Box>
          <MaskedInput
            name="altPhoneNumber"
            label={AltContactFields.ALT_PHONE_NUMBER}
            format={telMask}
            placeholder="Phone Number"
          />
          <FormField name="altEmail" label={AltContactFields.ALT_EMAIL} placeholder="Email" />
        </Box>
        <Box>
          <FormSelect
            options={relationsOptionsArray}
            name="altRelation"
            label={AltContactFields.ALT_RELATION}
          />
          <FormCheck name="isPreferredContact" label={AltContactFields.IS_PREFFERED}>
            {props?.values?.isPreferredContact ? `Yes` : `No`}
          </FormCheck>
          <FormCheck name="disclosePHI" label={AltContactFields.DISCLOSE_PHI}>
            {props?.values?.disclosePHI ? `Yes` : `No`}
          </FormCheck>
        </Box>
      </Grid>
    </Box>
  );
};

export default AltContactForm;
