import { Box, Button, Grid, GridItem, Heading } from '@rhythm/components';
import { FieldArray, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { FormSelect } from '~src/components/FormSelect';
import { FormOption } from '~src/components/FormSelect/FormSelect';
import { UseListTeam, useListTeam } from '~src/services/teams';
import { EnrollPatientFormValues } from '../EnrollPatientForm';

type ClinicalCareTeamFormProps = {
  initialTeamId?: string;
};

const ClinicalCareTeamForm: React.FC<ClinicalCareTeamFormProps> = (props) => {
  const {
    setFieldValue,
    values: { clinicians, isEditing },
  } = useFormikContext<EnrollPatientFormValues>();
  const [clinicalCareTeamOptions, setClinicalCareTeamOptions] = useState([]) as any;
  const [clinicalCareTeamSelected, setClinicalCareTeamSelected] = useState() as any;
  const [clinicalCareClinicianOptions, setClinicalCareClinicianOptions] = useState([]) as any;
  const [queryData, setQueryData] = useState<UseListTeam>({
    bypassPagination: true,
  });

  const fetchData = useCallback(() => {
    const updatedQueryData = {};
    setQueryData(updatedQueryData);
  }, [setQueryData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const paginatedData = useListTeam({ ...queryData });
  const teamsData = paginatedData?.data?.data;

  useEffect(() => {
    if (props.initialTeamId && teamsData) {
      setClinicalCareTeamSelected(props.initialTeamId);
    }
  }, [props.initialTeamId, teamsData]);

  useEffect(() => {
    if (teamsData) {
      const teamOptions = teamsData.map((team) => {
        return {
          label: team.teamName,
          value: team.id,
        };
      });
      setClinicalCareTeamOptions(teamOptions);
    }
  }, [teamsData]);

  const checkIfOptionAlreadySelected = (userId: any) => {
    for (let i = 0; i < clinicians.length; i++) {
      if (clinicians[i].id === userId) {
        return true;
      }
    }
  };

  useEffect(() => {
    if (clinicalCareTeamSelected && teamsData) {
      const selectedTeam = teamsData?.find((team) => team.id === clinicalCareTeamSelected);
      const clinicians = selectedTeam?.members;
      const getClinicianCreds = (userId: string) => {
        const clinician = clinicians?.find((clinician) => clinician?.user?.id === userId);
        const creds: string[] = [];
        clinician?.user.credentials?.forEach((credential) => {
          creds.push(credential.credential);
        });
        const joinedCreds = creds.join(', ');
        return creds.length > 0 ? `, ${joinedCreds}` : '';
      };
      let clinicianOptions = clinicians?.map((clinician) => {
        return {
          label: `${clinician?.user?.lastName}, ${clinician?.user?.firstName}${getClinicianCreds(
            clinician?.user?.id
          )}`,
          value: clinician?.user?.id,
          disabled: checkIfOptionAlreadySelected(clinician?.user?.id),
        };
      });
      if (selectedTeam?.teamLead) {
        const teamLead = {
          label: `${selectedTeam?.teamLead?.lastName}, ${selectedTeam?.teamLead?.firstName}${
            selectedTeam?.teamLead?.credentials?.length > 0
              ? `, ${selectedTeam?.teamLead?.credentials
                  ?.map((credential) => credential.credential)
                  .join(', ')}`
              : ''
          }`,
          value: selectedTeam?.teamLead?.id,
          disabled: checkIfOptionAlreadySelected(selectedTeam?.teamLead?.id),
        };
        const teamLeadIndex = clinicianOptions?.findIndex(
          (clinician) => clinician.value === teamLead.value
        );
        if (teamLeadIndex === -1) {
          clinicianOptions?.push(teamLead);
        }
      }

      clinicianOptions = clinicianOptions?.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });

      setClinicalCareClinicianOptions(clinicianOptions);
    }
  }, [clinicalCareTeamSelected, clinicians]);

  const isOptionDisabled = (option: FormOption) => {
    return option.disabled;
  };

  return (
    <Box mt="2xl">
      <Heading variant="h5" mb="2xl">
        Clinical Care Team
      </Heading>
      <Grid templateColumns="repeat(3, 1fr)" columnGap={'2xl'}>
        <GridItem colSpan={1}>
          <FormSelect
            name="team"
            label={isEditing ? 'Select team *' : 'Team *'}
            options={clinicalCareTeamOptions}
            onInputChange={(value) => {
              setClinicalCareTeamSelected(value);
              setFieldValue('clinicians', [{ id: '' }]);
            }}
          />
        </GridItem>
        <GridItem colSpan={1} rowStart={2}>
          <Heading variant="h6" fontWeight="bold" mb="lg">
            Clinicians *
          </Heading>
          <FieldArray
            name="clinicians"
            render={(arrayHelpers) => (
              <Box>
                {clinicians?.map((member: any, index: any) => (
                  <Box
                    key={member?.id}
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                    height={'xl'}
                    mt="md"
                  >
                    <Box width={'100%'}>
                      <FormSelect
                        style={{
                          marginTop: '1.7rem',
                        }}
                        label={''}
                        options={clinicalCareClinicianOptions}
                        name={`clinicians.${index}.id`}
                        placeholder="Clinician Name"
                        onInputChange={(value) => {
                          setFieldValue(`clinicians.${index}.id`, value);
                        }}
                        disabled={!clinicalCareTeamSelected}
                        optionsDisabled={isOptionDisabled}
                      />
                    </Box>
                    {clinicians?.length > 1 && isEditing ? (
                      <Button
                        height={'2.7rem'}
                        size={'md'}
                        variant="plain"
                        borderColor="#B8C0CD"
                        color="#B8C0CD"
                        borderWidth={1}
                        ml={2}
                        backgroundColor="#FFFFFF"
                        onClick={() => {
                          arrayHelpers.remove(index);
                        }}
                      >
                        Remove
                      </Button>
                    ) : null}
                  </Box>
                ))}
                {isEditing && (
                  <Button
                    height={'2.7rem'}
                    variant="secondaryLight"
                    backgroundColor={'#FFFFFF'}
                    borderColor="#1083CB"
                    mt="lg"
                    leftIcon="add"
                    onClick={() => {
                      if (clinicians.length > 0) {
                        if (clinicians[clinicians.length - 1].id !== '') {
                          arrayHelpers.push({ id: '' });
                        }
                      } else {
                        arrayHelpers.push({ id: '' });
                      }
                    }}
                  >
                    Add Clinician
                  </Button>
                )}
              </Box>
            )}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default ClinicalCareTeamForm;
