import { Prompt, useParams } from 'react-router';
import {
  Box,
  Text,
  Flex,
  Button,
  Heading,
  Select,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  VStack,
  useDisclosure,
} from '@rhythm/components';
import { Formik, Form, FormikHelpers, Field, FormikErrors } from 'formik';
import { Surface } from '../Surface';
import { NumberInput } from '../NumberInput';
import { savedNotes } from './savedNotes';
import { useCreateNote } from '~src/services/notes';
import { useCreateMonthlyContact, useCreatePatientTime } from '~src/services/patients';
import { Patient } from '~generated';
import RoleBasedRoutes from '../RoleBasedRoutes';
import { createPatientRoles } from '~src/constants/roles';
import { useState } from 'react';
import { getTimeZoneShortCode } from '~src/utils';

interface InitialValues {
  noteContent: string;
  contacted: boolean;
  timeAccrued: number;
}

type AddNoteProps = {
  data?: Patient;
};

export function AddNote({ data }: AddNoteProps) {
  const { id: patientId } = useParams<{ id: string }>();
  const createNote = useCreateNote();
  const createPatientTime = useCreatePatientTime();
  const [textAreaSize, setTextAreaSize] = useState('5');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const createMonthlyContact = useCreateMonthlyContact();
  const lastChanged: any = data;

  const initialValues = {
    noteContent: '',
    contacted: data?.hasMonthlyContact ? data.hasMonthlyContact.hasMonthlyContact : false,
    timeAccrued: 0,
  };

  const handleSubmit = async (
    { noteContent, timeAccrued, contacted }: InitialValues,
    helpers: FormikHelpers<InitialValues>
  ) => {
    const operations: Promise<unknown>[] = [
      createNote.mutateAsync({
        patientId,
        notes: noteContent,
        minutes: timeAccrued,
      }),
    ];

    if (timeAccrued > 0) {
      operations.push(createPatientTime.mutateAsync({ patientId, timeAccrued }));
    }

    await Promise.all(operations);

    helpers.resetForm({ values: { contacted, noteContent: '', timeAccrued: 0 } });
    setTextAreaSize('5');
  };

  const validateFunction = (values: InitialValues) => {
    const errors = {} as Partial<InitialValues>;
    if (!values.noteContent) {
      errors.noteContent = 'Required Field';
    }
    return errors;
  };

  const message = "Are you sure? You'll lose your changes!";

  const handleChange = (
    event: any,
    setFieldValue: (field: string, value: string) => Promise<void | FormikErrors<InitialValues>>,
    dirty: boolean,
    field: string
  ) => {
    if (dirty) {
      alert(message);
    }
    setFieldValue(field, event.value);
    setTextAreaSize(event.value.split('\n').length.toString());
  };
  const submitMonthlyContact = async (checked: boolean) => {
    if (checked) {
      setIsLoading(true);
      createMonthlyContact.mutateAsync(patientId);
    }
  };
  return (
    <Surface>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validateFunction}>
        {({ setFieldValue, dirty }) => (
          <Form>
            <Prompt when={dirty} message={message} />
            <>
              <Flex justifyContent={'space-between'}>
                <Box>
                  <Heading variant="h5">Add Note</Heading>
                </Box>
                <Box w={'150px'}>
                  <Select
                    options={savedNotes}
                    name="template"
                    placeholder="Templates"
                    onChange={(event) => handleChange(event, setFieldValue, dirty, 'noteContent')}
                  />
                </Box>
              </Flex>
              <Box mt={'20px'} mb={'20px'} h={'fit-content'}>
                <Field
                  as="textarea"
                  rows={textAreaSize}
                  name="noteContent"
                  placeholder="Notes..."
                  className="chakra-textarea"
                  style={{
                    height: '100%',
                    width: '100%',
                    outline: '2px solid transparent',
                    boxShadow: '0px 0.5px 1px rgb(0 0 0 / 10%)',
                    borderColor: '#B8C0CE',
                    borderWidth: '0.5px',
                    paddingInlineStart: '1rem',
                    paddingInlineEnd: '1rem',
                    borderRadius: '4px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                  }}
                />
              </Box>
              <Flex justifyContent="space-between">
                <RoleBasedRoutes allowedRoles={createPatientRoles}>
                  <Box>
                    {initialValues.contacted ? (
                      <Checkbox isChecked={true} isDisabled={true} name="contacted">
                        Made contact
                      </Checkbox>
                    ) : (
                      <Popover
                        isOpen={isOpen}
                        onOpen={onOpen}
                        onClose={onClose}
                        placement="bottom-start"
                      >
                        <PopoverTrigger>
                          <Button
                            bg={'transparent'}
                            _hover={{ bg: 'transparent' }}
                            h={'35px'}
                            w={'135px'}
                            ml={'-12px'}
                            pt={'0px'}
                          >
                            <Text h={'20px'}>
                              <input
                                type="checkbox"
                                checked={false}
                                id="myCheckbox"
                                readOnly
                                style={{ fontSize: '16px' }}
                              />
                            </Text>
                            <Text ml={'2'} mt={'-1'}>
                              Made contact
                            </Text>
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent w="180px" border={0}>
                          <PopoverArrow bg={'neutral.black'} />
                          <VStack
                            alignItems="flex-start"
                            spacing="xl"
                            bg="neutral.black"
                            p="xl"
                            w="full"
                            borderRadius="md"
                            borderWidth="x1"
                            borderColor="neutral.400"
                            boxShadow="0px 2px 16px rgba(108, 119, 137, 0.32)"
                            paddingTop={'20px'}
                          >
                            <Box>
                              <Text fontSize="12" color="white">
                                {' '}
                                Are you sure?
                              </Text>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" w={'150px'}>
                              <Button
                                bg={'transparent'}
                                mr={'2'}
                                size="sm"
                                _hover={{ bg: '#a09f9f4d' }}
                                onClick={() => {
                                  onClose();
                                }}
                              >
                                No
                              </Button>
                              <Button
                                type="submit"
                                form="escalate-form"
                                onClick={() => submitMonthlyContact(true)}
                                size="sm"
                                isLoading={isLoading}
                              >
                                Yes
                              </Button>
                            </Box>
                          </VStack>
                        </PopoverContent>
                      </Popover>
                    )}
                    <Box>
                      {data?.lastChangedBy?.firstName} {data?.lastChangedBy?.lastName} <br />
                      {lastChanged?.madeContactLastChangedDate ? (
                        <>
                          {lastChanged?.madeContactLastChangedDate}{' '}
                          {getTimeZoneShortCode(data?.address?.timeZone as string)}
                        </>
                      ) : null}
                    </Box>
                  </Box>
                </RoleBasedRoutes>
                <Box>
                  <Flex>
                    <NumberInput name="timeAccrued" />
                    <Text ml={'10px'} mt={'2'}>
                      Min
                    </Text>
                    <RoleBasedRoutes allowedRoles={createPatientRoles}>
                      <Button
                        ml={'20px'}
                        h={'35px'}
                        type="submit"
                        disabled={createNote.isLoading || createPatientTime.isLoading}
                        isLoading={createNote.isLoading || createPatientTime.isLoading}
                        loadingText="Adding note..."
                      >
                        Add Note
                      </Button>
                    </RoleBasedRoutes>
                  </Flex>
                </Box>
              </Flex>
            </>
          </Form>
        )}
      </Formik>
    </Surface>
  );
}
