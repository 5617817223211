import React, { createContext, useState } from 'react';

interface MyContextType {
  myState: boolean;
  setMyState: React.Dispatch<React.SetStateAction<boolean>>;
  setFastReviewState: React.Dispatch<
    React.SetStateAction<{
      error: boolean;
      totalReviewed: number;
      notReviewed: number;
      totalCount: number;
      bulkReview: boolean;
    }>
  >;
  fastReviewState: {
    error: boolean;
    totalReviewed: number;
    notReviewed: number;
    totalCount: number;
    bulkReview: boolean;
  };
}

export const MyContext = createContext<MyContextType>({
  myState: false,
  setMyState: () => false,
  fastReviewState: {
    totalReviewed: 0,
    notReviewed: 0,
    error: false,
    totalCount: 0,
    bulkReview: false,
  },
  setFastReviewState: () => ({
    totalReviewed: 0,
    notReviewed: 0,
    error: false,
    totalCount: 0,
    bulkReview: false,
  }),
});

export const MyContextProvider: React.FC = ({ children }) => {
  const [myState, setMyState] = useState(false);
  const [fastReviewState, setFastReviewState] = useState({
    totalReviewed: 0,
    notReviewed: 0,
    error: false,
    totalCount: 0,
    bulkReview: false,
  });

  return (
    <MyContext.Provider value={{ myState, setMyState, fastReviewState, setFastReviewState }}>
      {children}
    </MyContext.Provider>
  );
};
